import * as React from 'react'
import {ReactElement, useLayoutEffect} from 'react'
import Container, {ContainerProps} from "react-bootstrap/Container";
import {useLocation} from "@reach/router";
import AuthModal from "../auth/AuthModal";
import Header from "../Header";
import Footer from "../Footer";

type Props = {
    hasLeftMenu?: boolean,
    renderLeftMenu?: (onItemClicked: () => void) => ReactElement,
    hasNabBottomMenu?: boolean,
    renderNavBottomMenu?: () => ReactElement,
} & ContainerProps

export default function ContentContainer(props: Props) {
    const location = useLocation();
    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return <>
        <AuthModal/>
        <Header
            hasLeftMenu={props.hasLeftMenu}
            renderLeftMenu={props.renderLeftMenu}
            hasBottomMenu={props.hasNabBottomMenu}
            renderBottomMenu={props.renderNavBottomMenu}
        />
        <Container fluid={true} className={'main-container'}>
            <Container className={'full-height content-container'} {...props}/>
            <Footer/>
        </Container>

    </>
}
