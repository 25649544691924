import * as React from 'react';
import CommonMenu, {ModuleItemProps} from '../common/Menu';
import {
    AdminFullCourse, AdminFullCourseModule,
    CourseModule,
    TeacherCourseModule,
    TeacherFullCourse,
    UserCourseModule
} from "../../api/generated";
import Card from "react-bootstrap/Card";
import CommonHorizontalMenu, {HorizontalItemProps, HorizontalModuleItemProps} from "../common/HorizontalMenu";
import {useEffect, useRef} from "react";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";

type AdminModuleItemProps = {
    onModuleSelect: (mod: CourseModule) => void,
} & ModuleItemProps<CourseModule>

function ModuleItem({mod, isActive, onClick, onModuleSelect}: AdminModuleItemProps) {
    const className = isActive ? 'text-primary' : undefined
    return (
        <Card.Body className={'course-menu__module d-flex flex-row justify-content-between align-items-center'}
                   onClick={() => {
                       onModuleSelect(mod);
                       onClick(mod)
                   }}>
            <span className={className}>{mod.name}</span>

        </Card.Body>
    )
}

type Props = {
    course?: AdminFullCourse,
    loading: boolean,
    onModuleSelect: (mod: CourseModule) => void,
    initialModuleId?: number,
    onModuleAdd: () => void,
    onUserGroups: () => void,
    onUsers: () => void,
    onDiscussions: () => void,
}

function AddModule({onClick}: { onClick: () => void }) {
    return <Card.Body onClick={onClick}
                      className={'course-menu__module d-flex flex-row justify-content-between align-items-center'}>
        <span>Добавить модуль</span>
        <img src={'/images/add.svg'} className={'small-icon'}/>
    </Card.Body>
}

function HorizontalAddModule({onClick, isActive, scrollRef}: HorizontalItemProps) {
    const itemRef = useRef<HTMLDivElement | null>(null)
    const className = isActive ? 'text-primary' : undefined
    useEffect(() => {
        if (isActive) {
            if (scrollRef.current && itemRef.current) {
                const currentElementOffset = itemRef.current.offsetLeft
                const currentElementWidth = itemRef.current.clientWidth
                const windowWidth = window.innerWidth
                const offset = currentElementOffset - windowWidth / 2 + currentElementWidth / 2
                scrollRef.current.scrollTo({
                    behavior: "smooth",
                    top: 0,
                    left: offset,
                })
            }
        }
    }, [isActive])

    return (
        <div ref={itemRef} className={'scroll-menu-item'} onClick={onClick}>
            <span className={className}>Добавить модуль</span>
            <img src={'/images/add.svg'} className={'small-icon ml-1'}/>
        </div>
    )
}

export default function Menu(props: Props) {
    return <CommonMenu
        displayAboutCourse={true}
        aboutCourseLink={props.course ? `/admin/course/${props.course.id}` : undefined}
        {...props}
        onActiveModuleChanged={() => {
        }}
        renderModuleItem={(mod: CourseModule, isActive, onClick) => <ModuleItem mod={mod}
                                                                                onModuleSelect={props.onModuleSelect}
                                                                                isActive={isActive}
                                                                                onClick={onClick}/>}
        bottom={<AddModule onClick={props.onModuleAdd}/>}

        additionalMenuItems={<>
            <ListGroup.Item className={'as-link'} onClick={() => props.onUsers()}>
                <Card.Body className={'d-flex flex-row justify-content-between align-items-center'}>
                    <span>Пользователи</span>
                </Card.Body>
            </ListGroup.Item>
            <ListGroup.Item className={'as-link'} onClick={() => props.onUserGroups()}>
                <Card.Body className={'d-flex flex-row justify-content-between align-items-center'}>
                    <span>Группы</span>
                </Card.Body>
            </ListGroup.Item>
            <ListGroup.Item className={'as-link'} onClick={() => props.onDiscussions()}>
                <Card.Body className={'d-flex flex-row justify-content-between align-items-center'}>
                    <span>Обсуждения</span>
                </Card.Body>
            </ListGroup.Item>
        </>}
    />
}

function HorizontalModuleItem({mod, onClick, isActive, scrollRef}: HorizontalModuleItemProps<CourseModule>) {
    const itemRef = useRef<HTMLDivElement | null>(null)
    const className = isActive ? 'text-primary' : undefined
    useEffect(() => {
        if (isActive) {
            if (scrollRef.current && itemRef.current) {
                const currentElementOffset = itemRef.current.offsetLeft
                const currentElementWidth = itemRef.current.clientWidth
                const windowWidth = window.innerWidth
                const offset = currentElementOffset - windowWidth / 2 + currentElementWidth / 2
                scrollRef.current.scrollTo({
                    behavior: "smooth",
                    top: 0,
                    left: offset,
                })
            }
        }
    }, [isActive])

    return (
        <div ref={itemRef} className={'scroll-menu-item'} onClick={() => onClick(mod)}>
            <span className={className}>{mod.name}</span>
        </div>
    )
}


type HorizontalProps = {
    initialModuleId?: number
    course?: AdminFullCourse,
    loading: boolean,
    onActiveModuleChanged: (mod: CourseModule | null) => void,
    onModuleAdd: () => void,
}

export function HorizontalMenu(props: HorizontalProps) {
    if (props.course === undefined) {
        return null
    }
    return <CommonHorizontalMenu {...props}
                                 displayAboutCourse={true}
                                 aboutCourseLink={props.course ? `/admin/course/${props.course.id}` : undefined}
                                 renderEndElement={((isActive, onClick, scrollRef) =>
                                     <HorizontalAddModule isActive={isActive} onClick={() => {
                                         onClick()
                                         props.onModuleAdd()
                                     }} scrollRef={scrollRef}/>)}
                                 renderModuleItem={(mod: CourseModule, isActive, onClick, scrollRef) =>
                                     <HorizontalModuleItem key={`navModuleItem-${mod.id}`} mod={mod} isActive={isActive}
                                                           onClick={onClick} scrollRef={scrollRef}/>}
    />
}