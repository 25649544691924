import {Portfolio} from "../../api/generated";
import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export function Header(props: { portfolio: Portfolio }) {
    return <Row className={'mb-4'}>
        <Col xs={12} md={4}>
            <div className={"d-flex flex-column align-items-center m-4"}>
                <img className={"choose-photo__img rounded-circle"} src={props.portfolio!.user.photo
                    ? UPLOAD_BASE_PATH + props.portfolio!.user.photo : "/images/choose-photo-empty.png"}/>
                <span className={"mt-4"}>{props.portfolio!.user.name}</span>
            </div>
        </Col>
        <Col xs={12} md={8} className={'d-flex justify-content-center align-items-center'}>
            <h4>Портфолио на курсе &laquo;{props.portfolio!.course.name}&raquo;</h4>
        </Col>
    </Row>;
}