import * as React from "react";
import {ReactElement, useMemo} from "react";
import {Section} from "./Section";
import {PortfolioModule} from "../../../api/generated";
import UserDiscussionTopicList from "./UserDiscussionTopicList";
import Card from "react-bootstrap/Card";

type ModuleProps = {
    userId?: number;
    courseId: number;
    module: PortfolioModule;
}

export function Module({module, courseId, userId}: ModuleProps) {
    const sections = useMemo(() => {
        if (module.sections === undefined) {
            return []
        }

        return module.sections.filter(s => !!s.submodules)
    }, [module])
    return <>
        <h5 id={`${module.id}-${module.name}`}>{module.name}</h5>
        {sections.map(s => <Section section={s} courseId={courseId} userId={userId}/>)}
        {module.discussion_topics && module.discussion_topics.length > 0 && <div className={'mt-4 ml-4'}>
            <hr/>
            <h6>Участие в обсуждениях</h6>
            <UserDiscussionTopicList topics={module.discussion_topics}/>
        </div>
        }
        <hr className={'hr--divider'}/>
    </>
}