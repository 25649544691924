import * as React from 'react';
import {ReactElement, useContext, useEffect, useRef, useState, Suspense} from 'react';
import * as yup from "yup";
import {AdminFullCourseModule, CourseModuleAvailabilityStrategy, CreateModuleParams} from "../../api/generated";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import Form from 'react-bootstrap/Form';
import ErrorHandler from "../ErrorHandler";
import ApiFactory from "../../api/ApiFactory";
import {mutate} from "swr";
import {AuthContext} from "../auth/AuthContext";
import Card from 'react-bootstrap/Card';
import {ModuleAvailabilityStrategyEnum} from "../common/types";

import DictionarySelect from "../common/DictionarySelect";
import {LineLoader} from "../Loaders";

const DatePicker = React.lazy(() => import("react-datepicker"))

type Props = {
    courseId: number,
    onSucceed: (mod: AdminFullCourseModule) => void,
    onCancel: () => void,
}

type FormParams = {
    startTime?: string,
    isAvailable?: boolean,
} & CreateModuleParams

const formValidationSchema = yup.object<FormParams>({
    course_id: yup.number().required(),
    name: yup.string().required(),
    weight: yup.number().required(),
    course_module_availability_strategy_id: yup.number().required(),
    course_module_availability_strategy_data: yup.mixed(),
})

export default function ModuleCreate({courseId, onSucceed, onCancel}: Props) {
    const form = useRef<HTMLFormElement>(null)
    const {userId} = useContext(AuthContext);
    const [error, setError] = useState<Error | undefined>(undefined);

    const [strategyId, setStrategyId] = useState<number>(ModuleAvailabilityStrategyEnum.Auto);

    const [startTime, setStartTime] = useState<Date>(new Date());
    const [availableStrategies, setAvailableStrategies] = useState<CourseModuleAvailabilityStrategy[]>([])
    useEffect(() => {
        ApiFactory.Instance().StaticAPI().staticGet(["course_module_availability_strategy",])
            .then(resp => {
                if (resp.course_module_availability_strategy === undefined) {
                    setAvailableStrategies([])
                } else {
                    setAvailableStrategies(resp.course_module_availability_strategy)
                }
            })
    }, [])

    const {register, handleSubmit, errors, setValue, reset} = useForm<FormParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            name: "",
            weight: 100,
            course_id: courseId,
            startTime: startTime.toISOString(),
            isAvailable: true,
            course_module_availability_strategy_id: strategyId,
        }
    });

    useEffect(() => {
        register("startTime")
        register("course_id")
    }, [])

    const onSubmit = (params: FormParams) => {
        if (params.course_module_availability_strategy_id == ModuleAvailabilityStrategyEnum.ByTime && params.startTime === undefined) {
            setError(new Error("Необходимо заполнить время доступности"))
        }

        if (params.course_module_availability_strategy_id == ModuleAvailabilityStrategyEnum.Manual && params.isAvailable === undefined) {
            setError(new Error("Необходимо заполнить признак доступности"))
        }

        let typeParams = {}
        switch (params.course_module_availability_strategy_id) {
            case ModuleAvailabilityStrategyEnum.Auto:
                typeParams = {}
                break;
            case ModuleAvailabilityStrategyEnum.ByTime:
                typeParams = {
                    start_time: params.startTime
                }
                break;
            case ModuleAvailabilityStrategyEnum.Manual:
                typeParams = {
                    is_available: params.isAvailable
                }
        }

        setError(undefined)
        ApiFactory.Instance().CourseModuleAPI().adminCreateCourseModule({
            course_id: params.course_id,
            name: params.name,
            weight: params.weight,
            course_module_availability_strategy_id: params.course_module_availability_strategy_id,
            course_module_availability_strategy_data: typeParams,
        })
            .then(m => {
                mutate(["admin/course", courseId, userId])
                onSucceed(m);
            }).catch(e => setError(e))
    }

    let strategyContent: ReactElement | null = null;

    switch (strategyId) {
        case ModuleAvailabilityStrategyEnum.Manual:
            strategyContent = <Form.Group>
                <Form.Label>Модуль доступен</Form.Label>
                <Form.Check type={"checkbox"} name={"isAvailable"} ref={register}/>
                {errors && errors.isAvailable &&
                <Form.Text
                    className={'text-danger'}>{errors.isAvailable.message}</Form.Text>}
            </Form.Group>
            break;
        case ModuleAvailabilityStrategyEnum.ByTime:
            strategyContent = <Form.Group>
                <Form.Label>Дата с которой доступен модуль</Form.Label>
                <Suspense fallback={<LineLoader/>}>
                    <DatePicker dateFormat={'dd.MM.yyyy'} selected={startTime} className={'form-control'}
                                onChange={val => {
                                    const res = val as Date
                                    res.setHours(0, 0, 0, 0)
                                    setValue("startTime", res.toISOString())
                                    setStartTime(res)
                                }}/>
                </Suspense>

                {errors && errors.startTime &&
                <Form.Text
                    className={'text-danger'}>{errors.startTime.message}</Form.Text>}
            </Form.Group>
            break;
    }

    return <Card className={'mt-4'}>
        <Card.Body>
            {error && <ErrorHandler error={error}/>}
            <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
                <div className={'d-flex flex-row align-items-center justify-content-between'}>
                    <h5>Добавление модуля</h5>
                    <div className={'d-flex flex-row'}>
                        <img className={'button-icon ml-4 as-link'} src={"/images/cancel.svg"}
                             onClick={onCancel}/>
                        <img className={'button-icon ml-4 as-link'} src={"/images/save.svg"}
                             onClick={() => {
                                     if (form.current) {
                                         form.current.dispatchEvent(new Event('submit', {cancelable: true}))
                                     }
                                 }}/>
                    </div>
                </div>

                <Form.Group>
                    <Form.Label>Название</Form.Label>
                    <Form.Control ref={register} name={"name"}/>
                    {errors && errors.name &&
                    <Form.Text className={'text-danger'}>{(errors.name as any)?.message}</Form.Text>}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Вес</Form.Label>
                    <Form.Control type={'number'} ref={register} name={"weight"}/>
                    {errors && errors.weight &&
                    <Form.Text className={'text-danger'}>{errors.weight.message}</Form.Text>}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Политика доступности модуля</Form.Label>
                    <DictionarySelect items={availableStrategies}
                                      initial={{id: ModuleAvailabilityStrategyEnum.Auto, name: "Всегда"}}
                                      propertyName={"course_module_availability_strategy_id"}
                                      register={register}
                                      setValue={setValue}
                                      onValueChanged={i => setStrategyId(i.id)}
                    />
                </Form.Group>

                {strategyContent}

            </Form>
        </Card.Body>
    </Card>
}