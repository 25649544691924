import * as React from 'react'
import {useRef, useState} from 'react'
import useSWR from "swr";
import ApiFactory, {DEFAULT_PER_PAGE} from "../../../api/ApiFactory";
import {ListLoader} from "../../../components/Loaders";
import Pagination from "../../../components/common/Pagination";
import ErrorHandler from "../../../components/ErrorHandler";
import {AdminUser} from "../../../api/generated";
import Card from "react-bootstrap/Card";
import {RouteComponentProps, useNavigate} from "@reach/router";
import ContentContainer from "../../../components/common/ContentContainer";
import FormControl from 'react-bootstrap/FormControl'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import UserPhoto from "../../../components/common/UserPhoto";
import UserStatus from "../../../components/admin/UserStatus";

type Props = {

} & RouteComponentProps

type ItemProps = {
    user: AdminUser
}

function Item(props: ItemProps) {
    const navigate = useNavigate()
    const {user} = props
    return <Card className={'m-2 as-link'} onClick={() => navigate(`/admin/user/${user.id}`)}>
        <Card.Body>
            <Row className={'align-items-center'}>
                <Col xs={{span: 4, offset: 4}} md={{span: 2, offset: 0}}><UserPhoto user={user}
                                                                                    className={'admin-user-photo'}/></Col>
                <Col xs={12} md={4} className={'mt-1'}><h6>{user.username}</h6></Col>
                <Col xs={12} md={3} className={'mt-1'}><span>{user.name}</span></Col>
                <Col xs={12} md={3} className={'mt-1'}><UserStatus status={user.status}/></Col>
            </Row>
        </Card.Body>
    </Card>
}

export default function Index(props: Props) {
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [query, setQuery] = useState<string|undefined>(undefined)
    const {data: users, error} = useSWR(['admin/users', currentPage, query],
        () => ApiFactory.Instance().UserAPI().adminUserList(query, undefined, currentPage, DEFAULT_PER_PAGE))
    const loading = users === undefined && error === undefined

    const searchRef = useRef<HTMLInputElement|null>(null)
    const items = users?.items
    const total = users?.total
    return <ContentContainer>
        {loading && <ListLoader/>}
        {error && <ErrorHandler error={error}/>}
        <Row className={'mt-2 mb-4'}>
            <Col xs={8}>
                <FormControl ref={searchRef}/>
            </Col>
            <Col xs={4}>
                <Button
                    variant={'primary'}
                    className={'text-white'}
                    block={true}
                    onClick={() => {
                        if (searchRef.current) {
                            setQuery(searchRef.current.value)
                        }
                    }}
                >Поиск</Button>
            </Col>
        </Row>
        {items && items.map(ui => <Item key={`user-${ui.id}`} user={ui}/>)}
        <div className={'d-flex direction-row justify-content-center'}>
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalItems={total}/>
        </div>
    </ContentContainer>
}