import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import * as React from "react";

type MenuProps = {
    items: { id: number, name: string }[]
}

export function Menu(props: MenuProps) {
    return <Card>
        <ListGroup variant={'flush'}>
            <ListGroup.Item>
                <div>
                    {props.items.map(i =>
                        <div className={'course-menu__section d-flex justify-content-between align-items-center'}>
                            <a href={`#${i.id}-${i.name}`}><span>{i.name}</span></a>
                        </div>
                    )}
                    <div className={'course-menu__section d-flex justify-content-between align-items-center'}
                         onClick={() => {
                         }}>
                        <a href={`#course-discussions`}><span>Участие в обсуждениях</span></a>
                    </div>
                </div>
            </ListGroup.Item>
        </ListGroup>
    </Card>
}