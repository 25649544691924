import * as React from 'react';
import {useEffect, useRef} from 'react';
import {AvailabilityStatus, UserCourseModule, UserFullCourse} from "../../api/generated";
import CommonMenu, {ModuleItemProps} from "../common/Menu";
import Nav from 'react-bootstrap/Nav';
import CommonNavMenu from '../common/NavMenu'
import CommonHorizontalMenu, {HorizontalModuleItemProps} from '../common/HorizontalMenu';
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import {Link} from "@reach/router";

type Props = {
    initialModuleId?: number
    course?: UserFullCourse,
    loading: boolean,
    onActiveModuleChanged: (mod: UserCourseModule | null) => void,
    onMyPortfolio: () => void,
    onOtherUserPortfolio: () => void,
}

interface AvailabilityStatusContainer {
    availability_status: AvailabilityStatus,
    finished: boolean,
}

function AvailabilityIcon({model}: { model: AvailabilityStatusContainer }) {
    let icon;
    if (model.finished) {
        icon = '/images/finished.png';
    } else if (!model.availability_status.available) {
        icon = '/images/locked.png';
    }

    return icon !== undefined
        ? <img className={'course-menu__availability-icon'} src={icon}/>
        : null;
}

function NavModuleItem({mod, onClick, isActive}: ModuleItemProps<UserCourseModule>) {
    let sectionClassName = 'd-flex justify-content-between align-items-center';
    if (isActive) {
        sectionClassName += " text-primary"
    }
    return (
        <Nav.Item key={`moduleItem-${mod.id}`} className={sectionClassName}
                  onClick={() => onClick(mod)}>
            <span>{mod.name}</span>
            <AvailabilityIcon model={mod}/>
        </Nav.Item>
    )
}

function ModuleItem({mod, onClick, isActive}: ModuleItemProps<UserCourseModule>) {
    let sectionClassName = 'course-menu__section d-flex justify-content-between align-items-center';
    if (isActive) {
        sectionClassName += " text-primary"
    }
    return (
        <div className={sectionClassName}
             onClick={() => onClick(mod)}>
            <span className={isActive ? 'text-primary' : undefined}>{mod.name}</span>
            <AvailabilityIcon model={mod}/>
        </div>
    )
}

function HorizontalModuleItem({mod, onClick, isActive, scrollRef}: HorizontalModuleItemProps<UserCourseModule>) {
    const itemRef = useRef<HTMLDivElement|null>(null)

    useEffect(() => {
        if (isActive) {
            if (scrollRef.current && itemRef.current) {
                const currentElementOffset = itemRef.current.offsetLeft
                const currentElementWidth = itemRef.current.clientWidth
                const windowWidth = window.innerWidth
                const offset = currentElementOffset - windowWidth / 2 + currentElementWidth / 2
                scrollRef.current.scrollTo({
                    behavior: "smooth",
                    top: 0,
                    left: offset,
                })
            }
        }
    }, [isActive])

    return (
        <div ref={itemRef} className={'scroll-menu-item'} onClick={() => onClick(mod)}>
            <AvailabilityIcon model={mod}/>
            <span className={isActive ? 'text-primary' : undefined}>{mod.name}</span>
        </div>
    )
}


export function HorizontalMenu(props: Props) {
    if (props.course === undefined) {
        return null
    }
    return <CommonHorizontalMenu {...props}
                                 displayAboutCourse={true}
                                 additionalMenuItems={<>
                                     <Link to={`/user/portfolio/${props.course.id}`}><span className={'scroll-menu-item'}>Мое портфолио</span></Link>
                                 </>}
                                 aboutCourseLink={props.course ? `/user/course/${props.course.id}` : undefined}
                                 renderModuleItem={(mod: UserCourseModule, isActive, onClick, scrollRef) =>
                              <HorizontalModuleItem key={`navModuleItem-${mod.id}`} mod={mod} isActive={isActive} onClick={onClick} scrollRef={scrollRef}/>}
    />
}


export function NavMenu(props: Props) {
    if (props.course === undefined) {
        return null
    }
    return <CommonNavMenu {...props}
                          aboutCourseLink={props.course ? `/user/course/${props.course.id}` : undefined}
                          renderModuleItem={(mod: UserCourseModule, isActive, onClick) =>
                              <NavModuleItem key={`navModuleItem-${mod.id}`} mod={mod} isActive={isActive} onClick={onClick}/>}
    />
}

export default function Menu(props: Props) {
    if (props.course === undefined) {
        return null
    }
    return <CommonMenu {...props}
                       displayAboutCourse={true}
                       aboutCourseLink={props.course ? `/user/course/${props.course.id}` : undefined}
                       additionalMenuItems={<>
                           <ListGroup.Item className={'as-link'} onClick={() => props.onMyPortfolio()}>
                               <div className={'as-link course-menu__section d-flex flex-row justify-content-between align-items-center'}>
                                   <span>Мое портфолио</span>
                               </div>
                           </ListGroup.Item>
                           {/*<ListGroup.Item className={'as-link'} onClick={() => props.onOtherUserPortfolio()}>*/}
                           {/*    <Card.Body className={'d-flex flex-row justify-content-between align-items-center'}>*/}
                           {/*        <span>Портфолио других пользователей</span>*/}
                           {/*    </Card.Body>*/}
                           {/*</ListGroup.Item>*/}
                       </>}
                       renderModuleItem={(mod: UserCourseModule, isActive, onClick) =>
                           <ModuleItem key={`moduleItem-${mod.id}`} mod={mod} isActive={isActive} onClick={onClick}/>}
    />
}