/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Courses portal
 * API for courses portal
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "http://courses-portal.local:34000/v1".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * Список курсов для учителя (админа)
 * @export
 * @interface AdminCourseList
 */
export interface AdminCourseList {
    /**
     * 
     * @type {Array<AdminShortCourse>}
     * @memberof AdminCourseList
     */
    items: Array<AdminShortCourse>;
    /**
     * 
     * @type {number}
     * @memberof AdminCourseList
     */
    total: number;
}

/**
 * Список обсуждений
 * @export
 * @interface AdminDiscussionList
 */
export interface AdminDiscussionList {
    /**
     * 
     * @type {Array<ShortAdminDiscussion>}
     * @memberof AdminDiscussionList
     */
    items: Array<ShortAdminDiscussion>;
    /**
     * 
     * @type {number}
     * @memberof AdminDiscussionList
     */
    total: number;
}

/**
 * 
 * @export
 * @interface AdminDiscussionTopicList
 */
export interface AdminDiscussionTopicList {
    /**
     * 
     * @type {Array<ShortAdminDiscussionTopic>}
     * @memberof AdminDiscussionTopicList
     */
    items: Array<ShortAdminDiscussionTopic>;
    /**
     * 
     * @type {number}
     * @memberof AdminDiscussionTopicList
     */
    total: number;
}

/**
 * Модуль курса (администратор)
 * @export
 * @interface AdminFullCourseModule
 */
export interface AdminFullCourseModule {
    /**
     * 
     * @type {number}
     * @memberof AdminFullCourseModule
     */
    id: number;
    /**
     * 
     * @type {ModuleBreadcrumbs}
     * @memberof AdminFullCourseModule
     */
    breadcrumbs: ModuleBreadcrumbs;
    /**
     * 
     * @type {string}
     * @memberof AdminFullCourseModule
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof AdminFullCourseModule
     */
    weight: number;
    /**
     * 
     * @type {Array<FullCourseSection>}
     * @memberof AdminFullCourseModule
     */
    sections: Array<FullCourseSection>;
    /**
     * 
     * @type {CourseModuleAvailabilityStrategy}
     * @memberof AdminFullCourseModule
     */
    course_module_availability_strategy: CourseModuleAvailabilityStrategy;
    /**
     * 
     * @type {any}
     * @memberof AdminFullCourseModule
     */
    course_module_availability_strategy_data: any;
}

/**
 * Подмодуль курса - полное описание (для администратора)
 * @export
 * @interface AdminFullCourseSubmodule
 */
export interface AdminFullCourseSubmodule {
    /**
     * 
     * @type {number}
     * @memberof AdminFullCourseSubmodule
     */
    id: number;
    /**
     * 
     * @type {SubmoduleBreadcrumbs}
     * @memberof AdminFullCourseSubmodule
     */
    breadcrumbs: SubmoduleBreadcrumbs;
    /**
     * 
     * @type {CourseSubmoduleType}
     * @memberof AdminFullCourseSubmodule
     */
    course_submodule_type: CourseSubmoduleType;
    /**
     * 
     * @type {any}
     * @memberof AdminFullCourseSubmodule
     */
    course_submodule_type_data?: any;
    /**
     * 
     * @type {CourseSubmodulePointsStrategy}
     * @memberof AdminFullCourseSubmodule
     */
    course_submodule_points_strategy: CourseSubmodulePointsStrategy;
    /**
     * 
     * @type {any}
     * @memberof AdminFullCourseSubmodule
     */
    course_submodule_points_strategy_data?: any;
    /**
     * 
     * @type {CourseSubmodulePeriodStrategy}
     * @memberof AdminFullCourseSubmodule
     */
    course_submodule_period_strategy: CourseSubmodulePeriodStrategy;
    /**
     * 
     * @type {any}
     * @memberof AdminFullCourseSubmodule
     */
    course_submodule_period_strategy_data?: any;
    /**
     * 
     * @type {boolean}
     * @memberof AdminFullCourseSubmodule
     */
    is_verification_anonymous: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminFullCourseSubmodule
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof AdminFullCourseSubmodule
     */
    duration: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminFullCourseSubmodule
     */
    optional: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminFullCourseSubmodule
     */
    has_deadline: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminFullCourseSubmodule
     */
    deadline_time?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminFullCourseSubmodule
     */
    is_available_in_my_portfolio: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminFullCourseSubmodule
     */
    is_available_in_other_user_portfolio: boolean;
}

/**
 * 
 * @export
 * @interface AdminFullQuiz
 */
export interface AdminFullQuiz {
    /**
     * 
     * @type {number}
     * @memberof AdminFullQuiz
     */
    id: number;
    /**
     * 
     * @type {QuizType}
     * @memberof AdminFullQuiz
     */
    type: QuizType;
    /**
     * 
     * @type {Array<AdminQuizQuestion>}
     * @memberof AdminFullQuiz
     */
    questions: Array<AdminQuizQuestion>;
}

/**
 * 
 * @export
 * @interface AdminQuizAnswer
 */
export interface AdminQuizAnswer {
    /**
     * 
     * @type {number}
     * @memberof AdminQuizAnswer
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AdminQuizAnswer
     */
    answer: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminQuizAnswer
     */
    is_correct: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminQuizAnswer
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminQuizAnswer
     */
    result_description?: string;
}

/**
 * 
 * @export
 * @interface AdminQuizQuestion
 */
export interface AdminQuizQuestion {
    /**
     * 
     * @type {number}
     * @memberof AdminQuizQuestion
     */
    id: number;
    /**
     * 
     * @type {QuizQuestionType}
     * @memberof AdminQuizQuestion
     */
    type: QuizQuestionType;
    /**
     * 
     * @type {string}
     * @memberof AdminQuizQuestion
     */
    question: string;
    /**
     * 
     * @type {number}
     * @memberof AdminQuizQuestion
     */
    points?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminQuizQuestion
     */
    is_user_comment_required: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminQuizQuestion
     */
    is_fixed_points: boolean;
    /**
     * 
     * @type {Array<AdminQuizAnswer>}
     * @memberof AdminQuizQuestion
     */
    answers?: Array<AdminQuizAnswer>;
}

/**
 * 
 * @export
 * @interface AdminShortQuiz
 */
export interface AdminShortQuiz {
    /**
     * 
     * @type {number}
     * @memberof AdminShortQuiz
     */
    id: number;
    /**
     * 
     * @type {QuizType}
     * @memberof AdminShortQuiz
     */
    type: QuizType;
}

/**
 * Пользователь
 * @export
 * @interface AdminUser
 */
export interface AdminUser {
    /**
     * 
     * @type {number}
     * @memberof AdminUser
     */
    id: number;
    /**
     * 
     * @type {UserStatus}
     * @memberof AdminUser
     */
    status: UserStatus;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    photo?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    about?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUser
     */
    roles: Array<string>;
}

/**
 * Список пользователей
 * @export
 * @interface AdminUserList
 */
export interface AdminUserList {
    /**
     * 
     * @type {Array<AdminUser>}
     * @memberof AdminUserList
     */
    items: Array<AdminUser>;
    /**
     * 
     * @type {number}
     * @memberof AdminUserList
     */
    total: number;
}

/**
 * 
 * @export
 * @interface AvailabilityStatus
 */
export interface AvailabilityStatus {
    /**
     * 
     * @type {boolean}
     * @memberof AvailabilityStatus
     */
    available: boolean;
    /**
     * 
     * @type {number}
     * @memberof AvailabilityStatus
     */
    reason?: number;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityStatus
     */
    start_time?: string;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityStatus
     */
    message?: string;
}

/**
 * Список доступных курсов
 * @export
 * @interface AvailableCourseList
 */
export interface AvailableCourseList {
    /**
     * 
     * @type {Array<AvailableShortCourse>}
     * @memberof AvailableCourseList
     */
    items: Array<AvailableShortCourse>;
    /**
     * 
     * @type {number}
     * @memberof AvailableCourseList
     */
    total: number;
}

/**
 * Модуль курса
 * @export
 * @interface AvailableCourseModule
 */
export interface AvailableCourseModule {
    /**
     * 
     * @type {number}
     * @memberof AvailableCourseModule
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AvailableCourseModule
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof AvailableCourseModule
     */
    weight: number;
    /**
     * 
     * @type {Array<AvailableCourseSection>}
     * @memberof AvailableCourseModule
     */
    sections: Array<AvailableCourseSection>;
    /**
     * 
     * @type {CourseModuleAvailabilityStrategy}
     * @memberof AvailableCourseModule
     */
    course_module_availability_strategy: CourseModuleAvailabilityStrategy;
    /**
     * 
     * @type {any}
     * @memberof AvailableCourseModule
     */
    course_module_availability_strategy_data: any;
}

/**
 * Секция модуля
 * @export
 * @interface AvailableCourseSection
 */
export interface AvailableCourseSection {
    /**
     * 
     * @type {number}
     * @memberof AvailableCourseSection
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AvailableCourseSection
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AvailableCourseSection
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AvailableCourseSection
     */
    is_private: boolean;
}

/**
 * 
 * @export
 * @interface CommentCreateParams
 */
export interface CommentCreateParams {
    /**
     * 
     * @type {number}
     * @memberof CommentCreateParams
     */
    parent_comment_id?: number;
    /**
     * 
     * @type {string}
     * @memberof CommentCreateParams
     */
    text: string;
}

/**
 * 
 * @export
 * @interface CommentCreateParams1
 */
export interface CommentCreateParams1 {
    /**
     * 
     * @type {number}
     * @memberof CommentCreateParams1
     */
    parent_comment_id?: number;
    /**
     * 
     * @type {string}
     * @memberof CommentCreateParams1
     */
    text: string;
}

/**
 * 
 * @export
 * @interface CommentCreateParams2
 */
export interface CommentCreateParams2 {
    /**
     * 
     * @type {number}
     * @memberof CommentCreateParams2
     */
    parent_comment_id?: number;
    /**
     * 
     * @type {PurifiedString}
     * @memberof CommentCreateParams2
     */
    text: PurifiedString;
}

/**
 * 
 * @export
 * @interface CommentEditParams
 */
export interface CommentEditParams {
    /**
     * 
     * @type {string}
     * @memberof CommentEditParams
     */
    text: string;
}

/**
 * 
 * @export
 * @interface CommentEditParams1
 */
export interface CommentEditParams1 {
    /**
     * 
     * @type {string}
     * @memberof CommentEditParams1
     */
    text: string;
}

/**
 * 
 * @export
 * @interface CommentEditParams2
 */
export interface CommentEditParams2 {
    /**
     * 
     * @type {PurifiedString}
     * @memberof CommentEditParams2
     */
    text: PurifiedString;
}

/**
 * 
 * @export
 * @interface CourseCloneParams
 */
export interface CourseCloneParams {
    /**
     * 
     * @type {number}
     * @memberof CourseCloneParams
     */
    source_id: number;
    /**
     * 
     * @type {boolean}
     * @memberof CourseCloneParams
     */
    with_discussions: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseCloneParams
     */
    with_discussion_topics: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseCloneParams
     */
    with_topic_comments: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseCloneParams
     */
    with_teachers: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseCloneParams
     */
    with_admins: boolean;
}

/**
 * Информация о прохождении курса
 * @export
 * @interface CourseCompletion
 */
export interface CourseCompletion {
    /**
     * 
     * @type {number}
     * @memberof CourseCompletion
     */
    id: number;
    /**
     * 
     * @type {CourseCompletionStatus}
     * @memberof CourseCompletion
     */
    status: CourseCompletionStatus;
}

/**
 * 
 * @export
 * @interface CourseCreateParams
 */
export interface CourseCreateParams {
    /**
     * 
     * @type {CourseName}
     * @memberof CourseCreateParams
     */
    name: CourseName;
    /**
     * 
     * @type {number}
     * @memberof CourseCreateParams
     */
    min_rating?: number;
}

/**
 * 
 * @export
 * @interface CourseDescription
 */
export interface CourseDescription {
}

/**
 * 
 * @export
 * @interface CourseEditParams
 */
export interface CourseEditParams {
    /**
     * 
     * @type {CourseName}
     * @memberof CourseEditParams
     */
    name: CourseName;
    /**
     * 
     * @type {string}
     * @memberof CourseEditParams
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseEditParams
     */
    course_availability_type_id: number;
    /**
     * 
     * @type {number}
     * @memberof CourseEditParams
     */
    min_rating?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CourseEditParams
     */
    category_ids?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CourseEditParams
     */
    teacher_ids?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CourseEditParams
     */
    admin_ids?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof CourseEditParams
     */
    display_progress: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseEditParams
     */
    is_portfolio_available: boolean;
}

/**
 * Модуль курса
 * @export
 * @interface CourseModule
 */
export interface CourseModule {
    /**
     * 
     * @type {number}
     * @memberof CourseModule
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CourseModule
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CourseModule
     */
    weight: number;
    /**
     * 
     * @type {Array<ShortCourseSection>}
     * @memberof CourseModule
     */
    sections: Array<ShortCourseSection>;
    /**
     * 
     * @type {CourseModuleAvailabilityStrategy}
     * @memberof CourseModule
     */
    course_module_availability_strategy: CourseModuleAvailabilityStrategy;
    /**
     * 
     * @type {any}
     * @memberof CourseModule
     */
    course_module_availability_strategy_data: any;
}

/**
 * 
 * @export
 * @interface CourseModuleName
 */
export interface CourseModuleName {
}

/**
 * 
 * @export
 * @interface CourseName
 */
export interface CourseName {
}

/**
 * 
 * @export
 * @interface CourseNotifyUsersParams
 */
export interface CourseNotifyUsersParams {
    /**
     * 
     * @type {string}
     * @memberof CourseNotifyUsersParams
     */
    message?: string;
}

/**
 * 
 * @export
 * @interface CourseSectionDescription
 */
export interface CourseSectionDescription {
}

/**
 * 
 * @export
 * @interface CourseSectionName
 */
export interface CourseSectionName {
}

/**
 * 
 * @export
 * @interface CourseSetPrivateUsersParams
 */
export interface CourseSetPrivateUsersParams {
    /**
     * 
     * @type {Array<number>}
     * @memberof CourseSetPrivateUsersParams
     */
    course_private_user_ids?: Array<number>;
}

/**
 * 
 * @export
 * @interface CourseSubmoduleCompletionData
 */
export interface CourseSubmoduleCompletionData {
    /**
     * 
     * @type {PurifiedString}
     * @memberof CourseSubmoduleCompletionData
     */
    text?: PurifiedString;
    /**
     * 
     * @type {string}
     * @memberof CourseSubmoduleCompletionData
     */
    file?: string;
    /**
     * 
     * @type {UserQuizCompletion}
     * @memberof CourseSubmoduleCompletionData
     */
    quiz?: UserQuizCompletion;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseSubmoduleCompletionData
     */
    variants?: Array<string>;
    /**
     * 
     * @type {Array<CourseSubmoduleCompletionGroup>}
     * @memberof CourseSubmoduleCompletionData
     */
    groups?: Array<CourseSubmoduleCompletionGroup>;
}

/**
 * 
 * @export
 * @interface CourseSubmoduleCompletionGroup
 */
export interface CourseSubmoduleCompletionGroup {
    /**
     * 
     * @type {string}
     * @memberof CourseSubmoduleCompletionGroup
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseSubmoduleCompletionGroup
     */
    variants?: Array<string>;
}

/**
 * 
 * @export
 * @interface CourseSubmoduleName
 */
export interface CourseSubmoduleName {
}

/**
 * 
 * @export
 * @interface CourseUserGroupName
 */
export interface CourseUserGroupName {
}

/**
 * 
 * @export
 * @interface CreateModuleParams
 */
export interface CreateModuleParams {
    /**
     * 
     * @type {number}
     * @memberof CreateModuleParams
     */
    course_id: number;
    /**
     * 
     * @type {CourseModuleName}
     * @memberof CreateModuleParams
     */
    name: CourseModuleName;
    /**
     * 
     * @type {number}
     * @memberof CreateModuleParams
     */
    weight: number;
    /**
     * 
     * @type {number}
     * @memberof CreateModuleParams
     */
    course_module_availability_strategy_id: number;
    /**
     * 
     * @type {any}
     * @memberof CreateModuleParams
     */
    course_module_availability_strategy_data?: any;
}

/**
 * 
 * @export
 * @interface CreateSectionParams
 */
export interface CreateSectionParams {
    /**
     * 
     * @type {number}
     * @memberof CreateSectionParams
     */
    course_module_id: number;
    /**
     * 
     * @type {CourseSectionName}
     * @memberof CreateSectionParams
     */
    name: CourseSectionName;
    /**
     * 
     * @type {string}
     * @memberof CreateSectionParams
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSectionParams
     */
    is_private: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateSectionParams
     */
    course_user_group_id?: number;
}

/**
 * 
 * @export
 * @interface CreateSubmoduleParams
 */
export interface CreateSubmoduleParams {
    /**
     * 
     * @type {number}
     * @memberof CreateSubmoduleParams
     */
    course_section_id: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSubmoduleParams
     */
    course_submodule_type_id: number;
    /**
     * 
     * @type {any}
     * @memberof CreateSubmoduleParams
     */
    course_submodule_type_data?: any;
    /**
     * 
     * @type {number}
     * @memberof CreateSubmoduleParams
     */
    course_submodule_points_strategy_id: number;
    /**
     * 
     * @type {any}
     * @memberof CreateSubmoduleParams
     */
    course_submodule_points_strategy_data?: any;
    /**
     * 
     * @type {number}
     * @memberof CreateSubmoduleParams
     */
    course_submodule_period_strategy_id: number;
    /**
     * 
     * @type {any}
     * @memberof CreateSubmoduleParams
     */
    course_submodule_period_strategy_data?: any;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSubmoduleParams
     */
    is_verification_anonymous: boolean;
    /**
     * 
     * @type {CourseSubmoduleName}
     * @memberof CreateSubmoduleParams
     */
    name: CourseSubmoduleName;
    /**
     * 
     * @type {number}
     * @memberof CreateSubmoduleParams
     */
    duration: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSubmoduleParams
     */
    optional: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSubmoduleParams
     */
    has_deadline: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSubmoduleParams
     */
    deadline_time?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSubmoduleParams
     */
    is_available_in_my_portfolio: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSubmoduleParams
     */
    is_available_in_other_user_portfolio: boolean;
}

/**
 * 
 * @export
 * @interface CreateUserGroupParams
 */
export interface CreateUserGroupParams {
    /**
     * 
     * @type {CourseUserGroupName}
     * @memberof CreateUserGroupParams
     */
    name: CourseUserGroupName;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateUserGroupParams
     */
    user_ids: Array<number>;
}

/**
 * 
 * @export
 * @interface DiscussionBreadcrumbs
 */
export interface DiscussionBreadcrumbs {
    /**
     * 
     * @type {BreadcrumbItem}
     * @memberof DiscussionBreadcrumbs
     */
    course: BreadcrumbItem;
    /**
     * 
     * @type {BreadcrumbItem}
     * @memberof DiscussionBreadcrumbs
     */
    module?: BreadcrumbItem;
}

/**
 * 
 * @export
 * @interface DiscussionCreateParams
 */
export interface DiscussionCreateParams {
    /**
     * 
     * @type {DiscussionName}
     * @memberof DiscussionCreateParams
     */
    name: DiscussionName;
    /**
     * 
     * @type {boolean}
     * @memberof DiscussionCreateParams
     */
    is_private: boolean;
}

/**
 * 
 * @export
 * @interface DiscussionCreateParams1
 */
export interface DiscussionCreateParams1 {
    /**
     * 
     * @type {DiscussionName}
     * @memberof DiscussionCreateParams1
     */
    name: DiscussionName;
    /**
     * 
     * @type {boolean}
     * @memberof DiscussionCreateParams1
     */
    is_private: boolean;
}

/**
 * 
 * @export
 * @interface DiscussionEditParams
 */
export interface DiscussionEditParams {
    /**
     * 
     * @type {DiscussionName}
     * @memberof DiscussionEditParams
     */
    name: DiscussionName;
}

/**
 * Список обсуждений
 * @export
 * @interface DiscussionList
 */
export interface DiscussionList {
    /**
     * 
     * @type {Array<ShortDiscussion>}
     * @memberof DiscussionList
     */
    items: Array<ShortDiscussion>;
    /**
     * 
     * @type {number}
     * @memberof DiscussionList
     */
    total: number;
}

/**
 * 
 * @export
 * @interface DiscussionName
 */
export interface DiscussionName {
}

/**
 * 
 * @export
 * @interface DiscussionTopicBreadcrumbs
 */
export interface DiscussionTopicBreadcrumbs {
    /**
     * 
     * @type {BreadcrumbItem}
     * @memberof DiscussionTopicBreadcrumbs
     */
    course: BreadcrumbItem;
    /**
     * 
     * @type {BreadcrumbItem}
     * @memberof DiscussionTopicBreadcrumbs
     */
    module: BreadcrumbItem;
    /**
     * 
     * @type {BreadcrumbItem}
     * @memberof DiscussionTopicBreadcrumbs
     */
    discussion: BreadcrumbItem;
}

/**
 * Информация о комментарии к теме
 * @export
 * @interface DiscussionTopicComment
 */
export interface DiscussionTopicComment {
    /**
     * 
     * @type {number}
     * @memberof DiscussionTopicComment
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DiscussionTopicComment
     */
    text: string;
    /**
     * 
     * @type {ShortUserWithPhoto}
     * @memberof DiscussionTopicComment
     */
    created_by: ShortUserWithPhoto;
    /**
     * 
     * @type {string}
     * @memberof DiscussionTopicComment
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof DiscussionTopicComment
     */
    updated_at?: string;
    /**
     * 
     * @type {Array<DiscussionTopicComment>}
     * @memberof DiscussionTopicComment
     */
    comments?: Array<DiscussionTopicComment>;
}

/**
 * Список комментариев
 * @export
 * @interface DiscussionTopicCommentList
 */
export interface DiscussionTopicCommentList {
    /**
     * 
     * @type {Array<DiscussionTopicComment>}
     * @memberof DiscussionTopicCommentList
     */
    items: Array<DiscussionTopicComment>;
    /**
     * 
     * @type {number}
     * @memberof DiscussionTopicCommentList
     */
    total: number;
}

/**
 * 
 * @export
 * @interface DiscussionTopicList
 */
export interface DiscussionTopicList {
    /**
     * 
     * @type {Array<ShortDiscussionTopic>}
     * @memberof DiscussionTopicList
     */
    items: Array<ShortDiscussionTopic>;
    /**
     * 
     * @type {number}
     * @memberof DiscussionTopicList
     */
    total: number;
}

/**
 * 
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
    /**
     * 
     * @type {number}
     * @memberof ErrorMessage
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    message?: string;
}

/**
 * 
 * @export
 * @interface ExportParams
 */
export interface ExportParams {
    /**
     * 
     * @type {string}
     * @memberof ExportParams
     */
    format?: string;
}

/**
 * Список пользователей (с полными данными)
 * @export
 * @interface FullUserList
 */
export interface FullUserList extends Array<FullUser> {
}

/**
 * 
 * @export
 * @interface HandleConfirmationParams
 */
export interface HandleConfirmationParams {
    /**
     * 
     * @type {string}
     * @memberof HandleConfirmationParams
     */
    token: string;
}

/**
 * 
 * @export
 * @interface HandleResetPasswordParams
 */
export interface HandleResetPasswordParams {
    /**
     * 
     * @type {string}
     * @memberof HandleResetPasswordParams
     */
    token: string;
    /**
     * 
     * @type {UserPassword}
     * @memberof HandleResetPasswordParams
     */
    password: UserPassword;
}

/**
 * 
 * @export
 * @interface IdNameEntity
 */
export interface IdNameEntity {
    /**
     * 
     * @type {number}
     * @memberof IdNameEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IdNameEntity
     */
    name: string;
}

/**
 * 
 * @export
 * @interface IdNameEntityList
 */
export interface IdNameEntityList extends Array<IdNameEntity> {
}

/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    link?: string;
}

/**
 * 
 * @export
 * @interface LoginParams
 */
export interface LoginParams {
    /**
     * 
     * @type {UserUserName}
     * @memberof LoginParams
     */
    username?: UserUserName;
    /**
     * 
     * @type {UserPassword}
     * @memberof LoginParams
     */
    password?: UserPassword;
}

/**
 * 
 * @export
 * @interface MeChangePasswordParams
 */
export interface MeChangePasswordParams {
    /**
     * 
     * @type {UserPassword}
     * @memberof MeChangePasswordParams
     */
    newPassword: UserPassword;
}

/**
 * 
 * @export
 * @interface MeEditParams
 */
export interface MeEditParams {
    /**
     * 
     * @type {UserName}
     * @memberof MeEditParams
     */
    name: UserName;
    /**
     * 
     * @type {UserAbout}
     * @memberof MeEditParams
     */
    about: UserAbout;
}

/**
 * 
 * @export
 * @interface ModuleBreadcrumbs
 */
export interface ModuleBreadcrumbs {
    /**
     * 
     * @type {BreadcrumbItem}
     * @memberof ModuleBreadcrumbs
     */
    course: BreadcrumbItem;
}

/**
 * Оповещение пользователя о событии
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    id: number;
    /**
     * 
     * @type {NotificationType}
     * @memberof Notification
     */
    type: NotificationType;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    description: string;
    /**
     * 
     * @type {Date}
     * @memberof Notification
     */
    created_at: Date;
}

/**
 * Список нотификаций
 * @export
 * @interface NotificationList
 */
export interface NotificationList {
    /**
     * 
     * @type {Array<Notification>}
     * @memberof NotificationList
     */
    items: Array<Notification>;
    /**
     * 
     * @type {number}
     * @memberof NotificationList
     */
    total: number;
}

/**
 * Портфолио
 * @export
 * @interface Portfolio
 */
export interface Portfolio {
    /**
     * 
     * @type {ShortUserWithPhoto}
     * @memberof Portfolio
     */
    user: ShortUserWithPhoto;
    /**
     * 
     * @type {PortfolioCourse}
     * @memberof Portfolio
     */
    course: PortfolioCourse;
    /**
     * 
     * @type {Array<PortfolioModule>}
     * @memberof Portfolio
     */
    modules?: Array<PortfolioModule>;
    /**
     * 
     * @type {Array<PortfolioDiscussionTopic>}
     * @memberof Portfolio
     */
    discussion_topics?: Array<PortfolioDiscussionTopic>;
}

/**
 * Данные прохождения для портфолио
 * @export
 * @interface PortfolioCompletion
 */
export interface PortfolioCompletion {
    /**
     * 
     * @type {CourseSubmoduleCompletionStatus}
     * @memberof PortfolioCompletion
     */
    status?: CourseSubmoduleCompletionStatus;
    /**
     * 
     * @type {string}
     * @memberof PortfolioCompletion
     */
    start_time?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioCompletion
     */
    teacher_comment?: string;
    /**
     * 
     * @type {number}
     * @memberof PortfolioCompletion
     */
    points?: number;
    /**
     * 
     * @type {PortfolioCompletionData}
     * @memberof PortfolioCompletion
     */
    data?: PortfolioCompletionData;
}

/**
 * 
 * @export
 * @interface PortfolioCompletionData
 */
export interface PortfolioCompletionData {
    /**
     * 
     * @type {string}
     * @memberof PortfolioCompletionData
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioCompletionData
     */
    file?: string;
    /**
     * 
     * @type {UserQuizCompletion}
     * @memberof PortfolioCompletionData
     */
    quiz?: UserQuizCompletion;
    /**
     * 
     * @type {Array<string>}
     * @memberof PortfolioCompletionData
     */
    variants?: Array<string>;
    /**
     * 
     * @type {Array<TeacherSubmoduleCompletionCompletionDataGroups>}
     * @memberof PortfolioCompletionData
     */
    groups?: Array<TeacherSubmoduleCompletionCompletionDataGroups>;
}

/**
 * Список прохождений подмодуля
 * @export
 * @interface PortfolioCompletionList
 */
export interface PortfolioCompletionList extends Array<PortfolioCompletion> {
}

/**
 * 
 * @export
 * @interface PortfolioCourse
 */
export interface PortfolioCourse {
    /**
     * 
     * @type {number}
     * @memberof PortfolioCourse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioCourse
     */
    name: string;
}

/**
 * Краткая информация о теме обсуждения в портфолио
 * @export
 * @interface PortfolioDiscussionTopic
 */
export interface PortfolioDiscussionTopic {
    /**
     * 
     * @type {number}
     * @memberof PortfolioDiscussionTopic
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDiscussionTopic
     */
    discussion_id: number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioDiscussionTopic
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDiscussionTopic
     */
    comment_count: number;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioDiscussionTopic
     */
    is_private: boolean;
}

/**
 * Информация о модуле в портфолио
 * @export
 * @interface PortfolioModule
 */
export interface PortfolioModule {
    /**
     * 
     * @type {number}
     * @memberof PortfolioModule
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioModule
     */
    name: string;
    /**
     * 
     * @type {Array<PortfolioSection>}
     * @memberof PortfolioModule
     */
    sections?: Array<PortfolioSection>;
    /**
     * 
     * @type {Array<PortfolioDiscussionTopic>}
     * @memberof PortfolioModule
     */
    discussion_topics?: Array<PortfolioDiscussionTopic>;
}

/**
 * Информация о секции портфолио
 * @export
 * @interface PortfolioSection
 */
export interface PortfolioSection {
    /**
     * 
     * @type {number}
     * @memberof PortfolioSection
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSection
     */
    name?: string;
    /**
     * 
     * @type {Array<PortfolioSubmodule>}
     * @memberof PortfolioSection
     */
    submodules?: Array<PortfolioSubmodule>;
}

/**
 * Информация о подмодуле портфолио
 * @export
 * @interface PortfolioSubmodule
 */
export interface PortfolioSubmodule {
    /**
     * 
     * @type {number}
     * @memberof PortfolioSubmodule
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSubmodule
     */
    name: string;
    /**
     * 
     * @type {CourseSubmoduleType}
     * @memberof PortfolioSubmodule
     */
    type: CourseSubmoduleType;
    /**
     * 
     * @type {UserFullQuiz}
     * @memberof PortfolioSubmodule
     */
    quiz?: UserFullQuiz;
    /**
     * 
     * @type {PortfolioCompletion}
     * @memberof PortfolioSubmodule
     */
    completion?: PortfolioCompletion;
    /**
     * 
     * @type {any}
     * @memberof PortfolioSubmodule
     */
    course_submodule_type_data: any;
}

/**
 * 
 * @export
 * @interface PurifiedString
 */
export interface PurifiedString {
}

/**
 * 
 * @export
 * @interface QuizAnswer
 */
export interface QuizAnswer {
}

/**
 * 
 * @export
 * @interface QuizAnswerDescription
 */
export interface QuizAnswerDescription {
}

/**
 * 
 * @export
 * @interface QuizAnswerResultDescription
 */
export interface QuizAnswerResultDescription {
}

/**
 * 
 * @export
 * @interface QuizQuestion
 */
export interface QuizQuestion {
}

/**
 * 
 * @export
 * @interface RefreshTokenParams
 */
export interface RefreshTokenParams {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenParams
     */
    refresh_token: string;
}

/**
 * 
 * @export
 * @interface RegisterParams
 */
export interface RegisterParams {
    /**
     * 
     * @type {string}
     * @memberof RegisterParams
     */
    name: string;
    /**
     * 
     * @type {UserUserName}
     * @memberof RegisterParams
     */
    username: UserUserName;
    /**
     * 
     * @type {UserPassword}
     * @memberof RegisterParams
     */
    password: UserPassword;
}

/**
 * 
 * @export
 * @interface ResendConfirmationParams
 */
export interface ResendConfirmationParams {
    /**
     * 
     * @type {UserUserName}
     * @memberof ResendConfirmationParams
     */
    username: UserUserName;
}

/**
 * 
 * @export
 * @interface ResetPasswordParams
 */
export interface ResetPasswordParams {
    /**
     * 
     * @type {UserUserName}
     * @memberof ResetPasswordParams
     */
    username: UserUserName;
}

/**
 * 
 * @export
 * @interface SectionBreadcrumbs
 */
export interface SectionBreadcrumbs {
    /**
     * 
     * @type {BreadcrumbItem}
     * @memberof SectionBreadcrumbs
     */
    course: BreadcrumbItem;
    /**
     * 
     * @type {BreadcrumbItem}
     * @memberof SectionBreadcrumbs
     */
    module: BreadcrumbItem;
}

/**
 * Short course info
 * @export
 * @interface ShortCourse
 */
export interface ShortCourse {
    /**
     * 
     * @type {number}
     * @memberof ShortCourse
     */
    id: number;
    /**
     * 
     * @type {Array<CourseCategory>}
     * @memberof ShortCourse
     */
    categories: Array<CourseCategory>;
    /**
     * 
     * @type {string}
     * @memberof ShortCourse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShortCourse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortCourse
     */
    photo: string;
    /**
     * 
     * @type {CourseStatus}
     * @memberof ShortCourse
     */
    status?: CourseStatus;
}

/**
 * Секция модуля
 * @export
 * @interface ShortCourseSection
 */
export interface ShortCourseSection {
    /**
     * 
     * @type {number}
     * @memberof ShortCourseSection
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShortCourseSection
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShortCourseSection
     */
    is_private: boolean;
    /**
     * 
     * @type {ShortCourseUserGroup}
     * @memberof ShortCourseSection
     */
    course_user_group?: ShortCourseUserGroup;
}

/**
 * Подмодуль курса
 * @export
 * @interface ShortCourseSubmodule
 */
export interface ShortCourseSubmodule {
    /**
     * 
     * @type {number}
     * @memberof ShortCourseSubmodule
     */
    id: number;
    /**
     * 
     * @type {CourseSubmoduleType}
     * @memberof ShortCourseSubmodule
     */
    type?: CourseSubmoduleType;
    /**
     * 
     * @type {string}
     * @memberof ShortCourseSubmodule
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ShortCourseSubmodule
     */
    duration: number;
}

/**
 * Краткая информация о группе пользователей курса
 * @export
 * @interface ShortCourseUserGroup
 */
export interface ShortCourseUserGroup {
    /**
     * 
     * @type {number}
     * @memberof ShortCourseUserGroup
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShortCourseUserGroup
     */
    name: string;
}

/**
 * Список групп пользователей
 * @export
 * @interface ShortCourseUserGroupListResponse
 */
export interface ShortCourseUserGroupListResponse {
    /**
     * 
     * @type {Array<ShortCourseUserGroup>}
     * @memberof ShortCourseUserGroupListResponse
     */
    items: Array<ShortCourseUserGroup>;
    /**
     * 
     * @type {number}
     * @memberof ShortCourseUserGroupListResponse
     */
    total: number;
}

/**
 * Краткая информация об обсуждении
 * @export
 * @interface ShortDiscussion
 */
export interface ShortDiscussion {
    /**
     * 
     * @type {number}
     * @memberof ShortDiscussion
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShortDiscussion
     */
    name: string;
    /**
     * 
     * @type {DiscussionType}
     * @memberof ShortDiscussion
     */
    type: DiscussionType;
    /**
     * 
     * @type {boolean}
     * @memberof ShortDiscussion
     */
    is_private?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShortDiscussion
     */
    course_id: number;
}

/**
 * Краткая информация о теме обсуждения
 * @export
 * @interface ShortDiscussionTopic
 */
export interface ShortDiscussionTopic {
    /**
     * 
     * @type {number}
     * @memberof ShortDiscussionTopic
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShortDiscussionTopic
     */
    name: string;
    /**
     * 
     * @type {ShortUserWithPhoto}
     * @memberof ShortDiscussionTopic
     */
    created_by: ShortUserWithPhoto;
    /**
     * 
     * @type {string}
     * @memberof ShortDiscussionTopic
     */
    created_at?: string;
    /**
     * 
     * @type {number}
     * @memberof ShortDiscussionTopic
     */
    view_count: number;
    /**
     * 
     * @type {number}
     * @memberof ShortDiscussionTopic
     */
    comment_count: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShortDiscussionTopic
     */
    pinned: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShortDiscussionTopic
     */
    last_commented_at?: string;
    /**
     * 
     * @type {ShortUserWithPhoto}
     * @memberof ShortDiscussionTopic
     */
    last_commented_by?: ShortUserWithPhoto;
    /**
     * 
     * @type {boolean}
     * @memberof ShortDiscussionTopic
     */
    is_private: boolean;
    /**
     * 
     * @type {Array<ShortUserWithPhoto>}
     * @memberof ShortDiscussionTopic
     */
    private_users?: Array<ShortUserWithPhoto>;
    /**
     * 
     * @type {Array<ShortCourseUserGroup>}
     * @memberof ShortDiscussionTopic
     */
    private_user_groups?: Array<ShortCourseUserGroup>;
}

/**
 * Пользователь
 * @export
 * @interface ShortUser
 */
export interface ShortUser {
    /**
     * 
     * @type {number}
     * @memberof ShortUser
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShortUser
     */
    name: string;
}

/**
 * Список пользователей
 * @export
 * @interface ShortUserList
 */
export interface ShortUserList extends Array<ShortUser> {
}

/**
 * Список пользователей (краткий)
 * @export
 * @interface ShortUserListResponse
 */
export interface ShortUserListResponse {
    /**
     * 
     * @type {Array<ShortUser>}
     * @memberof ShortUserListResponse
     */
    items: Array<ShortUser>;
    /**
     * 
     * @type {number}
     * @memberof ShortUserListResponse
     */
    total: number;
}

/**
 * Пользователь с данными о прохождении курса
 * @export
 * @interface ShortUserWithCourseCompletion
 */
export interface ShortUserWithCourseCompletion {
    /**
     * 
     * @type {number}
     * @memberof ShortUserWithCourseCompletion
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShortUserWithCourseCompletion
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShortUserWithCourseCompletion
     */
    photo: string;
    /**
     * 
     * @type {CourseCompletionStatus}
     * @memberof ShortUserWithCourseCompletion
     */
    completion_status?: CourseCompletionStatus;
}

/**
 * Пользователь
 * @export
 * @interface ShortUserWithPhoto
 */
export interface ShortUserWithPhoto {
    /**
     * 
     * @type {number}
     * @memberof ShortUserWithPhoto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShortUserWithPhoto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShortUserWithPhoto
     */
    photo: string;
}

/**
 * Список пользователей (краткий)
 * @export
 * @interface ShortUserWithPhotoListResponse
 */
export interface ShortUserWithPhotoListResponse {
    /**
     * 
     * @type {Array<ShortUserWithPhoto>}
     * @memberof ShortUserWithPhotoListResponse
     */
    items: Array<ShortUserWithPhoto>;
    /**
     * 
     * @type {number}
     * @memberof ShortUserWithPhotoListResponse
     */
    total: number;
}

/**
 * 
 * @export
 * @interface StaticResult
 */
export interface StaticResult {
    /**
     * 
     * @type {Array<CourseStatus>}
     * @memberof StaticResult
     */
    course_status?: Array<CourseStatus>;
    /**
     * 
     * @type {Array<CourseCategory>}
     * @memberof StaticResult
     */
    course_category?: Array<CourseCategory>;
    /**
     * 
     * @type {Array<CourseModuleAvailabilityStrategy>}
     * @memberof StaticResult
     */
    course_module_availability_strategy?: Array<CourseModuleAvailabilityStrategy>;
    /**
     * 
     * @type {Array<CourseSubmoduleType>}
     * @memberof StaticResult
     */
    course_submodule_type?: Array<CourseSubmoduleType>;
    /**
     * 
     * @type {Array<QuizQuestionType>}
     * @memberof StaticResult
     */
    quiz_question_type?: Array<QuizQuestionType>;
    /**
     * 
     * @type {Array<CourseSubmodulePointsStrategy>}
     * @memberof StaticResult
     */
    course_submodule_points_strategy?: Array<CourseSubmodulePointsStrategy>;
    /**
     * 
     * @type {Array<CourseSubmodulePeriodStrategy>}
     * @memberof StaticResult
     */
    course_submodule_period_strategy?: Array<CourseSubmodulePeriodStrategy>;
    /**
     * 
     * @type {Array<CourseAvailabilityType>}
     * @memberof StaticResult
     */
    course_availability_type?: Array<CourseAvailabilityType>;
    /**
     * 
     * @type {Array<UserStatus>}
     * @memberof StaticResult
     */
    user_status?: Array<UserStatus>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StaticResult
     */
    user_role?: Array<string>;
}

/**
 * 
 * @export
 * @interface SubmoduleBreadcrumbs
 */
export interface SubmoduleBreadcrumbs {
    /**
     * 
     * @type {BreadcrumbItem}
     * @memberof SubmoduleBreadcrumbs
     */
    course: BreadcrumbItem;
    /**
     * 
     * @type {BreadcrumbItem}
     * @memberof SubmoduleBreadcrumbs
     */
    module: BreadcrumbItem;
    /**
     * 
     * @type {BreadcrumbItem}
     * @memberof SubmoduleBreadcrumbs
     */
    section: BreadcrumbItem;
}

/**
 * 
 * @export
 * @interface SubmoduleQuizAnswerCreateParams
 */
export interface SubmoduleQuizAnswerCreateParams {
    /**
     * 
     * @type {QuizAnswer}
     * @memberof SubmoduleQuizAnswerCreateParams
     */
    answer: QuizAnswer;
    /**
     * 
     * @type {QuizAnswerDescription}
     * @memberof SubmoduleQuizAnswerCreateParams
     */
    description?: QuizAnswerDescription;
    /**
     * 
     * @type {QuizAnswerResultDescription}
     * @memberof SubmoduleQuizAnswerCreateParams
     */
    result_description?: QuizAnswerResultDescription;
    /**
     * 
     * @type {boolean}
     * @memberof SubmoduleQuizAnswerCreateParams
     */
    is_correct: boolean;
}

/**
 * 
 * @export
 * @interface SubmoduleQuizAnswerEditParams
 */
export interface SubmoduleQuizAnswerEditParams {
    /**
     * 
     * @type {QuizAnswer}
     * @memberof SubmoduleQuizAnswerEditParams
     */
    answer: QuizAnswer;
    /**
     * 
     * @type {QuizAnswerResultDescription}
     * @memberof SubmoduleQuizAnswerEditParams
     */
    result_description?: QuizAnswerResultDescription;
    /**
     * 
     * @type {QuizAnswerDescription}
     * @memberof SubmoduleQuizAnswerEditParams
     */
    description?: QuizAnswerDescription;
    /**
     * 
     * @type {boolean}
     * @memberof SubmoduleQuizAnswerEditParams
     */
    is_correct: boolean;
}

/**
 * 
 * @export
 * @interface SubmoduleQuizQuestionCreateParams
 */
export interface SubmoduleQuizQuestionCreateParams {
    /**
     * 
     * @type {QuizQuestion}
     * @memberof SubmoduleQuizQuestionCreateParams
     */
    question: QuizQuestion;
    /**
     * 
     * @type {number}
     * @memberof SubmoduleQuizQuestionCreateParams
     */
    quiz_question_type_id: number;
    /**
     * 
     * @type {number}
     * @memberof SubmoduleQuizQuestionCreateParams
     */
    points: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubmoduleQuizQuestionCreateParams
     */
    is_fixed_points: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubmoduleQuizQuestionCreateParams
     */
    is_user_comment_required: boolean;
}

/**
 * 
 * @export
 * @interface SubmoduleQuizQuestionEditParams
 */
export interface SubmoduleQuizQuestionEditParams {
    /**
     * 
     * @type {QuizQuestion}
     * @memberof SubmoduleQuizQuestionEditParams
     */
    question: QuizQuestion;
    /**
     * 
     * @type {number}
     * @memberof SubmoduleQuizQuestionEditParams
     */
    quiz_question_type_id: number;
    /**
     * 
     * @type {number}
     * @memberof SubmoduleQuizQuestionEditParams
     */
    points: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubmoduleQuizQuestionEditParams
     */
    is_fixed_points: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubmoduleQuizQuestionEditParams
     */
    is_user_comment_required: boolean;
}

/**
 * 
 * @export
 * @interface TeacherCompletionSubmodule
 */
export interface TeacherCompletionSubmodule {
    /**
     * 
     * @type {number}
     * @memberof TeacherCompletionSubmodule
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TeacherCompletionSubmodule
     */
    name: string;
    /**
     * 
     * @type {CourseSubmoduleType}
     * @memberof TeacherCompletionSubmodule
     */
    type: CourseSubmoduleType;
    /**
     * 
     * @type {number}
     * @memberof TeacherCompletionSubmodule
     */
    course_submodule_points_strategy_id: number;
    /**
     * 
     * @type {any}
     * @memberof TeacherCompletionSubmodule
     */
    course_submodule_points_strategy_data: any;
    /**
     * 
     * @type {any}
     * @memberof TeacherCompletionSubmodule
     */
    course_submodule_type_data: any;
}

/**
 * Список курсов для учителя
 * @export
 * @interface TeacherCourseList
 */
export interface TeacherCourseList {
    /**
     * 
     * @type {Array<TeacherShortCourse>}
     * @memberof TeacherCourseList
     */
    items: Array<TeacherShortCourse>;
    /**
     * 
     * @type {number}
     * @memberof TeacherCourseList
     */
    total: number;
}

/**
 * Модуль курса для преподавателя
 * @export
 * @interface TeacherCourseModule
 */
export interface TeacherCourseModule {
    /**
     * 
     * @type {number}
     * @memberof TeacherCourseModule
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TeacherCourseModule
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TeacherCourseModule
     */
    weight: number;
    /**
     * 
     * @type {number}
     * @memberof TeacherCourseModule
     */
    wait_for_task_verification_users_count?: number;
    /**
     * 
     * @type {Array<TeacherShortCourseSection>}
     * @memberof TeacherCourseModule
     */
    sections: Array<TeacherShortCourseSection>;
}

/**
 * Список пользователей (краткий)
 * @export
 * @interface TeacherCourseUsersResponse
 */
export interface TeacherCourseUsersResponse {
    /**
     * 
     * @type {Array<ShortUserWithCourseCompletion>}
     * @memberof TeacherCourseUsersResponse
     */
    items: Array<ShortUserWithCourseCompletion>;
    /**
     * 
     * @type {number}
     * @memberof TeacherCourseUsersResponse
     */
    total: number;
}

/**
 * Список обсуждений
 * @export
 * @interface TeacherDiscussionList
 */
export interface TeacherDiscussionList {
    /**
     * 
     * @type {Array<ShortTeacherDiscussion>}
     * @memberof TeacherDiscussionList
     */
    items: Array<ShortTeacherDiscussion>;
    /**
     * 
     * @type {number}
     * @memberof TeacherDiscussionList
     */
    total: number;
}

/**
 * 
 * @export
 * @interface TeacherDiscussionTopicList
 */
export interface TeacherDiscussionTopicList {
    /**
     * 
     * @type {Array<ShortTeacherDiscussionTopic>}
     * @memberof TeacherDiscussionTopicList
     */
    items: Array<ShortTeacherDiscussionTopic>;
    /**
     * 
     * @type {number}
     * @memberof TeacherDiscussionTopicList
     */
    total: number;
}

/**
 * 
 * @export
 * @interface TeacherFullCourse
 */
export interface TeacherFullCourse {
    /**
     * 
     * @type {number}
     * @memberof TeacherFullCourse
     */
    id: number;
    /**
     * Количество пользователей завершивших прохождение
     * @type {number}
     * @memberof TeacherFullCourse
     */
    finished_users_count: number;
    /**
     * Количество пользователей в процессе прохождения
     * @type {number}
     * @memberof TeacherFullCourse
     */
    in_progress_users_count: number;
    /**
     * Количество пользователей ожидающих проверку задания
     * @type {number}
     * @memberof TeacherFullCourse
     */
    wait_for_task_verification_users_count: number;
    /**
     * 
     * @type {Array<TeacherCourseModule>}
     * @memberof TeacherFullCourse
     */
    modules: Array<TeacherCourseModule>;
}

/**
 * Модуль курса для преподавателя с подмодулями
 * @export
 * @interface TeacherFullCourseModule
 */
export interface TeacherFullCourseModule {
    /**
     * 
     * @type {number}
     * @memberof TeacherFullCourseModule
     */
    id: number;
    /**
     * 
     * @type {ModuleBreadcrumbs}
     * @memberof TeacherFullCourseModule
     */
    breadcrumbs: ModuleBreadcrumbs;
    /**
     * 
     * @type {string}
     * @memberof TeacherFullCourseModule
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TeacherFullCourseModule
     */
    weight: number;
    /**
     * 
     * @type {number}
     * @memberof TeacherFullCourseModule
     */
    wait_for_task_verification_users_count?: number;
    /**
     * 
     * @type {Array<TeacherFullCourseSection>}
     * @memberof TeacherFullCourseModule
     */
    sections: Array<TeacherFullCourseSection>;
}

/**
 * Секция модуля для преподавателя
 * @export
 * @interface TeacherShortCourseSection
 */
export interface TeacherShortCourseSection {
    /**
     * 
     * @type {number}
     * @memberof TeacherShortCourseSection
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TeacherShortCourseSection
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TeacherShortCourseSection
     */
    wait_for_task_verification_users_count?: number;
}

/**
 * 
 * @export
 * @interface TeacherSubmoduleCompletion
 */
export interface TeacherSubmoduleCompletion {
    /**
     * 
     * @type {TeacherCompletionSubmodule}
     * @memberof TeacherSubmoduleCompletion
     */
    submodule: TeacherCompletionSubmodule;
    /**
     * 
     * @type {TeacherSubmoduleCompletionUser}
     * @memberof TeacherSubmoduleCompletion
     */
    user: TeacherSubmoduleCompletionUser;
    /**
     * 
     * @type {TeacherSubmoduleCompletionCompletion}
     * @memberof TeacherSubmoduleCompletion
     */
    completion: TeacherSubmoduleCompletionCompletion;
}

/**
 * 
 * @export
 * @interface TeacherSubmoduleCompletionCompletion
 */
export interface TeacherSubmoduleCompletionCompletion {
    /**
     * 
     * @type {number}
     * @memberof TeacherSubmoduleCompletionCompletion
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TeacherSubmoduleCompletionCompletion
     */
    start_time: string;
    /**
     * 
     * @type {number}
     * @memberof TeacherSubmoduleCompletionCompletion
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof TeacherSubmoduleCompletionCompletion
     */
    max_points?: number;
    /**
     * 
     * @type {CourseSubmoduleCompletionStatus}
     * @memberof TeacherSubmoduleCompletionCompletion
     */
    status: CourseSubmoduleCompletionStatus;
    /**
     * 
     * @type {TeacherSubmoduleCompletionCompletionData}
     * @memberof TeacherSubmoduleCompletionCompletion
     */
    data?: TeacherSubmoduleCompletionCompletionData;
}

/**
 * 
 * @export
 * @interface TeacherSubmoduleCompletionCompletionData
 */
export interface TeacherSubmoduleCompletionCompletionData {
    /**
     * 
     * @type {string}
     * @memberof TeacherSubmoduleCompletionCompletionData
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherSubmoduleCompletionCompletionData
     */
    file?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeacherSubmoduleCompletionCompletionData
     */
    variants?: Array<string>;
    /**
     * 
     * @type {Array<TeacherSubmoduleCompletionCompletionDataGroups>}
     * @memberof TeacherSubmoduleCompletionCompletionData
     */
    groups?: Array<TeacherSubmoduleCompletionCompletionDataGroups>;
}

/**
 * 
 * @export
 * @interface TeacherSubmoduleCompletionCompletionDataGroups
 */
export interface TeacherSubmoduleCompletionCompletionDataGroups {
    /**
     * 
     * @type {string}
     * @memberof TeacherSubmoduleCompletionCompletionDataGroups
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeacherSubmoduleCompletionCompletionDataGroups
     */
    variants?: Array<string>;
}

/**
 * 
 * @export
 * @interface TeacherSubmoduleCompletionList
 */
export interface TeacherSubmoduleCompletionList {
    /**
     * 
     * @type {number}
     * @memberof TeacherSubmoduleCompletionList
     */
    total: number;
    /**
     * 
     * @type {Array<TeacherSubmoduleCompletion>}
     * @memberof TeacherSubmoduleCompletionList
     */
    items: Array<TeacherSubmoduleCompletion>;
}

/**
 * 
 * @export
 * @interface TeacherSubmoduleCompletionUser
 */
export interface TeacherSubmoduleCompletionUser {
    /**
     * 
     * @type {number}
     * @memberof TeacherSubmoduleCompletionUser
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TeacherSubmoduleCompletionUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherSubmoduleCompletionUser
     */
    photo: string;
}

/**
 * 
 * @export
 * @interface TokensResponse
 */
export interface TokensResponse {
    /**
     * 
     * @type {string}
     * @memberof TokensResponse
     */
    access_token: string;
    /**
     * 
     * @type {string}
     * @memberof TokensResponse
     */
    refresh_token: string;
    /**
     * 
     * @type {number}
     * @memberof TokensResponse
     */
    next_refresh_timestamp: number;
}

/**
 * 
 * @export
 * @interface TopicCreateParams
 */
export interface TopicCreateParams {
    /**
     * 
     * @type {TopicName}
     * @memberof TopicCreateParams
     */
    name: TopicName;
    /**
     * 
     * @type {string}
     * @memberof TopicCreateParams
     */
    text: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof TopicCreateParams
     */
    private_user_ids?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof TopicCreateParams
     */
    private_user_group_ids?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof TopicCreateParams
     */
    is_available_in_my_portfolio?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TopicCreateParams
     */
    is_available_in_other_user_portfolio?: boolean;
}

/**
 * 
 * @export
 * @interface TopicCreateParams1
 */
export interface TopicCreateParams1 {
    /**
     * 
     * @type {TopicName}
     * @memberof TopicCreateParams1
     */
    name: TopicName;
    /**
     * 
     * @type {string}
     * @memberof TopicCreateParams1
     */
    text: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof TopicCreateParams1
     */
    private_user_ids?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof TopicCreateParams1
     */
    private_user_group_ids?: Array<number>;
}

/**
 * 
 * @export
 * @interface TopicCreateParams2
 */
export interface TopicCreateParams2 {
    /**
     * 
     * @type {TopicName}
     * @memberof TopicCreateParams2
     */
    name: TopicName;
    /**
     * 
     * @type {PurifiedString}
     * @memberof TopicCreateParams2
     */
    text: PurifiedString;
}

/**
 * 
 * @export
 * @interface TopicEditParams
 */
export interface TopicEditParams {
    /**
     * 
     * @type {TopicName}
     * @memberof TopicEditParams
     */
    name: TopicName;
    /**
     * 
     * @type {string}
     * @memberof TopicEditParams
     */
    text: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof TopicEditParams
     */
    private_user_ids?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof TopicEditParams
     */
    private_user_group_ids?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof TopicEditParams
     */
    is_available_in_my_portfolio: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TopicEditParams
     */
    is_available_in_other_user_portfolio: boolean;
}

/**
 * 
 * @export
 * @interface TopicEditParams1
 */
export interface TopicEditParams1 {
    /**
     * 
     * @type {TopicName}
     * @memberof TopicEditParams1
     */
    name: TopicName;
    /**
     * 
     * @type {string}
     * @memberof TopicEditParams1
     */
    text: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof TopicEditParams1
     */
    private_user_ids?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof TopicEditParams1
     */
    private_user_group_ids?: Array<number>;
}

/**
 * 
 * @export
 * @interface TopicEditParams2
 */
export interface TopicEditParams2 {
    /**
     * 
     * @type {TopicName}
     * @memberof TopicEditParams2
     */
    name: TopicName;
    /**
     * 
     * @type {PurifiedString}
     * @memberof TopicEditParams2
     */
    text: PurifiedString;
}

/**
 * 
 * @export
 * @interface TopicName
 */
export interface TopicName {
}

/**
 * 
 * @export
 * @interface UpdateModuleAvailabilityStrategyParams
 */
export interface UpdateModuleAvailabilityStrategyParams {
    /**
     * 
     * @type {number}
     * @memberof UpdateModuleAvailabilityStrategyParams
     */
    course_module_availability_strategy_id: number;
    /**
     * 
     * @type {any}
     * @memberof UpdateModuleAvailabilityStrategyParams
     */
    course_module_availability_strategy_data?: any;
}

/**
 * 
 * @export
 * @interface UpdateModuleParams
 */
export interface UpdateModuleParams {
    /**
     * 
     * @type {CourseModuleName}
     * @memberof UpdateModuleParams
     */
    name: CourseModuleName;
    /**
     * 
     * @type {number}
     * @memberof UpdateModuleParams
     */
    weight: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateModuleParams
     */
    course_module_availability_strategy_id: number;
    /**
     * 
     * @type {any}
     * @memberof UpdateModuleParams
     */
    course_module_availability_strategy_data?: any;
}

/**
 * 
 * @export
 * @interface UpdateSectionParams
 */
export interface UpdateSectionParams {
    /**
     * 
     * @type {CourseSectionName}
     * @memberof UpdateSectionParams
     */
    name: CourseSectionName;
    /**
     * 
     * @type {string}
     * @memberof UpdateSectionParams
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSectionParams
     */
    is_private?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateSectionParams
     */
    course_user_group_id?: number;
}

/**
 * 
 * @export
 * @interface UpdateSubmoduleParams
 */
export interface UpdateSubmoduleParams {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSubmoduleParams
     */
    is_verification_anonymous: boolean;
    /**
     * 
     * @type {CourseSubmoduleName}
     * @memberof UpdateSubmoduleParams
     */
    name: CourseSubmoduleName;
    /**
     * 
     * @type {number}
     * @memberof UpdateSubmoduleParams
     */
    duration: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateSubmoduleParams
     */
    course_submodule_type_id: number;
    /**
     * 
     * @type {any}
     * @memberof UpdateSubmoduleParams
     */
    course_submodule_type_data?: any;
    /**
     * 
     * @type {number}
     * @memberof UpdateSubmoduleParams
     */
    course_submodule_points_strategy_id: number;
    /**
     * 
     * @type {any}
     * @memberof UpdateSubmoduleParams
     */
    course_submodule_points_strategy_data?: any;
    /**
     * 
     * @type {number}
     * @memberof UpdateSubmoduleParams
     */
    course_submodule_period_strategy_id: number;
    /**
     * 
     * @type {any}
     * @memberof UpdateSubmoduleParams
     */
    course_submodule_period_strategy_data?: any;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSubmoduleParams
     */
    optional: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSubmoduleParams
     */
    has_deadline: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateSubmoduleParams
     */
    deadline_time?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSubmoduleParams
     */
    is_available_in_my_portfolio: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSubmoduleParams
     */
    is_available_in_other_user_portfolio: boolean;
}

/**
 * 
 * @export
 * @interface UpdateUserGroupParams
 */
export interface UpdateUserGroupParams {
    /**
     * 
     * @type {CourseUserGroupName}
     * @memberof UpdateUserGroupParams
     */
    name: CourseUserGroupName;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateUserGroupParams
     */
    user_ids: Array<number>;
}

/**
 * 
 * @export
 * @interface UrlResponse
 */
export interface UrlResponse {
    /**
     * 
     * @type {string}
     * @memberof UrlResponse
     */
    url: string;
}

/**
 * Пользователь
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * 
     * @type {UserStatus}
     * @memberof User
     */
    status: UserStatus;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    photo?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    about?: string;
}

/**
 * 
 * @export
 * @interface UserAbout
 */
export interface UserAbout {
}

/**
 * 
 * @export
 * @interface UserComment
 */
export interface UserComment {
}

/**
 * List of courses for user
 * @export
 * @interface UserCourseList
 */
export interface UserCourseList {
    /**
     * 
     * @type {Array<UserShortCourse>}
     * @memberof UserCourseList
     */
    items: Array<UserShortCourse>;
    /**
     * 
     * @type {number}
     * @memberof UserCourseList
     */
    total: number;
}

/**
 * Модуль курса для пользоателя
 * @export
 * @interface UserCourseModule
 */
export interface UserCourseModule {
    /**
     * 
     * @type {number}
     * @memberof UserCourseModule
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserCourseModule
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof UserCourseModule
     */
    weight: number;
    /**
     * 
     * @type {Array<UserShortCourseSection>}
     * @memberof UserCourseModule
     */
    sections: Array<UserShortCourseSection>;
    /**
     * 
     * @type {AvailabilityStatus}
     * @memberof UserCourseModule
     */
    availability_status: AvailabilityStatus;
    /**
     * 
     * @type {boolean}
     * @memberof UserCourseModule
     */
    finished: boolean;
}

/**
 * 
 * @export
 * @interface UserCourseSubmoduleCompletion
 */
export interface UserCourseSubmoduleCompletion {
    /**
     * 
     * @type {number}
     * @memberof UserCourseSubmoduleCompletion
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserCourseSubmoduleCompletion
     */
    start_time: string;
    /**
     * 
     * @type {number}
     * @memberof UserCourseSubmoduleCompletion
     */
    points: number;
    /**
     * 
     * @type {string}
     * @memberof UserCourseSubmoduleCompletion
     */
    teacher_comment?: string;
    /**
     * 
     * @type {CourseSubmoduleCompletionStatus}
     * @memberof UserCourseSubmoduleCompletion
     */
    status: CourseSubmoduleCompletionStatus;
    /**
     * 
     * @type {boolean}
     * @memberof UserCourseSubmoduleCompletion
     */
    is_deadlined?: boolean;
}

/**
 * 
 * @export
 * @interface UserCourseSubmoduleCompletionResult
 */
export interface UserCourseSubmoduleCompletionResult {
    /**
     * 
     * @type {UserFullQuizResult}
     * @memberof UserCourseSubmoduleCompletionResult
     */
    quiz?: UserFullQuizResult;
}

/**
 * 
 * @export
 * @interface UserCreateParams
 */
export interface UserCreateParams {
    /**
     * 
     * @type {UserName}
     * @memberof UserCreateParams
     */
    name: UserName;
    /**
     * 
     * @type {UserUserName}
     * @memberof UserCreateParams
     */
    username: UserUserName;
    /**
     * 
     * @type {UserPassword}
     * @memberof UserCreateParams
     */
    password: UserPassword;
    /**
     * 
     * @type {number}
     * @memberof UserCreateParams
     */
    user_status_id: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCreateParams
     */
    roles: Array<string>;
}

/**
 * 
 * @export
 * @interface UserDiscussionCounts
 */
export interface UserDiscussionCounts {
    /**
     * 
     * @type {number}
     * @memberof UserDiscussionCounts
     */
    topic_count: number;
    /**
     * 
     * @type {number}
     * @memberof UserDiscussionCounts
     */
    comment_count: number;
}

/**
 * Список обсуждений
 * @export
 * @interface UserDiscussionList
 */
export interface UserDiscussionList {
    /**
     * 
     * @type {Array<ShortUserDiscussion>}
     * @memberof UserDiscussionList
     */
    items: Array<ShortUserDiscussion>;
    /**
     * 
     * @type {number}
     * @memberof UserDiscussionList
     */
    total: number;
}

/**
 * 
 * @export
 * @interface UserDiscussionTopicList
 */
export interface UserDiscussionTopicList {
    /**
     * 
     * @type {Array<ShortUserDiscussionTopic>}
     * @memberof UserDiscussionTopicList
     */
    items: Array<ShortUserDiscussionTopic>;
    /**
     * 
     * @type {number}
     * @memberof UserDiscussionTopicList
     */
    total: number;
}

/**
 * 
 * @export
 * @interface UserEditParams
 */
export interface UserEditParams {
    /**
     * 
     * @type {UserName}
     * @memberof UserEditParams
     */
    name?: UserName;
    /**
     * 
     * @type {UserPassword}
     * @memberof UserEditParams
     */
    password?: UserPassword;
    /**
     * 
     * @type {UserAbout}
     * @memberof UserEditParams
     */
    about?: UserAbout;
    /**
     * 
     * @type {number}
     * @memberof UserEditParams
     */
    user_status_id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserEditParams
     */
    roles?: Array<string>;
}

/**
 * Модуль курса для пользоателя (с подмодулями)
 * @export
 * @interface UserFullCourseModule
 */
export interface UserFullCourseModule {
    /**
     * 
     * @type {number}
     * @memberof UserFullCourseModule
     */
    id: number;
    /**
     * 
     * @type {ModuleBreadcrumbs}
     * @memberof UserFullCourseModule
     */
    breadcrumbs: ModuleBreadcrumbs;
    /**
     * 
     * @type {string}
     * @memberof UserFullCourseModule
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof UserFullCourseModule
     */
    weight: number;
    /**
     * 
     * @type {Array<UserFullCourseSection>}
     * @memberof UserFullCourseModule
     */
    sections: Array<UserFullCourseSection>;
    /**
     * 
     * @type {AvailabilityStatus}
     * @memberof UserFullCourseModule
     */
    availability_status: AvailabilityStatus;
    /**
     * 
     * @type {boolean}
     * @memberof UserFullCourseModule
     */
    finished: boolean;
}

/**
 * Подмодуль курса - полное описание (для пользователя)
 * @export
 * @interface UserFullCourseSubmodule
 */
export interface UserFullCourseSubmodule {
    /**
     * 
     * @type {number}
     * @memberof UserFullCourseSubmodule
     */
    id: number;
    /**
     * 
     * @type {SubmoduleBreadcrumbs}
     * @memberof UserFullCourseSubmodule
     */
    breadcrumbs: SubmoduleBreadcrumbs;
    /**
     * 
     * @type {CourseSubmoduleType}
     * @memberof UserFullCourseSubmodule
     */
    type: CourseSubmoduleType;
    /**
     * 
     * @type {any}
     * @memberof UserFullCourseSubmodule
     */
    course_submodule_type_data?: any;
    /**
     * 
     * @type {AvailabilityStatus}
     * @memberof UserFullCourseSubmodule
     */
    availability_status: AvailabilityStatus;
    /**
     * 
     * @type {boolean}
     * @memberof UserFullCourseSubmodule
     */
    finished: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserFullCourseSubmodule
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof UserFullCourseSubmodule
     */
    duration: number;
    /**
     * 
     * @type {UserPointsInfo}
     * @memberof UserFullCourseSubmodule
     */
    points_info?: UserPointsInfo;
    /**
     * 
     * @type {UserCourseSubmoduleCompletion}
     * @memberof UserFullCourseSubmodule
     */
    completion?: UserCourseSubmoduleCompletion;
}

/**
 * 
 * @export
 * @interface UserFullQuiz
 */
export interface UserFullQuiz {
    /**
     * 
     * @type {number}
     * @memberof UserFullQuiz
     */
    id: number;
    /**
     * 
     * @type {QuizType}
     * @memberof UserFullQuiz
     */
    type: QuizType;
    /**
     * 
     * @type {Array<UserQuizQuestion>}
     * @memberof UserFullQuiz
     */
    questions: Array<UserQuizQuestion>;
}

/**
 * 
 * @export
 * @interface UserFullQuizResult
 */
export interface UserFullQuizResult {
    /**
     * 
     * @type {number}
     * @memberof UserFullQuizResult
     */
    id: number;
    /**
     * 
     * @type {QuizType}
     * @memberof UserFullQuizResult
     */
    type: QuizType;
    /**
     * 
     * @type {Array<UserQuizQuestionResult>}
     * @memberof UserFullQuizResult
     */
    questions: Array<UserQuizQuestionResult>;
}

/**
 * 
 * @export
 * @interface UserName
 */
export interface UserName {
}

/**
 * 
 * @export
 * @interface UserPassword
 */
export interface UserPassword {
}

/**
 * Информация о возможном количестве баллов
 * @export
 * @interface UserPointsInfo
 */
export interface UserPointsInfo {
    /**
     * 
     * @type {number}
     * @memberof UserPointsInfo
     */
    from?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPointsInfo
     */
    to?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPointsInfo
     */
    fixed?: number;
}

/**
 * 
 * @export
 * @interface UserQuizAnswer
 */
export interface UserQuizAnswer {
    /**
     * 
     * @type {number}
     * @memberof UserQuizAnswer
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserQuizAnswer
     */
    answer: string;
    /**
     * 
     * @type {string}
     * @memberof UserQuizAnswer
     */
    description?: string;
}

/**
 * 
 * @export
 * @interface UserQuizAnswerResult
 */
export interface UserQuizAnswerResult {
    /**
     * 
     * @type {number}
     * @memberof UserQuizAnswerResult
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserQuizAnswerResult
     */
    answer: string;
    /**
     * 
     * @type {string}
     * @memberof UserQuizAnswerResult
     */
    description: string;
    /**
     * Может принимать значения 1 - когда ответ правильный, 0 - когда неправильный, -1 - когда результат неизвестен
     * @type {number}
     * @memberof UserQuizAnswerResult
     */
    answered_correctly: number;
}

/**
 * 
 * @export
 * @interface UserQuizCompletion
 */
export interface UserQuizCompletion {
    /**
     * 
     * @type {Array<UserQuizCompletionQuestion>}
     * @memberof UserQuizCompletion
     */
    questions: Array<UserQuizCompletionQuestion>;
}

/**
 * 
 * @export
 * @interface UserQuizCompletionQuestion
 */
export interface UserQuizCompletionQuestion {
    /**
     * 
     * @type {number}
     * @memberof UserQuizCompletionQuestion
     */
    question_id: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuizCompletionQuestion
     */
    answer_id?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserQuizCompletionQuestion
     */
    answer_ids?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof UserQuizCompletionQuestion
     */
    answer_text?: string;
    /**
     * 
     * @type {UserComment}
     * @memberof UserQuizCompletionQuestion
     */
    user_comment?: UserComment;
}

/**
 * 
 * @export
 * @interface UserQuizQuestion
 */
export interface UserQuizQuestion {
    /**
     * 
     * @type {number}
     * @memberof UserQuizQuestion
     */
    id: number;
    /**
     * 
     * @type {QuizQuestionType}
     * @memberof UserQuizQuestion
     */
    type: QuizQuestionType;
    /**
     * 
     * @type {string}
     * @memberof UserQuizQuestion
     */
    question: string;
    /**
     * 
     * @type {number}
     * @memberof UserQuizQuestion
     */
    points?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserQuizQuestion
     */
    is_user_comment_required: boolean;
    /**
     * 
     * @type {Array<UserQuizAnswer>}
     * @memberof UserQuizQuestion
     */
    answers?: Array<UserQuizAnswer>;
}

/**
 * 
 * @export
 * @interface UserQuizQuestionResult
 */
export interface UserQuizQuestionResult {
    /**
     * 
     * @type {number}
     * @memberof UserQuizQuestionResult
     */
    id: number;
    /**
     * 
     * @type {QuizQuestionType}
     * @memberof UserQuizQuestionResult
     */
    type: QuizQuestionType;
    /**
     * 
     * @type {string}
     * @memberof UserQuizQuestionResult
     */
    question: string;
    /**
     * 
     * @type {number}
     * @memberof UserQuizQuestionResult
     */
    points?: number;
    /**
     * 
     * @type {string}
     * @memberof UserQuizQuestionResult
     */
    user_comment?: string;
    /**
     * 
     * @type {Array<UserQuizAnswerResult>}
     * @memberof UserQuizQuestionResult
     */
    answers?: Array<UserQuizAnswerResult>;
}

/**
 * Секция модуля пользователя
 * @export
 * @interface UserShortCourseSection
 */
export interface UserShortCourseSection {
    /**
     * 
     * @type {number}
     * @memberof UserShortCourseSection
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserShortCourseSection
     */
    name: string;
    /**
     * 
     * @type {AvailabilityStatus}
     * @memberof UserShortCourseSection
     */
    availability_status: AvailabilityStatus;
    /**
     * 
     * @type {boolean}
     * @memberof UserShortCourseSection
     */
    finished: boolean;
}

/**
 * 
 * @export
 * @interface UserUserName
 */
export interface UserUserName {
}

/**
 * 
 * @export
 * @interface VerificationResult
 */
export interface VerificationResult {
    /**
     * 
     * @type {number}
     * @memberof VerificationResult
     */
    course_submodule_completion_status_id: number;
    /**
     * 
     * @type {PurifiedString}
     * @memberof VerificationResult
     */
    comment?: PurifiedString;
    /**
     * 
     * @type {number}
     * @memberof VerificationResult
     */
    points?: number;
}

/**
 * 
 * @export
 * @interface AdminDiscussionCounts
 */
export interface AdminDiscussionCounts {
    /**
     * 
     * @type {number}
     * @memberof AdminDiscussionCounts
     */
    topic_count: number;
    /**
     * 
     * @type {number}
     * @memberof AdminDiscussionCounts
     */
    comment_count: number;
    /**
     * 
     * @type {number}
     * @memberof AdminDiscussionCounts
     */
    unread_count: number;
}

/**
 * Short course info of admin (teacher)
 * @export
 * @interface AdminShortCourse
 */
export interface AdminShortCourse {
    /**
     * 
     * @type {number}
     * @memberof AdminShortCourse
     */
    id: number;
    /**
     * 
     * @type {Array<CourseCategory>}
     * @memberof AdminShortCourse
     */
    categories: Array<CourseCategory>;
    /**
     * 
     * @type {string}
     * @memberof AdminShortCourse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdminShortCourse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminShortCourse
     */
    photo: string;
    /**
     * 
     * @type {CourseStatus}
     * @memberof AdminShortCourse
     */
    status?: CourseStatus;
    /**
     * Количество пользователей завершивших прохождение
     * @type {number}
     * @memberof AdminShortCourse
     */
    finished_users_count: number;
    /**
     * Количество пользователей в процессе прохождения
     * @type {number}
     * @memberof AdminShortCourse
     */
    in_progress_users_count: number;
    /**
     * Количество пользователей ожидающих проверку задания
     * @type {number}
     * @memberof AdminShortCourse
     */
    wait_for_task_verification_users_count: number;
}

/**
 * Краткая общая информация о курсе
 * @export
 * @interface AvailableShortCourse
 */
export interface AvailableShortCourse {
    /**
     * 
     * @type {number}
     * @memberof AvailableShortCourse
     */
    id: number;
    /**
     * 
     * @type {Array<CourseCategory>}
     * @memberof AvailableShortCourse
     */
    categories: Array<CourseCategory>;
    /**
     * 
     * @type {string}
     * @memberof AvailableShortCourse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AvailableShortCourse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AvailableShortCourse
     */
    photo: string;
    /**
     * 
     * @type {CourseStatus}
     * @memberof AvailableShortCourse
     */
    status?: CourseStatus;
    /**
     * 
     * @type {boolean}
     * @memberof AvailableShortCourse
     */
    is_participated: boolean;
}

/**
 * Breadcrumb
 * @export
 * @interface BreadcrumbItem
 */
export interface BreadcrumbItem {
    /**
     * 
     * @type {number}
     * @memberof BreadcrumbItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BreadcrumbItem
     */
    name: string;
}

/**
 * Тип доступности курса
 * @export
 * @interface CourseAvailabilityType
 */
export interface CourseAvailabilityType {
    /**
     * 
     * @type {number}
     * @memberof CourseAvailabilityType
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CourseAvailabilityType
     */
    name: string;
}

/**
 * Категория курса
 * @export
 * @interface CourseCategory
 */
export interface CourseCategory {
    /**
     * 
     * @type {number}
     * @memberof CourseCategory
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CourseCategory
     */
    name: string;
}

/**
 * Статус прохождения курса
 * @export
 * @interface CourseCompletionStatus
 */
export interface CourseCompletionStatus {
    /**
     * 
     * @type {number}
     * @memberof CourseCompletionStatus
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CourseCompletionStatus
     */
    name: string;
}

/**
 * Стратегия доступности модуля
 * @export
 * @interface CourseModuleAvailabilityStrategy
 */
export interface CourseModuleAvailabilityStrategy {
    /**
     * 
     * @type {number}
     * @memberof CourseModuleAvailabilityStrategy
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CourseModuleAvailabilityStrategy
     */
    name: string;
}

/**
 * Статус курса
 * @export
 * @interface CourseStatus
 */
export interface CourseStatus {
    /**
     * 
     * @type {number}
     * @memberof CourseStatus
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CourseStatus
     */
    name: string;
}

/**
 * 
 * @export
 * @interface CourseSubmoduleCompletionStatus
 */
export interface CourseSubmoduleCompletionStatus {
    /**
     * 
     * @type {number}
     * @memberof CourseSubmoduleCompletionStatus
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CourseSubmoduleCompletionStatus
     */
    name: string;
}

/**
 * 
 * @export
 * @interface CourseSubmodulePeriodStrategy
 */
export interface CourseSubmodulePeriodStrategy {
    /**
     * 
     * @type {number}
     * @memberof CourseSubmodulePeriodStrategy
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CourseSubmodulePeriodStrategy
     */
    name: string;
}

/**
 * 
 * @export
 * @interface CourseSubmodulePointsStrategy
 */
export interface CourseSubmodulePointsStrategy {
    /**
     * 
     * @type {number}
     * @memberof CourseSubmodulePointsStrategy
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CourseSubmodulePointsStrategy
     */
    name: string;
}

/**
 * Тип подмодуля курса
 * @export
 * @interface CourseSubmoduleType
 */
export interface CourseSubmoduleType {
    /**
     * 
     * @type {number}
     * @memberof CourseSubmoduleType
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CourseSubmoduleType
     */
    name: string;
}

/**
 * Тип обсуждения
 * @export
 * @interface DiscussionType
 */
export interface DiscussionType {
    /**
     * 
     * @type {number}
     * @memberof DiscussionType
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DiscussionType
     */
    name: string;
}

/**
 * Полный курс
 * @export
 * @interface FullCourse
 */
export interface FullCourse {
    /**
     * 
     * @type {number}
     * @memberof FullCourse
     */
    id: number;
    /**
     * 
     * @type {Array<CourseCategory>}
     * @memberof FullCourse
     */
    categories: Array<CourseCategory>;
    /**
     * 
     * @type {string}
     * @memberof FullCourse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FullCourse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof FullCourse
     */
    photo: string;
    /**
     * 
     * @type {CourseStatus}
     * @memberof FullCourse
     */
    status?: CourseStatus;
    /**
     * 
     * @type {FullUserList}
     * @memberof FullCourse
     */
    teachers: FullUserList;
    /**
     * 
     * @type {number}
     * @memberof FullCourse
     */
    min_rating?: number;
    /**
     * 
     * @type {Array<CourseModule>}
     * @memberof FullCourse
     */
    modules: Array<CourseModule>;
    /**
     * 
     * @type {boolean}
     * @memberof FullCourse
     */
    is_portfolio_available: boolean;
}

/**
 * Секция модуля с контентом
 * @export
 * @interface FullCourseSection
 */
export interface FullCourseSection {
    /**
     * 
     * @type {number}
     * @memberof FullCourseSection
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FullCourseSection
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof FullCourseSection
     */
    is_private: boolean;
    /**
     * 
     * @type {ShortCourseUserGroup}
     * @memberof FullCourseSection
     */
    course_user_group?: ShortCourseUserGroup;
    /**
     * 
     * @type {SectionBreadcrumbs}
     * @memberof FullCourseSection
     */
    breadcrumbs: SectionBreadcrumbs;
    /**
     * 
     * @type {string}
     * @memberof FullCourseSection
     */
    description?: string;
    /**
     * 
     * @type {Array<ShortCourseSubmodule>}
     * @memberof FullCourseSection
     */
    submodules: Array<ShortCourseSubmodule>;
}

/**
 * Полная информация о группе пользователей курса
 * @export
 * @interface FullCourseUserGroup
 */
export interface FullCourseUserGroup {
    /**
     * 
     * @type {number}
     * @memberof FullCourseUserGroup
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FullCourseUserGroup
     */
    name: string;
    /**
     * 
     * @type {ShortUserList}
     * @memberof FullCourseUserGroup
     */
    users: ShortUserList;
}

/**
 * Полная информация об обсуждении
 * @export
 * @interface FullDiscussion
 */
export interface FullDiscussion {
    /**
     * 
     * @type {number}
     * @memberof FullDiscussion
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FullDiscussion
     */
    name: string;
    /**
     * 
     * @type {DiscussionType}
     * @memberof FullDiscussion
     */
    type: DiscussionType;
    /**
     * 
     * @type {boolean}
     * @memberof FullDiscussion
     */
    is_private?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FullDiscussion
     */
    course_id: number;
    /**
     * 
     * @type {DiscussionBreadcrumbs}
     * @memberof FullDiscussion
     */
    breadcrumbs: DiscussionBreadcrumbs;
}

/**
 * Полная информация о теме обсуждения
 * @export
 * @interface FullDiscussionTopic
 */
export interface FullDiscussionTopic {
    /**
     * 
     * @type {number}
     * @memberof FullDiscussionTopic
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FullDiscussionTopic
     */
    name: string;
    /**
     * 
     * @type {ShortUserWithPhoto}
     * @memberof FullDiscussionTopic
     */
    created_by: ShortUserWithPhoto;
    /**
     * 
     * @type {string}
     * @memberof FullDiscussionTopic
     */
    created_at?: string;
    /**
     * 
     * @type {number}
     * @memberof FullDiscussionTopic
     */
    view_count: number;
    /**
     * 
     * @type {number}
     * @memberof FullDiscussionTopic
     */
    comment_count: number;
    /**
     * 
     * @type {boolean}
     * @memberof FullDiscussionTopic
     */
    pinned: boolean;
    /**
     * 
     * @type {string}
     * @memberof FullDiscussionTopic
     */
    last_commented_at?: string;
    /**
     * 
     * @type {ShortUserWithPhoto}
     * @memberof FullDiscussionTopic
     */
    last_commented_by?: ShortUserWithPhoto;
    /**
     * 
     * @type {boolean}
     * @memberof FullDiscussionTopic
     */
    is_private: boolean;
    /**
     * 
     * @type {Array<ShortUserWithPhoto>}
     * @memberof FullDiscussionTopic
     */
    private_users?: Array<ShortUserWithPhoto>;
    /**
     * 
     * @type {Array<ShortCourseUserGroup>}
     * @memberof FullDiscussionTopic
     */
    private_user_groups?: Array<ShortCourseUserGroup>;
    /**
     * 
     * @type {DiscussionTopicBreadcrumbs}
     * @memberof FullDiscussionTopic
     */
    breadcrumbs: DiscussionTopicBreadcrumbs;
    /**
     * 
     * @type {string}
     * @memberof FullDiscussionTopic
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof FullDiscussionTopic
     */
    course_id: number;
}

/**
 * Пользователь
 * @export
 * @interface FullUser
 */
export interface FullUser {
    /**
     * 
     * @type {number}
     * @memberof FullUser
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FullUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FullUser
     */
    about?: string;
    /**
     * 
     * @type {string}
     * @memberof FullUser
     */
    photo?: string;
}

/**
 * Тип нотификации
 * @export
 * @interface NotificationType
 */
export interface NotificationType {
    /**
     * 
     * @type {number}
     * @memberof NotificationType
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationType
     */
    name: string;
}

/**
 * 
 * @export
 * @interface QuizQuestionType
 */
export interface QuizQuestionType {
    /**
     * 
     * @type {number}
     * @memberof QuizQuestionType
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof QuizQuestionType
     */
    name: string;
}

/**
 * 
 * @export
 * @interface QuizType
 */
export interface QuizType {
    /**
     * 
     * @type {number}
     * @memberof QuizType
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof QuizType
     */
    name: string;
}

/**
 * Информация об обсуждении для пользователя
 * @export
 * @interface ShortAdminDiscussion
 */
export interface ShortAdminDiscussion {
    /**
     * 
     * @type {number}
     * @memberof ShortAdminDiscussion
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShortAdminDiscussion
     */
    name: string;
    /**
     * 
     * @type {DiscussionType}
     * @memberof ShortAdminDiscussion
     */
    type: DiscussionType;
    /**
     * 
     * @type {boolean}
     * @memberof ShortAdminDiscussion
     */
    is_private?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShortAdminDiscussion
     */
    course_id: number;
    /**
     * 
     * @type {number}
     * @memberof ShortAdminDiscussion
     */
    topic_count: number;
    /**
     * 
     * @type {number}
     * @memberof ShortAdminDiscussion
     */
    comment_count: number;
    /**
     * 
     * @type {number}
     * @memberof ShortAdminDiscussion
     */
    unread_count: number;
}

/**
 * Краткая информация о теме обсуждения преподавателя
 * @export
 * @interface ShortAdminDiscussionTopic
 */
export interface ShortAdminDiscussionTopic {
    /**
     * 
     * @type {number}
     * @memberof ShortAdminDiscussionTopic
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShortAdminDiscussionTopic
     */
    name: string;
    /**
     * 
     * @type {ShortUserWithPhoto}
     * @memberof ShortAdminDiscussionTopic
     */
    created_by: ShortUserWithPhoto;
    /**
     * 
     * @type {string}
     * @memberof ShortAdminDiscussionTopic
     */
    created_at?: string;
    /**
     * 
     * @type {number}
     * @memberof ShortAdminDiscussionTopic
     */
    view_count: number;
    /**
     * 
     * @type {number}
     * @memberof ShortAdminDiscussionTopic
     */
    comment_count: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShortAdminDiscussionTopic
     */
    pinned: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShortAdminDiscussionTopic
     */
    last_commented_at?: string;
    /**
     * 
     * @type {ShortUserWithPhoto}
     * @memberof ShortAdminDiscussionTopic
     */
    last_commented_by?: ShortUserWithPhoto;
    /**
     * 
     * @type {boolean}
     * @memberof ShortAdminDiscussionTopic
     */
    is_private: boolean;
    /**
     * 
     * @type {Array<ShortUserWithPhoto>}
     * @memberof ShortAdminDiscussionTopic
     */
    private_users?: Array<ShortUserWithPhoto>;
    /**
     * 
     * @type {Array<ShortCourseUserGroup>}
     * @memberof ShortAdminDiscussionTopic
     */
    private_user_groups?: Array<ShortCourseUserGroup>;
    /**
     * 
     * @type {number}
     * @memberof ShortAdminDiscussionTopic
     */
    unread_count: number;
}

/**
 * Информация об обсуждении для пользователя
 * @export
 * @interface ShortTeacherDiscussion
 */
export interface ShortTeacherDiscussion {
    /**
     * 
     * @type {number}
     * @memberof ShortTeacherDiscussion
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShortTeacherDiscussion
     */
    name: string;
    /**
     * 
     * @type {DiscussionType}
     * @memberof ShortTeacherDiscussion
     */
    type: DiscussionType;
    /**
     * 
     * @type {boolean}
     * @memberof ShortTeacherDiscussion
     */
    is_private?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShortTeacherDiscussion
     */
    course_id: number;
    /**
     * 
     * @type {number}
     * @memberof ShortTeacherDiscussion
     */
    topic_count: number;
    /**
     * 
     * @type {number}
     * @memberof ShortTeacherDiscussion
     */
    comment_count: number;
    /**
     * 
     * @type {number}
     * @memberof ShortTeacherDiscussion
     */
    unread_count: number;
}

/**
 * Краткая информация о теме обсуждения преподавателя
 * @export
 * @interface ShortTeacherDiscussionTopic
 */
export interface ShortTeacherDiscussionTopic {
    /**
     * 
     * @type {number}
     * @memberof ShortTeacherDiscussionTopic
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShortTeacherDiscussionTopic
     */
    name: string;
    /**
     * 
     * @type {ShortUserWithPhoto}
     * @memberof ShortTeacherDiscussionTopic
     */
    created_by: ShortUserWithPhoto;
    /**
     * 
     * @type {string}
     * @memberof ShortTeacherDiscussionTopic
     */
    created_at?: string;
    /**
     * 
     * @type {number}
     * @memberof ShortTeacherDiscussionTopic
     */
    view_count: number;
    /**
     * 
     * @type {number}
     * @memberof ShortTeacherDiscussionTopic
     */
    comment_count: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShortTeacherDiscussionTopic
     */
    pinned: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShortTeacherDiscussionTopic
     */
    last_commented_at?: string;
    /**
     * 
     * @type {ShortUserWithPhoto}
     * @memberof ShortTeacherDiscussionTopic
     */
    last_commented_by?: ShortUserWithPhoto;
    /**
     * 
     * @type {boolean}
     * @memberof ShortTeacherDiscussionTopic
     */
    is_private: boolean;
    /**
     * 
     * @type {Array<ShortUserWithPhoto>}
     * @memberof ShortTeacherDiscussionTopic
     */
    private_users?: Array<ShortUserWithPhoto>;
    /**
     * 
     * @type {Array<ShortCourseUserGroup>}
     * @memberof ShortTeacherDiscussionTopic
     */
    private_user_groups?: Array<ShortCourseUserGroup>;
    /**
     * 
     * @type {number}
     * @memberof ShortTeacherDiscussionTopic
     */
    unread_count: number;
}

/**
 * Информация об обсуждении для пользователя
 * @export
 * @interface ShortUserDiscussion
 */
export interface ShortUserDiscussion {
    /**
     * 
     * @type {number}
     * @memberof ShortUserDiscussion
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShortUserDiscussion
     */
    name: string;
    /**
     * 
     * @type {DiscussionType}
     * @memberof ShortUserDiscussion
     */
    type: DiscussionType;
    /**
     * 
     * @type {boolean}
     * @memberof ShortUserDiscussion
     */
    is_private?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShortUserDiscussion
     */
    course_id: number;
    /**
     * 
     * @type {number}
     * @memberof ShortUserDiscussion
     */
    topic_count: number;
    /**
     * 
     * @type {number}
     * @memberof ShortUserDiscussion
     */
    comment_count: number;
}

/**
 * Краткая информация о теме обсуждения пользователя
 * @export
 * @interface ShortUserDiscussionTopic
 */
export interface ShortUserDiscussionTopic {
    /**
     * 
     * @type {number}
     * @memberof ShortUserDiscussionTopic
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShortUserDiscussionTopic
     */
    name: string;
    /**
     * 
     * @type {ShortUserWithPhoto}
     * @memberof ShortUserDiscussionTopic
     */
    created_by: ShortUserWithPhoto;
    /**
     * 
     * @type {string}
     * @memberof ShortUserDiscussionTopic
     */
    created_at?: string;
    /**
     * 
     * @type {number}
     * @memberof ShortUserDiscussionTopic
     */
    view_count: number;
    /**
     * 
     * @type {number}
     * @memberof ShortUserDiscussionTopic
     */
    comment_count: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShortUserDiscussionTopic
     */
    pinned: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShortUserDiscussionTopic
     */
    last_commented_at?: string;
    /**
     * 
     * @type {ShortUserWithPhoto}
     * @memberof ShortUserDiscussionTopic
     */
    last_commented_by?: ShortUserWithPhoto;
    /**
     * 
     * @type {boolean}
     * @memberof ShortUserDiscussionTopic
     */
    is_private: boolean;
    /**
     * 
     * @type {Array<ShortUserWithPhoto>}
     * @memberof ShortUserDiscussionTopic
     */
    private_users?: Array<ShortUserWithPhoto>;
    /**
     * 
     * @type {Array<ShortCourseUserGroup>}
     * @memberof ShortUserDiscussionTopic
     */
    private_user_groups?: Array<ShortCourseUserGroup>;
}

/**
 * 
 * @export
 * @interface TeacherDiscussionCounts
 */
export interface TeacherDiscussionCounts {
    /**
     * 
     * @type {number}
     * @memberof TeacherDiscussionCounts
     */
    topic_count: number;
    /**
     * 
     * @type {number}
     * @memberof TeacherDiscussionCounts
     */
    comment_count: number;
    /**
     * 
     * @type {number}
     * @memberof TeacherDiscussionCounts
     */
    unread_count: number;
}

/**
 * Преподавательская секция модуля с подмодулями
 * @export
 * @interface TeacherFullCourseSection
 */
export interface TeacherFullCourseSection {
    /**
     * 
     * @type {number}
     * @memberof TeacherFullCourseSection
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TeacherFullCourseSection
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TeacherFullCourseSection
     */
    wait_for_task_verification_users_count?: number;
    /**
     * 
     * @type {SectionBreadcrumbs}
     * @memberof TeacherFullCourseSection
     */
    breadcrumbs?: SectionBreadcrumbs;
    /**
     * 
     * @type {Array<TeacherShortCourseSubmodule>}
     * @memberof TeacherFullCourseSection
     */
    submodules?: Array<TeacherShortCourseSubmodule>;
}

/**
 * Короткие данные о курсе для учителя
 * @export
 * @interface TeacherShortCourse
 */
export interface TeacherShortCourse {
    /**
     * 
     * @type {number}
     * @memberof TeacherShortCourse
     */
    id: number;
    /**
     * 
     * @type {Array<CourseCategory>}
     * @memberof TeacherShortCourse
     */
    categories: Array<CourseCategory>;
    /**
     * 
     * @type {string}
     * @memberof TeacherShortCourse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherShortCourse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherShortCourse
     */
    photo: string;
    /**
     * 
     * @type {CourseStatus}
     * @memberof TeacherShortCourse
     */
    status?: CourseStatus;
    /**
     * Количество пользователей завершивших прохождение
     * @type {number}
     * @memberof TeacherShortCourse
     */
    finished_users_count: number;
    /**
     * Количество пользователей в процессе прохождения
     * @type {number}
     * @memberof TeacherShortCourse
     */
    in_progress_users_count: number;
    /**
     * Количество пользователей ожидающих проверку задания
     * @type {number}
     * @memberof TeacherShortCourse
     */
    wait_for_task_verification_users_count: number;
}

/**
 * Подмодуль курса для пользователя
 * @export
 * @interface TeacherShortCourseSubmodule
 */
export interface TeacherShortCourseSubmodule {
    /**
     * 
     * @type {number}
     * @memberof TeacherShortCourseSubmodule
     */
    id: number;
    /**
     * 
     * @type {CourseSubmoduleType}
     * @memberof TeacherShortCourseSubmodule
     */
    type?: CourseSubmoduleType;
    /**
     * 
     * @type {string}
     * @memberof TeacherShortCourseSubmodule
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TeacherShortCourseSubmodule
     */
    duration: number;
    /**
     * 
     * @type {number}
     * @memberof TeacherShortCourseSubmodule
     */
    wait_for_task_verification_users_count: number;
}

/**
 * Полный курс пользователя
 * @export
 * @interface UserFullCourse
 */
export interface UserFullCourse {
    /**
     * 
     * @type {number}
     * @memberof UserFullCourse
     */
    id: number;
    /**
     * 
     * @type {Array<CourseCategory>}
     * @memberof UserFullCourse
     */
    categories: Array<CourseCategory>;
    /**
     * 
     * @type {string}
     * @memberof UserFullCourse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserFullCourse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UserFullCourse
     */
    photo: string;
    /**
     * 
     * @type {CourseStatus}
     * @memberof UserFullCourse
     */
    status?: CourseStatus;
    /**
     * 
     * @type {number}
     * @memberof UserFullCourse
     */
    min_rating?: number;
    /**
     * 
     * @type {FullUserList}
     * @memberof UserFullCourse
     */
    teachers?: FullUserList;
    /**
     * 
     * @type {CourseCompletion}
     * @memberof UserFullCourse
     */
    completion: CourseCompletion;
    /**
     * Прогресс пользователя в прохождении курса
     * @type {number}
     * @memberof UserFullCourse
     */
    progress?: number;
    /**
     * Рейтинг пользователя при прохождении курса
     * @type {number}
     * @memberof UserFullCourse
     */
    rating?: number;
    /**
     * 
     * @type {Array<UserCourseModule>}
     * @memberof UserFullCourse
     */
    modules?: Array<UserCourseModule>;
    /**
     * 
     * @type {boolean}
     * @memberof UserFullCourse
     */
    is_portfolio_available: boolean;
}

/**
 * Пользовательская секция модуля с контентом
 * @export
 * @interface UserFullCourseSection
 */
export interface UserFullCourseSection {
    /**
     * 
     * @type {number}
     * @memberof UserFullCourseSection
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserFullCourseSection
     */
    name: string;
    /**
     * 
     * @type {AvailabilityStatus}
     * @memberof UserFullCourseSection
     */
    availability_status: AvailabilityStatus;
    /**
     * 
     * @type {boolean}
     * @memberof UserFullCourseSection
     */
    finished: boolean;
    /**
     * 
     * @type {SectionBreadcrumbs}
     * @memberof UserFullCourseSection
     */
    breadcrumbs: SectionBreadcrumbs;
    /**
     * 
     * @type {string}
     * @memberof UserFullCourseSection
     */
    description?: string;
    /**
     * 
     * @type {Array<UserShortCourseSubmodule>}
     * @memberof UserFullCourseSection
     */
    submodules: Array<UserShortCourseSubmodule>;
}

/**
 * Short course info of user
 * @export
 * @interface UserShortCourse
 */
export interface UserShortCourse {
    /**
     * 
     * @type {number}
     * @memberof UserShortCourse
     */
    id: number;
    /**
     * 
     * @type {Array<CourseCategory>}
     * @memberof UserShortCourse
     */
    categories: Array<CourseCategory>;
    /**
     * 
     * @type {string}
     * @memberof UserShortCourse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserShortCourse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShortCourse
     */
    photo: string;
    /**
     * 
     * @type {CourseStatus}
     * @memberof UserShortCourse
     */
    status?: CourseStatus;
    /**
     * 
     * @type {CourseCompletion}
     * @memberof UserShortCourse
     */
    completion?: CourseCompletion;
}

/**
 * Подмодуль курса для пользователя
 * @export
 * @interface UserShortCourseSubmodule
 */
export interface UserShortCourseSubmodule {
    /**
     * 
     * @type {number}
     * @memberof UserShortCourseSubmodule
     */
    id: number;
    /**
     * 
     * @type {CourseSubmoduleType}
     * @memberof UserShortCourseSubmodule
     */
    type?: CourseSubmoduleType;
    /**
     * 
     * @type {string}
     * @memberof UserShortCourseSubmodule
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof UserShortCourseSubmodule
     */
    duration: number;
    /**
     * 
     * @type {AvailabilityStatus}
     * @memberof UserShortCourseSubmodule
     */
    availability_status: AvailabilityStatus;
    /**
     * 
     * @type {boolean}
     * @memberof UserShortCourseSubmodule
     */
    finished: boolean;
}

/**
 * Статус пользователя
 * @export
 * @interface UserStatus
 */
export interface UserStatus {
    /**
     * 
     * @type {number}
     * @memberof UserStatus
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserStatus
     */
    name: string;
}

/**
 * 
 * @export
 * @interface AdminFullCourse
 */
export interface AdminFullCourse {
    /**
     * 
     * @type {number}
     * @memberof AdminFullCourse
     */
    id: number;
    /**
     * 
     * @type {Array<CourseCategory>}
     * @memberof AdminFullCourse
     */
    categories: Array<CourseCategory>;
    /**
     * 
     * @type {string}
     * @memberof AdminFullCourse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdminFullCourse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminFullCourse
     */
    photo: string;
    /**
     * 
     * @type {CourseStatus}
     * @memberof AdminFullCourse
     */
    status?: CourseStatus;
    /**
     * 
     * @type {FullUserList}
     * @memberof AdminFullCourse
     */
    teachers: FullUserList;
    /**
     * 
     * @type {number}
     * @memberof AdminFullCourse
     */
    min_rating?: number;
    /**
     * 
     * @type {Array<CourseModule>}
     * @memberof AdminFullCourse
     */
    modules: Array<CourseModule>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminFullCourse
     */
    is_portfolio_available: boolean;
    /**
     * 
     * @type {CourseAvailabilityType}
     * @memberof AdminFullCourse
     */
    course_availability_type: CourseAvailabilityType;
    /**
     * Количество пользователей завершивших прохождение
     * @type {number}
     * @memberof AdminFullCourse
     */
    finished_users_count: number;
    /**
     * Количество пользователей в процессе прохождения
     * @type {number}
     * @memberof AdminFullCourse
     */
    in_progress_users_count: number;
    /**
     * Количество пользователей ожидающих проверку задания
     * @type {number}
     * @memberof AdminFullCourse
     */
    wait_for_task_verification_users_count: number;
    /**
     * 
     * @type {FullUserList}
     * @memberof AdminFullCourse
     */
    admins: FullUserList;
    /**
     * 
     * @type {boolean}
     * @memberof AdminFullCourse
     */
    display_progress: boolean;
}

/**
 * Доступный полный курс
 * @export
 * @interface AvailableFullCourse
 */
export interface AvailableFullCourse {
    /**
     * 
     * @type {number}
     * @memberof AvailableFullCourse
     */
    id: number;
    /**
     * 
     * @type {Array<CourseCategory>}
     * @memberof AvailableFullCourse
     */
    categories: Array<CourseCategory>;
    /**
     * 
     * @type {string}
     * @memberof AvailableFullCourse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AvailableFullCourse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AvailableFullCourse
     */
    photo: string;
    /**
     * 
     * @type {CourseStatus}
     * @memberof AvailableFullCourse
     */
    status?: CourseStatus;
    /**
     * 
     * @type {boolean}
     * @memberof AvailableFullCourse
     */
    is_participated: boolean;
    /**
     * 
     * @type {FullUserList}
     * @memberof AvailableFullCourse
     */
    teachers: FullUserList;
    /**
     * 
     * @type {Array<AvailableCourseModule>}
     * @memberof AvailableFullCourse
     */
    modules: Array<AvailableCourseModule>;
}

/**
 * Полная информация о теме обсуждения админа
 * @export
 * @interface FullAdminDiscussionTopic
 */
export interface FullAdminDiscussionTopic {
    /**
     * 
     * @type {number}
     * @memberof FullAdminDiscussionTopic
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FullAdminDiscussionTopic
     */
    name: string;
    /**
     * 
     * @type {ShortUserWithPhoto}
     * @memberof FullAdminDiscussionTopic
     */
    created_by: ShortUserWithPhoto;
    /**
     * 
     * @type {string}
     * @memberof FullAdminDiscussionTopic
     */
    created_at?: string;
    /**
     * 
     * @type {number}
     * @memberof FullAdminDiscussionTopic
     */
    view_count: number;
    /**
     * 
     * @type {number}
     * @memberof FullAdminDiscussionTopic
     */
    comment_count: number;
    /**
     * 
     * @type {boolean}
     * @memberof FullAdminDiscussionTopic
     */
    pinned: boolean;
    /**
     * 
     * @type {string}
     * @memberof FullAdminDiscussionTopic
     */
    last_commented_at?: string;
    /**
     * 
     * @type {ShortUserWithPhoto}
     * @memberof FullAdminDiscussionTopic
     */
    last_commented_by?: ShortUserWithPhoto;
    /**
     * 
     * @type {boolean}
     * @memberof FullAdminDiscussionTopic
     */
    is_private: boolean;
    /**
     * 
     * @type {Array<ShortUserWithPhoto>}
     * @memberof FullAdminDiscussionTopic
     */
    private_users?: Array<ShortUserWithPhoto>;
    /**
     * 
     * @type {Array<ShortCourseUserGroup>}
     * @memberof FullAdminDiscussionTopic
     */
    private_user_groups?: Array<ShortCourseUserGroup>;
    /**
     * 
     * @type {DiscussionTopicBreadcrumbs}
     * @memberof FullAdminDiscussionTopic
     */
    breadcrumbs: DiscussionTopicBreadcrumbs;
    /**
     * 
     * @type {string}
     * @memberof FullAdminDiscussionTopic
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof FullAdminDiscussionTopic
     */
    course_id: number;
    /**
     * 
     * @type {number}
     * @memberof FullAdminDiscussionTopic
     */
    unread_count: number;
    /**
     * 
     * @type {boolean}
     * @memberof FullAdminDiscussionTopic
     */
    is_available_in_my_portfolio: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FullAdminDiscussionTopic
     */
    is_available_in_other_user_portfolio: boolean;
}

/**
 * Полная информация об обсуждении для пользователя
 * @export
 * @interface FullTeacherDiscussion
 */
export interface FullTeacherDiscussion {
    /**
     * 
     * @type {number}
     * @memberof FullTeacherDiscussion
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FullTeacherDiscussion
     */
    name: string;
    /**
     * 
     * @type {DiscussionType}
     * @memberof FullTeacherDiscussion
     */
    type: DiscussionType;
    /**
     * 
     * @type {boolean}
     * @memberof FullTeacherDiscussion
     */
    is_private?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FullTeacherDiscussion
     */
    course_id: number;
    /**
     * 
     * @type {DiscussionBreadcrumbs}
     * @memberof FullTeacherDiscussion
     */
    breadcrumbs: DiscussionBreadcrumbs;
    /**
     * 
     * @type {number}
     * @memberof FullTeacherDiscussion
     */
    topic_count: number;
    /**
     * 
     * @type {number}
     * @memberof FullTeacherDiscussion
     */
    comment_count: number;
    /**
     * 
     * @type {number}
     * @memberof FullTeacherDiscussion
     */
    unread_count: number;
}

/**
 * Полная информация о теме обсуждения преподавателя
 * @export
 * @interface FullTeacherDiscussionTopic
 */
export interface FullTeacherDiscussionTopic {
    /**
     * 
     * @type {number}
     * @memberof FullTeacherDiscussionTopic
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FullTeacherDiscussionTopic
     */
    name: string;
    /**
     * 
     * @type {ShortUserWithPhoto}
     * @memberof FullTeacherDiscussionTopic
     */
    created_by: ShortUserWithPhoto;
    /**
     * 
     * @type {string}
     * @memberof FullTeacherDiscussionTopic
     */
    created_at?: string;
    /**
     * 
     * @type {number}
     * @memberof FullTeacherDiscussionTopic
     */
    view_count: number;
    /**
     * 
     * @type {number}
     * @memberof FullTeacherDiscussionTopic
     */
    comment_count: number;
    /**
     * 
     * @type {boolean}
     * @memberof FullTeacherDiscussionTopic
     */
    pinned: boolean;
    /**
     * 
     * @type {string}
     * @memberof FullTeacherDiscussionTopic
     */
    last_commented_at?: string;
    /**
     * 
     * @type {ShortUserWithPhoto}
     * @memberof FullTeacherDiscussionTopic
     */
    last_commented_by?: ShortUserWithPhoto;
    /**
     * 
     * @type {boolean}
     * @memberof FullTeacherDiscussionTopic
     */
    is_private: boolean;
    /**
     * 
     * @type {Array<ShortUserWithPhoto>}
     * @memberof FullTeacherDiscussionTopic
     */
    private_users?: Array<ShortUserWithPhoto>;
    /**
     * 
     * @type {Array<ShortCourseUserGroup>}
     * @memberof FullTeacherDiscussionTopic
     */
    private_user_groups?: Array<ShortCourseUserGroup>;
    /**
     * 
     * @type {DiscussionTopicBreadcrumbs}
     * @memberof FullTeacherDiscussionTopic
     */
    breadcrumbs: DiscussionTopicBreadcrumbs;
    /**
     * 
     * @type {string}
     * @memberof FullTeacherDiscussionTopic
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof FullTeacherDiscussionTopic
     */
    course_id: number;
    /**
     * 
     * @type {number}
     * @memberof FullTeacherDiscussionTopic
     */
    unread_count: number;
}

/**
 * Полная информация об обсуждении для пользователя
 * @export
 * @interface FullUserDiscussion
 */
export interface FullUserDiscussion {
    /**
     * 
     * @type {number}
     * @memberof FullUserDiscussion
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FullUserDiscussion
     */
    name: string;
    /**
     * 
     * @type {DiscussionType}
     * @memberof FullUserDiscussion
     */
    type: DiscussionType;
    /**
     * 
     * @type {boolean}
     * @memberof FullUserDiscussion
     */
    is_private?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FullUserDiscussion
     */
    course_id: number;
    /**
     * 
     * @type {DiscussionBreadcrumbs}
     * @memberof FullUserDiscussion
     */
    breadcrumbs: DiscussionBreadcrumbs;
    /**
     * 
     * @type {number}
     * @memberof FullUserDiscussion
     */
    topic_count: number;
    /**
     * 
     * @type {number}
     * @memberof FullUserDiscussion
     */
    comment_count: number;
}

/**
 * Полная информация о теме обсуждения пользователя
 * @export
 * @interface FullUserDiscussionTopic
 */
export interface FullUserDiscussionTopic {
    /**
     * 
     * @type {number}
     * @memberof FullUserDiscussionTopic
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FullUserDiscussionTopic
     */
    name: string;
    /**
     * 
     * @type {ShortUserWithPhoto}
     * @memberof FullUserDiscussionTopic
     */
    created_by: ShortUserWithPhoto;
    /**
     * 
     * @type {string}
     * @memberof FullUserDiscussionTopic
     */
    created_at?: string;
    /**
     * 
     * @type {number}
     * @memberof FullUserDiscussionTopic
     */
    view_count: number;
    /**
     * 
     * @type {number}
     * @memberof FullUserDiscussionTopic
     */
    comment_count: number;
    /**
     * 
     * @type {boolean}
     * @memberof FullUserDiscussionTopic
     */
    pinned: boolean;
    /**
     * 
     * @type {string}
     * @memberof FullUserDiscussionTopic
     */
    last_commented_at?: string;
    /**
     * 
     * @type {ShortUserWithPhoto}
     * @memberof FullUserDiscussionTopic
     */
    last_commented_by?: ShortUserWithPhoto;
    /**
     * 
     * @type {boolean}
     * @memberof FullUserDiscussionTopic
     */
    is_private: boolean;
    /**
     * 
     * @type {Array<ShortUserWithPhoto>}
     * @memberof FullUserDiscussionTopic
     */
    private_users?: Array<ShortUserWithPhoto>;
    /**
     * 
     * @type {Array<ShortCourseUserGroup>}
     * @memberof FullUserDiscussionTopic
     */
    private_user_groups?: Array<ShortCourseUserGroup>;
    /**
     * 
     * @type {DiscussionTopicBreadcrumbs}
     * @memberof FullUserDiscussionTopic
     */
    breadcrumbs: DiscussionTopicBreadcrumbs;
    /**
     * 
     * @type {string}
     * @memberof FullUserDiscussionTopic
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof FullUserDiscussionTopic
     */
    course_id: number;
    /**
     * 
     * @type {boolean}
     * @memberof FullUserDiscussionTopic
     */
    subscribed: boolean;
}


/**
 * AuthApi - fetch parameter creator
 * @export
 */
export const AuthApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Проверка токена подтверждения
         * @param {HandleConfirmationParams} handleConfirmationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authHandleConfirmation(handleConfirmationParams: HandleConfirmationParams, options: any = {}): FetchArgs {
            // verify required parameter 'handleConfirmationParams' is not null or undefined
            if (handleConfirmationParams === null || handleConfirmationParams === undefined) {
                throw new RequiredError('handleConfirmationParams','Required parameter handleConfirmationParams was null or undefined when calling authHandleConfirmation.');
            }
            const localVarPath = `/auth/handle-confirmation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"HandleConfirmationParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(handleConfirmationParams || {}) : (handleConfirmationParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Подтверждение изменения пароля
         * @param {HandleResetPasswordParams} handleResetPasswordParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authHandleResetPassword(handleResetPasswordParams: HandleResetPasswordParams, options: any = {}): FetchArgs {
            // verify required parameter 'handleResetPasswordParams' is not null or undefined
            if (handleResetPasswordParams === null || handleResetPasswordParams === undefined) {
                throw new RequiredError('handleResetPasswordParams','Required parameter handleResetPasswordParams was null or undefined when calling authHandleResetPassword.');
            }
            const localVarPath = `/auth/handle-reset-password`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"HandleResetPasswordParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(handleResetPasswordParams || {}) : (handleResetPasswordParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Логин пользователя
         * @param {LoginParams} loginParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin(loginParams: LoginParams, options: any = {}): FetchArgs {
            // verify required parameter 'loginParams' is not null or undefined
            if (loginParams === null || loginParams === undefined) {
                throw new RequiredError('loginParams','Required parameter loginParams was null or undefined when calling authLogin.');
            }
            const localVarPath = `/auth/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LoginParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(loginParams || {}) : (loginParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновление токена
         * @param {RefreshTokenParams} refreshTokenParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefreshToken(refreshTokenParams: RefreshTokenParams, options: any = {}): FetchArgs {
            // verify required parameter 'refreshTokenParams' is not null or undefined
            if (refreshTokenParams === null || refreshTokenParams === undefined) {
                throw new RequiredError('refreshTokenParams','Required parameter refreshTokenParams was null or undefined when calling authRefreshToken.');
            }
            const localVarPath = `/auth/refresh-token`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RefreshTokenParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(refreshTokenParams || {}) : (refreshTokenParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Регистрация пользователя
         * @param {RegisterParams} registerParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRegister(registerParams: RegisterParams, options: any = {}): FetchArgs {
            // verify required parameter 'registerParams' is not null or undefined
            if (registerParams === null || registerParams === undefined) {
                throw new RequiredError('registerParams','Required parameter registerParams was null or undefined when calling authRegister.');
            }
            const localVarPath = `/auth/register`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RegisterParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(registerParams || {}) : (registerParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Переотправка emailа подтверждения
         * @param {ResendConfirmationParams} resendConfirmationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authResendConfirmation(resendConfirmationParams: ResendConfirmationParams, options: any = {}): FetchArgs {
            // verify required parameter 'resendConfirmationParams' is not null or undefined
            if (resendConfirmationParams === null || resendConfirmationParams === undefined) {
                throw new RequiredError('resendConfirmationParams','Required parameter resendConfirmationParams was null or undefined when calling authResendConfirmation.');
            }
            const localVarPath = `/auth/send-confirmation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ResendConfirmationParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(resendConfirmationParams || {}) : (resendConfirmationParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Восстановление пароля пользователя
         * @param {ResetPasswordParams} resetPasswordParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authResetPassword(resetPasswordParams: ResetPasswordParams, options: any = {}): FetchArgs {
            // verify required parameter 'resetPasswordParams' is not null or undefined
            if (resetPasswordParams === null || resetPasswordParams === undefined) {
                throw new RequiredError('resetPasswordParams','Required parameter resetPasswordParams was null or undefined when calling authResetPassword.');
            }
            const localVarPath = `/auth/reset-password`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ResetPasswordParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(resetPasswordParams || {}) : (resetPasswordParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Проверка токена подтверждения
         * @param {HandleConfirmationParams} handleConfirmationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authHandleConfirmation(handleConfirmationParams: HandleConfirmationParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authHandleConfirmation(handleConfirmationParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Подтверждение изменения пароля
         * @param {HandleResetPasswordParams} handleResetPasswordParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authHandleResetPassword(handleResetPasswordParams: HandleResetPasswordParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authHandleResetPassword(handleResetPasswordParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Логин пользователя
         * @param {LoginParams} loginParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin(loginParams: LoginParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokensResponse> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authLogin(loginParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Обновление токена
         * @param {RefreshTokenParams} refreshTokenParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefreshToken(refreshTokenParams: RefreshTokenParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokensResponse> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authRefreshToken(refreshTokenParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Регистрация пользователя
         * @param {RegisterParams} registerParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRegister(registerParams: RegisterParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokensResponse> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authRegister(registerParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Переотправка emailа подтверждения
         * @param {ResendConfirmationParams} resendConfirmationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authResendConfirmation(resendConfirmationParams: ResendConfirmationParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authResendConfirmation(resendConfirmationParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Восстановление пароля пользователя
         * @param {ResetPasswordParams} resetPasswordParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authResetPassword(resetPasswordParams: ResetPasswordParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authResetPassword(resetPasswordParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Проверка токена подтверждения
         * @param {HandleConfirmationParams} handleConfirmationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authHandleConfirmation(handleConfirmationParams: HandleConfirmationParams, options?: any) {
            return AuthApiFp(configuration).authHandleConfirmation(handleConfirmationParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Подтверждение изменения пароля
         * @param {HandleResetPasswordParams} handleResetPasswordParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authHandleResetPassword(handleResetPasswordParams: HandleResetPasswordParams, options?: any) {
            return AuthApiFp(configuration).authHandleResetPassword(handleResetPasswordParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Логин пользователя
         * @param {LoginParams} loginParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin(loginParams: LoginParams, options?: any) {
            return AuthApiFp(configuration).authLogin(loginParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Обновление токена
         * @param {RefreshTokenParams} refreshTokenParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefreshToken(refreshTokenParams: RefreshTokenParams, options?: any) {
            return AuthApiFp(configuration).authRefreshToken(refreshTokenParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Регистрация пользователя
         * @param {RegisterParams} registerParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRegister(registerParams: RegisterParams, options?: any) {
            return AuthApiFp(configuration).authRegister(registerParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Переотправка emailа подтверждения
         * @param {ResendConfirmationParams} resendConfirmationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authResendConfirmation(resendConfirmationParams: ResendConfirmationParams, options?: any) {
            return AuthApiFp(configuration).authResendConfirmation(resendConfirmationParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Восстановление пароля пользователя
         * @param {ResetPasswordParams} resetPasswordParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authResetPassword(resetPasswordParams: ResetPasswordParams, options?: any) {
            return AuthApiFp(configuration).authResetPassword(resetPasswordParams, options)(fetch, basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Проверка токена подтверждения
     * @param {HandleConfirmationParams} handleConfirmationParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authHandleConfirmation(handleConfirmationParams: HandleConfirmationParams, options?: any) {
        return AuthApiFp(this.configuration).authHandleConfirmation(handleConfirmationParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Подтверждение изменения пароля
     * @param {HandleResetPasswordParams} handleResetPasswordParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authHandleResetPassword(handleResetPasswordParams: HandleResetPasswordParams, options?: any) {
        return AuthApiFp(this.configuration).authHandleResetPassword(handleResetPasswordParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Логин пользователя
     * @param {LoginParams} loginParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLogin(loginParams: LoginParams, options?: any) {
        return AuthApiFp(this.configuration).authLogin(loginParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Обновление токена
     * @param {RefreshTokenParams} refreshTokenParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authRefreshToken(refreshTokenParams: RefreshTokenParams, options?: any) {
        return AuthApiFp(this.configuration).authRefreshToken(refreshTokenParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Регистрация пользователя
     * @param {RegisterParams} registerParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authRegister(registerParams: RegisterParams, options?: any) {
        return AuthApiFp(this.configuration).authRegister(registerParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Переотправка emailа подтверждения
     * @param {ResendConfirmationParams} resendConfirmationParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authResendConfirmation(resendConfirmationParams: ResendConfirmationParams, options?: any) {
        return AuthApiFp(this.configuration).authResendConfirmation(resendConfirmationParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Восстановление пароля пользователя
     * @param {ResetPasswordParams} resetPasswordParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authResetPassword(resetPasswordParams: ResetPasswordParams, options?: any) {
        return AuthApiFp(this.configuration).authResetPassword(resetPasswordParams, options)(this.fetch, this.basePath);
    }

}

/**
 * CourseApi - fetch parameter creator
 * @export
 */
export const CourseApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of available courses for admin (teacher)
         * @param {number} [status] 
         * @param {string} [query] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseList(status?: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/admin/course`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение сортировки модулей
         * @param {number} id 
         * @param {Array<number>} reorderModulesParams Содержит массив id модулей курса, в том порядке как они должны быть отсортированы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseModuleReorder(id: number, reorderModulesParams: Array<number>, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminCourseModuleReorder.');
            }
            // verify required parameter 'reorderModulesParams' is not null or undefined
            if (reorderModulesParams === null || reorderModulesParams === undefined) {
                throw new RequiredError('reorderModulesParams','Required parameter reorderModulesParams was null or undefined when calling adminCourseModuleReorder.');
            }
            const localVarPath = `/admin/course/{id}/reorder-modules`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;number&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(reorderModulesParams || {}) : (reorderModulesParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение статуса курса
         * @param {number} id 
         * @param {number} status_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSetStatus(id: number, status_id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminCourseSetStatus.');
            }
            // verify required parameter 'status_id' is not null or undefined
            if (status_id === null || status_id === undefined) {
                throw new RequiredError('status_id','Required parameter status_id was null or undefined when calling adminCourseSetStatus.');
            }
            const localVarPath = `/admin/course/{id}/set-status/{status_id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"status_id"}}`, encodeURIComponent(String(status_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка фотографии для курса
         * @param {number} id 
         * @param {any} [file] file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseUploadPhoto(id: number, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminCourseUploadPhoto.');
            }
            const localVarPath = `/admin/course/{id}/upload-photo`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр курса админом
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewCourse(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminViewCourse.');
            }
            const localVarPath = `/admin/course/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр пользователей, записавшихся на курс
         * @param {number} id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewCourseUsers(id: number, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminViewCourseUsers.');
            }
            const localVarPath = `/admin/course/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Клонирование курса
         * @param {CourseCloneParams} courseCloneParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneCourse(courseCloneParams: CourseCloneParams, options: any = {}): FetchArgs {
            // verify required parameter 'courseCloneParams' is not null or undefined
            if (courseCloneParams === null || courseCloneParams === undefined) {
                throw new RequiredError('courseCloneParams','Required parameter courseCloneParams was null or undefined when calling cloneCourse.');
            }
            const localVarPath = `/admin/course/clone`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CourseCloneParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(courseCloneParams || {}) : (courseCloneParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список доступных куросв
         * @param {string} [query] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseList(query?: string, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/course`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправка сообщения пользователям, записанным на курс
         * @param {number} id 
         * @param {CourseNotifyUsersParams} courseNotifyUsersParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseNotifyUsers(id: number, courseNotifyUsersParams: CourseNotifyUsersParams, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling courseNotifyUsers.');
            }
            // verify required parameter 'courseNotifyUsersParams' is not null or undefined
            if (courseNotifyUsersParams === null || courseNotifyUsersParams === undefined) {
                throw new RequiredError('courseNotifyUsersParams','Required parameter courseNotifyUsersParams was null or undefined when calling courseNotifyUsers.');
            }
            const localVarPath = `/admin/course/{id}/notify-users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CourseNotifyUsersParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(courseNotifyUsersParams || {}) : (courseNotifyUsersParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пользователи, которые имеют доступ к курсу
         * @param {number} id 
         * @param {CourseSetPrivateUsersParams} courseSetPrivateUsersParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseSetPrivateUsers(id: number, courseSetPrivateUsersParams: CourseSetPrivateUsersParams, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling courseSetPrivateUsers.');
            }
            // verify required parameter 'courseSetPrivateUsersParams' is not null or undefined
            if (courseSetPrivateUsersParams === null || courseSetPrivateUsersParams === undefined) {
                throw new RequiredError('courseSetPrivateUsersParams','Required parameter courseSetPrivateUsersParams was null or undefined when calling courseSetPrivateUsers.');
            }
            const localVarPath = `/admin/course/{id}/private-users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CourseSetPrivateUsersParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(courseSetPrivateUsersParams || {}) : (courseSetPrivateUsersParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание курса
         * @param {CourseCreateParams} courseCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCourse(courseCreateParams: CourseCreateParams, options: any = {}): FetchArgs {
            // verify required parameter 'courseCreateParams' is not null or undefined
            if (courseCreateParams === null || courseCreateParams === undefined) {
                throw new RequiredError('courseCreateParams','Required parameter courseCreateParams was null or undefined when calling createCourse.');
            }
            const localVarPath = `/admin/course`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CourseCreateParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(courseCreateParams || {}) : (courseCreateParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Подписка на курс
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participateCourse(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling participateCourse.');
            }
            const localVarPath = `/course/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пометить прохождение курса завершенным для пользователя
         * @param {number} user_id 
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCourseCompletionFinish(user_id: number, course_id: number, options: any = {}): FetchArgs {
            // verify required parameter 'user_id' is not null or undefined
            if (user_id === null || user_id === undefined) {
                throw new RequiredError('user_id','Required parameter user_id was null or undefined when calling teacherCourseCompletionFinish.');
            }
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling teacherCourseCompletionFinish.');
            }
            const localVarPath = `/teacher/course/{course_id}/user/{user_id}/finish`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)))
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список доступных курсов для учителя
         * @param {number} [status] 
         * @param {string} [query] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCourseList(status?: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/teacher/course`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр курса преподавателем
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherViewCourse(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling teacherViewCourse.');
            }
            const localVarPath = `/teacher/course/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр пользователей, записавшихся на курс
         * @param {number} id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherViewCourseUsers(id: number, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling teacherViewCourseUsers.');
            }
            const localVarPath = `/teacher/course/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование курса
         * @param {number} id 
         * @param {CourseEditParams} courseEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCourse(id: number, courseEditParams: CourseEditParams, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCourse.');
            }
            // verify required parameter 'courseEditParams' is not null or undefined
            if (courseEditParams === null || courseEditParams === undefined) {
                throw new RequiredError('courseEditParams','Required parameter courseEditParams was null or undefined when calling updateCourse.');
            }
            const localVarPath = `/admin/course/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CourseEditParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(courseEditParams || {}) : (courseEditParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of available courses
         * @param {number} [status] 
         * @param {string} [query] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCourseList(status?: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/user/course`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр курса пользователем
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userViewCourse(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userViewCourse.');
            }
            const localVarPath = `/user/course/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр пользователей, записавшихся на курс
         * @param {number} id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userViewCourseUsers(id: number, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userViewCourseUsers.');
            }
            const localVarPath = `/user/course/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр курса гостем
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewCourse(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling viewCourse.');
            }
            const localVarPath = `/course/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseApi - functional programming interface
 * @export
 */
export const CourseApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of available courses for admin (teacher)
         * @param {number} [status] 
         * @param {string} [query] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseList(status?: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminCourseList> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).adminCourseList(status, query, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Изменение сортировки модулей
         * @param {number} id 
         * @param {Array<number>} reorderModulesParams Содержит массив id модулей курса, в том порядке как они должны быть отсортированы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseModuleReorder(id: number, reorderModulesParams: Array<number>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminFullCourse> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).adminCourseModuleReorder(id, reorderModulesParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Изменение статуса курса
         * @param {number} id 
         * @param {number} status_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSetStatus(id: number, status_id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminFullCourse> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).adminCourseSetStatus(id, status_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Загрузка фотографии для курса
         * @param {number} id 
         * @param {any} [file] file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseUploadPhoto(id: number, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminFullCourse> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).adminCourseUploadPhoto(id, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр курса админом
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewCourse(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminFullCourse> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).adminViewCourse(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр пользователей, записавшихся на курс
         * @param {number} id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewCourseUsers(id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ShortUserWithPhotoListResponse> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).adminViewCourseUsers(id, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Клонирование курса
         * @param {CourseCloneParams} courseCloneParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneCourse(courseCloneParams: CourseCloneParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminFullCourse> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).cloneCourse(courseCloneParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Список доступных куросв
         * @param {string} [query] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseList(query?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AvailableCourseList> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).courseList(query, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Отправка сообщения пользователям, записанным на курс
         * @param {number} id 
         * @param {CourseNotifyUsersParams} courseNotifyUsersParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseNotifyUsers(id: number, courseNotifyUsersParams: CourseNotifyUsersParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).courseNotifyUsers(id, courseNotifyUsersParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Пользователи, которые имеют доступ к курсу
         * @param {number} id 
         * @param {CourseSetPrivateUsersParams} courseSetPrivateUsersParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseSetPrivateUsers(id: number, courseSetPrivateUsersParams: CourseSetPrivateUsersParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).courseSetPrivateUsers(id, courseSetPrivateUsersParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Создание курса
         * @param {CourseCreateParams} courseCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCourse(courseCreateParams: CourseCreateParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminFullCourse> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).createCourse(courseCreateParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Подписка на курс
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participateCourse(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AvailableShortCourse> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).participateCourse(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Пометить прохождение курса завершенным для пользователя
         * @param {number} user_id 
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCourseCompletionFinish(user_id: number, course_id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).teacherCourseCompletionFinish(user_id, course_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Список доступных курсов для учителя
         * @param {number} [status] 
         * @param {string} [query] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCourseList(status?: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TeacherCourseList> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).teacherCourseList(status, query, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр курса преподавателем
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherViewCourse(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TeacherFullCourse> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).teacherViewCourse(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр пользователей, записавшихся на курс
         * @param {number} id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherViewCourseUsers(id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TeacherCourseUsersResponse> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).teacherViewCourseUsers(id, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Редактирование курса
         * @param {number} id 
         * @param {CourseEditParams} courseEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCourse(id: number, courseEditParams: CourseEditParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminFullCourse> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).updateCourse(id, courseEditParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary List of available courses
         * @param {number} [status] 
         * @param {string} [query] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCourseList(status?: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserCourseList> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).userCourseList(status, query, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр курса пользователем
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userViewCourse(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserFullCourse> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).userViewCourse(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр пользователей, записавшихся на курс
         * @param {number} id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userViewCourseUsers(id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ShortUserWithPhotoListResponse> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).userViewCourseUsers(id, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр курса гостем
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewCourse(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AvailableFullCourse> {
            const localVarFetchArgs = CourseApiFetchParamCreator(configuration).viewCourse(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CourseApi - factory interface
 * @export
 */
export const CourseApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary List of available courses for admin (teacher)
         * @param {number} [status] 
         * @param {string} [query] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseList(status?: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return CourseApiFp(configuration).adminCourseList(status, query, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Изменение сортировки модулей
         * @param {number} id 
         * @param {Array<number>} reorderModulesParams Содержит массив id модулей курса, в том порядке как они должны быть отсортированы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseModuleReorder(id: number, reorderModulesParams: Array<number>, options?: any) {
            return CourseApiFp(configuration).adminCourseModuleReorder(id, reorderModulesParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Изменение статуса курса
         * @param {number} id 
         * @param {number} status_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSetStatus(id: number, status_id: number, options?: any) {
            return CourseApiFp(configuration).adminCourseSetStatus(id, status_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Загрузка фотографии для курса
         * @param {number} id 
         * @param {any} [file] file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseUploadPhoto(id: number, file?: any, options?: any) {
            return CourseApiFp(configuration).adminCourseUploadPhoto(id, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр курса админом
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewCourse(id: number, options?: any) {
            return CourseApiFp(configuration).adminViewCourse(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр пользователей, записавшихся на курс
         * @param {number} id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewCourseUsers(id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return CourseApiFp(configuration).adminViewCourseUsers(id, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Клонирование курса
         * @param {CourseCloneParams} courseCloneParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneCourse(courseCloneParams: CourseCloneParams, options?: any) {
            return CourseApiFp(configuration).cloneCourse(courseCloneParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Список доступных куросв
         * @param {string} [query] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseList(query?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return CourseApiFp(configuration).courseList(query, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Отправка сообщения пользователям, записанным на курс
         * @param {number} id 
         * @param {CourseNotifyUsersParams} courseNotifyUsersParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseNotifyUsers(id: number, courseNotifyUsersParams: CourseNotifyUsersParams, options?: any) {
            return CourseApiFp(configuration).courseNotifyUsers(id, courseNotifyUsersParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Пользователи, которые имеют доступ к курсу
         * @param {number} id 
         * @param {CourseSetPrivateUsersParams} courseSetPrivateUsersParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseSetPrivateUsers(id: number, courseSetPrivateUsersParams: CourseSetPrivateUsersParams, options?: any) {
            return CourseApiFp(configuration).courseSetPrivateUsers(id, courseSetPrivateUsersParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Создание курса
         * @param {CourseCreateParams} courseCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCourse(courseCreateParams: CourseCreateParams, options?: any) {
            return CourseApiFp(configuration).createCourse(courseCreateParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Подписка на курс
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participateCourse(id: number, options?: any) {
            return CourseApiFp(configuration).participateCourse(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Пометить прохождение курса завершенным для пользователя
         * @param {number} user_id 
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCourseCompletionFinish(user_id: number, course_id: number, options?: any) {
            return CourseApiFp(configuration).teacherCourseCompletionFinish(user_id, course_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Список доступных курсов для учителя
         * @param {number} [status] 
         * @param {string} [query] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCourseList(status?: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return CourseApiFp(configuration).teacherCourseList(status, query, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр курса преподавателем
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherViewCourse(id: number, options?: any) {
            return CourseApiFp(configuration).teacherViewCourse(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр пользователей, записавшихся на курс
         * @param {number} id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherViewCourseUsers(id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return CourseApiFp(configuration).teacherViewCourseUsers(id, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Редактирование курса
         * @param {number} id 
         * @param {CourseEditParams} courseEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCourse(id: number, courseEditParams: CourseEditParams, options?: any) {
            return CourseApiFp(configuration).updateCourse(id, courseEditParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary List of available courses
         * @param {number} [status] 
         * @param {string} [query] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCourseList(status?: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return CourseApiFp(configuration).userCourseList(status, query, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр курса пользователем
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userViewCourse(id: number, options?: any) {
            return CourseApiFp(configuration).userViewCourse(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр пользователей, записавшихся на курс
         * @param {number} id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userViewCourseUsers(id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return CourseApiFp(configuration).userViewCourseUsers(id, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр курса гостем
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewCourse(id: number, options?: any) {
            return CourseApiFp(configuration).viewCourse(id, options)(fetch, basePath);
        },
    };
};

/**
 * CourseApi - object-oriented interface
 * @export
 * @class CourseApi
 * @extends {BaseAPI}
 */
export class CourseApi extends BaseAPI {
    /**
     * 
     * @summary List of available courses for admin (teacher)
     * @param {number} [status] 
     * @param {string} [query] 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public adminCourseList(status?: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return CourseApiFp(this.configuration).adminCourseList(status, query, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Изменение сортировки модулей
     * @param {number} id 
     * @param {Array<number>} reorderModulesParams Содержит массив id модулей курса, в том порядке как они должны быть отсортированы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public adminCourseModuleReorder(id: number, reorderModulesParams: Array<number>, options?: any) {
        return CourseApiFp(this.configuration).adminCourseModuleReorder(id, reorderModulesParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Изменение статуса курса
     * @param {number} id 
     * @param {number} status_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public adminCourseSetStatus(id: number, status_id: number, options?: any) {
        return CourseApiFp(this.configuration).adminCourseSetStatus(id, status_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Загрузка фотографии для курса
     * @param {number} id 
     * @param {any} [file] file to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public adminCourseUploadPhoto(id: number, file?: any, options?: any) {
        return CourseApiFp(this.configuration).adminCourseUploadPhoto(id, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр курса админом
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public adminViewCourse(id: number, options?: any) {
        return CourseApiFp(this.configuration).adminViewCourse(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр пользователей, записавшихся на курс
     * @param {number} id 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public adminViewCourseUsers(id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return CourseApiFp(this.configuration).adminViewCourseUsers(id, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Клонирование курса
     * @param {CourseCloneParams} courseCloneParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public cloneCourse(courseCloneParams: CourseCloneParams, options?: any) {
        return CourseApiFp(this.configuration).cloneCourse(courseCloneParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Список доступных куросв
     * @param {string} [query] 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseList(query?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return CourseApiFp(this.configuration).courseList(query, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Отправка сообщения пользователям, записанным на курс
     * @param {number} id 
     * @param {CourseNotifyUsersParams} courseNotifyUsersParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseNotifyUsers(id: number, courseNotifyUsersParams: CourseNotifyUsersParams, options?: any) {
        return CourseApiFp(this.configuration).courseNotifyUsers(id, courseNotifyUsersParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Пользователи, которые имеют доступ к курсу
     * @param {number} id 
     * @param {CourseSetPrivateUsersParams} courseSetPrivateUsersParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseSetPrivateUsers(id: number, courseSetPrivateUsersParams: CourseSetPrivateUsersParams, options?: any) {
        return CourseApiFp(this.configuration).courseSetPrivateUsers(id, courseSetPrivateUsersParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Создание курса
     * @param {CourseCreateParams} courseCreateParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public createCourse(courseCreateParams: CourseCreateParams, options?: any) {
        return CourseApiFp(this.configuration).createCourse(courseCreateParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Подписка на курс
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public participateCourse(id: number, options?: any) {
        return CourseApiFp(this.configuration).participateCourse(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Пометить прохождение курса завершенным для пользователя
     * @param {number} user_id 
     * @param {number} course_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public teacherCourseCompletionFinish(user_id: number, course_id: number, options?: any) {
        return CourseApiFp(this.configuration).teacherCourseCompletionFinish(user_id, course_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Список доступных курсов для учителя
     * @param {number} [status] 
     * @param {string} [query] 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public teacherCourseList(status?: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return CourseApiFp(this.configuration).teacherCourseList(status, query, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр курса преподавателем
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public teacherViewCourse(id: number, options?: any) {
        return CourseApiFp(this.configuration).teacherViewCourse(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр пользователей, записавшихся на курс
     * @param {number} id 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public teacherViewCourseUsers(id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return CourseApiFp(this.configuration).teacherViewCourseUsers(id, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Редактирование курса
     * @param {number} id 
     * @param {CourseEditParams} courseEditParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public updateCourse(id: number, courseEditParams: CourseEditParams, options?: any) {
        return CourseApiFp(this.configuration).updateCourse(id, courseEditParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary List of available courses
     * @param {number} [status] 
     * @param {string} [query] 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public userCourseList(status?: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return CourseApiFp(this.configuration).userCourseList(status, query, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр курса пользователем
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public userViewCourse(id: number, options?: any) {
        return CourseApiFp(this.configuration).userViewCourse(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр пользователей, записавшихся на курс
     * @param {number} id 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public userViewCourseUsers(id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return CourseApiFp(this.configuration).userViewCourseUsers(id, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр курса гостем
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public viewCourse(id: number, options?: any) {
        return CourseApiFp(this.configuration).viewCourse(id, options)(this.fetch, this.basePath);
    }

}

/**
 * CourseModuleApi - fetch parameter creator
 * @export
 */
export const CourseModuleApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Изменение сортировки секций модуля
         * @param {number} id 
         * @param {Array<number>} sectionReorderParams Содержит массив id секций курса, в том порядке как они должны быть отсортированы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSectionReorder(id: number, sectionReorderParams: Array<number>, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminCourseSectionReorder.');
            }
            // verify required parameter 'sectionReorderParams' is not null or undefined
            if (sectionReorderParams === null || sectionReorderParams === undefined) {
                throw new RequiredError('sectionReorderParams','Required parameter sectionReorderParams was null or undefined when calling adminCourseSectionReorder.');
            }
            const localVarPath = `/admin/course-module/{id}/reorder-sections`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;number&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(sectionReorderParams || {}) : (sectionReorderParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание модуля курса
         * @param {CreateModuleParams} createModuleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseModule(createModuleParams: CreateModuleParams, options: any = {}): FetchArgs {
            // verify required parameter 'createModuleParams' is not null or undefined
            if (createModuleParams === null || createModuleParams === undefined) {
                throw new RequiredError('createModuleParams','Required parameter createModuleParams was null or undefined when calling adminCreateCourseModule.');
            }
            const localVarPath = `/admin/course-module`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateModuleParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(createModuleParams || {}) : (createModuleParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление модуля
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourseModule(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminDeleteCourseModule.');
            }
            const localVarPath = `/admin/course-module/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование модуля курса (администратор)
         * @param {number} id 
         * @param {UpdateModuleParams} updateModuleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateCourseModule(id: number, updateModuleParams: UpdateModuleParams, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminUpdateCourseModule.');
            }
            // verify required parameter 'updateModuleParams' is not null or undefined
            if (updateModuleParams === null || updateModuleParams === undefined) {
                throw new RequiredError('updateModuleParams','Required parameter updateModuleParams was null or undefined when calling adminUpdateCourseModule.');
            }
            const localVarPath = `/admin/course-module/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateModuleParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateModuleParams || {}) : (updateModuleParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Установка стратегии доступности для модуля курса (администратор)
         * @param {number} id 
         * @param {UpdateModuleAvailabilityStrategyParams} updateModuleAvailabilityStrategyParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateCourseModuleAvailabilityStrategy(id: number, updateModuleAvailabilityStrategyParams: UpdateModuleAvailabilityStrategyParams, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminUpdateCourseModuleAvailabilityStrategy.');
            }
            // verify required parameter 'updateModuleAvailabilityStrategyParams' is not null or undefined
            if (updateModuleAvailabilityStrategyParams === null || updateModuleAvailabilityStrategyParams === undefined) {
                throw new RequiredError('updateModuleAvailabilityStrategyParams','Required parameter updateModuleAvailabilityStrategyParams was null or undefined when calling adminUpdateCourseModuleAvailabilityStrategy.');
            }
            const localVarPath = `/admin/course-module/{id}/set-strategy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateModuleAvailabilityStrategyParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateModuleAvailabilityStrategyParams || {}) : (updateModuleAvailabilityStrategyParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр модуля курса (администратор)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewCourseModule(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminViewCourseModule.');
            }
            const localVarPath = `/admin/course-module/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр модуля курса преподавателем
         * @param {number} id 
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherViewCourseModule(id: number, course_id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling teacherViewCourseModule.');
            }
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling teacherViewCourseModule.');
            }
            const localVarPath = `/teacher/course/{course_id}/course-module/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр модуля курса пользователем
         * @param {number} id 
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userViewCourseModule(id: number, course_id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userViewCourseModule.');
            }
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling userViewCourseModule.');
            }
            const localVarPath = `/user/course/{course_id}/course-module/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseModuleApi - functional programming interface
 * @export
 */
export const CourseModuleApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Изменение сортировки секций модуля
         * @param {number} id 
         * @param {Array<number>} sectionReorderParams Содержит массив id секций курса, в том порядке как они должны быть отсортированы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSectionReorder(id: number, sectionReorderParams: Array<number>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminFullCourseModule> {
            const localVarFetchArgs = CourseModuleApiFetchParamCreator(configuration).adminCourseSectionReorder(id, sectionReorderParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Создание модуля курса
         * @param {CreateModuleParams} createModuleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseModule(createModuleParams: CreateModuleParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminFullCourseModule> {
            const localVarFetchArgs = CourseModuleApiFetchParamCreator(configuration).adminCreateCourseModule(createModuleParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Удаление модуля
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourseModule(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseModuleApiFetchParamCreator(configuration).adminDeleteCourseModule(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Редактирование модуля курса (администратор)
         * @param {number} id 
         * @param {UpdateModuleParams} updateModuleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateCourseModule(id: number, updateModuleParams: UpdateModuleParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminFullCourseModule> {
            const localVarFetchArgs = CourseModuleApiFetchParamCreator(configuration).adminUpdateCourseModule(id, updateModuleParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Установка стратегии доступности для модуля курса (администратор)
         * @param {number} id 
         * @param {UpdateModuleAvailabilityStrategyParams} updateModuleAvailabilityStrategyParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateCourseModuleAvailabilityStrategy(id: number, updateModuleAvailabilityStrategyParams: UpdateModuleAvailabilityStrategyParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminFullCourseModule> {
            const localVarFetchArgs = CourseModuleApiFetchParamCreator(configuration).adminUpdateCourseModuleAvailabilityStrategy(id, updateModuleAvailabilityStrategyParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр модуля курса (администратор)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewCourseModule(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminFullCourseModule> {
            const localVarFetchArgs = CourseModuleApiFetchParamCreator(configuration).adminViewCourseModule(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр модуля курса преподавателем
         * @param {number} id 
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherViewCourseModule(id: number, course_id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TeacherFullCourseModule> {
            const localVarFetchArgs = CourseModuleApiFetchParamCreator(configuration).teacherViewCourseModule(id, course_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр модуля курса пользователем
         * @param {number} id 
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userViewCourseModule(id: number, course_id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserFullCourseModule> {
            const localVarFetchArgs = CourseModuleApiFetchParamCreator(configuration).userViewCourseModule(id, course_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CourseModuleApi - factory interface
 * @export
 */
export const CourseModuleApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Изменение сортировки секций модуля
         * @param {number} id 
         * @param {Array<number>} sectionReorderParams Содержит массив id секций курса, в том порядке как они должны быть отсортированы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSectionReorder(id: number, sectionReorderParams: Array<number>, options?: any) {
            return CourseModuleApiFp(configuration).adminCourseSectionReorder(id, sectionReorderParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Создание модуля курса
         * @param {CreateModuleParams} createModuleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseModule(createModuleParams: CreateModuleParams, options?: any) {
            return CourseModuleApiFp(configuration).adminCreateCourseModule(createModuleParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Удаление модуля
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourseModule(id: number, options?: any) {
            return CourseModuleApiFp(configuration).adminDeleteCourseModule(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Редактирование модуля курса (администратор)
         * @param {number} id 
         * @param {UpdateModuleParams} updateModuleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateCourseModule(id: number, updateModuleParams: UpdateModuleParams, options?: any) {
            return CourseModuleApiFp(configuration).adminUpdateCourseModule(id, updateModuleParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Установка стратегии доступности для модуля курса (администратор)
         * @param {number} id 
         * @param {UpdateModuleAvailabilityStrategyParams} updateModuleAvailabilityStrategyParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateCourseModuleAvailabilityStrategy(id: number, updateModuleAvailabilityStrategyParams: UpdateModuleAvailabilityStrategyParams, options?: any) {
            return CourseModuleApiFp(configuration).adminUpdateCourseModuleAvailabilityStrategy(id, updateModuleAvailabilityStrategyParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр модуля курса (администратор)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewCourseModule(id: number, options?: any) {
            return CourseModuleApiFp(configuration).adminViewCourseModule(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр модуля курса преподавателем
         * @param {number} id 
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherViewCourseModule(id: number, course_id: number, options?: any) {
            return CourseModuleApiFp(configuration).teacherViewCourseModule(id, course_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр модуля курса пользователем
         * @param {number} id 
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userViewCourseModule(id: number, course_id: number, options?: any) {
            return CourseModuleApiFp(configuration).userViewCourseModule(id, course_id, options)(fetch, basePath);
        },
    };
};

/**
 * CourseModuleApi - object-oriented interface
 * @export
 * @class CourseModuleApi
 * @extends {BaseAPI}
 */
export class CourseModuleApi extends BaseAPI {
    /**
     * 
     * @summary Изменение сортировки секций модуля
     * @param {number} id 
     * @param {Array<number>} sectionReorderParams Содержит массив id секций курса, в том порядке как они должны быть отсортированы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseModuleApi
     */
    public adminCourseSectionReorder(id: number, sectionReorderParams: Array<number>, options?: any) {
        return CourseModuleApiFp(this.configuration).adminCourseSectionReorder(id, sectionReorderParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Создание модуля курса
     * @param {CreateModuleParams} createModuleParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseModuleApi
     */
    public adminCreateCourseModule(createModuleParams: CreateModuleParams, options?: any) {
        return CourseModuleApiFp(this.configuration).adminCreateCourseModule(createModuleParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Удаление модуля
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseModuleApi
     */
    public adminDeleteCourseModule(id: number, options?: any) {
        return CourseModuleApiFp(this.configuration).adminDeleteCourseModule(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Редактирование модуля курса (администратор)
     * @param {number} id 
     * @param {UpdateModuleParams} updateModuleParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseModuleApi
     */
    public adminUpdateCourseModule(id: number, updateModuleParams: UpdateModuleParams, options?: any) {
        return CourseModuleApiFp(this.configuration).adminUpdateCourseModule(id, updateModuleParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Установка стратегии доступности для модуля курса (администратор)
     * @param {number} id 
     * @param {UpdateModuleAvailabilityStrategyParams} updateModuleAvailabilityStrategyParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseModuleApi
     */
    public adminUpdateCourseModuleAvailabilityStrategy(id: number, updateModuleAvailabilityStrategyParams: UpdateModuleAvailabilityStrategyParams, options?: any) {
        return CourseModuleApiFp(this.configuration).adminUpdateCourseModuleAvailabilityStrategy(id, updateModuleAvailabilityStrategyParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр модуля курса (администратор)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseModuleApi
     */
    public adminViewCourseModule(id: number, options?: any) {
        return CourseModuleApiFp(this.configuration).adminViewCourseModule(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр модуля курса преподавателем
     * @param {number} id 
     * @param {number} course_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseModuleApi
     */
    public teacherViewCourseModule(id: number, course_id: number, options?: any) {
        return CourseModuleApiFp(this.configuration).teacherViewCourseModule(id, course_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр модуля курса пользователем
     * @param {number} id 
     * @param {number} course_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseModuleApi
     */
    public userViewCourseModule(id: number, course_id: number, options?: any) {
        return CourseModuleApiFp(this.configuration).userViewCourseModule(id, course_id, options)(this.fetch, this.basePath);
    }

}

/**
 * CourseSectionApi - fetch parameter creator
 * @export
 */
export const CourseSectionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Изменение сортировки подмодулей секции
         * @param {number} id 
         * @param {Array<number>} submoduleReorderParams Содержит массив id подмодулей курса, в том порядке как они должны быть отсортированы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSubmoduleReorder(id: number, submoduleReorderParams: Array<number>, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminCourseSubmoduleReorder.');
            }
            // verify required parameter 'submoduleReorderParams' is not null or undefined
            if (submoduleReorderParams === null || submoduleReorderParams === undefined) {
                throw new RequiredError('submoduleReorderParams','Required parameter submoduleReorderParams was null or undefined when calling adminCourseSubmoduleReorder.');
            }
            const localVarPath = `/admin/course-section/{id}/reorder-submodules`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;number&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(submoduleReorderParams || {}) : (submoduleReorderParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание секции курса
         * @param {CreateSectionParams} createSectionParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseSection(createSectionParams: CreateSectionParams, options: any = {}): FetchArgs {
            // verify required parameter 'createSectionParams' is not null or undefined
            if (createSectionParams === null || createSectionParams === undefined) {
                throw new RequiredError('createSectionParams','Required parameter createSectionParams was null or undefined when calling adminCreateCourseSection.');
            }
            const localVarPath = `/admin/course-section`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateSectionParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(createSectionParams || {}) : (createSectionParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление секции
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourseSection(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminDeleteCourseSection.');
            }
            const localVarPath = `/admin/course-section/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование секции курса (администратор)
         * @param {number} id 
         * @param {UpdateSectionParams} updateSectionParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateCourseSection(id: number, updateSectionParams: UpdateSectionParams, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminUpdateCourseSection.');
            }
            // verify required parameter 'updateSectionParams' is not null or undefined
            if (updateSectionParams === null || updateSectionParams === undefined) {
                throw new RequiredError('updateSectionParams','Required parameter updateSectionParams was null or undefined when calling adminUpdateCourseSection.');
            }
            const localVarPath = `/admin/course-section/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateSectionParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateSectionParams || {}) : (updateSectionParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр секции курса (администратор)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewCourseSection(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminViewCourseSection.');
            }
            const localVarPath = `/admin/course-section/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр секции курса пользователем
         * @param {number} course_id 
         * @param {number} course_completion_id 
         * @param {number} section_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userViewCourseSection(course_id: number, course_completion_id: number, section_id: number, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling userViewCourseSection.');
            }
            // verify required parameter 'course_completion_id' is not null or undefined
            if (course_completion_id === null || course_completion_id === undefined) {
                throw new RequiredError('course_completion_id','Required parameter course_completion_id was null or undefined when calling userViewCourseSection.');
            }
            // verify required parameter 'section_id' is not null or undefined
            if (section_id === null || section_id === undefined) {
                throw new RequiredError('section_id','Required parameter section_id was null or undefined when calling userViewCourseSection.');
            }
            const localVarPath = `/user/course/{course_id}/completion/{course_completion_id}/section/{section_id}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)))
                .replace(`{${"course_completion_id"}}`, encodeURIComponent(String(course_completion_id)))
                .replace(`{${"section_id"}}`, encodeURIComponent(String(section_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр секции курса
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewSection(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling viewSection.');
            }
            const localVarPath = `/course-section/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseSectionApi - functional programming interface
 * @export
 */
export const CourseSectionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Изменение сортировки подмодулей секции
         * @param {number} id 
         * @param {Array<number>} submoduleReorderParams Содержит массив id подмодулей курса, в том порядке как они должны быть отсортированы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSubmoduleReorder(id: number, submoduleReorderParams: Array<number>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullCourseSection> {
            const localVarFetchArgs = CourseSectionApiFetchParamCreator(configuration).adminCourseSubmoduleReorder(id, submoduleReorderParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Создание секции курса
         * @param {CreateSectionParams} createSectionParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseSection(createSectionParams: CreateSectionParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullCourseSection> {
            const localVarFetchArgs = CourseSectionApiFetchParamCreator(configuration).adminCreateCourseSection(createSectionParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Удаление секции
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourseSection(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseSectionApiFetchParamCreator(configuration).adminDeleteCourseSection(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Редактирование секции курса (администратор)
         * @param {number} id 
         * @param {UpdateSectionParams} updateSectionParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateCourseSection(id: number, updateSectionParams: UpdateSectionParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullCourseSection> {
            const localVarFetchArgs = CourseSectionApiFetchParamCreator(configuration).adminUpdateCourseSection(id, updateSectionParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр секции курса (администратор)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewCourseSection(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullCourseSection> {
            const localVarFetchArgs = CourseSectionApiFetchParamCreator(configuration).adminViewCourseSection(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр секции курса пользователем
         * @param {number} course_id 
         * @param {number} course_completion_id 
         * @param {number} section_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userViewCourseSection(course_id: number, course_completion_id: number, section_id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserFullCourseSection> {
            const localVarFetchArgs = CourseSectionApiFetchParamCreator(configuration).userViewCourseSection(course_id, course_completion_id, section_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр секции курса
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewSection(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullCourseSection> {
            const localVarFetchArgs = CourseSectionApiFetchParamCreator(configuration).viewSection(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CourseSectionApi - factory interface
 * @export
 */
export const CourseSectionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Изменение сортировки подмодулей секции
         * @param {number} id 
         * @param {Array<number>} submoduleReorderParams Содержит массив id подмодулей курса, в том порядке как они должны быть отсортированы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSubmoduleReorder(id: number, submoduleReorderParams: Array<number>, options?: any) {
            return CourseSectionApiFp(configuration).adminCourseSubmoduleReorder(id, submoduleReorderParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Создание секции курса
         * @param {CreateSectionParams} createSectionParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseSection(createSectionParams: CreateSectionParams, options?: any) {
            return CourseSectionApiFp(configuration).adminCreateCourseSection(createSectionParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Удаление секции
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourseSection(id: number, options?: any) {
            return CourseSectionApiFp(configuration).adminDeleteCourseSection(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Редактирование секции курса (администратор)
         * @param {number} id 
         * @param {UpdateSectionParams} updateSectionParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateCourseSection(id: number, updateSectionParams: UpdateSectionParams, options?: any) {
            return CourseSectionApiFp(configuration).adminUpdateCourseSection(id, updateSectionParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр секции курса (администратор)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewCourseSection(id: number, options?: any) {
            return CourseSectionApiFp(configuration).adminViewCourseSection(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр секции курса пользователем
         * @param {number} course_id 
         * @param {number} course_completion_id 
         * @param {number} section_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userViewCourseSection(course_id: number, course_completion_id: number, section_id: number, options?: any) {
            return CourseSectionApiFp(configuration).userViewCourseSection(course_id, course_completion_id, section_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр секции курса
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewSection(id: number, options?: any) {
            return CourseSectionApiFp(configuration).viewSection(id, options)(fetch, basePath);
        },
    };
};

/**
 * CourseSectionApi - object-oriented interface
 * @export
 * @class CourseSectionApi
 * @extends {BaseAPI}
 */
export class CourseSectionApi extends BaseAPI {
    /**
     * 
     * @summary Изменение сортировки подмодулей секции
     * @param {number} id 
     * @param {Array<number>} submoduleReorderParams Содержит массив id подмодулей курса, в том порядке как они должны быть отсортированы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSectionApi
     */
    public adminCourseSubmoduleReorder(id: number, submoduleReorderParams: Array<number>, options?: any) {
        return CourseSectionApiFp(this.configuration).adminCourseSubmoduleReorder(id, submoduleReorderParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Создание секции курса
     * @param {CreateSectionParams} createSectionParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSectionApi
     */
    public adminCreateCourseSection(createSectionParams: CreateSectionParams, options?: any) {
        return CourseSectionApiFp(this.configuration).adminCreateCourseSection(createSectionParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Удаление секции
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSectionApi
     */
    public adminDeleteCourseSection(id: number, options?: any) {
        return CourseSectionApiFp(this.configuration).adminDeleteCourseSection(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Редактирование секции курса (администратор)
     * @param {number} id 
     * @param {UpdateSectionParams} updateSectionParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSectionApi
     */
    public adminUpdateCourseSection(id: number, updateSectionParams: UpdateSectionParams, options?: any) {
        return CourseSectionApiFp(this.configuration).adminUpdateCourseSection(id, updateSectionParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр секции курса (администратор)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSectionApi
     */
    public adminViewCourseSection(id: number, options?: any) {
        return CourseSectionApiFp(this.configuration).adminViewCourseSection(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр секции курса пользователем
     * @param {number} course_id 
     * @param {number} course_completion_id 
     * @param {number} section_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSectionApi
     */
    public userViewCourseSection(course_id: number, course_completion_id: number, section_id: number, options?: any) {
        return CourseSectionApiFp(this.configuration).userViewCourseSection(course_id, course_completion_id, section_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр секции курса
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSectionApi
     */
    public viewSection(id: number, options?: any) {
        return CourseSectionApiFp(this.configuration).viewSection(id, options)(this.fetch, this.basePath);
    }

}

/**
 * CourseSubmoduleApi - fetch parameter creator
 * @export
 */
export const CourseSubmoduleApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Список доступных стратегий начисления баллов
         * @param {number} type_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSubmoduleAvailablePeriodStrategies(type_id: number, options: any = {}): FetchArgs {
            // verify required parameter 'type_id' is not null or undefined
            if (type_id === null || type_id === undefined) {
                throw new RequiredError('type_id','Required parameter type_id was null or undefined when calling adminCourseSubmoduleAvailablePeriodStrategies.');
            }
            const localVarPath = `/admin/course-submodule/{type_id}/available-period-strategies`
                .replace(`{${"type_id"}}`, encodeURIComponent(String(type_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список доступных стратегий начисления баллов
         * @param {number} type_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSubmoduleAvailablePointStrategies(type_id: number, options: any = {}): FetchArgs {
            // verify required parameter 'type_id' is not null or undefined
            if (type_id === null || type_id === undefined) {
                throw new RequiredError('type_id','Required parameter type_id was null or undefined when calling adminCourseSubmoduleAvailablePointStrategies.');
            }
            const localVarPath = `/admin/course-submodule/{type_id}/available-point-strategies`
                .replace(`{${"type_id"}}`, encodeURIComponent(String(type_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary View quiz for admin (teacher)
         * @param {number} course_submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSubmoduleQuiz(course_submodule_id: number, options: any = {}): FetchArgs {
            // verify required parameter 'course_submodule_id' is not null or undefined
            if (course_submodule_id === null || course_submodule_id === undefined) {
                throw new RequiredError('course_submodule_id','Required parameter course_submodule_id was null or undefined when calling adminCourseSubmoduleQuiz.');
            }
            const localVarPath = `/admin/course-submodule/{course_submodule_id}/quiz`
                .replace(`{${"course_submodule_id"}}`, encodeURIComponent(String(course_submodule_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка видео для подмодуля
         * @param {number} id 
         * @param {any} [file] file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSubmoduleUploadVideo(id: number, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminCourseSubmoduleUploadVideo.');
            }
            const localVarPath = `/admin/course-submodule/{id}/upload-video`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание подмодуля курса
         * @param {CreateSubmoduleParams} createSubmoduleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseSubModule(createSubmoduleParams: CreateSubmoduleParams, options: any = {}): FetchArgs {
            // verify required parameter 'createSubmoduleParams' is not null or undefined
            if (createSubmoduleParams === null || createSubmoduleParams === undefined) {
                throw new RequiredError('createSubmoduleParams','Required parameter createSubmoduleParams was null or undefined when calling adminCreateCourseSubModule.');
            }
            const localVarPath = `/admin/course-submodule`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateSubmoduleParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(createSubmoduleParams || {}) : (createSubmoduleParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление подмодуля
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourseSubModule(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminDeleteCourseSubModule.');
            }
            const localVarPath = `/admin/course-submodule/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование подмодуля курса (администратор)
         * @param {number} id 
         * @param {UpdateSubmoduleParams} updateSubmoduleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateCourseSubModule(id: number, updateSubmoduleParams: UpdateSubmoduleParams, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminUpdateCourseSubModule.');
            }
            // verify required parameter 'updateSubmoduleParams' is not null or undefined
            if (updateSubmoduleParams === null || updateSubmoduleParams === undefined) {
                throw new RequiredError('updateSubmoduleParams','Required parameter updateSubmoduleParams was null or undefined when calling adminUpdateCourseSubModule.');
            }
            const localVarPath = `/admin/course-submodule/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateSubmoduleParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateSubmoduleParams || {}) : (updateSubmoduleParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр подмодуля курса (администратор)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewCourseSubModule(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminViewCourseSubModule.');
            }
            const localVarPath = `/admin/course-submodule/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание ответа викторины подмодуля
         * @param {number} course_submodule_id 
         * @param {number} question_id 
         * @param {SubmoduleQuizAnswerCreateParams} submoduleQuizAnswerCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubmoduleQuizAnswer(course_submodule_id: number, question_id: number, submoduleQuizAnswerCreateParams: SubmoduleQuizAnswerCreateParams, options: any = {}): FetchArgs {
            // verify required parameter 'course_submodule_id' is not null or undefined
            if (course_submodule_id === null || course_submodule_id === undefined) {
                throw new RequiredError('course_submodule_id','Required parameter course_submodule_id was null or undefined when calling createSubmoduleQuizAnswer.');
            }
            // verify required parameter 'question_id' is not null or undefined
            if (question_id === null || question_id === undefined) {
                throw new RequiredError('question_id','Required parameter question_id was null or undefined when calling createSubmoduleQuizAnswer.');
            }
            // verify required parameter 'submoduleQuizAnswerCreateParams' is not null or undefined
            if (submoduleQuizAnswerCreateParams === null || submoduleQuizAnswerCreateParams === undefined) {
                throw new RequiredError('submoduleQuizAnswerCreateParams','Required parameter submoduleQuizAnswerCreateParams was null or undefined when calling createSubmoduleQuizAnswer.');
            }
            const localVarPath = `/admin/course-submodule/{course_submodule_id}/quiz/question/{question_id}/answer`
                .replace(`{${"course_submodule_id"}}`, encodeURIComponent(String(course_submodule_id)))
                .replace(`{${"question_id"}}`, encodeURIComponent(String(question_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SubmoduleQuizAnswerCreateParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(submoduleQuizAnswerCreateParams || {}) : (submoduleQuizAnswerCreateParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание вопроса викторины подмодуля
         * @param {number} course_submodule_id 
         * @param {SubmoduleQuizQuestionCreateParams} submoduleQuizQuestionCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubmoduleQuizQuestion(course_submodule_id: number, submoduleQuizQuestionCreateParams: SubmoduleQuizQuestionCreateParams, options: any = {}): FetchArgs {
            // verify required parameter 'course_submodule_id' is not null or undefined
            if (course_submodule_id === null || course_submodule_id === undefined) {
                throw new RequiredError('course_submodule_id','Required parameter course_submodule_id was null or undefined when calling createSubmoduleQuizQuestion.');
            }
            // verify required parameter 'submoduleQuizQuestionCreateParams' is not null or undefined
            if (submoduleQuizQuestionCreateParams === null || submoduleQuizQuestionCreateParams === undefined) {
                throw new RequiredError('submoduleQuizQuestionCreateParams','Required parameter submoduleQuizQuestionCreateParams was null or undefined when calling createSubmoduleQuizQuestion.');
            }
            const localVarPath = `/admin/course-submodule/{course_submodule_id}/quiz/question`
                .replace(`{${"course_submodule_id"}}`, encodeURIComponent(String(course_submodule_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SubmoduleQuizQuestionCreateParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(submoduleQuizQuestionCreateParams || {}) : (submoduleQuizQuestionCreateParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление ответа на вопрос
         * @param {number} course_submodule_id 
         * @param {number} question_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubmoduleQuizAnswer(course_submodule_id: number, question_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'course_submodule_id' is not null or undefined
            if (course_submodule_id === null || course_submodule_id === undefined) {
                throw new RequiredError('course_submodule_id','Required parameter course_submodule_id was null or undefined when calling deleteSubmoduleQuizAnswer.');
            }
            // verify required parameter 'question_id' is not null or undefined
            if (question_id === null || question_id === undefined) {
                throw new RequiredError('question_id','Required parameter question_id was null or undefined when calling deleteSubmoduleQuizAnswer.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSubmoduleQuizAnswer.');
            }
            const localVarPath = `/admin/course-submodule/{course_submodule_id}/quiz/question/{question_id}/answer/{id}`
                .replace(`{${"course_submodule_id"}}`, encodeURIComponent(String(course_submodule_id)))
                .replace(`{${"question_id"}}`, encodeURIComponent(String(question_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление вопроса
         * @param {number} course_submodule_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubmoduleQuizQuestion(course_submodule_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'course_submodule_id' is not null or undefined
            if (course_submodule_id === null || course_submodule_id === undefined) {
                throw new RequiredError('course_submodule_id','Required parameter course_submodule_id was null or undefined when calling deleteSubmoduleQuizQuestion.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSubmoduleQuizQuestion.');
            }
            const localVarPath = `/admin/course-submodule/{course_submodule_id}/quiz/question/{id}`
                .replace(`{${"course_submodule_id"}}`, encodeURIComponent(String(course_submodule_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование ответа викторины подмодуля
         * @param {number} course_submodule_id 
         * @param {number} question_id 
         * @param {number} id 
         * @param {SubmoduleQuizAnswerEditParams} submoduleQuizAnswerEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSubmoduleQuizAnswer(course_submodule_id: number, question_id: number, id: number, submoduleQuizAnswerEditParams: SubmoduleQuizAnswerEditParams, options: any = {}): FetchArgs {
            // verify required parameter 'course_submodule_id' is not null or undefined
            if (course_submodule_id === null || course_submodule_id === undefined) {
                throw new RequiredError('course_submodule_id','Required parameter course_submodule_id was null or undefined when calling editSubmoduleQuizAnswer.');
            }
            // verify required parameter 'question_id' is not null or undefined
            if (question_id === null || question_id === undefined) {
                throw new RequiredError('question_id','Required parameter question_id was null or undefined when calling editSubmoduleQuizAnswer.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling editSubmoduleQuizAnswer.');
            }
            // verify required parameter 'submoduleQuizAnswerEditParams' is not null or undefined
            if (submoduleQuizAnswerEditParams === null || submoduleQuizAnswerEditParams === undefined) {
                throw new RequiredError('submoduleQuizAnswerEditParams','Required parameter submoduleQuizAnswerEditParams was null or undefined when calling editSubmoduleQuizAnswer.');
            }
            const localVarPath = `/admin/course-submodule/{course_submodule_id}/quiz/question/{question_id}/answer/{id}`
                .replace(`{${"course_submodule_id"}}`, encodeURIComponent(String(course_submodule_id)))
                .replace(`{${"question_id"}}`, encodeURIComponent(String(question_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SubmoduleQuizAnswerEditParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(submoduleQuizAnswerEditParams || {}) : (submoduleQuizAnswerEditParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование вопроса викторины подмодуля
         * @param {number} course_submodule_id 
         * @param {number} id 
         * @param {SubmoduleQuizQuestionEditParams} submoduleQuizQuestionEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSubmoduleQuizQuestion(course_submodule_id: number, id: number, submoduleQuizQuestionEditParams: SubmoduleQuizQuestionEditParams, options: any = {}): FetchArgs {
            // verify required parameter 'course_submodule_id' is not null or undefined
            if (course_submodule_id === null || course_submodule_id === undefined) {
                throw new RequiredError('course_submodule_id','Required parameter course_submodule_id was null or undefined when calling editSubmoduleQuizQuestion.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling editSubmoduleQuizQuestion.');
            }
            // verify required parameter 'submoduleQuizQuestionEditParams' is not null or undefined
            if (submoduleQuizQuestionEditParams === null || submoduleQuizQuestionEditParams === undefined) {
                throw new RequiredError('submoduleQuizQuestionEditParams','Required parameter submoduleQuizQuestionEditParams was null or undefined when calling editSubmoduleQuizQuestion.');
            }
            const localVarPath = `/admin/course-submodule/{course_submodule_id}/quiz/question/{id}`
                .replace(`{${"course_submodule_id"}}`, encodeURIComponent(String(course_submodule_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SubmoduleQuizQuestionEditParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(submoduleQuizQuestionEditParams || {}) : (submoduleQuizQuestionEditParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary View quiz for user
         * @param {number} course_submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCourseSubmoduleQuiz(course_submodule_id: number, options: any = {}): FetchArgs {
            // verify required parameter 'course_submodule_id' is not null or undefined
            if (course_submodule_id === null || course_submodule_id === undefined) {
                throw new RequiredError('course_submodule_id','Required parameter course_submodule_id was null or undefined when calling userCourseSubmoduleQuiz.');
            }
            const localVarPath = `/user/course-submodule/{course_submodule_id}/quiz`
                .replace(`{${"course_submodule_id"}}`, encodeURIComponent(String(course_submodule_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр подмодуля пользователя
         * @param {number} course_id 
         * @param {number} course_completion_id 
         * @param {number} submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userViewCourseSubmodule(course_id: number, course_completion_id: number, submodule_id: number, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling userViewCourseSubmodule.');
            }
            // verify required parameter 'course_completion_id' is not null or undefined
            if (course_completion_id === null || course_completion_id === undefined) {
                throw new RequiredError('course_completion_id','Required parameter course_completion_id was null or undefined when calling userViewCourseSubmodule.');
            }
            // verify required parameter 'submodule_id' is not null or undefined
            if (submodule_id === null || submodule_id === undefined) {
                throw new RequiredError('submodule_id','Required parameter submodule_id was null or undefined when calling userViewCourseSubmodule.');
            }
            const localVarPath = `/user/course/{course_id}/completion/{course_completion_id}/submodule/{submodule_id}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)))
                .replace(`{${"course_completion_id"}}`, encodeURIComponent(String(course_completion_id)))
                .replace(`{${"submodule_id"}}`, encodeURIComponent(String(submodule_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр подробностей последнего прохождения
         * @param {number} course_id 
         * @param {number} course_completion_id 
         * @param {number} submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userViewSubmoduleResult(course_id: number, course_completion_id: number, submodule_id: number, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling userViewSubmoduleResult.');
            }
            // verify required parameter 'course_completion_id' is not null or undefined
            if (course_completion_id === null || course_completion_id === undefined) {
                throw new RequiredError('course_completion_id','Required parameter course_completion_id was null or undefined when calling userViewSubmoduleResult.');
            }
            // verify required parameter 'submodule_id' is not null or undefined
            if (submodule_id === null || submodule_id === undefined) {
                throw new RequiredError('submodule_id','Required parameter submodule_id was null or undefined when calling userViewSubmoduleResult.');
            }
            const localVarPath = `/user/course/{course_id}/completion/{course_completion_id}/submodule/{submodule_id}/result`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)))
                .replace(`{${"course_completion_id"}}`, encodeURIComponent(String(course_completion_id)))
                .replace(`{${"submodule_id"}}`, encodeURIComponent(String(submodule_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseSubmoduleApi - functional programming interface
 * @export
 */
export const CourseSubmoduleApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Список доступных стратегий начисления баллов
         * @param {number} type_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSubmoduleAvailablePeriodStrategies(type_id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IdNameEntityList> {
            const localVarFetchArgs = CourseSubmoduleApiFetchParamCreator(configuration).adminCourseSubmoduleAvailablePeriodStrategies(type_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Список доступных стратегий начисления баллов
         * @param {number} type_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSubmoduleAvailablePointStrategies(type_id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IdNameEntityList> {
            const localVarFetchArgs = CourseSubmoduleApiFetchParamCreator(configuration).adminCourseSubmoduleAvailablePointStrategies(type_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary View quiz for admin (teacher)
         * @param {number} course_submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSubmoduleQuiz(course_submodule_id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminFullQuiz> {
            const localVarFetchArgs = CourseSubmoduleApiFetchParamCreator(configuration).adminCourseSubmoduleQuiz(course_submodule_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Загрузка видео для подмодуля
         * @param {number} id 
         * @param {any} [file] file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSubmoduleUploadVideo(id: number, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UrlResponse> {
            const localVarFetchArgs = CourseSubmoduleApiFetchParamCreator(configuration).adminCourseSubmoduleUploadVideo(id, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Создание подмодуля курса
         * @param {CreateSubmoduleParams} createSubmoduleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseSubModule(createSubmoduleParams: CreateSubmoduleParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminFullCourseSubmodule> {
            const localVarFetchArgs = CourseSubmoduleApiFetchParamCreator(configuration).adminCreateCourseSubModule(createSubmoduleParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Удаление подмодуля
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourseSubModule(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseSubmoduleApiFetchParamCreator(configuration).adminDeleteCourseSubModule(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Редактирование подмодуля курса (администратор)
         * @param {number} id 
         * @param {UpdateSubmoduleParams} updateSubmoduleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateCourseSubModule(id: number, updateSubmoduleParams: UpdateSubmoduleParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminFullCourseSubmodule> {
            const localVarFetchArgs = CourseSubmoduleApiFetchParamCreator(configuration).adminUpdateCourseSubModule(id, updateSubmoduleParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр подмодуля курса (администратор)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewCourseSubModule(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminFullCourseSubmodule> {
            const localVarFetchArgs = CourseSubmoduleApiFetchParamCreator(configuration).adminViewCourseSubModule(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Создание ответа викторины подмодуля
         * @param {number} course_submodule_id 
         * @param {number} question_id 
         * @param {SubmoduleQuizAnswerCreateParams} submoduleQuizAnswerCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubmoduleQuizAnswer(course_submodule_id: number, question_id: number, submoduleQuizAnswerCreateParams: SubmoduleQuizAnswerCreateParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminQuizAnswer> {
            const localVarFetchArgs = CourseSubmoduleApiFetchParamCreator(configuration).createSubmoduleQuizAnswer(course_submodule_id, question_id, submoduleQuizAnswerCreateParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Создание вопроса викторины подмодуля
         * @param {number} course_submodule_id 
         * @param {SubmoduleQuizQuestionCreateParams} submoduleQuizQuestionCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubmoduleQuizQuestion(course_submodule_id: number, submoduleQuizQuestionCreateParams: SubmoduleQuizQuestionCreateParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminQuizQuestion> {
            const localVarFetchArgs = CourseSubmoduleApiFetchParamCreator(configuration).createSubmoduleQuizQuestion(course_submodule_id, submoduleQuizQuestionCreateParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Удаление ответа на вопрос
         * @param {number} course_submodule_id 
         * @param {number} question_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubmoduleQuizAnswer(course_submodule_id: number, question_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseSubmoduleApiFetchParamCreator(configuration).deleteSubmoduleQuizAnswer(course_submodule_id, question_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Удаление вопроса
         * @param {number} course_submodule_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubmoduleQuizQuestion(course_submodule_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseSubmoduleApiFetchParamCreator(configuration).deleteSubmoduleQuizQuestion(course_submodule_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Редактирование ответа викторины подмодуля
         * @param {number} course_submodule_id 
         * @param {number} question_id 
         * @param {number} id 
         * @param {SubmoduleQuizAnswerEditParams} submoduleQuizAnswerEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSubmoduleQuizAnswer(course_submodule_id: number, question_id: number, id: number, submoduleQuizAnswerEditParams: SubmoduleQuizAnswerEditParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminQuizAnswer> {
            const localVarFetchArgs = CourseSubmoduleApiFetchParamCreator(configuration).editSubmoduleQuizAnswer(course_submodule_id, question_id, id, submoduleQuizAnswerEditParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Редактирование вопроса викторины подмодуля
         * @param {number} course_submodule_id 
         * @param {number} id 
         * @param {SubmoduleQuizQuestionEditParams} submoduleQuizQuestionEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSubmoduleQuizQuestion(course_submodule_id: number, id: number, submoduleQuizQuestionEditParams: SubmoduleQuizQuestionEditParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminQuizQuestion> {
            const localVarFetchArgs = CourseSubmoduleApiFetchParamCreator(configuration).editSubmoduleQuizQuestion(course_submodule_id, id, submoduleQuizQuestionEditParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary View quiz for user
         * @param {number} course_submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCourseSubmoduleQuiz(course_submodule_id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserFullQuiz> {
            const localVarFetchArgs = CourseSubmoduleApiFetchParamCreator(configuration).userCourseSubmoduleQuiz(course_submodule_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр подмодуля пользователя
         * @param {number} course_id 
         * @param {number} course_completion_id 
         * @param {number} submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userViewCourseSubmodule(course_id: number, course_completion_id: number, submodule_id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserFullCourseSubmodule> {
            const localVarFetchArgs = CourseSubmoduleApiFetchParamCreator(configuration).userViewCourseSubmodule(course_id, course_completion_id, submodule_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр подробностей последнего прохождения
         * @param {number} course_id 
         * @param {number} course_completion_id 
         * @param {number} submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userViewSubmoduleResult(course_id: number, course_completion_id: number, submodule_id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserCourseSubmoduleCompletionResult> {
            const localVarFetchArgs = CourseSubmoduleApiFetchParamCreator(configuration).userViewSubmoduleResult(course_id, course_completion_id, submodule_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CourseSubmoduleApi - factory interface
 * @export
 */
export const CourseSubmoduleApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Список доступных стратегий начисления баллов
         * @param {number} type_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSubmoduleAvailablePeriodStrategies(type_id: number, options?: any) {
            return CourseSubmoduleApiFp(configuration).adminCourseSubmoduleAvailablePeriodStrategies(type_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Список доступных стратегий начисления баллов
         * @param {number} type_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSubmoduleAvailablePointStrategies(type_id: number, options?: any) {
            return CourseSubmoduleApiFp(configuration).adminCourseSubmoduleAvailablePointStrategies(type_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary View quiz for admin (teacher)
         * @param {number} course_submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSubmoduleQuiz(course_submodule_id: number, options?: any) {
            return CourseSubmoduleApiFp(configuration).adminCourseSubmoduleQuiz(course_submodule_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Загрузка видео для подмодуля
         * @param {number} id 
         * @param {any} [file] file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseSubmoduleUploadVideo(id: number, file?: any, options?: any) {
            return CourseSubmoduleApiFp(configuration).adminCourseSubmoduleUploadVideo(id, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Создание подмодуля курса
         * @param {CreateSubmoduleParams} createSubmoduleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseSubModule(createSubmoduleParams: CreateSubmoduleParams, options?: any) {
            return CourseSubmoduleApiFp(configuration).adminCreateCourseSubModule(createSubmoduleParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Удаление подмодуля
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourseSubModule(id: number, options?: any) {
            return CourseSubmoduleApiFp(configuration).adminDeleteCourseSubModule(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Редактирование подмодуля курса (администратор)
         * @param {number} id 
         * @param {UpdateSubmoduleParams} updateSubmoduleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateCourseSubModule(id: number, updateSubmoduleParams: UpdateSubmoduleParams, options?: any) {
            return CourseSubmoduleApiFp(configuration).adminUpdateCourseSubModule(id, updateSubmoduleParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр подмодуля курса (администратор)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewCourseSubModule(id: number, options?: any) {
            return CourseSubmoduleApiFp(configuration).adminViewCourseSubModule(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Создание ответа викторины подмодуля
         * @param {number} course_submodule_id 
         * @param {number} question_id 
         * @param {SubmoduleQuizAnswerCreateParams} submoduleQuizAnswerCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubmoduleQuizAnswer(course_submodule_id: number, question_id: number, submoduleQuizAnswerCreateParams: SubmoduleQuizAnswerCreateParams, options?: any) {
            return CourseSubmoduleApiFp(configuration).createSubmoduleQuizAnswer(course_submodule_id, question_id, submoduleQuizAnswerCreateParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Создание вопроса викторины подмодуля
         * @param {number} course_submodule_id 
         * @param {SubmoduleQuizQuestionCreateParams} submoduleQuizQuestionCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubmoduleQuizQuestion(course_submodule_id: number, submoduleQuizQuestionCreateParams: SubmoduleQuizQuestionCreateParams, options?: any) {
            return CourseSubmoduleApiFp(configuration).createSubmoduleQuizQuestion(course_submodule_id, submoduleQuizQuestionCreateParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Удаление ответа на вопрос
         * @param {number} course_submodule_id 
         * @param {number} question_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubmoduleQuizAnswer(course_submodule_id: number, question_id: number, id: number, options?: any) {
            return CourseSubmoduleApiFp(configuration).deleteSubmoduleQuizAnswer(course_submodule_id, question_id, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Удаление вопроса
         * @param {number} course_submodule_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubmoduleQuizQuestion(course_submodule_id: number, id: number, options?: any) {
            return CourseSubmoduleApiFp(configuration).deleteSubmoduleQuizQuestion(course_submodule_id, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Редактирование ответа викторины подмодуля
         * @param {number} course_submodule_id 
         * @param {number} question_id 
         * @param {number} id 
         * @param {SubmoduleQuizAnswerEditParams} submoduleQuizAnswerEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSubmoduleQuizAnswer(course_submodule_id: number, question_id: number, id: number, submoduleQuizAnswerEditParams: SubmoduleQuizAnswerEditParams, options?: any) {
            return CourseSubmoduleApiFp(configuration).editSubmoduleQuizAnswer(course_submodule_id, question_id, id, submoduleQuizAnswerEditParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Редактирование вопроса викторины подмодуля
         * @param {number} course_submodule_id 
         * @param {number} id 
         * @param {SubmoduleQuizQuestionEditParams} submoduleQuizQuestionEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSubmoduleQuizQuestion(course_submodule_id: number, id: number, submoduleQuizQuestionEditParams: SubmoduleQuizQuestionEditParams, options?: any) {
            return CourseSubmoduleApiFp(configuration).editSubmoduleQuizQuestion(course_submodule_id, id, submoduleQuizQuestionEditParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary View quiz for user
         * @param {number} course_submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCourseSubmoduleQuiz(course_submodule_id: number, options?: any) {
            return CourseSubmoduleApiFp(configuration).userCourseSubmoduleQuiz(course_submodule_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр подмодуля пользователя
         * @param {number} course_id 
         * @param {number} course_completion_id 
         * @param {number} submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userViewCourseSubmodule(course_id: number, course_completion_id: number, submodule_id: number, options?: any) {
            return CourseSubmoduleApiFp(configuration).userViewCourseSubmodule(course_id, course_completion_id, submodule_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр подробностей последнего прохождения
         * @param {number} course_id 
         * @param {number} course_completion_id 
         * @param {number} submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userViewSubmoduleResult(course_id: number, course_completion_id: number, submodule_id: number, options?: any) {
            return CourseSubmoduleApiFp(configuration).userViewSubmoduleResult(course_id, course_completion_id, submodule_id, options)(fetch, basePath);
        },
    };
};

/**
 * CourseSubmoduleApi - object-oriented interface
 * @export
 * @class CourseSubmoduleApi
 * @extends {BaseAPI}
 */
export class CourseSubmoduleApi extends BaseAPI {
    /**
     * 
     * @summary Список доступных стратегий начисления баллов
     * @param {number} type_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleApi
     */
    public adminCourseSubmoduleAvailablePeriodStrategies(type_id: number, options?: any) {
        return CourseSubmoduleApiFp(this.configuration).adminCourseSubmoduleAvailablePeriodStrategies(type_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Список доступных стратегий начисления баллов
     * @param {number} type_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleApi
     */
    public adminCourseSubmoduleAvailablePointStrategies(type_id: number, options?: any) {
        return CourseSubmoduleApiFp(this.configuration).adminCourseSubmoduleAvailablePointStrategies(type_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary View quiz for admin (teacher)
     * @param {number} course_submodule_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleApi
     */
    public adminCourseSubmoduleQuiz(course_submodule_id: number, options?: any) {
        return CourseSubmoduleApiFp(this.configuration).adminCourseSubmoduleQuiz(course_submodule_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Загрузка видео для подмодуля
     * @param {number} id 
     * @param {any} [file] file to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleApi
     */
    public adminCourseSubmoduleUploadVideo(id: number, file?: any, options?: any) {
        return CourseSubmoduleApiFp(this.configuration).adminCourseSubmoduleUploadVideo(id, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Создание подмодуля курса
     * @param {CreateSubmoduleParams} createSubmoduleParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleApi
     */
    public adminCreateCourseSubModule(createSubmoduleParams: CreateSubmoduleParams, options?: any) {
        return CourseSubmoduleApiFp(this.configuration).adminCreateCourseSubModule(createSubmoduleParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Удаление подмодуля
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleApi
     */
    public adminDeleteCourseSubModule(id: number, options?: any) {
        return CourseSubmoduleApiFp(this.configuration).adminDeleteCourseSubModule(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Редактирование подмодуля курса (администратор)
     * @param {number} id 
     * @param {UpdateSubmoduleParams} updateSubmoduleParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleApi
     */
    public adminUpdateCourseSubModule(id: number, updateSubmoduleParams: UpdateSubmoduleParams, options?: any) {
        return CourseSubmoduleApiFp(this.configuration).adminUpdateCourseSubModule(id, updateSubmoduleParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр подмодуля курса (администратор)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleApi
     */
    public adminViewCourseSubModule(id: number, options?: any) {
        return CourseSubmoduleApiFp(this.configuration).adminViewCourseSubModule(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Создание ответа викторины подмодуля
     * @param {number} course_submodule_id 
     * @param {number} question_id 
     * @param {SubmoduleQuizAnswerCreateParams} submoduleQuizAnswerCreateParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleApi
     */
    public createSubmoduleQuizAnswer(course_submodule_id: number, question_id: number, submoduleQuizAnswerCreateParams: SubmoduleQuizAnswerCreateParams, options?: any) {
        return CourseSubmoduleApiFp(this.configuration).createSubmoduleQuizAnswer(course_submodule_id, question_id, submoduleQuizAnswerCreateParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Создание вопроса викторины подмодуля
     * @param {number} course_submodule_id 
     * @param {SubmoduleQuizQuestionCreateParams} submoduleQuizQuestionCreateParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleApi
     */
    public createSubmoduleQuizQuestion(course_submodule_id: number, submoduleQuizQuestionCreateParams: SubmoduleQuizQuestionCreateParams, options?: any) {
        return CourseSubmoduleApiFp(this.configuration).createSubmoduleQuizQuestion(course_submodule_id, submoduleQuizQuestionCreateParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Удаление ответа на вопрос
     * @param {number} course_submodule_id 
     * @param {number} question_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleApi
     */
    public deleteSubmoduleQuizAnswer(course_submodule_id: number, question_id: number, id: number, options?: any) {
        return CourseSubmoduleApiFp(this.configuration).deleteSubmoduleQuizAnswer(course_submodule_id, question_id, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Удаление вопроса
     * @param {number} course_submodule_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleApi
     */
    public deleteSubmoduleQuizQuestion(course_submodule_id: number, id: number, options?: any) {
        return CourseSubmoduleApiFp(this.configuration).deleteSubmoduleQuizQuestion(course_submodule_id, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Редактирование ответа викторины подмодуля
     * @param {number} course_submodule_id 
     * @param {number} question_id 
     * @param {number} id 
     * @param {SubmoduleQuizAnswerEditParams} submoduleQuizAnswerEditParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleApi
     */
    public editSubmoduleQuizAnswer(course_submodule_id: number, question_id: number, id: number, submoduleQuizAnswerEditParams: SubmoduleQuizAnswerEditParams, options?: any) {
        return CourseSubmoduleApiFp(this.configuration).editSubmoduleQuizAnswer(course_submodule_id, question_id, id, submoduleQuizAnswerEditParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Редактирование вопроса викторины подмодуля
     * @param {number} course_submodule_id 
     * @param {number} id 
     * @param {SubmoduleQuizQuestionEditParams} submoduleQuizQuestionEditParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleApi
     */
    public editSubmoduleQuizQuestion(course_submodule_id: number, id: number, submoduleQuizQuestionEditParams: SubmoduleQuizQuestionEditParams, options?: any) {
        return CourseSubmoduleApiFp(this.configuration).editSubmoduleQuizQuestion(course_submodule_id, id, submoduleQuizQuestionEditParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary View quiz for user
     * @param {number} course_submodule_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleApi
     */
    public userCourseSubmoduleQuiz(course_submodule_id: number, options?: any) {
        return CourseSubmoduleApiFp(this.configuration).userCourseSubmoduleQuiz(course_submodule_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр подмодуля пользователя
     * @param {number} course_id 
     * @param {number} course_completion_id 
     * @param {number} submodule_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleApi
     */
    public userViewCourseSubmodule(course_id: number, course_completion_id: number, submodule_id: number, options?: any) {
        return CourseSubmoduleApiFp(this.configuration).userViewCourseSubmodule(course_id, course_completion_id, submodule_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр подробностей последнего прохождения
     * @param {number} course_id 
     * @param {number} course_completion_id 
     * @param {number} submodule_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleApi
     */
    public userViewSubmoduleResult(course_id: number, course_completion_id: number, submodule_id: number, options?: any) {
        return CourseSubmoduleApiFp(this.configuration).userViewSubmoduleResult(course_id, course_completion_id, submodule_id, options)(this.fetch, this.basePath);
    }

}

/**
 * CourseSubmoduleCompletionApi - fetch parameter creator
 * @export
 */
export const CourseSubmoduleCompletionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Отправка результатов прохождения подмодуля
         * @param {number} course_id 
         * @param {number} course_completion_id 
         * @param {number} submodule_id 
         * @param {number} submodule_completion_id 
         * @param {CourseSubmoduleCompletionData} submoduleCompletion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attemptCourseSubmoduleCompletion(course_id: number, course_completion_id: number, submodule_id: number, submodule_completion_id: number, submoduleCompletion: CourseSubmoduleCompletionData, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling attemptCourseSubmoduleCompletion.');
            }
            // verify required parameter 'course_completion_id' is not null or undefined
            if (course_completion_id === null || course_completion_id === undefined) {
                throw new RequiredError('course_completion_id','Required parameter course_completion_id was null or undefined when calling attemptCourseSubmoduleCompletion.');
            }
            // verify required parameter 'submodule_id' is not null or undefined
            if (submodule_id === null || submodule_id === undefined) {
                throw new RequiredError('submodule_id','Required parameter submodule_id was null or undefined when calling attemptCourseSubmoduleCompletion.');
            }
            // verify required parameter 'submodule_completion_id' is not null or undefined
            if (submodule_completion_id === null || submodule_completion_id === undefined) {
                throw new RequiredError('submodule_completion_id','Required parameter submodule_completion_id was null or undefined when calling attemptCourseSubmoduleCompletion.');
            }
            // verify required parameter 'submoduleCompletion' is not null or undefined
            if (submoduleCompletion === null || submoduleCompletion === undefined) {
                throw new RequiredError('submoduleCompletion','Required parameter submoduleCompletion was null or undefined when calling attemptCourseSubmoduleCompletion.');
            }
            const localVarPath = `/user/course/{course_id}/completion/{course_completion_id}/submodule/{submodule_id}/course-submodule-completion/{submodule_completion_id}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)))
                .replace(`{${"course_completion_id"}}`, encodeURIComponent(String(course_completion_id)))
                .replace(`{${"submodule_id"}}`, encodeURIComponent(String(submodule_id)))
                .replace(`{${"submodule_completion_id"}}`, encodeURIComponent(String(submodule_completion_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CourseSubmoduleCompletionData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(submoduleCompletion || {}) : (submoduleCompletion || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка файла для прохождения подмодуля
         * @param {number} course_id 
         * @param {number} course_completion_id 
         * @param {number} submodule_id 
         * @param {number} submodule_completion_id 
         * @param {any} [file] file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attemptSubmoduleCompletionUpload(course_id: number, course_completion_id: number, submodule_id: number, submodule_completion_id: number, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling attemptSubmoduleCompletionUpload.');
            }
            // verify required parameter 'course_completion_id' is not null or undefined
            if (course_completion_id === null || course_completion_id === undefined) {
                throw new RequiredError('course_completion_id','Required parameter course_completion_id was null or undefined when calling attemptSubmoduleCompletionUpload.');
            }
            // verify required parameter 'submodule_id' is not null or undefined
            if (submodule_id === null || submodule_id === undefined) {
                throw new RequiredError('submodule_id','Required parameter submodule_id was null or undefined when calling attemptSubmoduleCompletionUpload.');
            }
            // verify required parameter 'submodule_completion_id' is not null or undefined
            if (submodule_completion_id === null || submodule_completion_id === undefined) {
                throw new RequiredError('submodule_completion_id','Required parameter submodule_completion_id was null or undefined when calling attemptSubmoduleCompletionUpload.');
            }
            const localVarPath = `/user/course/{course_id}/completion/{course_completion_id}/submodule/{submodule_id}/course-submodule-completion/{submodule_completion_id}/upload`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)))
                .replace(`{${"course_completion_id"}}`, encodeURIComponent(String(course_completion_id)))
                .replace(`{${"submodule_id"}}`, encodeURIComponent(String(submodule_id)))
                .replace(`{${"submodule_completion_id"}}`, encodeURIComponent(String(submodule_completion_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Экспорт подмодулей
         * @param {number} course_id 
         * @param {number} submodule_id 
         * @param {ExportParams} exportParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherExportSubmoduleCompletionList(course_id: number, submodule_id: number, exportParams: ExportParams, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling teacherExportSubmoduleCompletionList.');
            }
            // verify required parameter 'submodule_id' is not null or undefined
            if (submodule_id === null || submodule_id === undefined) {
                throw new RequiredError('submodule_id','Required parameter submodule_id was null or undefined when calling teacherExportSubmoduleCompletionList.');
            }
            // verify required parameter 'exportParams' is not null or undefined
            if (exportParams === null || exportParams === undefined) {
                throw new RequiredError('exportParams','Required parameter exportParams was null or undefined when calling teacherExportSubmoduleCompletionList.');
            }
            const localVarPath = `/teacher/course/{course_id}/submodule/{submodule_id}/course-submodule-completion/export`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)))
                .replace(`{${"submodule_id"}}`, encodeURIComponent(String(submodule_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ExportParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(exportParams || {}) : (exportParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список попмодулей для проверки преподавателем
         * @param {number} course_id 
         * @param {number} submodule_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherSubmoduleCompletionList(course_id: number, submodule_id: number, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling teacherSubmoduleCompletionList.');
            }
            // verify required parameter 'submodule_id' is not null or undefined
            if (submodule_id === null || submodule_id === undefined) {
                throw new RequiredError('submodule_id','Required parameter submodule_id was null or undefined when calling teacherSubmoduleCompletionList.');
            }
            const localVarPath = `/teacher/course/{course_id}/submodule/{submodule_id}/course-submodule-completion`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)))
                .replace(`{${"submodule_id"}}`, encodeURIComponent(String(submodule_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Проверка результатов прохождения подмодуля преподавателем
         * @param {number} course_id 
         * @param {number} submodule_id 
         * @param {number} submodule_completion_id 
         * @param {VerificationResult} verificationResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherVerifySubmoduleCompletion(course_id: number, submodule_id: number, submodule_completion_id: number, verificationResult: VerificationResult, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling teacherVerifySubmoduleCompletion.');
            }
            // verify required parameter 'submodule_id' is not null or undefined
            if (submodule_id === null || submodule_id === undefined) {
                throw new RequiredError('submodule_id','Required parameter submodule_id was null or undefined when calling teacherVerifySubmoduleCompletion.');
            }
            // verify required parameter 'submodule_completion_id' is not null or undefined
            if (submodule_completion_id === null || submodule_completion_id === undefined) {
                throw new RequiredError('submodule_completion_id','Required parameter submodule_completion_id was null or undefined when calling teacherVerifySubmoduleCompletion.');
            }
            // verify required parameter 'verificationResult' is not null or undefined
            if (verificationResult === null || verificationResult === undefined) {
                throw new RequiredError('verificationResult','Required parameter verificationResult was null or undefined when calling teacherVerifySubmoduleCompletion.');
            }
            const localVarPath = `/teacher/course/{course_id}/submodule/{submodule_id}/course-submodule-completion/{submodule_completion_id}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)))
                .replace(`{${"submodule_id"}}`, encodeURIComponent(String(submodule_id)))
                .replace(`{${"submodule_completion_id"}}`, encodeURIComponent(String(submodule_completion_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"VerificationResult" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(verificationResult || {}) : (verificationResult || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Начало прохождения подмодуля
         * @param {number} course_id 
         * @param {number} course_completion_id 
         * @param {number} submodule_id 
         * @param {boolean} [skipOnExist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreateCourseSubmoduleCompletion(course_id: number, course_completion_id: number, submodule_id: number, skipOnExist?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling userCreateCourseSubmoduleCompletion.');
            }
            // verify required parameter 'course_completion_id' is not null or undefined
            if (course_completion_id === null || course_completion_id === undefined) {
                throw new RequiredError('course_completion_id','Required parameter course_completion_id was null or undefined when calling userCreateCourseSubmoduleCompletion.');
            }
            // verify required parameter 'submodule_id' is not null or undefined
            if (submodule_id === null || submodule_id === undefined) {
                throw new RequiredError('submodule_id','Required parameter submodule_id was null or undefined when calling userCreateCourseSubmoduleCompletion.');
            }
            const localVarPath = `/user/course/{course_id}/completion/{course_completion_id}/submodule/{submodule_id}/course-submodule-completion`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)))
                .replace(`{${"course_completion_id"}}`, encodeURIComponent(String(course_completion_id)))
                .replace(`{${"submodule_id"}}`, encodeURIComponent(String(submodule_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (skipOnExist !== undefined) {
                localVarQueryParameter['skipOnExist'] = skipOnExist;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseSubmoduleCompletionApi - functional programming interface
 * @export
 */
export const CourseSubmoduleCompletionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Отправка результатов прохождения подмодуля
         * @param {number} course_id 
         * @param {number} course_completion_id 
         * @param {number} submodule_id 
         * @param {number} submodule_completion_id 
         * @param {CourseSubmoduleCompletionData} submoduleCompletion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attemptCourseSubmoduleCompletion(course_id: number, course_completion_id: number, submodule_id: number, submodule_completion_id: number, submoduleCompletion: CourseSubmoduleCompletionData, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseSubmoduleCompletionApiFetchParamCreator(configuration).attemptCourseSubmoduleCompletion(course_id, course_completion_id, submodule_id, submodule_completion_id, submoduleCompletion, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Загрузка файла для прохождения подмодуля
         * @param {number} course_id 
         * @param {number} course_completion_id 
         * @param {number} submodule_id 
         * @param {number} submodule_completion_id 
         * @param {any} [file] file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attemptSubmoduleCompletionUpload(course_id: number, course_completion_id: number, submodule_id: number, submodule_completion_id: number, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UrlResponse> {
            const localVarFetchArgs = CourseSubmoduleCompletionApiFetchParamCreator(configuration).attemptSubmoduleCompletionUpload(course_id, course_completion_id, submodule_id, submodule_completion_id, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Экспорт подмодулей
         * @param {number} course_id 
         * @param {number} submodule_id 
         * @param {ExportParams} exportParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherExportSubmoduleCompletionList(course_id: number, submodule_id: number, exportParams: ExportParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseSubmoduleCompletionApiFetchParamCreator(configuration).teacherExportSubmoduleCompletionList(course_id, submodule_id, exportParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Список попмодулей для проверки преподавателем
         * @param {number} course_id 
         * @param {number} submodule_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherSubmoduleCompletionList(course_id: number, submodule_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TeacherSubmoduleCompletionList> {
            const localVarFetchArgs = CourseSubmoduleCompletionApiFetchParamCreator(configuration).teacherSubmoduleCompletionList(course_id, submodule_id, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Проверка результатов прохождения подмодуля преподавателем
         * @param {number} course_id 
         * @param {number} submodule_id 
         * @param {number} submodule_completion_id 
         * @param {VerificationResult} verificationResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherVerifySubmoduleCompletion(course_id: number, submodule_id: number, submodule_completion_id: number, verificationResult: VerificationResult, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseSubmoduleCompletionApiFetchParamCreator(configuration).teacherVerifySubmoduleCompletion(course_id, submodule_id, submodule_completion_id, verificationResult, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Начало прохождения подмодуля
         * @param {number} course_id 
         * @param {number} course_completion_id 
         * @param {number} submodule_id 
         * @param {boolean} [skipOnExist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreateCourseSubmoduleCompletion(course_id: number, course_completion_id: number, submodule_id: number, skipOnExist?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserCourseSubmoduleCompletion> {
            const localVarFetchArgs = CourseSubmoduleCompletionApiFetchParamCreator(configuration).userCreateCourseSubmoduleCompletion(course_id, course_completion_id, submodule_id, skipOnExist, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CourseSubmoduleCompletionApi - factory interface
 * @export
 */
export const CourseSubmoduleCompletionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Отправка результатов прохождения подмодуля
         * @param {number} course_id 
         * @param {number} course_completion_id 
         * @param {number} submodule_id 
         * @param {number} submodule_completion_id 
         * @param {CourseSubmoduleCompletionData} submoduleCompletion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attemptCourseSubmoduleCompletion(course_id: number, course_completion_id: number, submodule_id: number, submodule_completion_id: number, submoduleCompletion: CourseSubmoduleCompletionData, options?: any) {
            return CourseSubmoduleCompletionApiFp(configuration).attemptCourseSubmoduleCompletion(course_id, course_completion_id, submodule_id, submodule_completion_id, submoduleCompletion, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Загрузка файла для прохождения подмодуля
         * @param {number} course_id 
         * @param {number} course_completion_id 
         * @param {number} submodule_id 
         * @param {number} submodule_completion_id 
         * @param {any} [file] file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attemptSubmoduleCompletionUpload(course_id: number, course_completion_id: number, submodule_id: number, submodule_completion_id: number, file?: any, options?: any) {
            return CourseSubmoduleCompletionApiFp(configuration).attemptSubmoduleCompletionUpload(course_id, course_completion_id, submodule_id, submodule_completion_id, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Экспорт подмодулей
         * @param {number} course_id 
         * @param {number} submodule_id 
         * @param {ExportParams} exportParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherExportSubmoduleCompletionList(course_id: number, submodule_id: number, exportParams: ExportParams, options?: any) {
            return CourseSubmoduleCompletionApiFp(configuration).teacherExportSubmoduleCompletionList(course_id, submodule_id, exportParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Список попмодулей для проверки преподавателем
         * @param {number} course_id 
         * @param {number} submodule_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherSubmoduleCompletionList(course_id: number, submodule_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return CourseSubmoduleCompletionApiFp(configuration).teacherSubmoduleCompletionList(course_id, submodule_id, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Проверка результатов прохождения подмодуля преподавателем
         * @param {number} course_id 
         * @param {number} submodule_id 
         * @param {number} submodule_completion_id 
         * @param {VerificationResult} verificationResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherVerifySubmoduleCompletion(course_id: number, submodule_id: number, submodule_completion_id: number, verificationResult: VerificationResult, options?: any) {
            return CourseSubmoduleCompletionApiFp(configuration).teacherVerifySubmoduleCompletion(course_id, submodule_id, submodule_completion_id, verificationResult, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Начало прохождения подмодуля
         * @param {number} course_id 
         * @param {number} course_completion_id 
         * @param {number} submodule_id 
         * @param {boolean} [skipOnExist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreateCourseSubmoduleCompletion(course_id: number, course_completion_id: number, submodule_id: number, skipOnExist?: boolean, options?: any) {
            return CourseSubmoduleCompletionApiFp(configuration).userCreateCourseSubmoduleCompletion(course_id, course_completion_id, submodule_id, skipOnExist, options)(fetch, basePath);
        },
    };
};

/**
 * CourseSubmoduleCompletionApi - object-oriented interface
 * @export
 * @class CourseSubmoduleCompletionApi
 * @extends {BaseAPI}
 */
export class CourseSubmoduleCompletionApi extends BaseAPI {
    /**
     * 
     * @summary Отправка результатов прохождения подмодуля
     * @param {number} course_id 
     * @param {number} course_completion_id 
     * @param {number} submodule_id 
     * @param {number} submodule_completion_id 
     * @param {CourseSubmoduleCompletionData} submoduleCompletion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleCompletionApi
     */
    public attemptCourseSubmoduleCompletion(course_id: number, course_completion_id: number, submodule_id: number, submodule_completion_id: number, submoduleCompletion: CourseSubmoduleCompletionData, options?: any) {
        return CourseSubmoduleCompletionApiFp(this.configuration).attemptCourseSubmoduleCompletion(course_id, course_completion_id, submodule_id, submodule_completion_id, submoduleCompletion, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Загрузка файла для прохождения подмодуля
     * @param {number} course_id 
     * @param {number} course_completion_id 
     * @param {number} submodule_id 
     * @param {number} submodule_completion_id 
     * @param {any} [file] file to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleCompletionApi
     */
    public attemptSubmoduleCompletionUpload(course_id: number, course_completion_id: number, submodule_id: number, submodule_completion_id: number, file?: any, options?: any) {
        return CourseSubmoduleCompletionApiFp(this.configuration).attemptSubmoduleCompletionUpload(course_id, course_completion_id, submodule_id, submodule_completion_id, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Экспорт подмодулей
     * @param {number} course_id 
     * @param {number} submodule_id 
     * @param {ExportParams} exportParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleCompletionApi
     */
    public teacherExportSubmoduleCompletionList(course_id: number, submodule_id: number, exportParams: ExportParams, options?: any) {
        return CourseSubmoduleCompletionApiFp(this.configuration).teacherExportSubmoduleCompletionList(course_id, submodule_id, exportParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Список попмодулей для проверки преподавателем
     * @param {number} course_id 
     * @param {number} submodule_id 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleCompletionApi
     */
    public teacherSubmoduleCompletionList(course_id: number, submodule_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return CourseSubmoduleCompletionApiFp(this.configuration).teacherSubmoduleCompletionList(course_id, submodule_id, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Проверка результатов прохождения подмодуля преподавателем
     * @param {number} course_id 
     * @param {number} submodule_id 
     * @param {number} submodule_completion_id 
     * @param {VerificationResult} verificationResult 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleCompletionApi
     */
    public teacherVerifySubmoduleCompletion(course_id: number, submodule_id: number, submodule_completion_id: number, verificationResult: VerificationResult, options?: any) {
        return CourseSubmoduleCompletionApiFp(this.configuration).teacherVerifySubmoduleCompletion(course_id, submodule_id, submodule_completion_id, verificationResult, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Начало прохождения подмодуля
     * @param {number} course_id 
     * @param {number} course_completion_id 
     * @param {number} submodule_id 
     * @param {boolean} [skipOnExist] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseSubmoduleCompletionApi
     */
    public userCreateCourseSubmoduleCompletion(course_id: number, course_completion_id: number, submodule_id: number, skipOnExist?: boolean, options?: any) {
        return CourseSubmoduleCompletionApiFp(this.configuration).userCreateCourseSubmoduleCompletion(course_id, course_completion_id, submodule_id, skipOnExist, options)(this.fetch, this.basePath);
    }

}

/**
 * CourseUserGroupApi - fetch parameter creator
 * @export
 */
export const CourseUserGroupApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание группы пользователей
         * @param {number} course_id 
         * @param {CreateUserGroupParams} createUserGroupParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseUserGroup(course_id: number, createUserGroupParams: CreateUserGroupParams, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling adminCreateCourseUserGroup.');
            }
            // verify required parameter 'createUserGroupParams' is not null or undefined
            if (createUserGroupParams === null || createUserGroupParams === undefined) {
                throw new RequiredError('createUserGroupParams','Required parameter createUserGroupParams was null or undefined when calling adminCreateCourseUserGroup.');
            }
            const localVarPath = `/admin/course/{course_id}/user-group`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateUserGroupParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(createUserGroupParams || {}) : (createUserGroupParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление группы пользователей курса
         * @param {number} id 
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourseUserGroup(id: number, course_id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminDeleteCourseUserGroup.');
            }
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling adminDeleteCourseUserGroup.');
            }
            const localVarPath = `/admin/course/{course_id}/user-group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список всех групп пользователей курса
         * @param {number} course_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminListCourseUserGroups(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling adminListCourseUserGroups.');
            }
            const localVarPath = `/admin/course/{course_id}/user-group`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование группы пользователей курса (администратор)
         * @param {number} course_id 
         * @param {number} id 
         * @param {UpdateUserGroupParams} updateUserGroupParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateCourseUserGroup(course_id: number, id: number, updateUserGroupParams: UpdateUserGroupParams, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling adminUpdateCourseUserGroup.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminUpdateCourseUserGroup.');
            }
            // verify required parameter 'updateUserGroupParams' is not null or undefined
            if (updateUserGroupParams === null || updateUserGroupParams === undefined) {
                throw new RequiredError('updateUserGroupParams','Required parameter updateUserGroupParams was null or undefined when calling adminUpdateCourseUserGroup.');
            }
            const localVarPath = `/admin/course/{course_id}/user-group/{id}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateUserGroupParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateUserGroupParams || {}) : (updateUserGroupParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр группы пользователей курса
         * @param {number} course_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewCourseUserGroup(course_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling adminViewCourseUserGroup.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminViewCourseUserGroup.');
            }
            const localVarPath = `/admin/course/{course_id}/user-group/{id}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список всех групп пользователей курса
         * @param {number} course_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherListCourseUserGroups(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling teacherListCourseUserGroups.');
            }
            const localVarPath = `/teacher/course/{course_id}/user-group`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр группы пользователей курса
         * @param {number} course_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherViewCourseUserGroup(course_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling teacherViewCourseUserGroup.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling teacherViewCourseUserGroup.');
            }
            const localVarPath = `/teacher/course/{course_id}/user-group/{id}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseUserGroupApi - functional programming interface
 * @export
 */
export const CourseUserGroupApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание группы пользователей
         * @param {number} course_id 
         * @param {CreateUserGroupParams} createUserGroupParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseUserGroup(course_id: number, createUserGroupParams: CreateUserGroupParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullCourseUserGroup> {
            const localVarFetchArgs = CourseUserGroupApiFetchParamCreator(configuration).adminCreateCourseUserGroup(course_id, createUserGroupParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Удаление группы пользователей курса
         * @param {number} id 
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourseUserGroup(id: number, course_id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseUserGroupApiFetchParamCreator(configuration).adminDeleteCourseUserGroup(id, course_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Список всех групп пользователей курса
         * @param {number} course_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminListCourseUserGroups(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ShortCourseUserGroupListResponse> {
            const localVarFetchArgs = CourseUserGroupApiFetchParamCreator(configuration).adminListCourseUserGroups(course_id, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Редактирование группы пользователей курса (администратор)
         * @param {number} course_id 
         * @param {number} id 
         * @param {UpdateUserGroupParams} updateUserGroupParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateCourseUserGroup(course_id: number, id: number, updateUserGroupParams: UpdateUserGroupParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullCourseUserGroup> {
            const localVarFetchArgs = CourseUserGroupApiFetchParamCreator(configuration).adminUpdateCourseUserGroup(course_id, id, updateUserGroupParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр группы пользователей курса
         * @param {number} course_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewCourseUserGroup(course_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullCourseUserGroup> {
            const localVarFetchArgs = CourseUserGroupApiFetchParamCreator(configuration).adminViewCourseUserGroup(course_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Список всех групп пользователей курса
         * @param {number} course_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherListCourseUserGroups(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ShortCourseUserGroupListResponse> {
            const localVarFetchArgs = CourseUserGroupApiFetchParamCreator(configuration).teacherListCourseUserGroups(course_id, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр группы пользователей курса
         * @param {number} course_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherViewCourseUserGroup(course_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullCourseUserGroup> {
            const localVarFetchArgs = CourseUserGroupApiFetchParamCreator(configuration).teacherViewCourseUserGroup(course_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CourseUserGroupApi - factory interface
 * @export
 */
export const CourseUserGroupApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Создание группы пользователей
         * @param {number} course_id 
         * @param {CreateUserGroupParams} createUserGroupParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseUserGroup(course_id: number, createUserGroupParams: CreateUserGroupParams, options?: any) {
            return CourseUserGroupApiFp(configuration).adminCreateCourseUserGroup(course_id, createUserGroupParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Удаление группы пользователей курса
         * @param {number} id 
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteCourseUserGroup(id: number, course_id: number, options?: any) {
            return CourseUserGroupApiFp(configuration).adminDeleteCourseUserGroup(id, course_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Список всех групп пользователей курса
         * @param {number} course_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminListCourseUserGroups(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return CourseUserGroupApiFp(configuration).adminListCourseUserGroups(course_id, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Редактирование группы пользователей курса (администратор)
         * @param {number} course_id 
         * @param {number} id 
         * @param {UpdateUserGroupParams} updateUserGroupParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateCourseUserGroup(course_id: number, id: number, updateUserGroupParams: UpdateUserGroupParams, options?: any) {
            return CourseUserGroupApiFp(configuration).adminUpdateCourseUserGroup(course_id, id, updateUserGroupParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр группы пользователей курса
         * @param {number} course_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewCourseUserGroup(course_id: number, id: number, options?: any) {
            return CourseUserGroupApiFp(configuration).adminViewCourseUserGroup(course_id, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Список всех групп пользователей курса
         * @param {number} course_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherListCourseUserGroups(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return CourseUserGroupApiFp(configuration).teacherListCourseUserGroups(course_id, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр группы пользователей курса
         * @param {number} course_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherViewCourseUserGroup(course_id: number, id: number, options?: any) {
            return CourseUserGroupApiFp(configuration).teacherViewCourseUserGroup(course_id, id, options)(fetch, basePath);
        },
    };
};

/**
 * CourseUserGroupApi - object-oriented interface
 * @export
 * @class CourseUserGroupApi
 * @extends {BaseAPI}
 */
export class CourseUserGroupApi extends BaseAPI {
    /**
     * 
     * @summary Создание группы пользователей
     * @param {number} course_id 
     * @param {CreateUserGroupParams} createUserGroupParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseUserGroupApi
     */
    public adminCreateCourseUserGroup(course_id: number, createUserGroupParams: CreateUserGroupParams, options?: any) {
        return CourseUserGroupApiFp(this.configuration).adminCreateCourseUserGroup(course_id, createUserGroupParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Удаление группы пользователей курса
     * @param {number} id 
     * @param {number} course_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseUserGroupApi
     */
    public adminDeleteCourseUserGroup(id: number, course_id: number, options?: any) {
        return CourseUserGroupApiFp(this.configuration).adminDeleteCourseUserGroup(id, course_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Список всех групп пользователей курса
     * @param {number} course_id 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseUserGroupApi
     */
    public adminListCourseUserGroups(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return CourseUserGroupApiFp(this.configuration).adminListCourseUserGroups(course_id, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Редактирование группы пользователей курса (администратор)
     * @param {number} course_id 
     * @param {number} id 
     * @param {UpdateUserGroupParams} updateUserGroupParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseUserGroupApi
     */
    public adminUpdateCourseUserGroup(course_id: number, id: number, updateUserGroupParams: UpdateUserGroupParams, options?: any) {
        return CourseUserGroupApiFp(this.configuration).adminUpdateCourseUserGroup(course_id, id, updateUserGroupParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр группы пользователей курса
     * @param {number} course_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseUserGroupApi
     */
    public adminViewCourseUserGroup(course_id: number, id: number, options?: any) {
        return CourseUserGroupApiFp(this.configuration).adminViewCourseUserGroup(course_id, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Список всех групп пользователей курса
     * @param {number} course_id 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseUserGroupApi
     */
    public teacherListCourseUserGroups(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return CourseUserGroupApiFp(this.configuration).teacherListCourseUserGroups(course_id, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр группы пользователей курса
     * @param {number} course_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseUserGroupApi
     */
    public teacherViewCourseUserGroup(course_id: number, id: number, options?: any) {
        return CourseUserGroupApiFp(this.configuration).teacherViewCourseUserGroup(course_id, id, options)(this.fetch, this.basePath);
    }

}

/**
 * DiscussionApi - fetch parameter creator
 * @export
 */
export const DiscussionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение всех обсуждений для курса
         * @param {number} course_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseDiscussions(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling adminCourseDiscussions.');
            }
            const localVarPath = `/admin/course/{course_id}/discussion`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание обсуждения
         * @param {number} course_id 
         * @param {DiscussionCreateParams1} discussionCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseDiscussion(course_id: number, discussionCreateParams: DiscussionCreateParams1, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling adminCreateCourseDiscussion.');
            }
            // verify required parameter 'discussionCreateParams' is not null or undefined
            if (discussionCreateParams === null || discussionCreateParams === undefined) {
                throw new RequiredError('discussionCreateParams','Required parameter discussionCreateParams was null or undefined when calling adminCreateCourseDiscussion.');
            }
            const localVarPath = `/admin/course/{course_id}/discussion`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DiscussionCreateParams1" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(discussionCreateParams || {}) : (discussionCreateParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание обсуждения
         * @param {number} module_id 
         * @param {DiscussionCreateParams} discussionCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateModuleDiscussion(module_id: number, discussionCreateParams: DiscussionCreateParams, options: any = {}): FetchArgs {
            // verify required parameter 'module_id' is not null or undefined
            if (module_id === null || module_id === undefined) {
                throw new RequiredError('module_id','Required parameter module_id was null or undefined when calling adminCreateModuleDiscussion.');
            }
            // verify required parameter 'discussionCreateParams' is not null or undefined
            if (discussionCreateParams === null || discussionCreateParams === undefined) {
                throw new RequiredError('discussionCreateParams','Required parameter discussionCreateParams was null or undefined when calling adminCreateModuleDiscussion.');
            }
            const localVarPath = `/admin/module/{module_id}/discussion`
                .replace(`{${"module_id"}}`, encodeURIComponent(String(module_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DiscussionCreateParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(discussionCreateParams || {}) : (discussionCreateParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление обсуждения
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteDiscussion(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminDeleteDiscussion.');
            }
            const localVarPath = `/admin/discussion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение данных обсуждения по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDiscussion(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminDiscussion.');
            }
            const localVarPath = `/admin/discussion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список пользователей, которым доступно обсуждение
         * @param {number} id 
         * @param {string} [query] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDiscussionUserList(id: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminDiscussionUserList.');
            }
            const localVarPath = `/admin/discussion/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение всех обсуждений для модуля
         * @param {number} module_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminModuleDiscussions(module_id: number, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'module_id' is not null or undefined
            if (module_id === null || module_id === undefined) {
                throw new RequiredError('module_id','Required parameter module_id was null or undefined when calling adminModuleDiscussions.');
            }
            const localVarPath = `/admin/module/{module_id}/discussion`
                .replace(`{${"module_id"}}`, encodeURIComponent(String(module_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование обсуждения
         * @param {number} id 
         * @param {DiscussionEditParams} discussionEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateDiscussion(id: number, discussionEditParams: DiscussionEditParams, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminUpdateDiscussion.');
            }
            // verify required parameter 'discussionEditParams' is not null or undefined
            if (discussionEditParams === null || discussionEditParams === undefined) {
                throw new RequiredError('discussionEditParams','Required parameter discussionEditParams was null or undefined when calling adminUpdateDiscussion.');
            }
            const localVarPath = `/admin/discussion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DiscussionEditParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(discussionEditParams || {}) : (discussionEditParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение всех обсуждений для курса
         * @param {number} course_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCourseDiscussions(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling teacherCourseDiscussions.');
            }
            const localVarPath = `/teacher/course/{course_id}/discussion`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список пользователей, которые имеют доступ к обсуждению
         * @param {number} id 
         * @param {string} [query] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherDiscussionUserList(id: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling teacherDiscussionUserList.');
            }
            const localVarPath = `/teacher/discussion/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение данных обсуждения по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherModuleDiscussion(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling teacherModuleDiscussion.');
            }
            const localVarPath = `/teacher/discussion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение всех обсуждений для модуля
         * @param {number} module_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherModuleDiscussions(module_id: number, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'module_id' is not null or undefined
            if (module_id === null || module_id === undefined) {
                throw new RequiredError('module_id','Required parameter module_id was null or undefined when calling teacherModuleDiscussions.');
            }
            const localVarPath = `/teacher/module/{module_id}/discussion`
                .replace(`{${"module_id"}}`, encodeURIComponent(String(module_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение всех обсуждений для курса
         * @param {number} course_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCourseDiscussions(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling userCourseDiscussions.');
            }
            const localVarPath = `/user/course/{course_id}/discussion`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение данных обсуждения по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userModuleDiscussion(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userModuleDiscussion.');
            }
            const localVarPath = `/user/discussion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение всех обсуждений для модуля
         * @param {number} module_id 
         * @param {string} [search] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userModuleDiscussions(module_id: number, search?: string, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'module_id' is not null or undefined
            if (module_id === null || module_id === undefined) {
                throw new RequiredError('module_id','Required parameter module_id was null or undefined when calling userModuleDiscussions.');
            }
            const localVarPath = `/user/module/{module_id}/discussion`
                .replace(`{${"module_id"}}`, encodeURIComponent(String(module_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscussionApi - functional programming interface
 * @export
 */
export const DiscussionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение всех обсуждений для курса
         * @param {number} course_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseDiscussions(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DiscussionList> {
            const localVarFetchArgs = DiscussionApiFetchParamCreator(configuration).adminCourseDiscussions(course_id, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Создание обсуждения
         * @param {number} course_id 
         * @param {DiscussionCreateParams1} discussionCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseDiscussion(course_id: number, discussionCreateParams: DiscussionCreateParams1, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullDiscussion> {
            const localVarFetchArgs = DiscussionApiFetchParamCreator(configuration).adminCreateCourseDiscussion(course_id, discussionCreateParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Создание обсуждения
         * @param {number} module_id 
         * @param {DiscussionCreateParams} discussionCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateModuleDiscussion(module_id: number, discussionCreateParams: DiscussionCreateParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullDiscussion> {
            const localVarFetchArgs = DiscussionApiFetchParamCreator(configuration).adminCreateModuleDiscussion(module_id, discussionCreateParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Удаление обсуждения
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteDiscussion(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DiscussionApiFetchParamCreator(configuration).adminDeleteDiscussion(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Получение данных обсуждения по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDiscussion(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullDiscussion> {
            const localVarFetchArgs = DiscussionApiFetchParamCreator(configuration).adminDiscussion(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Список пользователей, которым доступно обсуждение
         * @param {number} id 
         * @param {string} [query] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDiscussionUserList(id: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ShortUserListResponse> {
            const localVarFetchArgs = DiscussionApiFetchParamCreator(configuration).adminDiscussionUserList(id, query, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Получение всех обсуждений для модуля
         * @param {number} module_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminModuleDiscussions(module_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DiscussionList> {
            const localVarFetchArgs = DiscussionApiFetchParamCreator(configuration).adminModuleDiscussions(module_id, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Редактирование обсуждения
         * @param {number} id 
         * @param {DiscussionEditParams} discussionEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateDiscussion(id: number, discussionEditParams: DiscussionEditParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullDiscussion> {
            const localVarFetchArgs = DiscussionApiFetchParamCreator(configuration).adminUpdateDiscussion(id, discussionEditParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Получение всех обсуждений для курса
         * @param {number} course_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCourseDiscussions(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TeacherDiscussionList> {
            const localVarFetchArgs = DiscussionApiFetchParamCreator(configuration).teacherCourseDiscussions(course_id, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Список пользователей, которые имеют доступ к обсуждению
         * @param {number} id 
         * @param {string} [query] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherDiscussionUserList(id: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ShortUserListResponse> {
            const localVarFetchArgs = DiscussionApiFetchParamCreator(configuration).teacherDiscussionUserList(id, query, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Получение данных обсуждения по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherModuleDiscussion(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullTeacherDiscussion> {
            const localVarFetchArgs = DiscussionApiFetchParamCreator(configuration).teacherModuleDiscussion(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Получение всех обсуждений для модуля
         * @param {number} module_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherModuleDiscussions(module_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TeacherDiscussionList> {
            const localVarFetchArgs = DiscussionApiFetchParamCreator(configuration).teacherModuleDiscussions(module_id, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Получение всех обсуждений для курса
         * @param {number} course_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCourseDiscussions(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDiscussionList> {
            const localVarFetchArgs = DiscussionApiFetchParamCreator(configuration).userCourseDiscussions(course_id, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Получение данных обсуждения по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userModuleDiscussion(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullUserDiscussion> {
            const localVarFetchArgs = DiscussionApiFetchParamCreator(configuration).userModuleDiscussion(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Получение всех обсуждений для модуля
         * @param {number} module_id 
         * @param {string} [search] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userModuleDiscussions(module_id: number, search?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDiscussionList> {
            const localVarFetchArgs = DiscussionApiFetchParamCreator(configuration).userModuleDiscussions(module_id, search, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DiscussionApi - factory interface
 * @export
 */
export const DiscussionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Получение всех обсуждений для курса
         * @param {number} course_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCourseDiscussions(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return DiscussionApiFp(configuration).adminCourseDiscussions(course_id, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Создание обсуждения
         * @param {number} course_id 
         * @param {DiscussionCreateParams1} discussionCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateCourseDiscussion(course_id: number, discussionCreateParams: DiscussionCreateParams1, options?: any) {
            return DiscussionApiFp(configuration).adminCreateCourseDiscussion(course_id, discussionCreateParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Создание обсуждения
         * @param {number} module_id 
         * @param {DiscussionCreateParams} discussionCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateModuleDiscussion(module_id: number, discussionCreateParams: DiscussionCreateParams, options?: any) {
            return DiscussionApiFp(configuration).adminCreateModuleDiscussion(module_id, discussionCreateParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Удаление обсуждения
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteDiscussion(id: number, options?: any) {
            return DiscussionApiFp(configuration).adminDeleteDiscussion(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Получение данных обсуждения по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDiscussion(id: number, options?: any) {
            return DiscussionApiFp(configuration).adminDiscussion(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Список пользователей, которым доступно обсуждение
         * @param {number} id 
         * @param {string} [query] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDiscussionUserList(id: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return DiscussionApiFp(configuration).adminDiscussionUserList(id, query, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Получение всех обсуждений для модуля
         * @param {number} module_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminModuleDiscussions(module_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return DiscussionApiFp(configuration).adminModuleDiscussions(module_id, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Редактирование обсуждения
         * @param {number} id 
         * @param {DiscussionEditParams} discussionEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateDiscussion(id: number, discussionEditParams: DiscussionEditParams, options?: any) {
            return DiscussionApiFp(configuration).adminUpdateDiscussion(id, discussionEditParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Получение всех обсуждений для курса
         * @param {number} course_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCourseDiscussions(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return DiscussionApiFp(configuration).teacherCourseDiscussions(course_id, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Список пользователей, которые имеют доступ к обсуждению
         * @param {number} id 
         * @param {string} [query] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherDiscussionUserList(id: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return DiscussionApiFp(configuration).teacherDiscussionUserList(id, query, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Получение данных обсуждения по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherModuleDiscussion(id: number, options?: any) {
            return DiscussionApiFp(configuration).teacherModuleDiscussion(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Получение всех обсуждений для модуля
         * @param {number} module_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherModuleDiscussions(module_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return DiscussionApiFp(configuration).teacherModuleDiscussions(module_id, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Получение всех обсуждений для курса
         * @param {number} course_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCourseDiscussions(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return DiscussionApiFp(configuration).userCourseDiscussions(course_id, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Получение данных обсуждения по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userModuleDiscussion(id: number, options?: any) {
            return DiscussionApiFp(configuration).userModuleDiscussion(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Получение всех обсуждений для модуля
         * @param {number} module_id 
         * @param {string} [search] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userModuleDiscussions(module_id: number, search?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return DiscussionApiFp(configuration).userModuleDiscussions(module_id, search, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
    };
};

/**
 * DiscussionApi - object-oriented interface
 * @export
 * @class DiscussionApi
 * @extends {BaseAPI}
 */
export class DiscussionApi extends BaseAPI {
    /**
     * 
     * @summary Получение всех обсуждений для курса
     * @param {number} course_id 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionApi
     */
    public adminCourseDiscussions(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return DiscussionApiFp(this.configuration).adminCourseDiscussions(course_id, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Создание обсуждения
     * @param {number} course_id 
     * @param {DiscussionCreateParams1} discussionCreateParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionApi
     */
    public adminCreateCourseDiscussion(course_id: number, discussionCreateParams: DiscussionCreateParams1, options?: any) {
        return DiscussionApiFp(this.configuration).adminCreateCourseDiscussion(course_id, discussionCreateParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Создание обсуждения
     * @param {number} module_id 
     * @param {DiscussionCreateParams} discussionCreateParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionApi
     */
    public adminCreateModuleDiscussion(module_id: number, discussionCreateParams: DiscussionCreateParams, options?: any) {
        return DiscussionApiFp(this.configuration).adminCreateModuleDiscussion(module_id, discussionCreateParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Удаление обсуждения
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionApi
     */
    public adminDeleteDiscussion(id: number, options?: any) {
        return DiscussionApiFp(this.configuration).adminDeleteDiscussion(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Получение данных обсуждения по id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionApi
     */
    public adminDiscussion(id: number, options?: any) {
        return DiscussionApiFp(this.configuration).adminDiscussion(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Список пользователей, которым доступно обсуждение
     * @param {number} id 
     * @param {string} [query] 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionApi
     */
    public adminDiscussionUserList(id: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return DiscussionApiFp(this.configuration).adminDiscussionUserList(id, query, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Получение всех обсуждений для модуля
     * @param {number} module_id 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionApi
     */
    public adminModuleDiscussions(module_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return DiscussionApiFp(this.configuration).adminModuleDiscussions(module_id, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Редактирование обсуждения
     * @param {number} id 
     * @param {DiscussionEditParams} discussionEditParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionApi
     */
    public adminUpdateDiscussion(id: number, discussionEditParams: DiscussionEditParams, options?: any) {
        return DiscussionApiFp(this.configuration).adminUpdateDiscussion(id, discussionEditParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Получение всех обсуждений для курса
     * @param {number} course_id 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionApi
     */
    public teacherCourseDiscussions(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return DiscussionApiFp(this.configuration).teacherCourseDiscussions(course_id, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Список пользователей, которые имеют доступ к обсуждению
     * @param {number} id 
     * @param {string} [query] 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionApi
     */
    public teacherDiscussionUserList(id: number, query?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return DiscussionApiFp(this.configuration).teacherDiscussionUserList(id, query, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Получение данных обсуждения по id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionApi
     */
    public teacherModuleDiscussion(id: number, options?: any) {
        return DiscussionApiFp(this.configuration).teacherModuleDiscussion(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Получение всех обсуждений для модуля
     * @param {number} module_id 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionApi
     */
    public teacherModuleDiscussions(module_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return DiscussionApiFp(this.configuration).teacherModuleDiscussions(module_id, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Получение всех обсуждений для курса
     * @param {number} course_id 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionApi
     */
    public userCourseDiscussions(course_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return DiscussionApiFp(this.configuration).userCourseDiscussions(course_id, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Получение данных обсуждения по id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionApi
     */
    public userModuleDiscussion(id: number, options?: any) {
        return DiscussionApiFp(this.configuration).userModuleDiscussion(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Получение всех обсуждений для модуля
     * @param {number} module_id 
     * @param {string} [search] 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionApi
     */
    public userModuleDiscussions(module_id: number, search?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return DiscussionApiFp(this.configuration).userModuleDiscussions(module_id, search, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

}

/**
 * DiscussionTopicApi - fetch parameter creator
 * @export
 */
export const DiscussionTopicApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание темы обсуждения
         * @param {number} discussion_id 
         * @param {TopicCreateParams} topicCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateDiscussionTopic(discussion_id: number, topicCreateParams: TopicCreateParams, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling adminCreateDiscussionTopic.');
            }
            // verify required parameter 'topicCreateParams' is not null or undefined
            if (topicCreateParams === null || topicCreateParams === undefined) {
                throw new RequiredError('topicCreateParams','Required parameter topicCreateParams was null or undefined when calling adminCreateDiscussionTopic.');
            }
            const localVarPath = `/admin/discussion/{discussion_id}/topic`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TopicCreateParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(topicCreateParams || {}) : (topicCreateParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteTopic(discussion_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling adminDeleteTopic.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminDeleteTopic.');
            }
            const localVarPath = `/admin/discussion/{discussion_id}/topic/{id}`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение данных темы обсуждения по id
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDiscussionTopic(discussion_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling adminDiscussionTopic.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminDiscussionTopic.');
            }
            const localVarPath = `/admin/discussion/{discussion_id}/topic/{id}`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение всех тем обсуждения
         * @param {number} discussion_id 
         * @param {string} [search] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDiscussionTopics(discussion_id: number, search?: string, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling adminDiscussionTopics.');
            }
            const localVarPath = `/admin/discussion/{discussion_id}/topic`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Закрепление темы
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPinDiscussionTopic(discussion_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling adminPinDiscussionTopic.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminPinDiscussionTopic.');
            }
            const localVarPath = `/admin/discussion/{discussion_id}/topic/{id}/pin`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Открепление темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnpinDiscussionTopic(discussion_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling adminUnpinDiscussionTopic.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminUnpinDiscussionTopic.');
            }
            const localVarPath = `/admin/discussion/{discussion_id}/topic/{id}/pin`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {TopicEditParams} topicEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateDiscussionTopic(discussion_id: number, id: number, topicEditParams: TopicEditParams, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling adminUpdateDiscussionTopic.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminUpdateDiscussionTopic.');
            }
            // verify required parameter 'topicEditParams' is not null or undefined
            if (topicEditParams === null || topicEditParams === undefined) {
                throw new RequiredError('topicEditParams','Required parameter topicEditParams was null or undefined when calling adminUpdateDiscussionTopic.');
            }
            const localVarPath = `/admin/discussion/{discussion_id}/topic/{id}`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TopicEditParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(topicEditParams || {}) : (topicEditParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание темы обсуждения
         * @param {number} discussion_id 
         * @param {TopicCreateParams1} topicCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCreateDiscussionTopic(discussion_id: number, topicCreateParams: TopicCreateParams1, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling teacherCreateDiscussionTopic.');
            }
            // verify required parameter 'topicCreateParams' is not null or undefined
            if (topicCreateParams === null || topicCreateParams === undefined) {
                throw new RequiredError('topicCreateParams','Required parameter topicCreateParams was null or undefined when calling teacherCreateDiscussionTopic.');
            }
            const localVarPath = `/teacher/discussion/{discussion_id}/topic`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TopicCreateParams1" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(topicCreateParams || {}) : (topicCreateParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherDeleteTopic(discussion_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling teacherDeleteTopic.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling teacherDeleteTopic.');
            }
            const localVarPath = `/teacher/discussion/{discussion_id}/topic/{id}`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение данных темы обсуждения по id
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherDiscussionTopic(discussion_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling teacherDiscussionTopic.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling teacherDiscussionTopic.');
            }
            const localVarPath = `/teacher/discussion/{discussion_id}/topic/{id}`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение всех тем обсуждения
         * @param {number} discussion_id 
         * @param {string} [search] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherDiscussionTopics(discussion_id: number, search?: string, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling teacherDiscussionTopics.');
            }
            const localVarPath = `/teacher/discussion/{discussion_id}/topic`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Закрепление темы
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherPinDiscussionTopic(discussion_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling teacherPinDiscussionTopic.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling teacherPinDiscussionTopic.');
            }
            const localVarPath = `/teacher/discussion/{discussion_id}/topic/{id}/pin`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Открепление темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherUnpinDiscussionTopic(discussion_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling teacherUnpinDiscussionTopic.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling teacherUnpinDiscussionTopic.');
            }
            const localVarPath = `/teacher/discussion/{discussion_id}/topic/{id}/pin`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {TopicEditParams1} topicEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherUpdateDiscussionTopic(discussion_id: number, id: number, topicEditParams: TopicEditParams1, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling teacherUpdateDiscussionTopic.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling teacherUpdateDiscussionTopic.');
            }
            // verify required parameter 'topicEditParams' is not null or undefined
            if (topicEditParams === null || topicEditParams === undefined) {
                throw new RequiredError('topicEditParams','Required parameter topicEditParams was null or undefined when calling teacherUpdateDiscussionTopic.');
            }
            const localVarPath = `/teacher/discussion/{discussion_id}/topic/{id}`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TopicEditParams1" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(topicEditParams || {}) : (topicEditParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание темы обсуждения
         * @param {number} discussion_id 
         * @param {TopicCreateParams2} topicCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreateDiscussionTopic(discussion_id: number, topicCreateParams: TopicCreateParams2, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling userCreateDiscussionTopic.');
            }
            // verify required parameter 'topicCreateParams' is not null or undefined
            if (topicCreateParams === null || topicCreateParams === undefined) {
                throw new RequiredError('topicCreateParams','Required parameter topicCreateParams was null or undefined when calling userCreateDiscussionTopic.');
            }
            const localVarPath = `/user/discussion/{discussion_id}/topic`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TopicCreateParams2" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(topicCreateParams || {}) : (topicCreateParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение данных темы обсуждения по id
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDiscussionTopic(discussion_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling userDiscussionTopic.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userDiscussionTopic.');
            }
            const localVarPath = `/user/discussion/{discussion_id}/topic/{id}`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Инкремент счетчика просмотров
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDiscussionTopicIncViewCount(discussion_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling userDiscussionTopicIncViewCount.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userDiscussionTopicIncViewCount.');
            }
            const localVarPath = `/user/discussion/{discussion_id}/topic/{id}/view-count`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Подписка на тему
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDiscussionTopicSubscribe(discussion_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling userDiscussionTopicSubscribe.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userDiscussionTopicSubscribe.');
            }
            const localVarPath = `/user/discussion/{discussion_id}/topic/{id}/subscription`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отписка от темы
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDiscussionTopicUnsubscribe(discussion_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling userDiscussionTopicUnsubscribe.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userDiscussionTopicUnsubscribe.');
            }
            const localVarPath = `/user/discussion/{discussion_id}/topic/{id}/subscription`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение всех тем обсуждения
         * @param {number} discussion_id 
         * @param {string} [search] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDiscussionTopics(discussion_id: number, search?: string, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling userDiscussionTopics.');
            }
            const localVarPath = `/user/discussion/{discussion_id}/topic`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {TopicEditParams2} topicEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateDiscussionTopic(discussion_id: number, id: number, topicEditParams: TopicEditParams2, options: any = {}): FetchArgs {
            // verify required parameter 'discussion_id' is not null or undefined
            if (discussion_id === null || discussion_id === undefined) {
                throw new RequiredError('discussion_id','Required parameter discussion_id was null or undefined when calling userUpdateDiscussionTopic.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userUpdateDiscussionTopic.');
            }
            // verify required parameter 'topicEditParams' is not null or undefined
            if (topicEditParams === null || topicEditParams === undefined) {
                throw new RequiredError('topicEditParams','Required parameter topicEditParams was null or undefined when calling userUpdateDiscussionTopic.');
            }
            const localVarPath = `/user/discussion/{discussion_id}/topic/{id}`
                .replace(`{${"discussion_id"}}`, encodeURIComponent(String(discussion_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TopicEditParams2" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(topicEditParams || {}) : (topicEditParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscussionTopicApi - functional programming interface
 * @export
 */
export const DiscussionTopicApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание темы обсуждения
         * @param {number} discussion_id 
         * @param {TopicCreateParams} topicCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateDiscussionTopic(discussion_id: number, topicCreateParams: TopicCreateParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullAdminDiscussionTopic> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).adminCreateDiscussionTopic(discussion_id, topicCreateParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Удаление темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteTopic(discussion_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).adminDeleteTopic(discussion_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Получение данных темы обсуждения по id
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDiscussionTopic(discussion_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullAdminDiscussionTopic> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).adminDiscussionTopic(discussion_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Получение всех тем обсуждения
         * @param {number} discussion_id 
         * @param {string} [search] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDiscussionTopics(discussion_id: number, search?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminDiscussionTopicList> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).adminDiscussionTopics(discussion_id, search, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Закрепление темы
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPinDiscussionTopic(discussion_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullAdminDiscussionTopic> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).adminPinDiscussionTopic(discussion_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Открепление темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnpinDiscussionTopic(discussion_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullAdminDiscussionTopic> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).adminUnpinDiscussionTopic(discussion_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Редактирование темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {TopicEditParams} topicEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateDiscussionTopic(discussion_id: number, id: number, topicEditParams: TopicEditParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullAdminDiscussionTopic> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).adminUpdateDiscussionTopic(discussion_id, id, topicEditParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Создание темы обсуждения
         * @param {number} discussion_id 
         * @param {TopicCreateParams1} topicCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCreateDiscussionTopic(discussion_id: number, topicCreateParams: TopicCreateParams1, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullTeacherDiscussionTopic> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).teacherCreateDiscussionTopic(discussion_id, topicCreateParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Удаление темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherDeleteTopic(discussion_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).teacherDeleteTopic(discussion_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Получение данных темы обсуждения по id
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherDiscussionTopic(discussion_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullTeacherDiscussionTopic> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).teacherDiscussionTopic(discussion_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Получение всех тем обсуждения
         * @param {number} discussion_id 
         * @param {string} [search] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherDiscussionTopics(discussion_id: number, search?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TeacherDiscussionTopicList> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).teacherDiscussionTopics(discussion_id, search, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Закрепление темы
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherPinDiscussionTopic(discussion_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullTeacherDiscussionTopic> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).teacherPinDiscussionTopic(discussion_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Открепление темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherUnpinDiscussionTopic(discussion_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullTeacherDiscussionTopic> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).teacherUnpinDiscussionTopic(discussion_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Редактирование темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {TopicEditParams1} topicEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherUpdateDiscussionTopic(discussion_id: number, id: number, topicEditParams: TopicEditParams1, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullTeacherDiscussionTopic> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).teacherUpdateDiscussionTopic(discussion_id, id, topicEditParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Создание темы обсуждения
         * @param {number} discussion_id 
         * @param {TopicCreateParams2} topicCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreateDiscussionTopic(discussion_id: number, topicCreateParams: TopicCreateParams2, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullUserDiscussionTopic> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).userCreateDiscussionTopic(discussion_id, topicCreateParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Получение данных темы обсуждения по id
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDiscussionTopic(discussion_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullUserDiscussionTopic> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).userDiscussionTopic(discussion_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Инкремент счетчика просмотров
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDiscussionTopicIncViewCount(discussion_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullUserDiscussionTopic> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).userDiscussionTopicIncViewCount(discussion_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Подписка на тему
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDiscussionTopicSubscribe(discussion_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullUserDiscussionTopic> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).userDiscussionTopicSubscribe(discussion_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Отписка от темы
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDiscussionTopicUnsubscribe(discussion_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullUserDiscussionTopic> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).userDiscussionTopicUnsubscribe(discussion_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Получение всех тем обсуждения
         * @param {number} discussion_id 
         * @param {string} [search] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDiscussionTopics(discussion_id: number, search?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDiscussionTopicList> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).userDiscussionTopics(discussion_id, search, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Редактирование темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {TopicEditParams2} topicEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateDiscussionTopic(discussion_id: number, id: number, topicEditParams: TopicEditParams2, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FullUserDiscussionTopic> {
            const localVarFetchArgs = DiscussionTopicApiFetchParamCreator(configuration).userUpdateDiscussionTopic(discussion_id, id, topicEditParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DiscussionTopicApi - factory interface
 * @export
 */
export const DiscussionTopicApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Создание темы обсуждения
         * @param {number} discussion_id 
         * @param {TopicCreateParams} topicCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateDiscussionTopic(discussion_id: number, topicCreateParams: TopicCreateParams, options?: any) {
            return DiscussionTopicApiFp(configuration).adminCreateDiscussionTopic(discussion_id, topicCreateParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Удаление темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteTopic(discussion_id: number, id: number, options?: any) {
            return DiscussionTopicApiFp(configuration).adminDeleteTopic(discussion_id, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Получение данных темы обсуждения по id
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDiscussionTopic(discussion_id: number, id: number, options?: any) {
            return DiscussionTopicApiFp(configuration).adminDiscussionTopic(discussion_id, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Получение всех тем обсуждения
         * @param {number} discussion_id 
         * @param {string} [search] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDiscussionTopics(discussion_id: number, search?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return DiscussionTopicApiFp(configuration).adminDiscussionTopics(discussion_id, search, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Закрепление темы
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPinDiscussionTopic(discussion_id: number, id: number, options?: any) {
            return DiscussionTopicApiFp(configuration).adminPinDiscussionTopic(discussion_id, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Открепление темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnpinDiscussionTopic(discussion_id: number, id: number, options?: any) {
            return DiscussionTopicApiFp(configuration).adminUnpinDiscussionTopic(discussion_id, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Редактирование темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {TopicEditParams} topicEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateDiscussionTopic(discussion_id: number, id: number, topicEditParams: TopicEditParams, options?: any) {
            return DiscussionTopicApiFp(configuration).adminUpdateDiscussionTopic(discussion_id, id, topicEditParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Создание темы обсуждения
         * @param {number} discussion_id 
         * @param {TopicCreateParams1} topicCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCreateDiscussionTopic(discussion_id: number, topicCreateParams: TopicCreateParams1, options?: any) {
            return DiscussionTopicApiFp(configuration).teacherCreateDiscussionTopic(discussion_id, topicCreateParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Удаление темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherDeleteTopic(discussion_id: number, id: number, options?: any) {
            return DiscussionTopicApiFp(configuration).teacherDeleteTopic(discussion_id, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Получение данных темы обсуждения по id
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherDiscussionTopic(discussion_id: number, id: number, options?: any) {
            return DiscussionTopicApiFp(configuration).teacherDiscussionTopic(discussion_id, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Получение всех тем обсуждения
         * @param {number} discussion_id 
         * @param {string} [search] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherDiscussionTopics(discussion_id: number, search?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return DiscussionTopicApiFp(configuration).teacherDiscussionTopics(discussion_id, search, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Закрепление темы
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherPinDiscussionTopic(discussion_id: number, id: number, options?: any) {
            return DiscussionTopicApiFp(configuration).teacherPinDiscussionTopic(discussion_id, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Открепление темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherUnpinDiscussionTopic(discussion_id: number, id: number, options?: any) {
            return DiscussionTopicApiFp(configuration).teacherUnpinDiscussionTopic(discussion_id, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Редактирование темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {TopicEditParams1} topicEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherUpdateDiscussionTopic(discussion_id: number, id: number, topicEditParams: TopicEditParams1, options?: any) {
            return DiscussionTopicApiFp(configuration).teacherUpdateDiscussionTopic(discussion_id, id, topicEditParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Создание темы обсуждения
         * @param {number} discussion_id 
         * @param {TopicCreateParams2} topicCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreateDiscussionTopic(discussion_id: number, topicCreateParams: TopicCreateParams2, options?: any) {
            return DiscussionTopicApiFp(configuration).userCreateDiscussionTopic(discussion_id, topicCreateParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Получение данных темы обсуждения по id
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDiscussionTopic(discussion_id: number, id: number, options?: any) {
            return DiscussionTopicApiFp(configuration).userDiscussionTopic(discussion_id, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Инкремент счетчика просмотров
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDiscussionTopicIncViewCount(discussion_id: number, id: number, options?: any) {
            return DiscussionTopicApiFp(configuration).userDiscussionTopicIncViewCount(discussion_id, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Подписка на тему
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDiscussionTopicSubscribe(discussion_id: number, id: number, options?: any) {
            return DiscussionTopicApiFp(configuration).userDiscussionTopicSubscribe(discussion_id, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Отписка от темы
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDiscussionTopicUnsubscribe(discussion_id: number, id: number, options?: any) {
            return DiscussionTopicApiFp(configuration).userDiscussionTopicUnsubscribe(discussion_id, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Получение всех тем обсуждения
         * @param {number} discussion_id 
         * @param {string} [search] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDiscussionTopics(discussion_id: number, search?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return DiscussionTopicApiFp(configuration).userDiscussionTopics(discussion_id, search, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Редактирование темы обсуждения
         * @param {number} discussion_id 
         * @param {number} id 
         * @param {TopicEditParams2} topicEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateDiscussionTopic(discussion_id: number, id: number, topicEditParams: TopicEditParams2, options?: any) {
            return DiscussionTopicApiFp(configuration).userUpdateDiscussionTopic(discussion_id, id, topicEditParams, options)(fetch, basePath);
        },
    };
};

/**
 * DiscussionTopicApi - object-oriented interface
 * @export
 * @class DiscussionTopicApi
 * @extends {BaseAPI}
 */
export class DiscussionTopicApi extends BaseAPI {
    /**
     * 
     * @summary Создание темы обсуждения
     * @param {number} discussion_id 
     * @param {TopicCreateParams} topicCreateParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public adminCreateDiscussionTopic(discussion_id: number, topicCreateParams: TopicCreateParams, options?: any) {
        return DiscussionTopicApiFp(this.configuration).adminCreateDiscussionTopic(discussion_id, topicCreateParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Удаление темы обсуждения
     * @param {number} discussion_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public adminDeleteTopic(discussion_id: number, id: number, options?: any) {
        return DiscussionTopicApiFp(this.configuration).adminDeleteTopic(discussion_id, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Получение данных темы обсуждения по id
     * @param {number} discussion_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public adminDiscussionTopic(discussion_id: number, id: number, options?: any) {
        return DiscussionTopicApiFp(this.configuration).adminDiscussionTopic(discussion_id, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Получение всех тем обсуждения
     * @param {number} discussion_id 
     * @param {string} [search] 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public adminDiscussionTopics(discussion_id: number, search?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return DiscussionTopicApiFp(this.configuration).adminDiscussionTopics(discussion_id, search, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Закрепление темы
     * @param {number} discussion_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public adminPinDiscussionTopic(discussion_id: number, id: number, options?: any) {
        return DiscussionTopicApiFp(this.configuration).adminPinDiscussionTopic(discussion_id, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Открепление темы обсуждения
     * @param {number} discussion_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public adminUnpinDiscussionTopic(discussion_id: number, id: number, options?: any) {
        return DiscussionTopicApiFp(this.configuration).adminUnpinDiscussionTopic(discussion_id, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Редактирование темы обсуждения
     * @param {number} discussion_id 
     * @param {number} id 
     * @param {TopicEditParams} topicEditParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public adminUpdateDiscussionTopic(discussion_id: number, id: number, topicEditParams: TopicEditParams, options?: any) {
        return DiscussionTopicApiFp(this.configuration).adminUpdateDiscussionTopic(discussion_id, id, topicEditParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Создание темы обсуждения
     * @param {number} discussion_id 
     * @param {TopicCreateParams1} topicCreateParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public teacherCreateDiscussionTopic(discussion_id: number, topicCreateParams: TopicCreateParams1, options?: any) {
        return DiscussionTopicApiFp(this.configuration).teacherCreateDiscussionTopic(discussion_id, topicCreateParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Удаление темы обсуждения
     * @param {number} discussion_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public teacherDeleteTopic(discussion_id: number, id: number, options?: any) {
        return DiscussionTopicApiFp(this.configuration).teacherDeleteTopic(discussion_id, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Получение данных темы обсуждения по id
     * @param {number} discussion_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public teacherDiscussionTopic(discussion_id: number, id: number, options?: any) {
        return DiscussionTopicApiFp(this.configuration).teacherDiscussionTopic(discussion_id, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Получение всех тем обсуждения
     * @param {number} discussion_id 
     * @param {string} [search] 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public teacherDiscussionTopics(discussion_id: number, search?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return DiscussionTopicApiFp(this.configuration).teacherDiscussionTopics(discussion_id, search, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Закрепление темы
     * @param {number} discussion_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public teacherPinDiscussionTopic(discussion_id: number, id: number, options?: any) {
        return DiscussionTopicApiFp(this.configuration).teacherPinDiscussionTopic(discussion_id, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Открепление темы обсуждения
     * @param {number} discussion_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public teacherUnpinDiscussionTopic(discussion_id: number, id: number, options?: any) {
        return DiscussionTopicApiFp(this.configuration).teacherUnpinDiscussionTopic(discussion_id, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Редактирование темы обсуждения
     * @param {number} discussion_id 
     * @param {number} id 
     * @param {TopicEditParams1} topicEditParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public teacherUpdateDiscussionTopic(discussion_id: number, id: number, topicEditParams: TopicEditParams1, options?: any) {
        return DiscussionTopicApiFp(this.configuration).teacherUpdateDiscussionTopic(discussion_id, id, topicEditParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Создание темы обсуждения
     * @param {number} discussion_id 
     * @param {TopicCreateParams2} topicCreateParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public userCreateDiscussionTopic(discussion_id: number, topicCreateParams: TopicCreateParams2, options?: any) {
        return DiscussionTopicApiFp(this.configuration).userCreateDiscussionTopic(discussion_id, topicCreateParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Получение данных темы обсуждения по id
     * @param {number} discussion_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public userDiscussionTopic(discussion_id: number, id: number, options?: any) {
        return DiscussionTopicApiFp(this.configuration).userDiscussionTopic(discussion_id, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Инкремент счетчика просмотров
     * @param {number} discussion_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public userDiscussionTopicIncViewCount(discussion_id: number, id: number, options?: any) {
        return DiscussionTopicApiFp(this.configuration).userDiscussionTopicIncViewCount(discussion_id, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Подписка на тему
     * @param {number} discussion_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public userDiscussionTopicSubscribe(discussion_id: number, id: number, options?: any) {
        return DiscussionTopicApiFp(this.configuration).userDiscussionTopicSubscribe(discussion_id, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Отписка от темы
     * @param {number} discussion_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public userDiscussionTopicUnsubscribe(discussion_id: number, id: number, options?: any) {
        return DiscussionTopicApiFp(this.configuration).userDiscussionTopicUnsubscribe(discussion_id, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Получение всех тем обсуждения
     * @param {number} discussion_id 
     * @param {string} [search] 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public userDiscussionTopics(discussion_id: number, search?: string, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return DiscussionTopicApiFp(this.configuration).userDiscussionTopics(discussion_id, search, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Редактирование темы обсуждения
     * @param {number} discussion_id 
     * @param {number} id 
     * @param {TopicEditParams2} topicEditParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicApi
     */
    public userUpdateDiscussionTopic(discussion_id: number, id: number, topicEditParams: TopicEditParams2, options?: any) {
        return DiscussionTopicApiFp(this.configuration).userUpdateDiscussionTopic(discussion_id, id, topicEditParams, options)(this.fetch, this.basePath);
    }

}

/**
 * DiscussionTopicCommentApi - fetch parameter creator
 * @export
 */
export const DiscussionTopicCommentApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание комментария темы
         * @param {number} topic_id 
         * @param {CommentCreateParams} commentCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateTopicComment(topic_id: number, commentCreateParams: CommentCreateParams, options: any = {}): FetchArgs {
            // verify required parameter 'topic_id' is not null or undefined
            if (topic_id === null || topic_id === undefined) {
                throw new RequiredError('topic_id','Required parameter topic_id was null or undefined when calling adminCreateTopicComment.');
            }
            // verify required parameter 'commentCreateParams' is not null or undefined
            if (commentCreateParams === null || commentCreateParams === undefined) {
                throw new RequiredError('commentCreateParams','Required parameter commentCreateParams was null or undefined when calling adminCreateTopicComment.');
            }
            const localVarPath = `/admin/topic/{topic_id}/comment`
                .replace(`{${"topic_id"}}`, encodeURIComponent(String(topic_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CommentCreateParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(commentCreateParams || {}) : (commentCreateParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление комментария
         * @param {number} topic_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteComment(topic_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'topic_id' is not null or undefined
            if (topic_id === null || topic_id === undefined) {
                throw new RequiredError('topic_id','Required parameter topic_id was null or undefined when calling adminDeleteComment.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminDeleteComment.');
            }
            const localVarPath = `/admin/topic/{topic_id}/comment/{id}`
                .replace(`{${"topic_id"}}`, encodeURIComponent(String(topic_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение всех комментариев темы
         * @param {number} topic_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTopicComments(topic_id: number, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'topic_id' is not null or undefined
            if (topic_id === null || topic_id === undefined) {
                throw new RequiredError('topic_id','Required parameter topic_id was null or undefined when calling adminTopicComments.');
            }
            const localVarPath = `/admin/topic/{topic_id}/comment`
                .replace(`{${"topic_id"}}`, encodeURIComponent(String(topic_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование темы обсуждения
         * @param {number} topic_id 
         * @param {number} id 
         * @param {CommentEditParams} commentEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateTopicComment(topic_id: number, id: number, commentEditParams: CommentEditParams, options: any = {}): FetchArgs {
            // verify required parameter 'topic_id' is not null or undefined
            if (topic_id === null || topic_id === undefined) {
                throw new RequiredError('topic_id','Required parameter topic_id was null or undefined when calling adminUpdateTopicComment.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminUpdateTopicComment.');
            }
            // verify required parameter 'commentEditParams' is not null or undefined
            if (commentEditParams === null || commentEditParams === undefined) {
                throw new RequiredError('commentEditParams','Required parameter commentEditParams was null or undefined when calling adminUpdateTopicComment.');
            }
            const localVarPath = `/admin/topic/{topic_id}/comment/{id}`
                .replace(`{${"topic_id"}}`, encodeURIComponent(String(topic_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CommentEditParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(commentEditParams || {}) : (commentEditParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание комментария темы
         * @param {number} topic_id 
         * @param {CommentCreateParams1} commentCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCreateTopicComment(topic_id: number, commentCreateParams: CommentCreateParams1, options: any = {}): FetchArgs {
            // verify required parameter 'topic_id' is not null or undefined
            if (topic_id === null || topic_id === undefined) {
                throw new RequiredError('topic_id','Required parameter topic_id was null or undefined when calling teacherCreateTopicComment.');
            }
            // verify required parameter 'commentCreateParams' is not null or undefined
            if (commentCreateParams === null || commentCreateParams === undefined) {
                throw new RequiredError('commentCreateParams','Required parameter commentCreateParams was null or undefined when calling teacherCreateTopicComment.');
            }
            const localVarPath = `/teacher/topic/{topic_id}/comment`
                .replace(`{${"topic_id"}}`, encodeURIComponent(String(topic_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CommentCreateParams1" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(commentCreateParams || {}) : (commentCreateParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление комментария
         * @param {number} topic_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherDeleteComment(topic_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'topic_id' is not null or undefined
            if (topic_id === null || topic_id === undefined) {
                throw new RequiredError('topic_id','Required parameter topic_id was null or undefined when calling teacherDeleteComment.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling teacherDeleteComment.');
            }
            const localVarPath = `/teacher/topic/{topic_id}/comment/{id}`
                .replace(`{${"topic_id"}}`, encodeURIComponent(String(topic_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение всех комментариев темы
         * @param {number} topic_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherTopicComments(topic_id: number, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'topic_id' is not null or undefined
            if (topic_id === null || topic_id === undefined) {
                throw new RequiredError('topic_id','Required parameter topic_id was null or undefined when calling teacherTopicComments.');
            }
            const localVarPath = `/teacher/topic/{topic_id}/comment`
                .replace(`{${"topic_id"}}`, encodeURIComponent(String(topic_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование темы обсуждения
         * @param {number} topic_id 
         * @param {number} id 
         * @param {CommentEditParams1} commentEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherUpdateTopicComment(topic_id: number, id: number, commentEditParams: CommentEditParams1, options: any = {}): FetchArgs {
            // verify required parameter 'topic_id' is not null or undefined
            if (topic_id === null || topic_id === undefined) {
                throw new RequiredError('topic_id','Required parameter topic_id was null or undefined when calling teacherUpdateTopicComment.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling teacherUpdateTopicComment.');
            }
            // verify required parameter 'commentEditParams' is not null or undefined
            if (commentEditParams === null || commentEditParams === undefined) {
                throw new RequiredError('commentEditParams','Required parameter commentEditParams was null or undefined when calling teacherUpdateTopicComment.');
            }
            const localVarPath = `/teacher/topic/{topic_id}/comment/{id}`
                .replace(`{${"topic_id"}}`, encodeURIComponent(String(topic_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CommentEditParams1" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(commentEditParams || {}) : (commentEditParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание комментария темы
         * @param {number} topic_id 
         * @param {CommentCreateParams2} commentCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreateTopicComment(topic_id: number, commentCreateParams: CommentCreateParams2, options: any = {}): FetchArgs {
            // verify required parameter 'topic_id' is not null or undefined
            if (topic_id === null || topic_id === undefined) {
                throw new RequiredError('topic_id','Required parameter topic_id was null or undefined when calling userCreateTopicComment.');
            }
            // verify required parameter 'commentCreateParams' is not null or undefined
            if (commentCreateParams === null || commentCreateParams === undefined) {
                throw new RequiredError('commentCreateParams','Required parameter commentCreateParams was null or undefined when calling userCreateTopicComment.');
            }
            const localVarPath = `/user/topic/{topic_id}/comment`
                .replace(`{${"topic_id"}}`, encodeURIComponent(String(topic_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CommentCreateParams2" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(commentCreateParams || {}) : (commentCreateParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление комментария
         * @param {number} topic_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeleteComment(topic_id: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'topic_id' is not null or undefined
            if (topic_id === null || topic_id === undefined) {
                throw new RequiredError('topic_id','Required parameter topic_id was null or undefined when calling userDeleteComment.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userDeleteComment.');
            }
            const localVarPath = `/user/topic/{topic_id}/comment/{id}`
                .replace(`{${"topic_id"}}`, encodeURIComponent(String(topic_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение всех комментариев темы
         * @param {number} topic_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTopicComments(topic_id: number, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'topic_id' is not null or undefined
            if (topic_id === null || topic_id === undefined) {
                throw new RequiredError('topic_id','Required parameter topic_id was null or undefined when calling userTopicComments.');
            }
            const localVarPath = `/user/topic/{topic_id}/comment`
                .replace(`{${"topic_id"}}`, encodeURIComponent(String(topic_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование темы обсуждения
         * @param {number} topic_id 
         * @param {number} id 
         * @param {CommentEditParams2} commentEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateTopicComment(topic_id: number, id: number, commentEditParams: CommentEditParams2, options: any = {}): FetchArgs {
            // verify required parameter 'topic_id' is not null or undefined
            if (topic_id === null || topic_id === undefined) {
                throw new RequiredError('topic_id','Required parameter topic_id was null or undefined when calling userUpdateTopicComment.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userUpdateTopicComment.');
            }
            // verify required parameter 'commentEditParams' is not null or undefined
            if (commentEditParams === null || commentEditParams === undefined) {
                throw new RequiredError('commentEditParams','Required parameter commentEditParams was null or undefined when calling userUpdateTopicComment.');
            }
            const localVarPath = `/user/topic/{topic_id}/comment/{id}`
                .replace(`{${"topic_id"}}`, encodeURIComponent(String(topic_id)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CommentEditParams2" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(commentEditParams || {}) : (commentEditParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscussionTopicCommentApi - functional programming interface
 * @export
 */
export const DiscussionTopicCommentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание комментария темы
         * @param {number} topic_id 
         * @param {CommentCreateParams} commentCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateTopicComment(topic_id: number, commentCreateParams: CommentCreateParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DiscussionTopicComment> {
            const localVarFetchArgs = DiscussionTopicCommentApiFetchParamCreator(configuration).adminCreateTopicComment(topic_id, commentCreateParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Удаление комментария
         * @param {number} topic_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteComment(topic_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DiscussionTopicCommentApiFetchParamCreator(configuration).adminDeleteComment(topic_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Получение всех комментариев темы
         * @param {number} topic_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTopicComments(topic_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DiscussionTopicCommentList> {
            const localVarFetchArgs = DiscussionTopicCommentApiFetchParamCreator(configuration).adminTopicComments(topic_id, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Редактирование темы обсуждения
         * @param {number} topic_id 
         * @param {number} id 
         * @param {CommentEditParams} commentEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateTopicComment(topic_id: number, id: number, commentEditParams: CommentEditParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DiscussionTopicComment> {
            const localVarFetchArgs = DiscussionTopicCommentApiFetchParamCreator(configuration).adminUpdateTopicComment(topic_id, id, commentEditParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Создание комментария темы
         * @param {number} topic_id 
         * @param {CommentCreateParams1} commentCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCreateTopicComment(topic_id: number, commentCreateParams: CommentCreateParams1, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DiscussionTopicComment> {
            const localVarFetchArgs = DiscussionTopicCommentApiFetchParamCreator(configuration).teacherCreateTopicComment(topic_id, commentCreateParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Удаление комментария
         * @param {number} topic_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherDeleteComment(topic_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DiscussionTopicCommentApiFetchParamCreator(configuration).teacherDeleteComment(topic_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Получение всех комментариев темы
         * @param {number} topic_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherTopicComments(topic_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DiscussionTopicCommentList> {
            const localVarFetchArgs = DiscussionTopicCommentApiFetchParamCreator(configuration).teacherTopicComments(topic_id, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Редактирование темы обсуждения
         * @param {number} topic_id 
         * @param {number} id 
         * @param {CommentEditParams1} commentEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherUpdateTopicComment(topic_id: number, id: number, commentEditParams: CommentEditParams1, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DiscussionTopicComment> {
            const localVarFetchArgs = DiscussionTopicCommentApiFetchParamCreator(configuration).teacherUpdateTopicComment(topic_id, id, commentEditParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Создание комментария темы
         * @param {number} topic_id 
         * @param {CommentCreateParams2} commentCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreateTopicComment(topic_id: number, commentCreateParams: CommentCreateParams2, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DiscussionTopicComment> {
            const localVarFetchArgs = DiscussionTopicCommentApiFetchParamCreator(configuration).userCreateTopicComment(topic_id, commentCreateParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Удаление комментария
         * @param {number} topic_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeleteComment(topic_id: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DiscussionTopicCommentApiFetchParamCreator(configuration).userDeleteComment(topic_id, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Получение всех комментариев темы
         * @param {number} topic_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTopicComments(topic_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DiscussionTopicCommentList> {
            const localVarFetchArgs = DiscussionTopicCommentApiFetchParamCreator(configuration).userTopicComments(topic_id, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Редактирование темы обсуждения
         * @param {number} topic_id 
         * @param {number} id 
         * @param {CommentEditParams2} commentEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateTopicComment(topic_id: number, id: number, commentEditParams: CommentEditParams2, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DiscussionTopicComment> {
            const localVarFetchArgs = DiscussionTopicCommentApiFetchParamCreator(configuration).userUpdateTopicComment(topic_id, id, commentEditParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DiscussionTopicCommentApi - factory interface
 * @export
 */
export const DiscussionTopicCommentApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Создание комментария темы
         * @param {number} topic_id 
         * @param {CommentCreateParams} commentCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateTopicComment(topic_id: number, commentCreateParams: CommentCreateParams, options?: any) {
            return DiscussionTopicCommentApiFp(configuration).adminCreateTopicComment(topic_id, commentCreateParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Удаление комментария
         * @param {number} topic_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteComment(topic_id: number, id: number, options?: any) {
            return DiscussionTopicCommentApiFp(configuration).adminDeleteComment(topic_id, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Получение всех комментариев темы
         * @param {number} topic_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTopicComments(topic_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return DiscussionTopicCommentApiFp(configuration).adminTopicComments(topic_id, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Редактирование темы обсуждения
         * @param {number} topic_id 
         * @param {number} id 
         * @param {CommentEditParams} commentEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateTopicComment(topic_id: number, id: number, commentEditParams: CommentEditParams, options?: any) {
            return DiscussionTopicCommentApiFp(configuration).adminUpdateTopicComment(topic_id, id, commentEditParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Создание комментария темы
         * @param {number} topic_id 
         * @param {CommentCreateParams1} commentCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherCreateTopicComment(topic_id: number, commentCreateParams: CommentCreateParams1, options?: any) {
            return DiscussionTopicCommentApiFp(configuration).teacherCreateTopicComment(topic_id, commentCreateParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Удаление комментария
         * @param {number} topic_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherDeleteComment(topic_id: number, id: number, options?: any) {
            return DiscussionTopicCommentApiFp(configuration).teacherDeleteComment(topic_id, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Получение всех комментариев темы
         * @param {number} topic_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherTopicComments(topic_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return DiscussionTopicCommentApiFp(configuration).teacherTopicComments(topic_id, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Редактирование темы обсуждения
         * @param {number} topic_id 
         * @param {number} id 
         * @param {CommentEditParams1} commentEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherUpdateTopicComment(topic_id: number, id: number, commentEditParams: CommentEditParams1, options?: any) {
            return DiscussionTopicCommentApiFp(configuration).teacherUpdateTopicComment(topic_id, id, commentEditParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Создание комментария темы
         * @param {number} topic_id 
         * @param {CommentCreateParams2} commentCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreateTopicComment(topic_id: number, commentCreateParams: CommentCreateParams2, options?: any) {
            return DiscussionTopicCommentApiFp(configuration).userCreateTopicComment(topic_id, commentCreateParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Удаление комментария
         * @param {number} topic_id 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeleteComment(topic_id: number, id: number, options?: any) {
            return DiscussionTopicCommentApiFp(configuration).userDeleteComment(topic_id, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Получение всех комментариев темы
         * @param {number} topic_id 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTopicComments(topic_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return DiscussionTopicCommentApiFp(configuration).userTopicComments(topic_id, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Редактирование темы обсуждения
         * @param {number} topic_id 
         * @param {number} id 
         * @param {CommentEditParams2} commentEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateTopicComment(topic_id: number, id: number, commentEditParams: CommentEditParams2, options?: any) {
            return DiscussionTopicCommentApiFp(configuration).userUpdateTopicComment(topic_id, id, commentEditParams, options)(fetch, basePath);
        },
    };
};

/**
 * DiscussionTopicCommentApi - object-oriented interface
 * @export
 * @class DiscussionTopicCommentApi
 * @extends {BaseAPI}
 */
export class DiscussionTopicCommentApi extends BaseAPI {
    /**
     * 
     * @summary Создание комментария темы
     * @param {number} topic_id 
     * @param {CommentCreateParams} commentCreateParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicCommentApi
     */
    public adminCreateTopicComment(topic_id: number, commentCreateParams: CommentCreateParams, options?: any) {
        return DiscussionTopicCommentApiFp(this.configuration).adminCreateTopicComment(topic_id, commentCreateParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Удаление комментария
     * @param {number} topic_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicCommentApi
     */
    public adminDeleteComment(topic_id: number, id: number, options?: any) {
        return DiscussionTopicCommentApiFp(this.configuration).adminDeleteComment(topic_id, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Получение всех комментариев темы
     * @param {number} topic_id 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicCommentApi
     */
    public adminTopicComments(topic_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return DiscussionTopicCommentApiFp(this.configuration).adminTopicComments(topic_id, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Редактирование темы обсуждения
     * @param {number} topic_id 
     * @param {number} id 
     * @param {CommentEditParams} commentEditParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicCommentApi
     */
    public adminUpdateTopicComment(topic_id: number, id: number, commentEditParams: CommentEditParams, options?: any) {
        return DiscussionTopicCommentApiFp(this.configuration).adminUpdateTopicComment(topic_id, id, commentEditParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Создание комментария темы
     * @param {number} topic_id 
     * @param {CommentCreateParams1} commentCreateParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicCommentApi
     */
    public teacherCreateTopicComment(topic_id: number, commentCreateParams: CommentCreateParams1, options?: any) {
        return DiscussionTopicCommentApiFp(this.configuration).teacherCreateTopicComment(topic_id, commentCreateParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Удаление комментария
     * @param {number} topic_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicCommentApi
     */
    public teacherDeleteComment(topic_id: number, id: number, options?: any) {
        return DiscussionTopicCommentApiFp(this.configuration).teacherDeleteComment(topic_id, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Получение всех комментариев темы
     * @param {number} topic_id 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicCommentApi
     */
    public teacherTopicComments(topic_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return DiscussionTopicCommentApiFp(this.configuration).teacherTopicComments(topic_id, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Редактирование темы обсуждения
     * @param {number} topic_id 
     * @param {number} id 
     * @param {CommentEditParams1} commentEditParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicCommentApi
     */
    public teacherUpdateTopicComment(topic_id: number, id: number, commentEditParams: CommentEditParams1, options?: any) {
        return DiscussionTopicCommentApiFp(this.configuration).teacherUpdateTopicComment(topic_id, id, commentEditParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Создание комментария темы
     * @param {number} topic_id 
     * @param {CommentCreateParams2} commentCreateParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicCommentApi
     */
    public userCreateTopicComment(topic_id: number, commentCreateParams: CommentCreateParams2, options?: any) {
        return DiscussionTopicCommentApiFp(this.configuration).userCreateTopicComment(topic_id, commentCreateParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Удаление комментария
     * @param {number} topic_id 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicCommentApi
     */
    public userDeleteComment(topic_id: number, id: number, options?: any) {
        return DiscussionTopicCommentApiFp(this.configuration).userDeleteComment(topic_id, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Получение всех комментариев темы
     * @param {number} topic_id 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicCommentApi
     */
    public userTopicComments(topic_id: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return DiscussionTopicCommentApiFp(this.configuration).userTopicComments(topic_id, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Редактирование темы обсуждения
     * @param {number} topic_id 
     * @param {number} id 
     * @param {CommentEditParams2} commentEditParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionTopicCommentApi
     */
    public userUpdateTopicComment(topic_id: number, id: number, commentEditParams: CommentEditParams2, options?: any) {
        return DiscussionTopicCommentApiFp(this.configuration).userUpdateTopicComment(topic_id, id, commentEditParams, options)(this.fetch, this.basePath);
    }

}

/**
 * NotificationApi - fetch parameter creator
 * @export
 */
export const NotificationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Открытие нотификации
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followNotification(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling followNotification.');
            }
            const localVarPath = `/user/notification/follow/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список нотификаций
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationList(options: any = {}): FetchArgs {
            const localVarPath = `/user/notification`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Помечаем все уведомления как прочитанные
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsMarkRead(options: any = {}): FetchArgs {
            const localVarPath = `/user/notification/mark-read`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Открытие нотификации
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followNotification(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = NotificationApiFetchParamCreator(configuration).followNotification(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Список нотификаций
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NotificationList> {
            const localVarFetchArgs = NotificationApiFetchParamCreator(configuration).notificationList(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Помечаем все уведомления как прочитанные
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsMarkRead(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NotificationApiFetchParamCreator(configuration).notificationsMarkRead(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Открытие нотификации
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followNotification(id: number, options?: any) {
            return NotificationApiFp(configuration).followNotification(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Список нотификаций
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationList(options?: any) {
            return NotificationApiFp(configuration).notificationList(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Помечаем все уведомления как прочитанные
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsMarkRead(options?: any) {
            return NotificationApiFp(configuration).notificationsMarkRead(options)(fetch, basePath);
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @summary Открытие нотификации
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public followNotification(id: number, options?: any) {
        return NotificationApiFp(this.configuration).followNotification(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Список нотификаций
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public notificationList(options?: any) {
        return NotificationApiFp(this.configuration).notificationList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Помечаем все уведомления как прочитанные
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public notificationsMarkRead(options?: any) {
        return NotificationApiFp(this.configuration).notificationsMarkRead(options)(this.fetch, this.basePath);
    }

}

/**
 * PortfolioApi - fetch parameter creator
 * @export
 */
export const PortfolioApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Мое портфолио
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPortfolio(course_id: number, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling myPortfolio.');
            }
            const localVarPath = `/user/portfolio/{course_id}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary История прохождений подмодуля для портфолио
         * @param {number} course_id 
         * @param {number} course_submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPortfolioSubmoduleHistory(course_id: number, course_submodule_id: number, options: any = {}): FetchArgs {
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling myPortfolioSubmoduleHistory.');
            }
            // verify required parameter 'course_submodule_id' is not null or undefined
            if (course_submodule_id === null || course_submodule_id === undefined) {
                throw new RequiredError('course_submodule_id','Required parameter course_submodule_id was null or undefined when calling myPortfolioSubmoduleHistory.');
            }
            const localVarPath = `/user/portfolio/{course_id}/submodule/{course_submodule_id}/history`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)))
                .replace(`{${"course_submodule_id"}}`, encodeURIComponent(String(course_submodule_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary История прохождений подмодуля для портфолио (для преподавателя)
         * @param {number} user_id 
         * @param {number} course_id 
         * @param {number} course_submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherPortfolioSubmoduleHistory(user_id: number, course_id: number, course_submodule_id: number, options: any = {}): FetchArgs {
            // verify required parameter 'user_id' is not null or undefined
            if (user_id === null || user_id === undefined) {
                throw new RequiredError('user_id','Required parameter user_id was null or undefined when calling teacherPortfolioSubmoduleHistory.');
            }
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling teacherPortfolioSubmoduleHistory.');
            }
            // verify required parameter 'course_submodule_id' is not null or undefined
            if (course_submodule_id === null || course_submodule_id === undefined) {
                throw new RequiredError('course_submodule_id','Required parameter course_submodule_id was null or undefined when calling teacherPortfolioSubmoduleHistory.');
            }
            const localVarPath = `/teacher/{user_id}/portfolio/{course_id}/submodule/{course_submodule_id}/history`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)))
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)))
                .replace(`{${"course_submodule_id"}}`, encodeURIComponent(String(course_submodule_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Портфолио пользователя (роль преподаватель)
         * @param {number} user_id 
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherUserPortfolio(user_id: number, course_id: number, options: any = {}): FetchArgs {
            // verify required parameter 'user_id' is not null or undefined
            if (user_id === null || user_id === undefined) {
                throw new RequiredError('user_id','Required parameter user_id was null or undefined when calling teacherUserPortfolio.');
            }
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling teacherUserPortfolio.');
            }
            const localVarPath = `/teacher/{user_id}/portfolio/{course_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)))
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Мое портфолио
         * @param {number} user_id 
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPortfolio(user_id: number, course_id: number, options: any = {}): FetchArgs {
            // verify required parameter 'user_id' is not null or undefined
            if (user_id === null || user_id === undefined) {
                throw new RequiredError('user_id','Required parameter user_id was null or undefined when calling userPortfolio.');
            }
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling userPortfolio.');
            }
            const localVarPath = `/user/{user_id}/portfolio/{course_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)))
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary История прохождений подмодуля для портфолио
         * @param {number} user_id 
         * @param {number} course_id 
         * @param {number} course_submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPortfolioSubmoduleHistory(user_id: number, course_id: number, course_submodule_id: number, options: any = {}): FetchArgs {
            // verify required parameter 'user_id' is not null or undefined
            if (user_id === null || user_id === undefined) {
                throw new RequiredError('user_id','Required parameter user_id was null or undefined when calling userPortfolioSubmoduleHistory.');
            }
            // verify required parameter 'course_id' is not null or undefined
            if (course_id === null || course_id === undefined) {
                throw new RequiredError('course_id','Required parameter course_id was null or undefined when calling userPortfolioSubmoduleHistory.');
            }
            // verify required parameter 'course_submodule_id' is not null or undefined
            if (course_submodule_id === null || course_submodule_id === undefined) {
                throw new RequiredError('course_submodule_id','Required parameter course_submodule_id was null or undefined when calling userPortfolioSubmoduleHistory.');
            }
            const localVarPath = `/user/{user_id}/portfolio/{course_id}/submodule/{course_submodule_id}/history`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)))
                .replace(`{${"course_id"}}`, encodeURIComponent(String(course_id)))
                .replace(`{${"course_submodule_id"}}`, encodeURIComponent(String(course_submodule_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioApi - functional programming interface
 * @export
 */
export const PortfolioApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Мое портфолио
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPortfolio(course_id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Portfolio> {
            const localVarFetchArgs = PortfolioApiFetchParamCreator(configuration).myPortfolio(course_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary История прохождений подмодуля для портфолио
         * @param {number} course_id 
         * @param {number} course_submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPortfolioSubmoduleHistory(course_id: number, course_submodule_id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PortfolioCompletionList> {
            const localVarFetchArgs = PortfolioApiFetchParamCreator(configuration).myPortfolioSubmoduleHistory(course_id, course_submodule_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary История прохождений подмодуля для портфолио (для преподавателя)
         * @param {number} user_id 
         * @param {number} course_id 
         * @param {number} course_submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherPortfolioSubmoduleHistory(user_id: number, course_id: number, course_submodule_id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PortfolioCompletionList> {
            const localVarFetchArgs = PortfolioApiFetchParamCreator(configuration).teacherPortfolioSubmoduleHistory(user_id, course_id, course_submodule_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Портфолио пользователя (роль преподаватель)
         * @param {number} user_id 
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherUserPortfolio(user_id: number, course_id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Portfolio> {
            const localVarFetchArgs = PortfolioApiFetchParamCreator(configuration).teacherUserPortfolio(user_id, course_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Мое портфолио
         * @param {number} user_id 
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPortfolio(user_id: number, course_id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Portfolio> {
            const localVarFetchArgs = PortfolioApiFetchParamCreator(configuration).userPortfolio(user_id, course_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary История прохождений подмодуля для портфолио
         * @param {number} user_id 
         * @param {number} course_id 
         * @param {number} course_submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPortfolioSubmoduleHistory(user_id: number, course_id: number, course_submodule_id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PortfolioCompletionList> {
            const localVarFetchArgs = PortfolioApiFetchParamCreator(configuration).userPortfolioSubmoduleHistory(user_id, course_id, course_submodule_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PortfolioApi - factory interface
 * @export
 */
export const PortfolioApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Мое портфолио
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPortfolio(course_id: number, options?: any) {
            return PortfolioApiFp(configuration).myPortfolio(course_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary История прохождений подмодуля для портфолио
         * @param {number} course_id 
         * @param {number} course_submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPortfolioSubmoduleHistory(course_id: number, course_submodule_id: number, options?: any) {
            return PortfolioApiFp(configuration).myPortfolioSubmoduleHistory(course_id, course_submodule_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary История прохождений подмодуля для портфолио (для преподавателя)
         * @param {number} user_id 
         * @param {number} course_id 
         * @param {number} course_submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherPortfolioSubmoduleHistory(user_id: number, course_id: number, course_submodule_id: number, options?: any) {
            return PortfolioApiFp(configuration).teacherPortfolioSubmoduleHistory(user_id, course_id, course_submodule_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Портфолио пользователя (роль преподаватель)
         * @param {number} user_id 
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherUserPortfolio(user_id: number, course_id: number, options?: any) {
            return PortfolioApiFp(configuration).teacherUserPortfolio(user_id, course_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Мое портфолио
         * @param {number} user_id 
         * @param {number} course_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPortfolio(user_id: number, course_id: number, options?: any) {
            return PortfolioApiFp(configuration).userPortfolio(user_id, course_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary История прохождений подмодуля для портфолио
         * @param {number} user_id 
         * @param {number} course_id 
         * @param {number} course_submodule_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPortfolioSubmoduleHistory(user_id: number, course_id: number, course_submodule_id: number, options?: any) {
            return PortfolioApiFp(configuration).userPortfolioSubmoduleHistory(user_id, course_id, course_submodule_id, options)(fetch, basePath);
        },
    };
};

/**
 * PortfolioApi - object-oriented interface
 * @export
 * @class PortfolioApi
 * @extends {BaseAPI}
 */
export class PortfolioApi extends BaseAPI {
    /**
     * 
     * @summary Мое портфолио
     * @param {number} course_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public myPortfolio(course_id: number, options?: any) {
        return PortfolioApiFp(this.configuration).myPortfolio(course_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary История прохождений подмодуля для портфолио
     * @param {number} course_id 
     * @param {number} course_submodule_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public myPortfolioSubmoduleHistory(course_id: number, course_submodule_id: number, options?: any) {
        return PortfolioApiFp(this.configuration).myPortfolioSubmoduleHistory(course_id, course_submodule_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary История прохождений подмодуля для портфолио (для преподавателя)
     * @param {number} user_id 
     * @param {number} course_id 
     * @param {number} course_submodule_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public teacherPortfolioSubmoduleHistory(user_id: number, course_id: number, course_submodule_id: number, options?: any) {
        return PortfolioApiFp(this.configuration).teacherPortfolioSubmoduleHistory(user_id, course_id, course_submodule_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Портфолио пользователя (роль преподаватель)
     * @param {number} user_id 
     * @param {number} course_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public teacherUserPortfolio(user_id: number, course_id: number, options?: any) {
        return PortfolioApiFp(this.configuration).teacherUserPortfolio(user_id, course_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Мое портфолио
     * @param {number} user_id 
     * @param {number} course_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public userPortfolio(user_id: number, course_id: number, options?: any) {
        return PortfolioApiFp(this.configuration).userPortfolio(user_id, course_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary История прохождений подмодуля для портфолио
     * @param {number} user_id 
     * @param {number} course_id 
     * @param {number} course_submodule_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public userPortfolioSubmoduleHistory(user_id: number, course_id: number, course_submodule_id: number, options?: any) {
        return PortfolioApiFp(this.configuration).userPortfolioSubmoduleHistory(user_id, course_id, course_submodule_id, options)(this.fetch, this.basePath);
    }

}

/**
 * StaticApi - fetch parameter creator
 * @export
 */
export const StaticApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns static dictionaries of project.
         * @summary Get static dictionaries.
         * @param {Array<string>} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        staticGet(expand?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/static`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (expand) {
                localVarQueryParameter['expand'] = expand.join(COLLECTION_FORMATS["csv"]);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaticApi - functional programming interface
 * @export
 */
export const StaticApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Returns static dictionaries of project.
         * @summary Get static dictionaries.
         * @param {Array<string>} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        staticGet(expand?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StaticResult> {
            const localVarFetchArgs = StaticApiFetchParamCreator(configuration).staticGet(expand, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * StaticApi - factory interface
 * @export
 */
export const StaticApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Returns static dictionaries of project.
         * @summary Get static dictionaries.
         * @param {Array<string>} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        staticGet(expand?: Array<string>, options?: any) {
            return StaticApiFp(configuration).staticGet(expand, options)(fetch, basePath);
        },
    };
};

/**
 * StaticApi - object-oriented interface
 * @export
 * @class StaticApi
 * @extends {BaseAPI}
 */
export class StaticApi extends BaseAPI {
    /**
     * Returns static dictionaries of project.
     * @summary Get static dictionaries.
     * @param {Array<string>} [expand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticApi
     */
    public staticGet(expand?: Array<string>, options?: any) {
        return StaticApiFp(this.configuration).staticGet(expand, options)(this.fetch, this.basePath);
    }

}

/**
 * UploadApi - fetch parameter creator
 * @export
 */
export const UploadApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Загрузка документа для общего назначения пользователем
         * @param {any} [file] file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUploadDocument(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/user/upload/document`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка изображения для общего назначения пользователем
         * @param {any} [file] file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUploadImage(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/user/upload/image`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Загрузка документа для общего назначения пользователем
         * @param {any} [file] file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUploadDocument(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UrlResponse> {
            const localVarFetchArgs = UploadApiFetchParamCreator(configuration).userUploadDocument(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Загрузка изображения для общего назначения пользователем
         * @param {any} [file] file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUploadImage(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UrlResponse> {
            const localVarFetchArgs = UploadApiFetchParamCreator(configuration).userUploadImage(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Загрузка документа для общего назначения пользователем
         * @param {any} [file] file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUploadDocument(file?: any, options?: any) {
            return UploadApiFp(configuration).userUploadDocument(file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Загрузка изображения для общего назначения пользователем
         * @param {any} [file] file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUploadImage(file?: any, options?: any) {
            return UploadApiFp(configuration).userUploadImage(file, options)(fetch, basePath);
        },
    };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * 
     * @summary Загрузка документа для общего назначения пользователем
     * @param {any} [file] file to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public userUploadDocument(file?: any, options?: any) {
        return UploadApiFp(this.configuration).userUploadDocument(file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Загрузка изображения для общего назначения пользователем
     * @param {any} [file] file to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public userUploadImage(file?: any, options?: any) {
        return UploadApiFp(this.configuration).userUploadImage(file, options)(this.fetch, this.basePath);
    }

}

/**
 * UserApi - fetch parameter creator
 * @export
 */
export const UserApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание пользователя
         * @param {UserCreateParams} userCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateUser(userCreateParams: UserCreateParams, options: any = {}): FetchArgs {
            // verify required parameter 'userCreateParams' is not null or undefined
            if (userCreateParams === null || userCreateParams === undefined) {
                throw new RequiredError('userCreateParams','Required parameter userCreateParams was null or undefined when calling adminCreateUser.');
            }
            const localVarPath = `/admin/user`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserCreateParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(userCreateParams || {}) : (userCreateParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование пользователя
         * @param {number} id 
         * @param {UserEditParams} userEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateUser(id: number, userEditParams: UserEditParams, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminUpdateUser.');
            }
            // verify required parameter 'userEditParams' is not null or undefined
            if (userEditParams === null || userEditParams === undefined) {
                throw new RequiredError('userEditParams','Required parameter userEditParams was null or undefined when calling adminUpdateUser.');
            }
            const localVarPath = `/admin/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserEditParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(userEditParams || {}) : (userEditParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список пользователей
         * @param {string} [query] 
         * @param {number} [private_course_id] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserList(query?: string, private_course_id?: number, X_Current_Page?: number, X_Per_Page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/admin/user`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (private_course_id !== undefined) {
                localVarQueryParameter['private_course_id'] = private_course_id;
            }

            if (X_Current_Page !== undefined && X_Current_Page !== null) {
                localVarHeaderParameter['X-Current-Page'] = String(X_Current_Page);
            }

            if (X_Per_Page !== undefined && X_Per_Page !== null) {
                localVarHeaderParameter['X-Per-Page'] = String(X_Per_Page);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр пользователя
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewUser(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminViewUser.');
            }
            const localVarPath = `/admin/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Смена пароля пользователем
         * @param {MeChangePasswordParams} meChangePasswordParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meChangePassword(meChangePasswordParams: MeChangePasswordParams, options: any = {}): FetchArgs {
            // verify required parameter 'meChangePasswordParams' is not null or undefined
            if (meChangePasswordParams === null || meChangePasswordParams === undefined) {
                throw new RequiredError('meChangePasswordParams','Required parameter meChangePasswordParams was null or undefined when calling meChangePassword.');
            }
            const localVarPath = `/user/me/change-password`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MeChangePasswordParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(meChangePasswordParams || {}) : (meChangePasswordParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование профиля пользователя
         * @param {MeEditParams} meEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meEdit(meEditParams: MeEditParams, options: any = {}): FetchArgs {
            // verify required parameter 'meEditParams' is not null or undefined
            if (meEditParams === null || meEditParams === undefined) {
                throw new RequiredError('meEditParams','Required parameter meEditParams was null or undefined when calling meEdit.');
            }
            const localVarPath = `/user/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MeEditParams" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(meEditParams || {}) : (meEditParams || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка фото пользователя
         * @param {any} [file] file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mePhotoUpload(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/user/me/upload-photo`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMe(options: any = {}): FetchArgs {
            const localVarPath = `/user/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание пользователя
         * @param {UserCreateParams} userCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateUser(userCreateParams: UserCreateParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminUser> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).adminCreateUser(userCreateParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Редактирование пользователя
         * @param {number} id 
         * @param {UserEditParams} userEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateUser(id: number, userEditParams: UserEditParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminUser> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).adminUpdateUser(id, userEditParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Список пользователей
         * @param {string} [query] 
         * @param {number} [private_course_id] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserList(query?: string, private_course_id?: number, X_Current_Page?: number, X_Per_Page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminUserList> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).adminUserList(query, private_course_id, X_Current_Page, X_Per_Page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр пользователя
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewUser(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminUser> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).adminViewUser(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Смена пароля пользователем
         * @param {MeChangePasswordParams} meChangePasswordParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meChangePassword(meChangePasswordParams: MeChangePasswordParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).meChangePassword(meChangePasswordParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Редактирование профиля пользователя
         * @param {MeEditParams} meEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meEdit(meEditParams: MeEditParams, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<User> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).meEdit(meEditParams, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Загрузка фото пользователя
         * @param {any} [file] file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mePhotoUpload(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<User> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).mePhotoUpload(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Просмотр текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMe(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<User> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userMe(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Создание пользователя
         * @param {UserCreateParams} userCreateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateUser(userCreateParams: UserCreateParams, options?: any) {
            return UserApiFp(configuration).adminCreateUser(userCreateParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Редактирование пользователя
         * @param {number} id 
         * @param {UserEditParams} userEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateUser(id: number, userEditParams: UserEditParams, options?: any) {
            return UserApiFp(configuration).adminUpdateUser(id, userEditParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Список пользователей
         * @param {string} [query] 
         * @param {number} [private_course_id] 
         * @param {number} [X_Current_Page] 
         * @param {number} [X_Per_Page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserList(query?: string, private_course_id?: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
            return UserApiFp(configuration).adminUserList(query, private_course_id, X_Current_Page, X_Per_Page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр пользователя
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminViewUser(id: number, options?: any) {
            return UserApiFp(configuration).adminViewUser(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Смена пароля пользователем
         * @param {MeChangePasswordParams} meChangePasswordParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meChangePassword(meChangePasswordParams: MeChangePasswordParams, options?: any) {
            return UserApiFp(configuration).meChangePassword(meChangePasswordParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Редактирование профиля пользователя
         * @param {MeEditParams} meEditParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meEdit(meEditParams: MeEditParams, options?: any) {
            return UserApiFp(configuration).meEdit(meEditParams, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Загрузка фото пользователя
         * @param {any} [file] file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mePhotoUpload(file?: any, options?: any) {
            return UserApiFp(configuration).mePhotoUpload(file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Просмотр текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMe(options?: any) {
            return UserApiFp(configuration).userMe(options)(fetch, basePath);
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Создание пользователя
     * @param {UserCreateParams} userCreateParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public adminCreateUser(userCreateParams: UserCreateParams, options?: any) {
        return UserApiFp(this.configuration).adminCreateUser(userCreateParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Редактирование пользователя
     * @param {number} id 
     * @param {UserEditParams} userEditParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public adminUpdateUser(id: number, userEditParams: UserEditParams, options?: any) {
        return UserApiFp(this.configuration).adminUpdateUser(id, userEditParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Список пользователей
     * @param {string} [query] 
     * @param {number} [private_course_id] 
     * @param {number} [X_Current_Page] 
     * @param {number} [X_Per_Page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public adminUserList(query?: string, private_course_id?: number, X_Current_Page?: number, X_Per_Page?: number, options?: any) {
        return UserApiFp(this.configuration).adminUserList(query, private_course_id, X_Current_Page, X_Per_Page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр пользователя
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public adminViewUser(id: number, options?: any) {
        return UserApiFp(this.configuration).adminViewUser(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Смена пароля пользователем
     * @param {MeChangePasswordParams} meChangePasswordParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public meChangePassword(meChangePasswordParams: MeChangePasswordParams, options?: any) {
        return UserApiFp(this.configuration).meChangePassword(meChangePasswordParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Редактирование профиля пользователя
     * @param {MeEditParams} meEditParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public meEdit(meEditParams: MeEditParams, options?: any) {
        return UserApiFp(this.configuration).meEdit(meEditParams, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Загрузка фото пользователя
     * @param {any} [file] file to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public mePhotoUpload(file?: any, options?: any) {
        return UserApiFp(this.configuration).mePhotoUpload(file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Просмотр текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userMe(options?: any) {
        return UserApiFp(this.configuration).userMe(options)(this.fetch, this.basePath);
    }

}

