import * as React from 'react';
import {useContext, useRef, useState} from 'react';
import * as yup from "yup";
import {DiscussionCreateParams} from "../../api/generated";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import {mutate} from "swr";
import {AuthContext} from "../auth/AuthContext";
import ApiFactory from "../../api/ApiFactory";
import ErrorHandler from "../ErrorHandler";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import {DiscussionTypeEnum} from "../../models/discussion";

type Props = {
    externalId: number,
    type: DiscussionTypeEnum,
    onSucceed: () => void;
    onCancel: () => void;
}

const formValidationSchema = yup.object<DiscussionCreateParams>({
    name: yup.string().required(),
    is_private: yup.boolean().required().default(false),
})


function DiscussionForm({externalId, type, onSucceed, onCancel}: Props) {
    const form = useRef<HTMLFormElement>(null);
    const {userId} = useContext(AuthContext);
    const [error, setError] = useState<Error | undefined>(undefined);

    const {register, handleSubmit, errors, setValue, reset} = useForm<DiscussionCreateParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            name: "",
        }
    });

    const onSubmit = (params: DiscussionCreateParams) => {
        setError(undefined)
        if (type === DiscussionTypeEnum.Module) {
            ApiFactory.Instance().DiscussionAPI().adminCreateModuleDiscussion(externalId, params)
                .then(s => {
                    mutate(["admin/discussions", externalId, type, 0])
                    onSucceed()
                }).catch(e => setError(e));
        } else if (type === DiscussionTypeEnum.Course) {
            ApiFactory.Instance().DiscussionAPI().adminCreateCourseDiscussion(externalId, params)
                .then(s => {
                    mutate(["admin/discussions", externalId, type, 0])
                    onSucceed()
                }).catch(e => setError(e));
        }
    }

    return <Card className={'m-4'}>
        <Card.Body>
            {error && <ErrorHandler error={error}/>}
            <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
                <div className={'d-flex flex-row align-items-center justify-content-end'}>
                    <div className={'d-flex flex-row'}>
                        <img className={'button-icon ml-4 as-link'} src={"/images/cancel.svg"}
                             onClick={onCancel}/>
                        <img className={'button-icon ml-4 as-link'} src={"/images/save.svg"}
                             onClick={() => {
                                 if (form.current) {
                                     form.current.dispatchEvent(new Event('submit', {cancelable: true}))
                                 }
                             }}/>
                    </div>
                </div>
                <Form.Group>
                    <Form.Label>Название</Form.Label>
                    <Form.Control ref={register} name={"name"}/>
                    {errors && errors.name &&
                    <Form.Text className={'text-danger'}>{(errors.name as any)?.message}</Form.Text>}
                </Form.Group>
                <Form.Group>
                    <Form.Check ref={register} name={"is_private"} label={'Личное обсуждение'}/>
                    {errors && errors.is_private &&
                    <Form.Text className={'text-danger'}>{errors.is_private.message}</Form.Text>}
                </Form.Group>
            </Form>
        </Card.Body>

    </Card>;
}


export default function DiscussionCreate(props: Props) {
    return <DiscussionForm {...props}/>;
}