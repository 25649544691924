import * as React from 'react';
import {Suspense, useContext, useEffect, useRef, useState} from 'react';
import {
    AdminFullCourseSubmodule,
    CourseSubmodulePeriodStrategy,
    CourseSubmodulePointsStrategy,
    CreateSubmoduleParams,
    ErrorMessage, IdNameEntity
} from "../../../api/generated";
import ErrorHandler from "../../ErrorHandler";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import Form from 'react-bootstrap/Form';
import Select from "react-select";
import ApiFactory from "../../../api/ApiFactory";
import {Type} from "../types";
import Card from "react-bootstrap/Card";
import {AuthContext} from "../../auth/AuthContext";
import {SubmodulePeriodStrategyEnum, SubmodulePointStrategyEnum} from "../../common/types";
import DictionarySelect from "../../common/DictionarySelect";
import {LineLoader} from "../../Loaders";

const DatePicker = React.lazy(() => import("react-datepicker"));

type Props = {
    onSuccess: (sub: AdminFullCourseSubmodule) => void
    onCancel: () => void
    sectionId: number
}

type FormParams = {
    course_submodule_type_text?: string
    course_submodule_type_description?: string
    course_submodule_type_content_url?: string
    max_retries?: number
    min_time_between_retries?: number
    points?: number
    max_points?: number
} & CreateSubmoduleParams;

type SelectOption = { value: string, label: string };

const formValidationSchema = yup.object<FormParams>({
    name: yup.string().required(),
    duration: yup.number().required(),
    optional: yup.boolean().required(),
    is_verification_anonymous: yup.mixed().oneOf([true, false]).required(),
    course_submodule_type_id: yup.number().required(),
    course_submodule_type_data: yup.mixed(),
    course_submodule_points_strategy_data: yup.mixed(),
    course_submodule_points_strategy_id: yup.number().required(),
    course_submodule_period_strategy_data: yup.mixed(),
    course_submodule_period_strategy_id: yup.number().required(),
    min_time_between_retries: yup.number().min(0),
    max_points: yup.number(),
    max_retries: yup.number(),
    points: yup.number(),
    course_section_id: yup.number().required(),
    has_deadline: yup.boolean().required(),
    is_available_in_my_portfolio: yup.boolean().required(),
    is_available_in_other_user_portfolio: yup.boolean().required(),
})

export default function SubmoduleCreate(props: Props) {
    const form = useRef<HTMLFormElement>(null);
    const {userId} = useContext(AuthContext)
    const [error, setError] = useState<Error | undefined>(undefined);
    const [pointsStrategyId, setPointsStrategyId] = useState<SubmodulePointStrategyEnum | undefined>(undefined);
    const [periodStrategyId, setPeriodStrategyId] = useState<SubmodulePeriodStrategyEnum | undefined>(undefined);

    const [selectPeriod, setSelectPeriod] = useState<IdNameEntity | null>(null);
    const [selectPoints, setSelectPoints] = useState<IdNameEntity | null>(null);
    const [selectTypeId, setSelectTypeId] = useState<SelectOption | undefined>(undefined);

    const [typeId, setTypeId] = useState<number>(Type.Text);

    const [availableTypes, setAvailableTypes] = useState<SelectOption[]>([])
    const [availablePeriodStrategy, setAvailablePeriodStrategy] = useState<CourseSubmodulePeriodStrategy[]>([])
    const [availablePointsStrategy, setAvailablePointsStrategy] = useState<CourseSubmodulePointsStrategy[]>([])

    const [deadlineTime, setDeadlineTime] = useState<Date>(new Date());
    const [hasDeadline, setHasDeadline] = useState<boolean>(false);

    useEffect(() => {
        ApiFactory.Instance().CourseSubmoduleAPI().adminCourseSubmoduleAvailablePeriodStrategies(typeId)
            .then(resp => {
                const strat = resp.find(r => r.id == periodStrategyId)
                if (strat === undefined) {
                    setPeriodStrategyId(undefined)
                    setSelectPeriod(null)
                } else {
                    setSelectPeriod(strat)
                }

                setAvailablePeriodStrategy(resp)
            })
        ApiFactory.Instance().CourseSubmoduleAPI().adminCourseSubmoduleAvailablePointStrategies(typeId)
            .then(resp => {
                const strat = resp.find(r => r.id == pointsStrategyId)
                if (strat === undefined) {
                    setPointsStrategyId(undefined)
                    setSelectPoints(null)
                } else {
                    setSelectPoints(strat)
                }

                setAvailablePointsStrategy(resp)
            })
    }, [typeId])

    useEffect(() => {
        ApiFactory.Instance().StaticAPI().staticGet(["course_submodule_type"])
            .then(resp => {
                if (resp.course_submodule_type === undefined) {
                    setAvailableTypes([])
                } else {
                    const firstType = resp.course_submodule_type[0]
                    setSelectTypeId({value: "" + firstType.id, label: firstType.name})
                    setAvailableTypes(resp.course_submodule_type.map<SelectOption>(t => ({
                        value: "" + t.id,
                        label: t.name
                    })))
                }
            })
        register("is_verification_anonymous")
        register("course_submodule_type_id")
    }, [])

    const {register, handleSubmit, errors, setValue, reset} = useForm<FormParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            name: "",
            duration: 0,
            course_section_id: props.sectionId,
            is_verification_anonymous: false,
            course_submodule_type_id: typeId,
            course_submodule_period_strategy_data: {},
            course_submodule_period_strategy_id: SubmodulePeriodStrategyEnum.NoLimits,
            course_submodule_points_strategy_data: {},
            course_submodule_points_strategy_id: SubmodulePointStrategyEnum.NoPoints,
            has_deadline: false,
            is_available_in_other_user_portfolio: false,
            is_available_in_my_portfolio: false,
        }
    });

    useEffect(() => {
        register("course_section_id")
        register("deadline_time")
    }, [props.sectionId])

    const onSubmit = (params: FormParams) => {
        let pointsStrategyData = {}
        let periodStrategyData = {}

        switch (params.course_submodule_points_strategy_id) {
            case SubmodulePointStrategyEnum.FixedPoints:
                pointsStrategyData = {
                    points: params.points
                }
                break;
            case SubmodulePointStrategyEnum.ManualPoints:
            case SubmodulePointStrategyEnum.ManualPointsNonBlocking:
                pointsStrategyData = {
                    max_points: params.max_points
                }
                break;
        }

        switch (params.course_submodule_period_strategy_id) {
            case SubmodulePeriodStrategyEnum.LimitByCount:
                periodStrategyData = {
                    max_retries: params.max_retries
                }
                break;
            case SubmodulePeriodStrategyEnum.LimitByCountAndTime:
                periodStrategyData = {
                    max_retries: params.max_retries,
                    min_time_between_retries: params.min_time_between_retries,
                }
        }

        ApiFactory.Instance().CourseSubmoduleAPI().adminCreateCourseSubModule({
            is_verification_anonymous: params.is_verification_anonymous,
            duration: params.duration,
            optional: params.optional,
            name: params.name,
            course_section_id: props.sectionId,
            course_submodule_period_strategy_id: params.course_submodule_period_strategy_id,
            course_submodule_period_strategy_data: periodStrategyData,
            course_submodule_points_strategy_id: params.course_submodule_points_strategy_id,
            course_submodule_points_strategy_data: pointsStrategyData,
            course_submodule_type_id: params.course_submodule_type_id,
            course_submodule_type_data: {},
            has_deadline: params.has_deadline,
            deadline_time: params.deadline_time,
            is_available_in_my_portfolio: params.is_available_in_my_portfolio,
            is_available_in_other_user_portfolio: params.is_available_in_other_user_portfolio,
        }).then((sm) => {
            props.onSuccess(sm)
        }).catch(e => {
            const resp = e as Response
            resp.json()
                .then((j: ErrorMessage) => setError(new Error(j.message)))
                .catch(e => setError(e))
        })
    }
    return <Card className={'content-card__card'}>
        <Card.Body>
            {error && <ErrorHandler error={error}/>}
            <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
                <div className={'d-flex flex-row align-items-center justify-content-end'}>
                    <div className={'d-flex flex-row'}>
                        <img className={'button-icon ml-4 as-link'} src={"/images/cancel.svg"}
                             onClick={props.onCancel}/>
                        <img className={'button-icon ml-4 as-link'} src={"/images/save.svg"}
                             onClick={() => {
                                 if (form.current) {
                                     form.current.dispatchEvent(new Event('submit', {cancelable: true}))
                                 }
                             }}/>
                    </div>
                </div>
                <Form.Group>
                    <Form.Label>Название</Form.Label>
                    <Form.Control ref={register} name={"name"}/>
                    {errors && errors.name &&
                    <Form.Text className={'text-danger'}>{(errors.name as any)?.message}</Form.Text>}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Длительность</Form.Label>
                    <Form.Control ref={register} name={"duration"}/>
                    {errors && errors.duration &&
                    <Form.Text className={'text-danger'}>{errors.duration.message}</Form.Text>}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Тип</Form.Label>
                    <Select value={selectTypeId}
                            placeholder={'Выберите тип'}
                            onChange={(v: any) => {
                                const val = v as SelectOption;
                                setSelectTypeId(val)
                                const newVal = Number(v.value)
                                setTypeId(newVal)
                                setValue("course_submodule_type_id", newVal)
                            }}
                            options={availableTypes}
                    />
                    {errors && errors.course_submodule_type_id &&
                    <Form.Text className={'text-danger'}>{errors.course_submodule_type_id.message}</Form.Text>}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Политика повторного прохождения</Form.Label>
                    <DictionarySelect
                        items={availablePeriodStrategy}
                        propertyName={'course_submodule_period_strategy_id'}
                        register={register}
                        setValue={setValue}
                        initial={selectPeriod ? selectPeriod : null}
                        onValueChanged={i => {
                            setSelectPeriod(i)
                            setPeriodStrategyId(i.id)
                        }}
                    />
                    {errors && errors.course_submodule_period_strategy_id &&
                    <Form.Text
                        className={'text-danger'}>{errors.course_submodule_period_strategy_id.message}</Form.Text>}
                </Form.Group>

                {periodStrategyId === SubmodulePeriodStrategyEnum.LimitByCount
                && <>
                    <Form.Group>
                        <Form.Label>Максимальное количество прохождений</Form.Label>
                        <Form.Control ref={register} name={"max_retries"} type={'number'}/>
                        {errors && errors.max_retries &&
                        <Form.Text className={'text-danger'}>{errors.max_retries.message}</Form.Text>}
                    </Form.Group>
                </>
                }

                {periodStrategyId === SubmodulePeriodStrategyEnum.LimitByCountAndTime
                && <>
                    <Form.Group>
                        <Form.Label>Максимальное количество прохождений</Form.Label>
                        <Form.Control ref={register} name={"max_retries"} type={'number'}/>
                        {errors && errors.max_retries &&
                        <Form.Text className={'text-danger'}>{errors.max_retries.message}</Form.Text>}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Время между прохождениями (мин)</Form.Label>
                        <Form.Control ref={register} name={"min_time_between_retries"} type={'number'}/>
                        {errors && errors.min_time_between_retries &&
                        <Form.Text className={'text-danger'}>{errors.min_time_between_retries.message}</Form.Text>}
                    </Form.Group>
                </>
                }

                <Form.Group>
                    <Form.Label>Политика начисления баллов</Form.Label>
                    <DictionarySelect
                        items={availablePointsStrategy}
                        propertyName={'course_submodule_points_strategy_id'}
                        register={register}
                        initial={selectPoints ? selectPoints : null}
                        setValue={setValue}
                        onValueChanged={i => {
                            setSelectPoints(i)
                            setPointsStrategyId(i.id)
                        }}
                    />
                    {errors && errors.course_submodule_points_strategy_id &&
                    <Form.Text
                        className={'text-danger'}>{errors.course_submodule_points_strategy_id.message}</Form.Text>}
                </Form.Group>

                {(pointsStrategyId === SubmodulePointStrategyEnum.ManualPoints || pointsStrategyId === SubmodulePointStrategyEnum.ManualPointsNonBlocking)
                && <Form.Group>
                    <Form.Label>Максимальное количество баллов для начисления</Form.Label>
                    <Form.Control ref={register} name={"max_points"} type={'number'}/>
                    {errors && errors.max_points &&
                    <Form.Text className={'text-danger'}>{errors.max_points.message}</Form.Text>}
                </Form.Group>
                }

                {pointsStrategyId === SubmodulePointStrategyEnum.FixedPoints
                && <Form.Group>
                    <Form.Label>Количество баллов для начисления</Form.Label>
                    <Form.Control ref={register} name={"points"} type={'number'}/>
                    {errors && errors.points &&
                    <Form.Text className={'text-danger'}>{errors.points.message}</Form.Text>}
                </Form.Group>
                }
                <Form.Group>
                    <Form.Check ref={register}
                                name={"optional"}
                                type={'checkbox'}
                                label={'Необязательный для прохождения'}
                    />
                    {errors && errors.optional &&
                    <Form.Text className={'text-danger'}>{errors.optional.message}</Form.Text>}
                </Form.Group>
                <Form.Group>
                    <Form.Check ref={register}
                                name={"has_deadline"}
                                type={'checkbox'}
                                label={'Задание должно быть выполнено до определенной даты'}
                                onChange={(e: any) => setHasDeadline(e.target.value)}
                    />
                    {errors && errors.has_deadline &&
                    <Form.Text className={'text-danger'}>{errors.has_deadline.message}</Form.Text>}
                </Form.Group>
                {hasDeadline &&
                    <Form.Group>
                        <Form.Label>Дата ограничения выполнения задания</Form.Label>
                        <Suspense fallback={<LineLoader/>}>
                            <DatePicker dateFormat={'dd.MM.yyyy'} selected={deadlineTime} className={'form-control'}
                                        onChange={val => {
                                            const res = val as Date
                                            res.setHours(0, 0, 0, 0)
                                            setValue("deadline_time", res.toISOString())
                                            setDeadlineTime(res)
                                        }}/>
                        </Suspense>
                        {errors && errors.deadline_time &&
                        <Form.Text
                            className={'text-danger'}>{errors.deadline_time.message}</Form.Text>}
                    </Form.Group>
                }
                <Form.Group>
                    <Form.Check label={'Подмодуль доступен в собственном портфолио пользователя'} type={'checkbox'} ref={register} name={"is_available_in_my_portfolio"}/>
                    {errors && errors.is_available_in_my_portfolio &&
                    <Form.Text className={'text-danger'}>{errors.is_available_in_my_portfolio.message}</Form.Text>}
                </Form.Group>
                <Form.Group>
                    <Form.Check label={'Подмодуль доступен в портфолио других пользователей'} type={'checkbox'} ref={register} name={"is_available_in_other_user_portfolio"}/>
                    {errors && errors.is_available_in_other_user_portfolio &&
                    <Form.Text className={'text-danger'}>{errors.is_available_in_other_user_portfolio.message}</Form.Text>}
                </Form.Group>

            </Form>
        </Card.Body>
    </Card>
}
