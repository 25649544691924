import React, {useContext, useEffect, useMemo, useState} from "react";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import Card from "react-bootstrap/Card";
import {UserFullCourseSubmodule} from "../../api/generated";
import {AuthContext} from "../auth/AuthContext";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import {bool} from "yup";
import RichContent from "../common/RichContent";
import ErrorHandler from "../ErrorHandler";
import Button from "react-bootstrap/Button";
import ApiFactory from "../../api/ApiFactory";
import {mutate} from "swr";
import PreviousSubmoduleResult from "./PreviousSubmoduleResult";

type Item = {
    id: string,
    content: string,
    userDefined: boolean,
}

const reorder = (list: Item[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const reorderVariants = (list: string[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source: Item[], destination: Item[], droppableSource: { index: number, droppableId: string }, droppableDestination: { index: number, droppableId: string }) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result: { [key: string]: any } = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // change background colour if dragging

    // styles we need to apply on draggables
    ...draggableStyle
});
const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "lightblue" : undefined,
});

type Props = {
    submodule: UserFullCourseSubmodule,
    courseId: number
    courseCompletionId: number
}

type TypeData = {
    description: string
    variants: string[]
    groups: string[]
    user_can_add_groups: boolean
    user_can_add_variants: boolean
    min_groups: number
}

type Group = {
    name: string
    variants: string[]
    userDefined: boolean
}


type AddVariantProps = {
    onCancel: () => void;
    onSuccess: (text: string) => void;
}

function AddVariantForm(props: AddVariantProps) {
    const [variant, setVariant] = useState<string>("")
    return <Card className={'rounded-0 m-2'}>
        <Card.Body className={'p-2'}>
            <Form.Row>
                <Col xs={9}>
                    <Form.Control type={'text'} value={variant} onChange={e => setVariant(e.target.value)} placeholder="Введите текст варианта"/>
                </Col>
                <Col xs={3} className={'d-flex justify-content-end align-items-center justify-content-center'}>
                    <div className={'d-flex flex-row'}>
                        <img className={'button-icon ml-2 as-link'} src={"/images/cancel.svg"}
                             onClick={props.onCancel}/>
                        <img className={'button-icon ml-2 as-link'} src={"/images/save.svg"}
                             onClick={() => {
                                 props.onSuccess(variant)
                             }}/>
                    </div>
                </Col>
            </Form.Row>
        </Card.Body>
    </Card>
}

type AddGroupProps = {
    onCancel: () => void;
    onSuccess: (text: string) => void;
}

function AddGroupForm(props: AddGroupProps) {
    const [group, setGroup] = useState<string>("")
    return <Card>
        <Card.Body className={'p-3'}>
            <Form.Row>
                <Col xs={9}>
                    <Form.Control type={'text'} value={group} onChange={e => setGroup(e.target.value)} placeholder="Введите название группы"/>
                </Col>
                <Col xs={3} className={'d-flex justify-content-end align-items-center justify-content-center'}>
                    <div className={'d-flex flex-row'}>
                        <img className={'button-icon ml-2 as-link'} src={"/images/cancel.svg"}
                             onClick={props.onCancel}/>
                        <img className={'button-icon ml-2 as-link'} src={"/images/save.svg"}
                             onClick={() => {
                                 props.onSuccess(group)
                             }}/>
                    </div>
                </Col>
            </Form.Row>
        </Card.Body>
        <hr className={'hr--no-margin'}/>
        <Card.Body>

        </Card.Body>
    </Card>
}

export default function SortingSubmodule(props: Props) {
    const {userId} = useContext(AuthContext);
    const [error, setError] = useState<Error|undefined>()
    const initialData = props.submodule.course_submodule_type_data as TypeData
    const [variants, setVariants] = useState<string[]>(initialData.variants);
    const [showAddVariantForm, setShowAddVariantForm] = useState<boolean>(false);
    const [showAddGroupForm, setShowAddGroupForm] = useState<boolean>(false);
    const [displayForm, setDisplayForm] = useState<boolean>(false)
    const [draggableVariants, setDraggableVariants] = useState<Item[]>(initialData.variants.map((v, i) => ({
        id: `variant-${i}-${(new Date()).getTime()}`,
        content: v,
        userDefined: false,
    })))
    const [groups, setGroups] = useState<Group[]>(initialData.groups.map(g => ({
        name: g,
        variants: [] as string[],
        userDefined: false,
    })))

    const initDraggableState = () => {
        const res: Item[][] = []
        res.push(draggableVariants)
        groups.forEach((g, gi) => {
            res.push([])
        })
        return res
    }

    const [draggableState, setDraggableState] = useState<Item[][]>(initDraggableState())

    const onSubmit = () => {
        if (props.submodule.completion === undefined) {
            setError(new Error('нет доступных прохождений'))
            return
        }

        if (initialData.user_can_add_groups && groups.length < initialData.min_groups) {
            setError(new Error('минимальное количество групп - ' + initialData.min_groups))
            return
        }

        const subCompl = {
            groups: groups
        };

        ApiFactory.Instance().CourseSubmoduleCompletionAPI().attemptCourseSubmoduleCompletion(
            props.courseId,
            props.courseCompletionId,
            props.submodule.id,
            props.submodule.completion.id,
            subCompl,
        ).then(() => {
            mutate(['user/course-submodule', props.submodule.id, userId])
            setDisplayForm(false)
        })
            .catch(e => {
                setError(e)
            })
    }


    function onDragEnd(result: DropResult) {
        const {source, destination} = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            const items = reorder(draggableState[sInd], source.index, destination.index);
            const newState = [...draggableState];
            newState[sInd] = items;
            setDraggableState(newState);

            // Если перемещаем варианты между собой, то не обращаем на них внимание
            if (sInd > 0) {
                const newGroups = [...groups];
                newGroups[sInd - 1].variants = reorderVariants(newGroups[sInd - 1].variants, source.index, destination.index)
                setGroups(newGroups)
            }
        } else {
            const result = move(draggableState[sInd], draggableState[dInd], source, destination);
            const newState = [...draggableState];
            newState[sInd] = result[sInd];
            newState[dInd] = result[dInd];

            setDraggableState(newState);

            // Если перемещаем вариант из свободных в какую-то группу
            let sVariants: string[] = [];
            const newGroups = [...groups];
            if (sInd == 0) {
                sVariants = variants
            } else {
                sVariants = newGroups[sInd - 1].variants
            }

            const selectedVariant = sVariants[source.index]
            if (sInd > 0) {
                newGroups[sInd - 1].variants = [...sVariants.slice(0, source.index), ...sVariants.slice(source.index + 1)]
            } else {
                setVariants([...sVariants.slice(0, source.index), ...sVariants.slice(source.index + 1)])
            }
            if (dInd > 0) {
                newGroups[dInd - 1].variants = [
                    ...newGroups[dInd - 1].variants.slice(0, source.index),
                    selectedVariant,
                    ...newGroups[dInd - 1].variants.slice(source.index)
                ]
            } else {
                setVariants([...variants.slice(0, source.index), selectedVariant, ...variants.slice(source.index)])
            }
            setGroups(newGroups)
        }
    }

    if (!displayForm) {
        return <PreviousSubmoduleResult submodule={props.submodule}
                                        courseId={props.courseId}
                                        courseCompletionId={props.courseCompletionId}
                                        setDisplayForm={setDisplayForm}
                                        error={error}
                                        setError={setError}/>
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {error && <ErrorHandler error={error}/>}
            <h5 className={'text-primary'}>
                {props.submodule.name}
            </h5>
            <div className={'content-card__text'}>
                <RichContent text={initialData.description}/>
            </div>
            <div className={'d-flex flex-row align-items-center mt-4 mb-4'}>
                <h5 className={'mb-0'}>Варианты</h5>
                {initialData.user_can_add_variants &&
                <img src={'/images/add.svg'} className={'small-icon ml-2'} onClick={() => setShowAddVariantForm(true)}/>}
            </div>

            <Droppable key={"variants-droppable"} droppableId={"0"}>
                {(provided, snapshot) => (
                    <Row
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                    >
                        {draggableState[0].map((item, index) => (
                            <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                            >
                                {(provided, snapshot) => (
                                    <Col xs={12} md={6}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        <Card className={'rounded-0 m-2'}>
                                            {item.userDefined &&
                                            <Card.Body className={'p-2 d-flex flex-row justify-content-between'}>
                                                <span>{item.content}</span>
                                                <img src={'/images/delete.svg'} className={'small-icon ml-2'} onClick={() => {
                                                    const newState = [...draggableState]
                                                    newState[0] = [...newState[0].slice(0, index), ...newState[0].slice(index + 1)]
                                                    setVariants([...variants.slice(0, index), ...variants.slice(index + 1)])
                                                    setDraggableState(newState)
                                                }}/>
                                            </Card.Body>
                                            }
                                            {!item.userDefined &&
                                            <Card.Body className={'p-2'}>
                                                <span>{item.content}</span>
                                            </Card.Body>
                                            }

                                        </Card>
                                    </Col>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        {showAddVariantForm
                            && <Col xs={12} md={6}>
                                <AddVariantForm onCancel={() => setShowAddVariantForm(false)} onSuccess={(text) => {
                                    const newState = [...draggableState];
                                    const variant = text
                                    newState[0].push({
                                        id: `variant-${variants.length}-${(new Date()).getTime()}`,
                                        content: variant,
                                        userDefined: true,
                                    })
                                    setDraggableState(newState);
                                    setVariants([...variants, variant])
                                    setShowAddVariantForm(false)
                                }}/>
                            </Col>
                        }

                    </Row>
                )}
            </Droppable>

            <div className={'d-flex flex-row align-items-center mt-4 mb-4'}>
                <h5 className={'mb-0'}>Группы</h5>
                {initialData.user_can_add_groups &&
                <img src={'/images/add.svg'} className={'small-icon ml-2'} onClick={() => setShowAddGroupForm(true)}/>
                }
            </div>
            <Row>
                {draggableState.slice(1).map((el, ind) => {
                    if (groups[ind] === undefined) {
                        return null
                    }
                    return <Col xs={12} md={6}>
                        <Card className={'mb-4'}>
                            {groups[ind].userDefined &&
                            <Card.Body className={'p-3 d-flex flex-row justify-content-between'}>
                                <span>{groups[ind].name ?? "Неизвестная группа"}</span>
                                <img src={'/images/delete.svg'} className={'small-icon ml-2'} onClick={() => {
                                    setError(undefined);
                                    if (groups[ind].variants.length > 0) {
                                        setError(new Error("Вы не можете удалить группу, пока в ней есть варианты"))
                                        return
                                    }
                                    const newState = [...draggableState.slice(0, ind), ...draggableState.slice(ind + 1)]
                                    setDraggableState(newState)
                                    setGroups([...groups.slice(0, ind), ...groups.slice(ind + 1)])
                                }}/>
                            </Card.Body>
                            }
                            {!groups[ind].userDefined &&
                            <Card.Body className={'p-3'}>
                                <span>{groups[ind].name ?? "Неизвестная группа"}</span>
                            </Card.Body>
                            }

                            <hr className={'hr--no-margin'}/>
                            <Card.Body>
                                <Droppable key={ind} droppableId={`${ind + 1}`}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                            {...provided.droppableProps}
                                        >
                                            {el.map((item, index) => (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={item.id}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            <Card className={'rounded-0 m-2'}>
                                                                <Card.Body className={'p-2'}>
                                                                    {item.content}
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                                {groups[ind].variants.length == 0 &&
                                    <div className={'d-flex flex-fill align-items-center justify-content-center'}>
                                        <small className={'text-muted'}>Перетащите варианты</small>
                                    </div>
                                }

                            </Card.Body>
                        </Card>
                    </Col>
                })}
                {showAddGroupForm && <Col xs={12} md={6}>
                    <AddGroupForm onCancel={() => setShowAddGroupForm(false)} onSuccess={(grp) => {
                        setDraggableState([...draggableState, []]);
                        setGroups([...groups, {name: grp, variants: [], userDefined: true}])
                        setShowAddGroupForm(false)
                    }}/>
                </Col>
                }
            </Row>
            <div className={'d-flex flex-row justify-content-end mt-4'}>
                <Button variant={'primary text-white'} onClick={() => onSubmit()}>Отправить на проверку</Button>
            </div>
        </DragDropContext>
    );
}

