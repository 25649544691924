import * as React from 'react'
import Card from "react-bootstrap/Card";

export enum Level {
    Info,
    Warn
}

type Props = {
    message: string
    level: Level
}

export default function InfoMessage({message, level}: Props) {
    return <Card className={'mt-4 mb-4'}>
        <Card.Body className={'d-flex flex-row justify-content-center align-items-center pt-4 pb-4'}>
            {level == Level.Info && <h6>{message}</h6>}
            {level == Level.Warn && <h6 className={'text-danger'}><img src={'/images/warning.svg'} className={'small-icon'}/>{message}</h6>}

        </Card.Body>
    </Card>
}