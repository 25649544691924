import * as React from 'react';
import {useState} from 'react';
import PageName from "../../components/common/PageName";
import {Redirect, RouteComponentProps} from "@reach/router";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import ApiFactory from "../../api/ApiFactory";
import ErrorHandler from "../../components/ErrorHandler";
import ContentContainer from "../../components/common/ContentContainer";

type Props = {
    token?: string
} & RouteComponentProps

type FormParams = {
    password: string
    passwordConfirm: string
}

export default function ResetPassword(props: Props) {
    const {t} = useTranslation()
    const [resetSucceed, setResetSucceed] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>(undefined);

    const formValidationSchema = yup.object<FormParams>({
        password: yup.string().required().min(6, t("passwordSmallLength")).required("passwordRequired"),
        passwordConfirm: yup.string().required().oneOf([yup.ref('password')], t('passwordConfirmShouldMatch')),
    })

    const {register, handleSubmit, errors} = useForm<FormParams>({
        resolver: yupResolver(formValidationSchema)
    });

    const onSubmit = (params: FormParams) => {
        ApiFactory.Instance().AuthAPI().authHandleResetPassword({
            password: params.password,
            token: props.token!,
        }).then(() => {
            setResetSucceed(true)
        }).catch(e => setError(e))
    }

    if (resetSucceed) {
        return <Redirect noThrow={true} to={"/action/reset-succeed"}/>
    }

    if (props.token === undefined) {
        return <ErrorHandler error={new Error('Не передан токен для восстановления пароля')}/>
    }

    return <ContentContainer>
        <PageName title={'Восстановление пароля'}/>
        <Row>
            <Col xs={12} md={{offset: 3, span: 6}} lg={{offset: 4, span: 4}}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {error && <ErrorHandler error={error}/>}
                    <FormGroup>
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control ref={register} name={'password'} style={{marginTop: '5px'}} type={'password'}/>
                        {errors.password && <Form.Text>{errors.password.message}</Form.Text>}
                    </FormGroup>
                    <FormGroup>
                        <Form.Label>Подтверждение пароля</Form.Label>
                        <Form.Control ref={register} name={'passwordConfirm'} style={{marginTop: '5px'}}
                                      type={'password'}/>
                        {errors.passwordConfirm && <Form.Text>{errors.passwordConfirm.message}</Form.Text>}
                    </FormGroup>
                    <Button type={'submit'} style={{marginTop: '30px'}} block={true} variant={'primary'}
                            className={'text-white'}>Сохранить</Button>
                </Form>
            </Col>
        </Row>
    </ContentContainer>
}