import {UserStatus as ModelUserStatus} from "../../api/generated";
import {UserStatusEnum} from "../common/types";
import * as React from "react";


export default function UserStatus(props: {status: ModelUserStatus}) {
    let className = ""
    switch (props.status.id as UserStatusEnum) {
        case UserStatusEnum.Active:
            className = "text-success"
            break;
        case UserStatusEnum.Blocked:
            className = "text-danger"
            break;
        case UserStatusEnum.Deleted:
            className = "text-danger"
            break;
        case UserStatusEnum.NotConfirmed:
            className = "text-warning"
            break;
    }

    return <h6 className={className}>{props.status.name}</h6>
}