import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../auth/AuthContext";
import useSWR from "swr";
import ApiFactory from "../../api/ApiFactory";
import {Roles} from "../auth/Auth";
import NavDropdown from "react-bootstrap/NavDropdown";
import {useNavigate} from "@reach/router";
import UserPhoto from "../common/UserPhoto";
import Navbar from "react-bootstrap/Navbar";

type User = {
    name: string
    photo?: string
}

export default function UserInfo() {
    const navigate = useNavigate()
    const {userId, logout, roles} = useContext(AuthContext)
    const [user, setUser] = useState<User | undefined>(undefined)
    const {data: apiUser, error} = useSWR(["user/me", userId], () => ApiFactory.Instance().UserAPI().userMe())
    const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false)
    useEffect(() => {
        if (apiUser !== undefined) {
            setUser(apiUser)
            return
        }
        if (error != undefined) {
            setUser({
                name: "Неизвестно"
            })
        }
    }, [error, apiUser])

    if (user === undefined) {
        return null
    }

    const closeItems = (onItemClick?: () => void) => {
        return <>
            {roles.has(Roles.User) && <>
                <NavDropdown.Item onClick={() => {
                    navigate("/")
                    if (onItemClick) {
                        onItemClick()
                    }
                }}>Мои курсы</NavDropdown.Item>
                <NavDropdown.Divider/>
            </>}
            {(!roles.has(Roles.Admin) && roles.has(Roles.CourseAdmin)) && <>
                <NavDropdown.Item className={'font-weight-bold'} disabled={true}>Админ</NavDropdown.Item>
                <NavDropdown.Item onClick={() => {
                    navigate("/admin")
                    if (onItemClick) {
                        onItemClick()
                    }
                }}>Курсы</NavDropdown.Item>
                <NavDropdown.Divider/>
            </>}
            {roles.has(Roles.Admin) && <>
                <NavDropdown.Item className={'font-weight-bold'} disabled={true}>Админ</NavDropdown.Item>
                <NavDropdown.Item onClick={() => {
                    navigate("/admin")
                    if (onItemClick) {
                        onItemClick()
                    }
                }}>Курсы</NavDropdown.Item>
                <NavDropdown.Item onClick={() => {
                    navigate("/admin/user")
                    if (onItemClick) {
                        onItemClick()
                    }
                }}>Пользователи</NavDropdown.Item>
                <NavDropdown.Divider/>
            </>}
            {roles.has(Roles.Teacher) && <>
                <NavDropdown.Item className={'font-weight-bold'} disabled={true}>Преподаватель</NavDropdown.Item>
                <NavDropdown.Item onClick={() => {
                    navigate("/teacher")
                    if (onItemClick) {
                        onItemClick()
                    }
                }}>Курсы</NavDropdown.Item>
                <NavDropdown.Divider/>
            </>}
            <NavDropdown.Item onClick={() => {
                navigate("/user/profile")
                if (onItemClick) {
                    onItemClick()
                }
            }}>Профиль</NavDropdown.Item>
            <NavDropdown.Divider/>
            <NavDropdown.Item onClick={() => {
                logout()
                if (onItemClick) {
                    onItemClick()
                }
            }}>Выход</NavDropdown.Item>
        </>
    }

    return (<>
            <span className={'d-block d-sm-none'} onClick={() => setMobileMenuOpen(true)}>
                <span className={'d-none d-sm-inline mr-1'}>{user.name}</span>
                <UserPhoto user={user} style={{width: '30px', height: '30px'}} className={'rounded-circle'}/>
            </span>
            <div id="mobile-user-menu" className="menu-overlay" style={{"width": mobileMenuOpen ? '100%' : '0%'}}>
                <span onClick={() => setMobileMenuOpen(false)} className="menu-closebtn">&times;</span>
                <div className={'d-flex flex-row justify-content-center align-items-center p-4'}>
                    <img className={'logo'} src="/images/logo.png"/>
                </div>
                <div className="menu-overlay-content">
                    {closeItems(() => setMobileMenuOpen(false))}
                </div>
            </div>
            <NavDropdown className={'d-none d-sm-block'} title={<span>
                            <span className={'d-none d-sm-inline mr-1'}>{user.name}</span>
                            <UserPhoto user={user} style={{width: '30px', height: '30px'}}
                                       className={'rounded-circle'}/>
                        </span>}
                         id="user-dropdown">
                {closeItems()}
            </NavDropdown>
        </>
    )
}