import * as React from 'react';
import {
    QuizQuestion,
    CourseSubmoduleCompletionData, UserQuizAnswer, UserQuizCompletion, UserQuizCompletionQuestion, UserQuizQuestion,
    UserQuizQuestionResult,
    UserShortCourseSubmodule
} from "../../../api/generated";
import RichContent from "../../common/RichContent";
import useSWR from "swr";
import ApiFactory from "../../../api/ApiFactory";
import ErrorHandler from "../../ErrorHandler";
import {Loader} from "../../Loaders";
import {useMemo} from "react";
import {QuestionTypeEnum} from "../../../models/quiz";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

type Props = {
    submoduleId: number
    completionData?: CourseSubmoduleCompletionData
}


type QuestionResultCardProps = {
    question: UserQuizQuestion,
    index: number,
    completion?: UserQuizCompletionQuestion
}

type AnswerProps = {
    ans: UserQuizAnswer
    answered: boolean
}

function AnswerResult(props: AnswerProps) {
    return <div className={'m-3 d-flex flex-row align-items-center'}>
        {props.answered && <img src={'/images/correct.svg'} className={'small-icon'}/>}
        <div className={'d-flex flex-column'}
             style={!props.answered ? {'marginLeft': '25px'} : undefined}>
            <span>{props.ans.answer}</span>
        </div>

    </div>
}

function QuestionResult({question, index, completion}: QuestionResultCardProps) {
    return <div className={'mt-2 mb-2'}>
        <div className={'d-flex flex-row justify-content-start'}>
            <h6 className={'text-primary'}>
                {"Вопрос №" + index}
            </h6>
        </div>
        <div className={'content-card__text'}>
            <RichContent text={question.question}/>
        </div>
        <div className={'content-card__text'}>
            {question.type.id === QuestionTypeEnum.UserDefined &&
                <div className={'m-3 d-flex flex-row align-items-center'}>
                    <div className={'d-flex flex-column'}
                         style={{'marginLeft': '25px'}}>
                        <span>{completion !== undefined ? completion.answer_text : ""}</span>
                    </div>

                </div>
            }
            {question.type.id === QuestionTypeEnum.Single && <>
                {question.answers && question.answers.map(ans => <AnswerResult ans={ans} answered={completion !== undefined ? completion.answer_id == ans.id: false}/>)}
            </>}

            {question.type.id === QuestionTypeEnum.Multiple && <>
                {question.answers && question.answers.map(ans => <AnswerResult ans={ans}
                      answered={
                          !!completion &&
                          !!completion.answer_ids &&
                          completion.answer_ids.find(a => a == ans.id) !== undefined}/>)}
            </>}

            {question.type.id === QuestionTypeEnum.Scale && <>
                <Row>
                    {question.answers && question.answers.map(a => <Col className={'d-flex flex-column justify-content-between align-items-center'}>
                        <div className={'d-flex flex-column align-items-center'}>
                            <span>{a.answer}</span>
                            <Form.Check checked={completion ? a.id == completion.answer_id: false} className={'quiz-scale-checkbox p-0 m-0'} type={"radio"} readOnly={true}/>
                        </div>
                        <div>
                            <small>{a.description ?? ""}</small>
                        </div>
                    </Col>)}
                </Row>
            </>}

        </div>
        {completion && completion.user_comment && <div className={'content-card__text flex-column'}>
            <h6 className={'mb-2'}>Комментарий участника</h6>
            <span>{completion.user_comment}</span>
        </div>}

        <hr/>
    </div>
}

type questionMap = {
    [key: number]: UserQuizCompletionQuestion
}

export default function TestResult(props: Props) {
    const complMap = useMemo(() => {
        if (props.completionData === undefined || props.completionData.quiz === undefined) {
            return {}
        }
        return props.completionData.quiz.questions.reduce<questionMap>(
            (acc, curr) => {acc[curr.question_id] = curr; return acc}, {} as questionMap)
    }, [props.completionData])
    const fetcher = () => ApiFactory.Instance().CourseSubmoduleAPI().userCourseSubmoduleQuiz(props.submoduleId)
    const {data: quiz, error} = useSWR(['/user/portfolio/quiz', props.submoduleId], fetcher)
    if (props.completionData == undefined) {
        return null;
    }
    if (error !== undefined) {
        return <ErrorHandler error={error}/>
    }

    if (quiz === undefined) {
        return <Loader/>
    }
    return <div className={'mt-4 mb-4'}>
        {quiz && quiz.questions.map((q, i) =>
            <QuestionResult question={q} index={i + 1} completion={complMap[q.id]}/>)}
    </div>
}
