import * as React from 'react'
import {ChangeEvent, ReactElement, useContext, useEffect, useState} from 'react'
import useSWR, {mutate} from "swr";
import ApiFactory from "../../api/ApiFactory";
import ErrorHandler from "../ErrorHandler";
import {ListLoader, Loader} from "../Loaders";
import {
    CourseSubmoduleCompletionData,
    UserFullCourseSubmodule,
    UserQuizCompletionQuestion,
    UserQuizQuestion,
    UserQuizQuestionResult
} from "../../api/generated";
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {PRIMARY_COLOR} from "../../config";
import {AuthContext} from "../auth/AuthContext";
import {SubmoduleCompletionStatus} from "../submod/types";
import RichContent from "../common/RichContent";
import {QuestionTypeEnum} from "../../models/quiz";

type Props = {
    submodule: UserFullCourseSubmodule
    courseId: number
    courseCompletionId: number
}

type QuestionCardProps = {
    question: UserQuizQuestion,
    index: number
    onAnswer: (cq: UserQuizCompletionQuestion) => void
}

type AnswerIDs = { [key: string]: boolean }

function QuestionCard({question, index, onAnswer}: QuestionCardProps) {
    const [answerIds, setAnswerIds] = useState<AnswerIDs>({})
    const [answerId, setAnswerId] = useState<number | undefined>(undefined)
    const [answerText, setAnswerText] = useState<string>("")
    const [comment, setComment] = useState<string>("")

    useEffect(() => {
        if (question.is_user_comment_required && comment == "") {
            return
        }
        if ((question.type.id === QuestionTypeEnum.Single || question.type.id === QuestionTypeEnum.Scale) && answerId) {
            onAnswer({
                answer_id: answerId,
                question_id: question.id,
                user_comment: comment,
            })
        } else if (question.type.id === QuestionTypeEnum.Multiple && answerIds) {
            onAnswer({
                answer_ids: Object.keys(answerIds).map(id => Number(id)),
                question_id: question.id,
                user_comment: comment,
            })
        } else if (question.type.id === QuestionTypeEnum.UserDefined && answerText) {
            onAnswer({
                answer_text: answerText,
                question_id: question.id,
                user_comment: comment,
            })
        }

    }, [answerId, answerIds, comment, answerText])

    return <Card className={'content-card__card'}>
        <Card.Body>
            <div className={'d-flex flex-row justify-content-between'}>
                <h6 className={'text-primary'}>
                    {"Вопрос №" + index}
                </h6>
                <div>
                    <span style={{
                        padding: '3px',
                        backgroundColor: PRIMARY_COLOR,
                        borderRadius: '5px',
                        color: '#fff'
                    }}>{question.points ? question.points : 0} балла(ов)</span>
                </div>
            </div>
            <div className={'content-card__text'}>
                <RichContent text={question.question}/>
            </div>
            <div className={'content-card__text'}>
                {question.type.id == QuestionTypeEnum.UserDefined &&
                <Form.Control value={answerText} type={'text'} onChange={e => {
                    setAnswerText(e.target.value)
                }}/>
                }
                {question.type.id == QuestionTypeEnum.Scale && question.answers &&
                    <Row>
                        {question.answers.map(a => <Col className={'d-flex flex-column justify-content-between align-items-center'}>
                            <div className={'d-flex flex-column align-items-center'}>
                                <span>{a.answer}</span>
                                <Form.Check checked={a.id == answerId} className={'quiz-scale-checkbox p-0 m-0'} type={"radio"} onChange={(e: any) => {
                                    if (e.target.checked) {
                                        setAnswerId(a.id)
                                    }
                                }}/>
                            </div>
                            <div>
                                <small>{a.description ?? ""}</small>
                            </div>
                        </Col>)}
                    </Row>
                }
                {(question.type.id == QuestionTypeEnum.Single || question.type.id == QuestionTypeEnum.Multiple)
                    && question.answers && question.answers.map(a => {
                    const questionType = question.type.id as QuestionTypeEnum
                    switch (questionType) {
                        case QuestionTypeEnum.Single:
                            return <div className={'d-flex flex-row m-2'}>
                                <Form.Check type={'radio'} checked={answerId == a.id}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                setAnswerId(a.id)
                                            }}/>
                                <span>{a.answer}</span>
                            </div>
                        case QuestionTypeEnum.Multiple:
                            return <div className={'d-flex flex-row m-2'}>
                                <Form.Check type={'checkbox'} checked={answerIds["" + a.id] !== undefined}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                const newAnswerIds = Object.assign({}, answerIds)
                                                if (newAnswerIds["" + a.id] === undefined) {
                                                    newAnswerIds["" + a.id] = true
                                                } else {
                                                    delete newAnswerIds["" + a.id]
                                                }
                                                setAnswerIds(newAnswerIds)
                                            }}/>
                                <span>{a.answer}</span>
                            </div>
                        case QuestionTypeEnum.UserDefined:
                            return null
                    }
                })}
            </div>
            {question.is_user_comment_required &&
            <div className={'content-card__text'}>
                <Form.Group>
                    <Form.Label>Комментарий</Form.Label>
                    <Form.Control as={"textarea"} rows={3} value={comment} onChange={e => setComment(e.target.value)}/>
                </Form.Group>
            </div>
            }

        </Card.Body>
    </Card>
}

type QuestionResultCardProps = {
    question: UserQuizQuestionResult,
    index: number,
}

function QuestionResult({question, index}: QuestionResultCardProps) {
    return <div className={'mt-2 mb-2'}>
        <div className={'d-flex flex-row justify-content-start'}>
            <h6 className={'text-primary'}>
                {"Вопрос №" + index}
            </h6>
        </div>
        <div className={'content-card__text'}>
            <RichContent text={question.question}/>
        </div>
        <div className={'content-card__text'}>
            {question.type.id === QuestionTypeEnum.Scale
                ?  <Row>
                    {question.answers && question.answers.map(a => <Col className={'d-flex flex-column justify-content-between align-items-center'}>
                        <div className={'d-flex flex-column align-items-center'}>
                            <span>{a.answer}</span>
                            <Form.Check checked={a.answered_correctly !== -1} className={'quiz-scale-checkbox p-0 m-0'} type={"radio"} readOnly={true}/>
                        </div>
                        <div>
                            <small>{a.description ?? ""}</small>
                        </div>
                    </Col>)}
                </Row>
                : <>
                    {question.answers && question.answers.map(ans => <div>
                        <div className={'m-3 d-flex flex-row align-items-center'}>
                            {ans.answered_correctly === 1 && <img src={'/images/correct-green.svg'} className={'small-icon'}/>}
                            {ans.answered_correctly === 0 && <img src={'/images/wrong-red.svg'} className={'small-icon'}/>}
                            <div className={'d-flex flex-column'}
                                 style={ans.answered_correctly === -1 ? {'marginLeft': '25px'} : undefined}>
                                <span>{ans.answer}</span>
                                {/*{ans.description && <small>{ans.description}</small>}*/}
                            </div>

                        </div>
                    </div>)}
                </>
            }
        </div>
        <hr/>
    </div>
}

type QuestionMap = { [key: string]: UserQuizCompletionQuestion }

function Quiz({submodule, courseId, courseCompletionId, onAttemptSucceed}: { submodule: UserFullCourseSubmodule, onAttemptSucceed: () => void } & Props) {
    const [questionMap, setQuestionMap] = useState<QuestionMap>({});
    const [sendError, setSendError] = useState<Error | undefined>(undefined);

    const fetcher = () => ApiFactory.Instance().CourseSubmoduleAPI().userCourseSubmoduleQuiz(submodule.id)
    const {data: quiz, error} = useSWR(['/admin/course-submodule/quiz', submodule.id], fetcher)

    if (quiz === undefined) {
        return <Loader/>
    }

    return <>
        {error && <ErrorHandler error={error}/>}
        {sendError && <ErrorHandler error={sendError}/>}
        <h5 className={'text-primary m-4'}>{submodule.name}</h5>
        {submodule.course_submodule_type_data.description &&  <div className={'mt-4 mb-4'}>
            <RichContent text={submodule.course_submodule_type_data.description}/>
        </div>}
        {quiz.questions.map((q, i) => <QuestionCard key={`quest-${q.id}`} question={q} index={i + 1} onAnswer={(cq) => {
            const map = Object.assign({}, questionMap)
            map["" + cq.question_id] = cq
            setQuestionMap(map)
        }
        }/>)}
        <div className={'d-flex flex-row justify-content-end'}>
            <Button variant={'primary text-white'} disabled={quiz.questions.length != Object.keys(questionMap).length}
                    onClick={() => {
                        if (!submodule.completion) {
                            return
                        }

                        const completionData: CourseSubmoduleCompletionData = {
                            quiz: {
                                questions: Object.keys(questionMap).map(qId => questionMap[qId]),
                            }
                        }
                        setSendError(undefined);
                        ApiFactory.Instance().CourseSubmoduleCompletionAPI().attemptCourseSubmoduleCompletion(
                            courseId, courseCompletionId,
                            submodule.id, submodule.completion.id, completionData)
                            .then(() => {
                                onAttemptSucceed()
                            }).catch(e => setSendError(e))
                    }}>Отправить на проверку</Button>
        </div>
    </>
}

type ResultsProps = {
    courseId: number
    courseCompletionId: number,
    submoduleId: number,
}

function Results(props: ResultsProps) {
    const {courseId, courseCompletionId, submoduleId} = props
    const fetcher = () => ApiFactory.Instance().CourseSubmoduleAPI().userViewSubmoduleResult(courseId, courseCompletionId, submoduleId)
    const {data: complResult, error} = useSWR(['/admin/course-submodule/quiz/results', submoduleId], fetcher)

    if (error !== undefined) {
        return <ErrorHandler error={error}/>
    }

    if (complResult === undefined) {
        return <ListLoader/>
    }

    return <div className={'mt-4 mb-4'}>
        {complResult.quiz && complResult.quiz.questions.map((q, i) => <QuestionResult question={q} index={i + 1}/>)}
    </div>
}

export default function TestSubmodule(props: Props) {
    const {userId} = useContext(AuthContext)
    const {submodule, courseId, courseCompletionId} = props;
    const [error, setError] = useState<Error | undefined>(undefined);
    const [displayQuestions, setDisplayQuestions] = useState<boolean>(false)

    if (displayQuestions) {
        return <Quiz {...props} onAttemptSucceed={() => {
            mutate(['user/course-submodule', submodule.id, userId])
            setDisplayQuestions(false)
        }}/>
    }

    let content: ReactElement | null = null;
    if (!submodule.completion) {
        content = <Button variant={'primary text-white mt-4'} onClick={() => {
            ApiFactory.Instance().CourseSubmoduleCompletionAPI().userCreateCourseSubmoduleCompletion(courseId, courseCompletionId, submodule.id)
                .then(() => {
                    mutate(['user/course-submodule', submodule.id, userId])
                    setDisplayQuestions(true)
                }).catch(e => setError(e))
        }}>Пройти тест</Button>
    } else if (submodule.completion.status.id == SubmoduleCompletionStatus.Finished ||
        submodule.completion.status.id == SubmoduleCompletionStatus.AcceptedAsDraft) {
        content = <>
            <span><img src={'/images/finished.png'} className={'small-icon'}/>Ваш предыдущий результат: {submodule.completion.points} балла(ов)</span>
            {submodule.course_submodule_type_data.show_result &&
            <Results courseId={courseId} courseCompletionId={courseCompletionId} submoduleId={submodule.id}/>}
            <div>
                <Button variant={'primary text-white mt-4'} onClick={() => {
                    ApiFactory.Instance().CourseSubmoduleCompletionAPI().userCreateCourseSubmoduleCompletion(courseId, courseCompletionId, submodule.id)
                        .then(() => {
                            mutate(['user/course-submodule', submodule.id, userId])
                            setDisplayQuestions(true)
                        }).catch(e => setError(e))
                }}>Пройти заново</Button>
            </div>
        </>
    } else if (submodule.completion.status.id == SubmoduleCompletionStatus.InProgress) {
        content = <Button variant={'primary text-white  mt-4'} onClick={() => {
            setDisplayQuestions(true)
        }}>Продолжить</Button>
    } else if (submodule.completion.status.id == SubmoduleCompletionStatus.WaitForReview) {
        content = <span className={'text-danger'}>Ожидает проверки</span>
    }

    return <Card className={'m-4'}>
        <Card.Body>
            {error && <ErrorHandler error={error}/>}
            <h5 className={'text-primary'}>
                {submodule.name}
            </h5>
            {content}
        </Card.Body>
    </Card>

}