import React from 'react';
import {initReactI18next} from "react-i18next";
import i18n from "i18next";
import translations from './i18n/i18n'
import {AuthProvider} from "./components/auth/AuthContext";
import './styles/bootstrap.scss'
import './styles/app.css'
import SWRWrapper from "./components/SWRWrapper";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-datepicker/dist/react-datepicker.css'
import Router from "./components/Router";


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: translations,
        lng: "ru",
        fallbackLng: "ru",

        interpolation: {
            escapeValue: false
        }
    });

function App() {
    return (
        <AuthProvider>
            <SWRWrapper>
                <Router/>
            </SWRWrapper>
        </AuthProvider>
    );
}

export default App;
