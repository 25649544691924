import * as React from 'react';
import {CourseSubmoduleType, TeacherShortCourseSubmodule} from "../../api/generated";
import Row, {RowProps} from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {minutesDurationToString} from "../Formatter";
import {Type} from "../submod/types";
import {AvailabilityIcon, AvailabilityStatusContainer} from "./AvailabilityIcon";
import Badge from 'react-bootstrap/Badge';

type BaseSubmodule = {
    name: string
    type?: CourseSubmoduleType
    duration: number
}

type Props<T> = {
    submodule: T
    onSubmoduleClick?: (sub: T) => void
}

function SubmoduleIcon<T extends { type?: CourseSubmoduleType }>({submodule}: { submodule: T }) {
    let icon: string = '/images/text-submodule.svg'
    switch (submodule.type?.id) {
        case Type.Text:
            icon = '/images/text-submodule.svg'
            break;
        case Type.Video:
            icon = '/images/video-submodule.svg'
            break;
        case Type.Test:
            icon = '/images/test-submodule.svg'
            break;
        case Type.Ranging:
            icon = '/images/range.svg'
            break;
        case Type.Sorting:
            icon = '/images/sort.svg'
            break;
        case Type.FileSend:
            icon = '/images/upload-submodule.svg'
            break;
        case Type.TextSend:
            icon = '/images/upload-submodule.svg'
            break;
        case Type.Discussion:
            icon = '/images/discussion.svg'
            break;
    }
    return <img className={'course-submodule__icon'} src={icon}/>
}

export function TeacherSubmoduleItem({submodule, onSubmoduleClick}: { submodule: TeacherShortCourseSubmodule, onSubmoduleClick: (sub: TeacherShortCourseSubmodule) => void }) {
    return <Row className={'as-link mt-4 mb-4'} onClick={() => {
        if (onSubmoduleClick !== undefined) {
            onSubmoduleClick(submodule)
        }
    }}>
        <Col xs={10}>
            <span><SubmoduleIcon submodule={submodule}/>{submodule.name}</span>
        </Col>
        <Col xs={2}>
            {submodule.wait_for_task_verification_users_count > 0
            && <span className={'d-flex align-items-center justify-content-end'}>
                <Badge variant={'primary'}
                       className={'text-white'}>{submodule.wait_for_task_verification_users_count}</Badge>
            </span>
            }

        </Col>
    </Row>
}


export function UserSubmoduleItem<T extends BaseSubmodule & AvailabilityStatusContainer>({submodule, onSubmoduleClick}: Props<T>) {
    return <Row className={'as-link mt-4 mb-4'} onClick={() => {
        if (onSubmoduleClick !== undefined) {
            onSubmoduleClick(submodule)
        }
    }}>
        <Col xs={10}>
            <span><AvailabilityIcon model={submodule}/><SubmoduleIcon submodule={submodule}/>{submodule.name}</span>
        </Col>
        <Col xs={2}>
                    <span className={'d-flex align-items-center justify-content-end'}><img
                        className={'course-submodule__icon'}
                        src={'/images/clocks.svg'}/>{minutesDurationToString(submodule.duration)}</span>
        </Col>
    </Row>
}

export default function SubmoduleItem<T extends BaseSubmodule>({submodule, onSubmoduleClick, className}: Props<T> & RowProps) {
    return <Row className={className} onClick={() => {
        if (onSubmoduleClick !== undefined) {
            onSubmoduleClick(submodule)
        }
    }}>
        <Col xs={10}>
            <span><SubmoduleIcon submodule={submodule}/>{submodule.name}</span>
        </Col>
        <Col xs={2}>
                    <span className={'d-flex align-items-center justify-content-end'}><img
                        className={'course-submodule__icon'}
                        src={'/images/clocks.svg'}/>{minutesDurationToString(submodule.duration)}</span>
        </Col>
    </Row>
}