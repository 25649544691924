import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {AvailableCourseModule, AvailableFullCourse} from "../../api/generated";
import ApiFactory from "../../api/ApiFactory";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useSWR from 'swr'
import ErrorHandler from "../../components/ErrorHandler";
import Menu, {HorizontalMenu} from "../../components/available/Menu";
import CourseHeader from "../../components/available/CourseHeader";
import CourseContent from "../../components/common/CourseContent";
import {AuthContext} from "../../components/auth/AuthContext";
import {RouteComponentProps, useNavigate} from "@reach/router";
import ModuleContent from "../../components/available/ModuleContent";
import ContentContainer from "../../components/common/ContentContainer";

type Props = {
    id?: string
    moduleId?: string
} & RouteComponentProps

export default function Course(props: Props) {
    const navigate = useNavigate();
    const {id, moduleId} = props;

    const fetcher = () => ApiFactory.Instance().CourseAPI().viewCourse(Number(id));
    const {data: course, error} = useSWR<AvailableFullCourse>(['course', id, useContext(AuthContext).userId], fetcher)
    const loading = course === undefined;
    const [module, setModule] = useState<AvailableCourseModule | undefined>(undefined)

    useEffect(() => {
        if (course !== undefined && moduleId !== undefined) {
            setModule(course.modules.find(m => m.id === Number(moduleId)))
        }
    }, [course, moduleId])


    return (
        <>
            <ContentContainer
                hasNabBottomMenu={true}
                renderNavBottomMenu={() => <HorizontalMenu
                    initialModuleId={moduleId !== undefined ? Number(moduleId) : undefined}
                    course={course}
                    loading={loading}
                    onActiveModuleChanged={(mod) => {
                        if (mod !== null) {
                            navigate(`/course/${course!.id}/module/${mod.id}`)
                        }
                    }}
                />}
            >
                <Row>
                    <Col xs={12} md={3} className={'d-none d-sm-block'}>
                        <Menu course={course}
                              loading={loading}
                              initialModuleId={moduleId !== undefined ? Number(moduleId) : undefined}
                              onActiveModuleChanged={(mod) => {
                                  if (mod !== null) {
                                      navigate(`/course/${course!.id}/module/${mod.id}`)
                                  }
                              }}
                        />
                    </Col>
                    <Col xs={12} md={9}>
                        {error && <ErrorHandler error={error}/>}
                        {moduleId && module
                            ? <ModuleContent module={module}/>
                            : <CourseContent
                                loading={loading}
                                course={course}
                                header={<CourseHeader loading={loading} course={course}/>}
                            />
                        }
                    </Col>
                </Row>
            </ContentContainer>
        </>
    )
}