import * as React from 'react';
import {ReactElement, useEffect, useState} from 'react';
import Card from "react-bootstrap/Card";
import {MenuLoader} from "../Loaders";
import {useNavigate} from "@reach/router";
import ListGroup from "react-bootstrap/ListGroup";

interface BaseCourse<T1 extends BaseModule> {
    modules?: T1[],
}

type BaseModule = {
    id: number
}

type RenderModuleItemFunc<T2> = (mod: T2, isActive: boolean, onClick: (mod: T2) => void) => ReactElement

export type ModuleItemProps<T1> = { mod: T1, isActive: boolean, onClick: (mod: T1) => void };

type Props<T, T1> = {
    initialModuleId?: number
    course?: T,
    loading: boolean,
    onActiveModuleChanged: (mod: T1 | null) => void,
    renderModuleItem: RenderModuleItemFunc<T1>,
    bottom?: ReactElement,
    aboutCourseLink?: string,
    displayAboutCourse?: boolean
    additionalMenuItems?: ReactElement
}

export default function Menu<T extends BaseCourse<T1>, T1 extends BaseModule>(
    {
        course, loading, renderModuleItem, bottom,
        initialModuleId, aboutCourseLink,
        onActiveModuleChanged, displayAboutCourse,
        additionalMenuItems
    }: Props<T, T1>) {

    const navigate = useNavigate();
    const [activeModuleId, setActiveModuleId] = useState<number | undefined>(initialModuleId);

    useEffect(() => {
        setActiveModuleId(initialModuleId)
    }, [initialModuleId])

    if (course === undefined || course.modules === undefined) {
        return null
    }
    if (loading) {
        return <MenuLoader/>
    }

    return (
        <Card>
            <ListGroup className={'as-link'} variant={'flush'}>
                {displayAboutCourse && <ListGroup.Item>
                    <div className={'d-flex flex-row justify-content-between align-items-center card-body'} onClick={() => {
                        if (aboutCourseLink) {
                            navigate(aboutCourseLink)
                        }
                    }}>
                        О курсе
                    </div>
                </ListGroup.Item>}

                {additionalMenuItems}

                <ListGroup.Item>
                    <div>
                        {course && course.modules && course.modules.map(mod =>
                            <>
                                {renderModuleItem(mod, activeModuleId === mod.id, (mod) => {
                                    setActiveModuleId(mod.id)
                                    onActiveModuleChanged(mod);
                                })}
                            </>
                        )}
                        {bottom}
                    </div>
                </ListGroup.Item>
            </ListGroup>
        </Card>
    )
}