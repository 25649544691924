import * as React from 'react';
import {Suspense} from "react";
import {Loader} from "../../Loaders";
import RichContent from "../../common/RichContent";
import Card from "react-bootstrap/Card";
import {UserShortCourseSubmodule} from "../../../api/generated";
import {SubmoduleTypeData, VideoSubmoduleTypeData} from "../../common/types";
const Player = React.lazy(() => import('../../common/lazy/Player'))

type Props = {
    typeData: VideoSubmoduleTypeData
}

export default function VideoResult(props: Props) {
    return <div className={'mt-4 mb-4'}>
        <div className={'content-card__video'}>
            <Suspense fallback={<Loader/>}>
                <Player fluid={true} aspectRatio={"16:9"} controls={true} autoplay={false} sources={[
                    {src: props.typeData.content_url, type: 'video/mp4'}
                ]}/>
            </Suspense>
        </div>

        <div className={'content-card__text'}>
            <RichContent text={props.typeData.description}/>
        </div>
    </div>
}