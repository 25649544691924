import * as React from 'react';
import {TypeProps} from "./TypeControls";
import Form from "react-bootstrap/Form";
import {Suspense, useEffect, useState} from "react";
import {Loader} from "../../Loaders";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import {navigate} from "@reach/router";
import {ShortCourseSubmodule} from "../../../api/generated";
import Card from "react-bootstrap/Card";
import InnerSubmoduleItem from "../../common/SubmoduleItem";
import Col from "react-bootstrap/Col";

const Editor = React.lazy(() => import("../../common/lazy/AdminCKEditor"))

type AddVariantProps = {
    onCancel: () => void;
    onSuccess: (text: string) => void;
}

function AddVariantForm(props: AddVariantProps) {
    const [variant, setVariant] = useState<string>("")
    return <Form.Row>
        <Col xs={10}>
            <Form.Control type={'text'} value={variant} onChange={e => setVariant(e.target.value)} placeholder="Введите текст варианта"/>
        </Col>
        <Col xs={2} className={'d-flex justify-content-end align-items-center'}>
            <div className={'d-flex flex-row'}>
                <img className={'button-icon ml-4 as-link'} src={"/images/cancel.svg"}
                     onClick={props.onCancel}/>
                <img className={'button-icon ml-4 as-link'} src={"/images/save.svg"}
                     onClick={() => {
                         props.onSuccess(variant)
                     }}/>
            </div>
        </Col>

    </Form.Row>
}

export default function RangingTypeControls(props: TypeProps) {
    const [showAddVariant, setShowAddVariant] = useState<boolean>(false);
    const [variants, setVariants] = useState<string[]>(props.submodule.course_submodule_type_data.variants ?? [])
    const [order, setOrder] = useState<number[]>(props.submodule.course_submodule_type_data.variants ?
        props.submodule.course_submodule_type_data.variants.map((v: string, i: number) => i) : [])

    const addVariant = (variant: string) => {
        setVariants([...variants, variant])
    }

    const deleteVariant = (i: number) => {
        setVariants([...variants.slice(0, i), ...variants.slice(i + 1)])
    }

    useEffect(() => {
        props.setValue("course_submodule_type_variants", variants)
    }, [variants])

    const reorder = (list: number[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const reorderVariants = (list: string[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    function onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const reordered = reorder(
            order,
            result.source.index,
            result.destination.index
        );
        setVariants(reorderVariants(variants, result.source.index, result.destination.index))
        setOrder(reordered);
    }

    return <>
        <Form.Group>
            <Form.Label>Описание</Form.Label>
            <Suspense fallback={<Loader/>}>
                <Editor initialContent={props.submodule.course_submodule_type_data.description || ""}
                        onContentChange={text => {
                            props.setValue("course_submodule_type_description", text)
                        }}/>
            </Suspense>
            {props.errors && props.errors.course_submodule_type_description &&
            <Form.Text
                className={'text-danger'}>{props.errors.course_submodule_type_description.message}</Form.Text>}
        </Form.Group>
        <Form.Group>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={`submodule-range-${props.submodule.id}`}>
                    {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {variants.map((v, i) => {
                                return <Draggable draggableId={props.submodule.id + "-" + i} index={i}>
                                    {provided => (
                                        <Card className={'mt-2 mb-2'} ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}>
                                            <Card.Body className={'p-3 d-flex justify-content-between'}>
                                                <span>{v}</span>
                                                <img src={'/images/delete.svg'} className={'small-icon m-2 as-link'} onClick={() => deleteVariant(i)}/>
                                            </Card.Body>
                                        </Card>
                                    )}
                                </Draggable>
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {showAddVariant
                ? <AddVariantForm onCancel={() => setShowAddVariant(false)} onSuccess={(v) => {
                    addVariant(v)
                    setShowAddVariant(false)
                }}/>
                : <div className={'d-flex flex-row mt-4 justify-content-center as-link'}
                       onClick={() => setShowAddVariant(true)}>
                    <span>Добавить вариант  <img src={"/images/add.svg"} className={'small-icon m-2'}/></span>
                </div>
            }
        </Form.Group>

    </>
}