import * as React from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import useSWR, {mutate} from "swr";
import ApiFactory from "../../../api/ApiFactory";
import ErrorHandler from "../../../components/ErrorHandler";
import {Loader} from "../../../components/Loaders";
import UserEdit from "../../../components/admin/UserEdit";
import {navigate, RouteComponentProps} from "@reach/router";
import {useState} from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import UserStatus from "../../../components/admin/UserStatus";
import {AdminUser} from "../../../api/generated";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContentContainer from "../../../components/common/ContentContainer";
import Badge from 'react-bootstrap/Badge';
import Breadcrumb from "react-bootstrap/Breadcrumb";
import BreadcrumbItem from "react-bootstrap/BreadcrumbItem";
import {Link} from "@reach/router";

type Props = {
    id?: string
} & RouteComponentProps

enum Mode {
    View,
    Edit
}

function Content({user}: {user: AdminUser}) {
    const [mode, setMode] = useState<Mode>(Mode.View)

    return mode === Mode.Edit
        ? <UserEdit user={user}
                    onCancel={() => setMode(Mode.View)}
                    onFinish={() => setMode(Mode.View)}
        />
        : <Card className={'content-card__card m-2'}>
            <Card.Body>
                <div className={'mt-2 mb-2 d-flex flex-row justify-content-end align-items-center'}>
                    <img className={'button-icon as-link m-2'} src={"/images/edit.svg"}
                         onClick={() => setMode(Mode.Edit)}/>
                </div>
                <Table responsive={true}>
                    <tbody>
                    <tr>
                        <td className={'font-weight-bold'}>Логин</td>
                        <td>{user.username}</td>
                    </tr>
                    <tr>
                        <td className={'font-weight-bold'}>ФИО пользователя</td>
                        <td>{user.name}</td>
                    </tr>
                    <tr>
                        <td className={'font-weight-bold'}>Статус</td>
                        <td><UserStatus status={user.status}/></td>
                    </tr>
                    <tr>
                        <td className={'font-weight-bold'}>Информация о пользователе</td>
                        <td>{user.about}</td>
                    </tr>
                    <tr>
                        <td className={'font-weight-bold'}>Роли</td>
                        <td>{user.roles.map(r => <Badge key={`role-${r}`} variant={'primary'}
                                                        className={'text-white m-1'}>{r}</Badge>)}</td>
                    </tr>
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
}

export default function View(props: Props) {
    const id = Number(props.id)
    const {data: user, error} = useSWR(['admin/user', id], () => ApiFactory.Instance().UserAPI().adminViewUser(id))

    return <ContentContainer>
        <Breadcrumb>
            <BreadcrumbItem><Link to={"/admin/user"}>К списку пользователей</Link></BreadcrumbItem>
        </Breadcrumb>
        <Row>
            <Col xs={12}>
                {error && <ErrorHandler error={error}/>}
                {user === undefined && error === undefined && <Loader/>}
                {user !== undefined &&
                <Content user={user}/>
                }
            </Col>
        </Row>
    </ContentContainer>
}