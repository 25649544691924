import * as React from 'react';
import ApiFactory, {MAX_PER_PAGE} from "../../api/ApiFactory";
import useSWR from "swr";
import {ShortUserWithPhotoListResponse} from "../../api/generated";
import ErrorHandler from "../ErrorHandler";
import {Loader} from "../Loaders";
import Card from "react-bootstrap/Card";
import User from "../common/User";
import {Link} from '@reach/router';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

type Props = {
    courseId: number;
}

export default function CourseParticipants(props: Props) {
    const fetcher = () => ApiFactory.Instance().CourseAPI().userViewCourseUsers(props.courseId, 0, MAX_PER_PAGE)
    const {data: users, error} = useSWR<ShortUserWithPhotoListResponse>(['course/users', props.courseId], fetcher);

    if (error !== undefined) {
        return <ErrorHandler error={error}/>
    }

    if (users === undefined) {
        return <Loader/>
    }

    return <Card className={'course__card mt-4'}>
        <Card.Body>
            <h5 className={'text-primary'}>Участники</h5>
            <Row>
                {users.items.map(u => <Col xs={12} md={6}>
                        <Link to={`/user/portfolio/${props.courseId}/${u.id}`}>
                            <User key={`participant-${u.id}`} user={u} showAbout={false}/>
                        </Link>
                    </Col>
                )}
            </Row>
        </Card.Body>
    </Card>
}