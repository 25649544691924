import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {AdminFullCourseSubmodule} from "../../../api/generated";
import {Loader} from "../../Loaders";
import ApiFactory from "../../../api/ApiFactory";
import {AuthContext} from "../../auth/AuthContext";
import ErrorHandler from "../../ErrorHandler";
import SubmoduleEdit from "./SubmoduleEdit";
import useSWR, {mutate} from "swr";
import {navigate, RouteComponentProps} from "@reach/router";

type Props = {
    id?: string,
    courseId: number,
    sectionId: number,
    moduleId: number,
} & RouteComponentProps;

export default function SubmoduleAdmin(props: Props) {
    const userId = useContext(AuthContext).userId
    const fetcher = () => ApiFactory.Instance().CourseSubmoduleAPI().adminViewCourseSubModule(Number(props.id!));
    const key = ['admin/course-submodule', Number(props.id), userId]
    const {data: submodule, error} = useSWR<AdminFullCourseSubmodule>(key, fetcher)

    if (error !== undefined) {
        return <ErrorHandler error={error}/>;
    }

    if (submodule === undefined) {
        return <Loader/>
    }

    return <SubmoduleEdit moduleId={props.moduleId} courseId={props.courseId} submodule={submodule!} onChange={() => {
        mutate(key)
        mutate(['admin/course-section', props.sectionId, userId])
    }} onDelete={() => {
        mutate(['admin/course-section', Number(props.sectionId), userId])
        navigate(`/admin/course/${props.courseId}/module/${props.moduleId}/section/${props.sectionId}`)
    }}/>
}