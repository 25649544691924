import * as React from 'react';
import {useContext, useEffect, useState, Suspense, useMemo} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {RouteComponentProps, useNavigate} from "@reach/router";
import SubmoduleContent from "../../../components/submod/SubmoduleContent";
import ApiFactory, {DEFAULT_PER_PAGE} from "../../../api/ApiFactory";
import useSWR, {mutate} from "swr";
import {
    CommentCreateParams,
    FullUserDiscussion,
    ShortDiscussionTopic, TopicCreateParams,
    UserFullCourseSubmodule
} from "../../../api/generated";
import {AuthContext} from "../../../components/auth/AuthContext";
import ContentContainer from "../../../components/common/ContentContainer";
import HorizontalScroll, {Item} from "../../../components/common/HorizontalScroll";
import {LineLoader, ListLoader, Loader} from "../../../components/Loaders";
import ErrorHandler, {AlertSize} from "../../../components/ErrorHandler";
import Menu from "../../../components/user/Menu";
import Pagination from "../../../components/common/Pagination";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import UserPhoto from "../../../components/common/UserPhoto";
import {DATETIME_FORMAT} from "../../../config";
import moment from 'moment';
import Form from 'react-bootstrap/Form'
import Button from "react-bootstrap/Button";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";

const Editor = React.lazy(() => import("../../../components/common/lazy/CKEditor"));
type Props = {
    id?: string
    search?: string
} & RouteComponentProps


type BreadcrumbsProps = {
    discussion?: FullUserDiscussion,
    error?: Error
}

function Breadcrumbs(props: BreadcrumbsProps) {
    const navigate = useNavigate()
    const {discussion, error} = props

    const items = useMemo<Item[]>(() => {
        if (!props.discussion) {
            return []
        }
        const discussion = props.discussion;
        const res: Item[] = [];
        res.push({title: 'Список курсов /', active: false, onClick: () => navigate("/")});
        res.push({
            title: discussion.breadcrumbs.course.name + " /",
            active: false,
            onClick: () => navigate(`/user/course/${discussion.breadcrumbs.course.id}`)
        })
        if (discussion.breadcrumbs.module && discussion.breadcrumbs.module.id) {
            res.push({
                title: discussion!.breadcrumbs.module.name + " /",
                active: false,
                onClick: () => navigate(`/user/course/${discussion.breadcrumbs.course.id}/module/${discussion.breadcrumbs.module!.id}`)
            })
        }
        res.push({title: discussion.name, active: true})
        return res;
    }, [props.discussion])

    if (error !== undefined) {
        return null
    }
    if (discussion === undefined) {
        return <LineLoader/>
    }

    return <div className={'mt-4'}>
        <HorizontalScroll alignCenter={false} items={items}/>
    </div>
}

type TopicSearchProps = {
    initial?: string,
    onSearch: (q: string | undefined) => void,
    onCreateTopic: () => void,
}

function TopicSearch(props: TopicSearchProps) {
    const [query, setQuery] = useState<string | undefined>(props.initial)
    return <Form onSubmit={(e) => {
        props.onSearch(query)
        e.preventDefault()
    }}>
        <div className={'d-flex flex-row'}>
            <Form.Control value={query} onChange={e => setQuery(e.target.value)}/>
            <Button type={'submit'} variant={'primary'} className={'ml-2 ml-sm-4'}>
                <span className={'d-none d-sm-block text-white'}>Поиск</span>
                <img className={'d-block d-sm-none in-button-icon'} src={'/images/search-white.svg'}/>
            </Button>
            <Button variant={'success'} className={'ml-2 ml-sm-4 discussion-new-topic-button'}
                    onClick={props.onCreateTopic}>
                <span className={'d-none d-sm-block text-white'}>Новая тема</span>
                <img className={'d-block d-sm-none in-button-icon'} src={'/images/add-white.svg'}/>
            </Button>
        </div>
    </Form>


}

type TopicItemProps = {
    topic: ShortDiscussionTopic
    discussionId: number
}

function TopicItem(props: TopicItemProps) {
    const navigate = useNavigate()
    const {topic} = props
    return <Card className={'mt-4 mb-4'}>
        <Card.Body
            className={'as-link'} onClick={() => {
            navigate(`/user/discussion/${props.discussionId}/topic/${topic.id}`)
        }}>
            <Row>
                <Col xs={12} md={8} className={'d-flex flex-row mt-2'}>
                    {topic.pinned && <img src={"/images/pinned.svg"} className={'small-icon'}/>}
                    <div>
                        <h6 className={'mb-2'}>{topic.name}</h6>
                        {topic.is_private && <small>
                            <img src={'/images/private.svg'} className={'small-icon'}/>
                            Личная тема с преподавателем
                        </small>}
                    </div>
                </Col>
                <Col xs={12} md={4} className={'d-flex flex-row topic-counters mt-2'}>
                    <div className={'d-flex flex-column align-items-center pl-2 pr-2'}>
                        <span>{topic.view_count}</span>
                        <span className={'x-small-text'}>просмотров</span>
                    </div>
                    <div className={'d-flex flex-column align-items-center pl-2 pr-2'}>
                        <span>{topic.comment_count}</span>
                        <span className={'x-small-text'}>комментариев</span>
                    </div>
                </Col>
            </Row>
        </Card.Body>
        <Card.Body className={'d-flex flex-row justify-content-between'}>
            <div>
                <UserPhoto user={topic.created_by} className={'small-user-photo'}/>
                <span className={'small ml-2'}>{topic.created_by.name}</span>
            </div>
            <div>
                {topic.last_commented_by && <div>
                    <span className={'x-small-text mr-2'}>Последний комментарий</span>
                    <span
                        className={'x-small-text ml-2'}>{moment(topic.last_commented_at!).format(DATETIME_FORMAT)}</span>
                </div>}
            </div>
        </Card.Body>
    </Card>
}

type TopicListProps = {
    discussionId: number,
    search?: string,
}

function TopicList(props: TopicListProps) {
    const {userId} = useContext(AuthContext)
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [showNewTopic, setShowNewTopic] = useState<boolean>(false);
    const [search, setSearch] = useState<string | undefined>(props.search)
    const fetcher = (key: any, id: number, userId: any, currentPage: number, search: string | undefined) =>
        ApiFactory.Instance().DiscussionTopicAPI().userDiscussionTopics(id, search, currentPage, DEFAULT_PER_PAGE)
    const key = ['user/discussion-topics', props.discussionId, userId, currentPage, search]
    const {data: topics, error} = useSWR(key, fetcher)

    return <>
        <TopicSearch initial={search} onSearch={s => setSearch(s)} onCreateTopic={() => setShowNewTopic(true)}/>
        {showNewTopic && <CreateTopic
            discussionId={props.discussionId}
            onFinish={() => {
                setShowNewTopic(false)
                mutate(key)
            }}
            onCancel={() => {
                setShowNewTopic(false)
            }}
        />}
        {error && <ErrorHandler error={error}/>}
        {topics === undefined && error === undefined && <ListLoader/>}
        {topics !== undefined &&
        <>
            {topics.items.map(topic => <TopicItem key={`topic-${topic.id}`} topic={topic}
                                                  discussionId={props.discussionId}/>)}
            <Pagination totalItems={topics.total} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </>
        }
    </>
}

type CreateTopicProps = {
    discussionId: number,
    onCancel: () => void,
    onFinish: () => void,
}

function CreateTopic(props: CreateTopicProps) {
    const formValidationSchema = yup.object<TopicCreateParams>({
        text: yup.string().required("Текст темы не может быть пустым"),
        name: yup.string().required("Название темы не может быть пустым")
    })

    const {register, handleSubmit, errors, setValue} = useForm<TopicCreateParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            name: "",
            text: "",
        }
    });

    useEffect(() => {
        register("text")
    }, [])

    const [error, setError] = useState<Error | undefined>(undefined);
    const onSubmit = (params: TopicCreateParams) => {
        setError(undefined);
        ApiFactory.Instance().DiscussionTopicAPI().userCreateDiscussionTopic(props.discussionId, params)
            .then(val => {
                props.onFinish()
            })
            .catch(e => setError(e))
    };


    return <Card className={'mt-4 mb-4'}>
        <Card.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
                {error && <ErrorHandler size={AlertSize.Small} error={error}/>}
                <Form.Group>
                    <Form.Label>Название темы</Form.Label>
                    <Form.Control name={"name"} ref={register}/>
                    {errors.name && <Form.Text>{(errors.name as any)?.message}</Form.Text>}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Текст</Form.Label>
                    <Suspense fallback={<Loader/>}>
                        <Editor initialContent={""} onContentChange={text => setValue("text", text)}/>
                    </Suspense>

                    {errors.text && <Form.Text>{(errors.text as any)?.message}</Form.Text>}
                </Form.Group>
                <div className={'d-flex flex-row justify-content-end'}>
                    <Button type={'submit'} variant={'primary'}
                            className={'text-white'}>Сохранить</Button>
                    {props.onCancel &&
                    <Button className={'ml-2'} variant={'light'} onClick={() => {
                        setValue("text", "")
                        if (props.onCancel) {
                            props.onCancel()
                        }
                    }}>Отменить</Button>
                    }

                </div>
            </Form>
        </Card.Body>
    </Card>
}

export default function Discussion(props: Props) {
    const id = Number(props.id)
    const {userId} = useContext(AuthContext)
    const navigate = useNavigate()
    const fetcher = () => ApiFactory.Instance().DiscussionAPI().userModuleDiscussion(id);
    const {data: discussion, error: breadcrumbsError} = useSWR(['user/discussion', id, userId], fetcher)

    if (Number.isNaN(id)) {
        return <ErrorHandler error={new Error("id is not a number")}/>
    }
    return (
        <ContentContainer>

            <Breadcrumbs error={breadcrumbsError} discussion={discussion}/>

            <hr className={'hr--divider'}/>
            <Row>
                <Col xs={12}>
                    <TopicList discussionId={id}/>
                </Col>
            </Row>
        </ContentContainer>
    )
}