import * as React from "react";
import {useEffect, useState} from "react";
import ApiFactory, {MAX_PER_PAGE} from "../../api/ApiFactory";
import useSWR, {mutate} from "swr";
import ErrorHandler from "../ErrorHandler";
import {ListLoader} from "../Loaders";
import DiscussionCreate from "./DiscussionCreate";
import {useNavigate} from "@reach/router";
import DiscussionEdit from "./DiscussionEdit";
import Card from "react-bootstrap/Card";
import {AdminFullCourseModule, DiscussionType, ShortDiscussion} from "../../api/generated";
import {Mode} from "./ModuleAdmin";
import {DiscussionTypeEnum} from "../../models/discussion";

type DiscussionItemProps = {
    externalId: number,
    type: DiscussionTypeEnum,
    discussion: ShortDiscussion,
}

function DiscussionItem({discussion, type, externalId}: DiscussionItemProps) {
    const navigate = useNavigate()
    const [mode, setMode] = useState<Mode>(Mode.View)

    if (mode === Mode.Edit) {
        return <DiscussionEdit
            externalId={externalId}
            type={type}
            discussion={discussion}
            onCancel={() => setMode(Mode.View)}
            onSucceed={() => setMode(Mode.View)}
        />
    }

    return <Card key={`discussion-${discussion.id}`} className={'m-4 as-link'} onClick={() => {
        navigate(`/admin/discussion/${discussion.id}`)
    }}>
        <Card.Body className={'p-4 d-flex flex-row justify-content-between align-items-center'}>
            <div>
                <h6>{discussion.name}</h6>
                {discussion.is_private &&
                <small><img src={'/images/private.svg'} className={'small-icon'}/>Личное обсуждение</small>}
            </div>
            <div className={'d-flex flex-row justify-content-end align-items-center'}>
                <img onClick={(e) => {
                    e.stopPropagation()
                    if (window.confirm(`Вы действительно хотите удалить обсуждение ${discussion.name}`)) {
                        ApiFactory.Instance().DiscussionAPI().adminDeleteDiscussion(discussion.id)
                            .then(() => {
                                mutate(['admin/discussions', externalId, type, 0])
                            })
                    }
                }} src={"/images/delete.svg"} className={'button-icon as-link'}/>
                <img className={'button-icon as-link m-2'} src={"/images/edit.svg"}
                     onClick={(e) => {
                         e.stopPropagation()
                         setMode(Mode.Edit)
                     }}/>
            </div>
        </Card.Body>
    </Card>
}

type DiscussionsInfoProps = {
    externalId: number,
    discussionType: DiscussionTypeEnum,
    onLoad: (total: number) => void,
}

export default function DiscussionsList({externalId, discussionType: type, onLoad}: DiscussionsInfoProps) {
    const [showAddDiscussion, setShowAddDiscussion] = useState<boolean>(false);
    let fetcher;
    if (type === DiscussionTypeEnum.Module) {
        fetcher = () => ApiFactory.Instance().DiscussionAPI().adminModuleDiscussions(externalId, 0, MAX_PER_PAGE)
    } else {
        fetcher = () => ApiFactory.Instance().DiscussionAPI().adminCourseDiscussions(externalId, 0, MAX_PER_PAGE)
    }

    const {data: discussions, error} = useSWR(['admin/discussions', externalId, type, 0], fetcher)
    useEffect(() => {
        if (discussions !== undefined) {
            onLoad(discussions.total)
        }
    }, [discussions])

    if (error != undefined) {
        return <ErrorHandler error={error}/>
    }

    if (discussions === undefined) {
        return <ListLoader/>
    }

    return <>
        {discussions.items.map(disc => <DiscussionItem key={`discussion-${disc.id}`} discussion={disc} type={type}
                                                       externalId={externalId}/>)}
        {showAddDiscussion
            ? <DiscussionCreate externalId={externalId} type={type} onSucceed={() => setShowAddDiscussion(false)}
                                onCancel={() => setShowAddDiscussion(false)}/>
            : <div className={'d-flex flex-row align-items-center justify-content-center p-2 as-link'}
                   onClick={() => setShowAddDiscussion(true)}>
                <h5 className={'m-0'}>Добавить обсуждение</h5>
                <img src={"/images/add.svg"} className={'small-icon as-link m-2'}/>
            </div>
        }
    </>
}