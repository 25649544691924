import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import useSWR from "swr";
import {TeacherFullCourseModule, TeacherFullCourseSection} from "../../api/generated";
import Badge from 'react-bootstrap/Badge';
import {Type} from "../submod/types";
import {AuthContext} from "../auth/AuthContext";
import ApiFactory from "../../api/ApiFactory";
import ErrorHandler from "../ErrorHandler";
import {MenuLoader} from "../Loaders";
import {TypeIcon} from "../submod/TypeIcon";

type Props = {
    courseId: number;
    moduleId: number;
    onSubmoduleClick: (activeSubmoduleId: number) => void,
    activeSubmoduleId: number,
}

export default function Menu({moduleId, courseId, onSubmoduleClick, activeSubmoduleId}: Props) {
    const {userId} = useContext(AuthContext)
    const [activeSectionId, setActiveSectionId] = useState<number | undefined>(undefined);
    const fetcher = () => ApiFactory.Instance().CourseModuleAPI().teacherViewCourseModule(moduleId, courseId)
    const {data: module, error} = useSWR<TeacherFullCourseModule>(['teacher/course-module', moduleId, userId], fetcher)
    useEffect(() => {
        if (activeSubmoduleId && module && module.sections) {
            const actSection = module.sections.find(sec => {
                return sec.submodules?.some(sm => sm.id === activeSubmoduleId)
            })

            if (actSection !== undefined) {
                setActiveSectionId(actSection.id)
            }
        }
    }, [module, activeSubmoduleId])

    const loading = module === undefined;

    if (error) {
        return <ErrorHandler error={error}/>
    }

    const Submodules = ({section}: { section: TeacherFullCourseSection }) => {
        return <div>
            {section.submodules && section.submodules.map(sub => {
                let submoduleClassName = '';
                if (sub.id === activeSubmoduleId) {
                    submoduleClassName += " text-primary"
                }
                return <div
                    onClick={() => {
                        onSubmoduleClick(sub.id);
                    }}
                    className={'course-menu__submodule d-flex align-items-center justify-content-between'}
                    key={`course-menu__submodule-${sub.id}`}>
                    <div className={'d-flex flex-row align-items-center'}>
                        <TypeIcon type={sub.type?.id}/>
                        <span className={`course-menu__submodule--text ${submoduleClassName}`}>{sub.name}</span>
                    </div>

                    <span>
                        {sub.wait_for_task_verification_users_count > 0
                        && <Badge variant={'primary'}
                                  className={'text-white'}>{sub.wait_for_task_verification_users_count}</Badge>}
                    </span>
                </div>
            })}
        </div>
    }

    return loading
        ? <MenuLoader/>
        : <Accordion activeKey={activeSectionId === undefined
            ? undefined
            : "" + activeSectionId
        } onSelect={(id) => setActiveSectionId(Number(id))}>
            {module && module.sections && module.sections.map(sec => <Card key={`course-menu__sec-${sec.id}`}>
                    <Card.Body className={'course-menu__module d-flex flex-row justify-content-between align-items-center'}
                               onClick={() => setActiveSectionId(sec.id)}>
                        <span><Accordion.Toggle as={'a'} eventKey={"" + sec.id}>
                            {sec.name}
                        </Accordion.Toggle></span>
                        <span>
                            {sec.wait_for_task_verification_users_count
                            && <Badge variant={'primary'}
                                      className={'text-white'}>{sec.wait_for_task_verification_users_count}</Badge>}
                        </span>
                    </Card.Body>
                    <Accordion.Collapse eventKey={"" + sec.id}>
                        <Submodules section={sec}/>
                    </Accordion.Collapse>
                </Card>
            )}

        </Accordion>
}