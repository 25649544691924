import {ButtonProps} from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import * as React from "react";

type Props = {
    loading: boolean
    spinnerColor?: string
} & ButtonProps

export default function LoadingButton(props: Props) {
    return <Button  {...props}>
        {props.children}
    </Button>
}