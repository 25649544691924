import jwt_decode from "jwt-decode";

const ACCESS_TOKEN_KEY = "accessToken";
const REFRESH_TOKEN_KEY = "refreshToken";

export interface User {
    id: string
}

export enum Roles {
    Guest = "guest",
    User = "user",
    NotConfirmedUser = "notConfirmedUser",
    Teacher = "teacher",
    Admin = "admin",
    CourseAdmin = "courseAdmin",
}

export default class Auth {
    private static _instance: Auth;

    static Instance() {
        if (!this._instance) {
            this._instance = new Auth()
        }
        return this._instance;
    }

    setTokens(accessToken: string, refreshToken: string) {
        localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    }

    clearTokens() {
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        localStorage.removeItem(ACCESS_TOKEN_KEY);
    }

    roles(): Set<string> {
        const token = this.getAccessToken()
        if (token === undefined) {
            const res = new Set<string>()
            res.add(Roles.Guest)
            return res
        }

        const decoded: { roles: string[] } = jwt_decode(token)
        return decoded.roles.reduce((acc, val) => acc.add(val), new Set<string>())
    }

    getAccessToken() {
        return localStorage.getItem(ACCESS_TOKEN_KEY) || undefined
    }

    getRefreshToken() {
        const rt = localStorage.getItem(REFRESH_TOKEN_KEY) || undefined;
        return rt
    }

    isLoggedIn() {
        return localStorage.getItem(ACCESS_TOKEN_KEY) !== null
    }

    getUserName() {

    }

    getUserID() {
        const token = this.getAccessToken()
        if (token === undefined) {
            return undefined
        }
        const decoded: { iss: string } = jwt_decode(token)
        return Number(decoded.iss);
    }
}