import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import Card from "react-bootstrap/Card";
import {LineLoader, MenuLoader} from "../../Loaders";
import {Type} from "../types";
import {AdminFullCourseModule, FullCourseSection, ShortCourseSubmodule} from "../../../api/generated";
import ListGroup from "react-bootstrap/ListGroup";
import ApiFactory from "../../../api/ApiFactory";
import useSWR from "swr";
import {AuthContext} from "../../auth/AuthContext";
import ErrorHandler from "../../ErrorHandler";
import {TypeIcon} from "../TypeIcon";

type Props = {
    section?: FullCourseSection;
    initialActiveSubmoduleId?: number;
    onSubmoduleClick: (sub: ShortCourseSubmodule) => void;
    onSectionAboutClick: () => void;
    onSubmoduleAdd: () => void,
}

function AddSubmodule({onClick}: { onClick: () => void }) {
    return <Card.Body onClick={onClick}
                      className={'d-flex flex-row justify-content-center align-items-center as-link'}>
        <div className={'d-flex flex-row'}>
            <small>Добавить подмодуль</small>
            <img src={'/images/add.svg'} className={'small-icon ml-2'}/>
        </div>

    </Card.Body>
}

type HorizontalProps = {
    courseId: number;
    moduleId: number;
    sectionId?: number;
    onSubmoduleClick: (activeSubmoduleId: number) => void,
    activeSubmoduleId?: number,
    onAddSubmodule: () => void,
}

type SubmoduleItemProps = {
    sub: ShortCourseSubmodule,
    scrollRef: React.MutableRefObject<HTMLDivElement | null>
    isActive: boolean,
    onClick: () => void,
}

type AddSubmoduleProps = {
    scrollRef: React.MutableRefObject<HTMLDivElement | null>
    isActive: boolean,
    onClick: () => void,
}

function HorizontalAddSubmoduleItem(props: AddSubmoduleProps) {
    const itemRef = useRef<HTMLDivElement | null>(null)
    const {isActive, onClick, scrollRef} = props

    useEffect(() => {
        if (isActive) {
            if (scrollRef.current && itemRef.current) {
                const currentElementOffset = itemRef.current.offsetLeft
                const currentElementWidth = itemRef.current.clientWidth
                const windowWidth = window.innerWidth
                const offset = currentElementOffset - windowWidth / 2 + currentElementWidth / 2
                scrollRef.current.scrollTo({
                    behavior: "smooth",
                    top: 0,
                    left: offset,
                })
            }
        }
    }, [isActive])

    return <div ref={itemRef}
                onClick={onClick}
                className={'scroll-menu-item'}
                key={`add-submodule`}>
        <span className={'course-menu__submodule--text ' + (isActive ? 'text-primary' : '')}>Добавить подмодуль</span>
        <img src={'/images/add.svg'} className={'small-icon ml-2'}/>
    </div>
}

function HorizontalSubmoduleItem(props: SubmoduleItemProps) {
    const itemRef = useRef<HTMLDivElement | null>(null)
    const {sub, isActive, scrollRef, onClick} = props

    useEffect(() => {
        if (isActive) {
            if (scrollRef.current && itemRef.current) {
                const currentElementOffset = itemRef.current.offsetLeft
                const currentElementWidth = itemRef.current.clientWidth
                const windowWidth = window.innerWidth
                const offset = currentElementOffset - windowWidth / 2 + currentElementWidth / 2
                scrollRef.current.scrollTo({
                    behavior: "smooth",
                    top: 0,
                    left: offset,
                })
            }
        }
    }, [isActive])
    return <div
        ref={itemRef}
        onClick={onClick}
        className={'scroll-menu-item'}
        key={`course-menu__submodule-${sub.id}`}>
        <TypeIcon type={sub.type?.id}/>
        <span className={'course-menu__submodule--text ' + (isActive ? 'text-primary' : '')}>{sub.name}</span>
    </div>
}

export function HorizontalMenu(props: HorizontalProps) {
    const scrollRef = useRef<HTMLDivElement | null>(null)
    const {moduleId, courseId, sectionId, activeSubmoduleId, onSubmoduleClick, onAddSubmodule} = props
    const fetcher = () => ApiFactory.Instance().CourseModuleAPI().adminViewCourseModule(moduleId, courseId)
    const {data: module, error} = useSWR<AdminFullCourseModule>(['admin/course-module', moduleId, useContext(AuthContext).userId], fetcher)

    const loading = module === undefined;

    if (error) {
        return <ErrorHandler error={error}/>
    }

    const Submodules = ({section}: { section: FullCourseSection }) => {
        return <>
            {section.submodules && section.submodules.map(sub => <HorizontalSubmoduleItem sub={sub}
                                                                                          scrollRef={scrollRef}
                                                                                          isActive={sub.id === activeSubmoduleId}
                                                                                          onClick={() => onSubmoduleClick(sub.id)}/>)}
        </>
    }

    const activeSection = module?.sections.find(s => s.id == sectionId)

    if (loading) {
        return <LineLoader/>
    }

    if (activeSection === undefined) {
        return null
    }

    return <div ref={scrollRef} className={'scroll-menu d-inline-block d-sm-none'}>
        <Submodules section={activeSection}/>
        <HorizontalAddSubmoduleItem scrollRef={scrollRef} isActive={activeSubmoduleId === undefined}
                                    onClick={onAddSubmodule}/>
    </div>
}

export default function Menu({section, onSubmoduleClick, onSectionAboutClick, initialActiveSubmoduleId, onSubmoduleAdd}: Props) {
    const [activeSubmoduleId, setActiveSubmoduleId] = useState<number | undefined>(initialActiveSubmoduleId);

    useEffect(() => {
        setActiveSubmoduleId(initialActiveSubmoduleId)
    }, [initialActiveSubmoduleId])

    if (section === undefined) {
        return <MenuLoader/>
    }

    const Submodules = ({section}: { section: FullCourseSection }) => {
        return <div>
            {section.submodules && section.submodules.map(sub => {

                return <div
                    onClick={() => {
                        setActiveSubmoduleId(sub.id);
                        onSubmoduleClick(sub);
                    }}
                    className={'course-menu__submodule d-flex align-items-center justify-content-between'}
                    key={`course-menu__submodule-${sub.id}`}>
                    <div className={'d-flex flex-row align-items-center'}>
                        <TypeIcon type={sub.type?.id}/>
                        <span
                            className={(sub.id === activeSubmoduleId ? 'text-primary ' : '') + 'course-menu__submodule--text'}>{sub.name}</span>
                    </div>
                </div>
            })}
        </div>
    }

    return <Card>
        <ListGroup variant={'flush'}>
            <ListGroup.Item className={'as-link'} onClick={onSectionAboutClick}>
                <Card.Body className={activeSubmoduleId === undefined ? 'text-primary' : undefined}>О секции
                </Card.Body>
            </ListGroup.Item>
            <ListGroup.Item>
                <div>
                    <Submodules section={section}/>
                </div>
                <AddSubmodule onClick={onSubmoduleAdd}/>
            </ListGroup.Item>
        </ListGroup>
    </Card>

}