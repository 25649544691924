import * as React from 'react';
import {useContext, useState} from 'react';
import {UserFullCourse} from "../../../api/generated";
import ApiFactory from "../../../api/ApiFactory";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useSWR from 'swr'
import ErrorHandler from "../../../components/ErrorHandler";
import Menu, {HorizontalMenu} from "../../../components/user/Menu";
import CourseHeader from "../../../components/user/CourseHeader";
import CourseContent from "../../../components/common/CourseContent";
import {AuthContext} from "../../../components/auth/AuthContext";
import {RouteComponentProps, useNavigate} from "@reach/router";
import ModuleContent from "../../../components/user/ModuleContent";
import ContentContainer from "../../../components/common/ContentContainer";
import {Loader} from "../../../components/Loaders";

type Props = {
    id?: string
    moduleId?: string
} & RouteComponentProps

export default function Course(props: Props) {
    const {id, moduleId} = props;

    const [activeModuleId, setActiveModuleId] = useState<number | undefined>(moduleId ? Number(moduleId) : undefined)
    const navigate = useNavigate()
    const fetcher = () => ApiFactory.Instance().CourseAPI().userViewCourse(Number(id));
    const {data: course, error} = useSWR<UserFullCourse>(['user/course', id, useContext(AuthContext).userId], fetcher)
    const loading = course === undefined;
    const initialModuleID = moduleId !== undefined ? Number(moduleId) : undefined
    return (
        <>
            <ContentContainer
                hasNabBottomMenu={true}
                renderNavBottomMenu={() => <HorizontalMenu
                    onOtherUserPortfolio={() => {}}
                    onMyPortfolio={() => {
                        if (navigate) {
                            navigate(`/user/portfolio/${course?.id}`)
                        }
                    }}
                    initialModuleId={initialModuleID}
                    course={course}
                    loading={loading}
                    onActiveModuleChanged={(mod) => {
                        setActiveModuleId(mod?.id)
                        navigate(`/user/course/${id}/module/${mod?.id}`)
                    }}/>}>
                <Row>
                    <Col xs={12} md={3} className={'d-none d-sm-block'}>
                        <Menu
                            initialModuleId={initialModuleID}
                            course={course}
                            loading={loading}
                            onMyPortfolio={() => {
                                if (navigate) {
                                    navigate(`/user/portfolio/${course?.id}`)
                                }
                            }}
                            onOtherUserPortfolio={() => {}}
                            onActiveModuleChanged={(mod) => {
                                setActiveModuleId(mod?.id)
                                navigate(`/user/course/${id}/module/${mod?.id}`)
                            }}
                        />
                    </Col>
                    <Col xs={12} md={9}>
                        {error && <ErrorHandler error={error}/>}
                        {course === undefined && error === undefined && <Loader/>}
                        {course !== undefined &&
                        <>
                            {moduleId
                                ? <ModuleContent courseId={Number(id)} completionId={Number(course?.completion.id)}
                                                 id={Number(moduleId)}/>
                                : <CourseContent
                                    loading={loading}
                                    course={course}
                                    header={<CourseHeader loading={loading} course={course}/>}
                                />
                            }
                        </>
                        }
                    </Col>
                </Row>
            </ContentContainer>
        </>
    )
}