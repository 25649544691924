import * as React from 'react';
import {useEffect, useState} from 'react';
import {AdminFullCourseModule} from "../../api/generated";
import Nav from 'react-bootstrap/Nav';
import {ModuleEdit} from "./ModuleEdit";
import {ModuleView} from "./ModuleView";
import useSWR from "swr";
import ApiFactory, {MAX_PER_PAGE} from "../../api/ApiFactory";
import Badge from "react-bootstrap/Badge";
import SectionsInfo from "./SectionsInfo";
import DiscussionsList from "./DiscussionsList";
import {DiscussionTypeEnum} from "../../models/discussion";

type Props = {
    courseId?: number,
    module: AdminFullCourseModule,
    onReorder: () => void,
}

export enum Mode {
    View,
    Edit
}

enum NavKey {
    Main = "main",
    Sections = "sections",
    Discussions = "discussions",
}

type MainInfoProps = {
    courseId?: number,
    module: AdminFullCourseModule,
}

function MainInfo({courseId, module}: MainInfoProps) {
    const [mode, setMode] = useState<Mode>(Mode.View)
    return mode == Mode.View
        ? <ModuleView courseId={courseId} setMode={setMode} module={module}/>
        : <ModuleEdit courseId={courseId} module={module} onSucceed={() => setMode(Mode.View)}
                      onCancel={() => setMode(Mode.View)}/>
}



export default function ModuleAdmin({courseId, module, onReorder}: Props) {
    const [navKey, setNavKey] = useState<NavKey>(NavKey.Main)
    const [discussionsCount, setDiscussionsCount] = useState<number | undefined>(undefined);
    const fetcher = () => ApiFactory.Instance().DiscussionAPI().adminModuleDiscussions(module.id, 0, MAX_PER_PAGE)
    const {data: discussions} = useSWR(['admin/discussions', module.id, 0], fetcher)
    useEffect(() => {
        if (discussions !== undefined) {
            setDiscussionsCount(discussions.total)
        }
    }, [discussions])
    return <>
        <Nav fill variant="tabs" activeKey={navKey} defaultActiveKey={NavKey.Main} onSelect={ek => {
            if (ek !== null) {
                setNavKey(ek as NavKey)
            }
        }}>
            <Nav.Item>
                <Nav.Link eventKey={NavKey.Main}>Главное</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey={NavKey.Sections}>Секции <Badge
                    variant={'info'}>{module.sections.length}</Badge></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey={NavKey.Discussions}>Обсуждения{discussionsCount !== undefined &&
                <Badge variant={'info'}>{discussionsCount}</Badge>}</Nav.Link>
            </Nav.Item>
        </Nav>

        {navKey === NavKey.Main && <MainInfo module={module} courseId={courseId}/>}
        {navKey === NavKey.Sections && <SectionsInfo module={module} courseId={courseId} onReorder={onReorder}/>}
        {navKey === NavKey.Discussions &&
        <DiscussionsList externalId={module.id} discussionType={DiscussionTypeEnum.Module} onLoad={total => setDiscussionsCount(total)}/>}
    </>
}