import * as React from 'react';
import Card from "react-bootstrap/Card";
import RichContent from "./RichContent";

type Props = {
    title: string
    text: string
    canEdit?: boolean
    onEdit?: () => void
}

export default function ContentCard({title, text, canEdit, onEdit}: Props) {
    return <Card className={'content-card__card mt-4'}>
        <Card.Body>
            <div className={'d-flex flex-row justify-content-between'}>
                <h5 className={'text-primary'}>
                    {title}
                </h5>
                {canEdit && onEdit &&
                <img className={'button-icon'} src={"/images/edit.svg"} onClick={() => onEdit()}/>}
            </div>

            <div className={'content-card__text'}>
                <RichContent text={text}/>
            </div>
        </Card.Body>
    </Card>
}