import * as React from 'react';
import {useContext, useState} from 'react';
import {AuthContext} from "../auth/AuthContext";
import useSWR, {mutate} from "swr";
import ApiFactory from "../../api/ApiFactory";
import moment from "moment";
import {DATETIME_FORMAT} from "../../config";
import {Notification} from "../../api/generated";
import {Redirect} from "@reach/router";
import NavDropdown from "react-bootstrap/NavDropdown";
import UserPhoto from "../common/UserPhoto";


export default function Notifications() {
    const {isLoggedIn, userId} = useContext(AuthContext)
    const [redirectLink, setRedirectLink] = useState<string | undefined>(undefined);
    const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false)
    const {data: notifications, error} = useSWR(
        ["user/notifications", userId],
        () => ApiFactory.Instance().NotificationAPI().notificationList(),
        {
            refreshInterval: 60000
        }
    )

    const onNotificationClick = (not: Notification) => {
        ApiFactory.Instance().NotificationAPI().followNotification(not.id)
            .then((resp) => {
                mutate(["user/notifications", userId])
                setRedirectLink(resp.link)
            }).catch(e => console.warn(e))
    }

    const onClear = () => {
        ApiFactory.Instance().NotificationAPI().notificationsMarkRead()
            .then(() => {
                mutate(["user/notifications", userId])
            }).catch(e => console.warn(e))
    }

    if (!isLoggedIn) {
        return null;
    }

    if (notifications === undefined) {
        return null
    }

    if (error !== undefined) {
        console.warn(error)
        return null
    }

    return (<>
            {redirectLink && <Redirect noThrow={true} to={redirectLink}/>}
            <span className={'d-block d-sm-none'} onClick={() => setMobileMenuOpen(true)}>
                <span className={'notification__button'}>
                    {notifications.total > 0 && <span className={'notification__badge'}>{notifications.total}</span>}
                    <img className={'notification__icon'} src={'/images/notifications.png'}/>
                </span>
            </span>
            <div id="mobile-user-menu" className="menu-overlay" style={{"width": mobileMenuOpen ? '100%' : '0%'}}>
                <span onClick={() => setMobileMenuOpen(false)} className="menu-closebtn">&times;</span>
                <div className={'d-flex flex-row justify-content-center align-items-center p-4'}>
                    <img className={'logo'} src="/images/logo.png"/>
                </div>
                {
                    notifications.total === 0
                        ? <div className={"menu-overlay-no-content font-weight-bold"}>
                            Нет уведомлений
                        </div>
                        : <div className="menu-overlay-content">
                            <div className={'d-flex flex-row justify-content-between align-items-center p-3'}>
                        <span style={{
                            fontWeight: "bold",
                            fontSize: '12px',
                            whiteSpace: "nowrap",
                            paddingLeft: "5px",
                            paddingRight: "5px"
                        }}>
                            {notifications.total + " уведомлений(я)"}
                        </span>
                                <span className={'notification-clear__button as-link'} onClick={onClear}>Очистить</span>

                            </div>
                            {notifications.items.map(n => <NavDropdown.Item key={`not-${n.id}`}
                                                                            onClick={() => onNotificationClick(n)}>
                                <h6 className={'notification-item__title'}>{n.title}</h6>
                                <span className={'notification-item__description'}>{n.description}</span>
                                <div className={'d-flex flex-row justify-content-end'}><span
                                    className={'notification-item__time'}>{moment(n.created_at).format(DATETIME_FORMAT)}</span>
                                </div>
                            </NavDropdown.Item>)}
                        </div>
                }
            </div>
            <NavDropdown className={'d-none d-sm-block'} title={
                <span className={'notification__button'}>
                    {notifications.total > 0 && <span className={'notification__badge'}>{notifications.total}</span>}
                    <img className={'notification__icon'} src={'/images/notifications.png'}/>
                </span>} id="notification-dropdown">
                <div className={'d-flex flex-row justify-content-between align-items-center p-3'}>
                        <span style={{
                            fontWeight: "bold",
                            fontSize: '12px',
                            whiteSpace: "nowrap",
                            paddingLeft: "5px",
                            paddingRight: "5px"
                        }}>
                            {notifications.total > 0 ? notifications.total + " уведомлений(я)" : "Нет уведомлений"}
                        </span>
                    {notifications.total > 0
                    && <span>
                            <span className={'notification-clear__button as-link'} onClick={onClear}>Очистить</span>
                        </span>}

                </div>
                {notifications.items.map(n => <NavDropdown.Item key={`not-${n.id}`}
                                                                onClick={() => onNotificationClick(n)}>
                    <h6 className={'notification-item__title'}>{n.title}</h6>
                    <span className={'notification-item__description'}>{n.description}</span>
                    <div className={'d-flex flex-row justify-content-end'}><span
                        className={'notification-item__time'}>{moment(n.created_at).format(DATETIME_FORMAT)}</span>
                    </div>
                </NavDropdown.Item>)}
            </NavDropdown>
        </>
    )
}