import * as React from 'react';
import RichContent from "../../common/RichContent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {CourseSubmoduleCompletionData} from "../../../api/generated";

type Props = {
    typeData: any
    completionData?: CourseSubmoduleCompletionData
}

export default function SortingResult(props: Props) {
    return <>
        <div className={'content-card__text'}>
            <RichContent text={props.typeData.description}/>
        </div>
        <Row>
            {props.completionData && props.completionData.groups && props.completionData.groups.map(g => (
                <Col xs={12} md={6}>
                    <Card className={'mb-4'}>
                        <Card.Body className={'p-3'}>
                            <span>{g.name}</span>
                        </Card.Body>

                        <hr className={'hr--no-margin'}/>
                        <Card.Body>
                            {g.variants && g.variants && g.variants.map(v => (
                                <Card className={'rounded-0 m-2'}>
                                    <Card.Body className={'p-2'}>
                                        {v}
                                    </Card.Body>
                                </Card>
                            ))}
                        </Card.Body>
                    </Card>
                </Col>
            ))}

        </Row>
    </>
}