import * as React from 'react'
import {useContext, useEffect, useRef, useState} from 'react'
import {AuthContext} from "../auth/AuthContext";
import useSWR, {mutate} from "swr";
import {
    CourseSubmoduleType,
    TeacherSubmoduleCompletion,
    TeacherSubmoduleCompletionList,
    VerificationResult
} from "../../api/generated";
import ApiFactory, {DEFAULT_PER_PAGE} from "../../api/ApiFactory";
import {Loader} from "../Loaders";
import ErrorHandler from "../ErrorHandler";
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import moment from "moment";
import {SubmoduleCompletionStatus, Type} from "../submod/types";
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import UserPhoto from "../common/UserPhoto";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import Form from 'react-bootstrap/Form'
import {SubmodulePointStrategyEnum} from "../common/types";
import {SubmoduleCompletionStatusEnum} from "../user/CoursesList";
import Pagination from "../common/Pagination";
import RangingResult from "../submod/completion/RangingResult";
import SortingResult from "../submod/completion/SortingResult";
import DiscussionResult from "../submod/teacher/DiscussionResult";
import FileUploadResult from "../submod/completion/FileUploadResult";
import TextUploadResult from "../submod/completion/TextUploadResult";
import SubmoduleCompletionStatusBadge from "../common/SubmoduleCompletionStatusBadge";
import {saveAs} from "file-saver";
import {bool, boolean} from "yup";

type Props = {
    courseId: number,
    moduleId: number,
    submoduleId: number
}

type VerifyItemProps = {
    compl: TeacherSubmoduleCompletion;
    onMutate: () => void;
} & Props

type ItemProps = {
    compl: TeacherSubmoduleCompletion
}

type SubmoduleProps = {
    compl: TeacherSubmoduleCompletion
}

type SortGroup = {
    name: string;
    variants: string[]
}


function VerifySubmoduleCompletionItem({compl, courseId, moduleId, submoduleId, onMutate}: VerifyItemProps) {
    const [collapsed, setCollapsed] = useState<boolean>(true)
    const [submitError, setSubmitError] = useState<Error | undefined>(undefined);
    const {userId} = useContext(AuthContext);
    const form = useRef<HTMLFormElement>(null)

    const validationSchema = yup.object<VerificationResult>({
        comment: yup.string(),
        points: yup.number().integer(),
        course_submodule_completion_status_id: yup.number().required(),
    })

    const {register, handleSubmit, errors, setValue, setError} = useForm<VerificationResult>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            points: 0,
        }
    });

    useEffect(() => {
        register("course_submodule_completion_status_id")
    }, [])

    if (collapsed) {
        return <Card className={'teacher-completion as-link mb-4'} onClick={() => setCollapsed(!collapsed)}>
            <Card.Body>
                <Row>
                    <Col xs={6} className={'d-flex flex-row align-items-center'}>
                        <img className={'medium-icon'}
                             src={collapsed ? '/images/arrow-right.svg' : '/images/arrow-bottom.svg'}/>
                        <SubmoduleCompletionStatusBadge status={compl.completion.status}/>
                    </Col>
                    <Col xs={6} className={'d-flex align-items-center justify-content-end'}>
                        <div className={'d-flex flex-row align-items-center mr-4'}>
                            <span className={'mr-2'}>{compl.user.name}</span>
                            <UserPhoto user={compl.user} className={'teacher-completion__user-icon rounded-circle'}/>
                        </div>
                        <span
                            className={'text-bold'}>{moment(compl.completion.start_time).format('DD.MM.YYYY HH:mm')}</span>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    }

    const onSubmit = (params: VerificationResult) => {
        if (params.course_submodule_completion_status_id == SubmoduleCompletionStatus.Declined && !params.comment) {
            setError("comment", {type: "manual", message: "При отклонение задания, необходимо заполнить комментарий"})
            return
        }

        setSubmitError(undefined)
        ApiFactory.Instance().CourseSubmoduleCompletionAPI().teacherVerifySubmoduleCompletion(courseId, submoduleId, compl.completion.id, params).then(() => {
            onMutate();
            mutate(['teacher/course-module', moduleId, userId])
        }).catch(e => {
            setSubmitError(e)
        })
    }

    const typeData = compl.submodule.course_submodule_type_data
    return <Card className={'teacher-completion'}>
        <Card.Body>
            {submitError && <ErrorHandler error={submitError}/>}
            <Row className={'as-link'} onClick={() => setCollapsed(!collapsed)}>
                <Col xs={6} className={'d-flex flex-row align-items-center'}>
                    <img className={'medium-icon'}
                         src={collapsed ? '/images/arrow-right.svg' : '/images/arrow-bottom.svg'}/>
                    <SubmoduleCompletionStatusBadge status={compl.completion.status}/>
                </Col>
                <Col xs={6} className={'d-flex align-items-center justify-content-end'}>
                    <div className={'d-flex flex-row align-items-center mr-4'}>
                        <span className={'mr-2'}>{compl.user.name}</span>
                        <UserPhoto user={compl.user} className={'teacher-completion__user-icon rounded-circle'}/>
                    </div>
                    <span
                        className={'text-bold'}>{moment(compl.completion.start_time).format('DD.MM.YYYY HH:mm')}</span>
                </Col>
            </Row>
            <hr/>
            {compl.submodule.type.id == Type.TextSend
            && <TextUploadResult typeData={compl.submodule.course_submodule_type_data} completionData={compl.completion.data}/>}

            {compl.submodule.type.id == Type.FileSend
            && <FileUploadResult name={compl.submodule.name} typeData={compl.submodule.course_submodule_type_data} completionData={compl.completion.data}/>}

            {compl.submodule.type.id == Type.Discussion
            && <DiscussionResult typeData={compl.submodule.course_submodule_type_data}/>}

            {compl.submodule.type.id == Type.Ranging
            && <div className={'mt-4'}>
                <RangingResult
                    completionData={compl.completion.data}
                    typeData={compl.submodule.course_submodule_type_data}/>
            </div>}

            {compl.submodule.type.id == Type.Sorting
            && <div className={'mt-4'}>
                <SortingResult typeData={compl.submodule.course_submodule_type_data}
                    completionData={compl.completion.data}
                />
            </div>}

            <Form ref={form} onSubmit={handleSubmit(onSubmit)} className={'mt-4'}>
                <Form.Group>
                    <Form.Label>Комментарий</Form.Label>
                    <FormControl as={'textarea'} rows={3} ref={register} name={"comment"}/>
                    {errors.comment &&
                    <Form.Text className={'text-danger'}>{(errors.comment as any)?.message}</Form.Text>}
                </Form.Group>

                {(compl.submodule.course_submodule_points_strategy_id === SubmodulePointStrategyEnum.ManualPoints
                || compl.submodule.course_submodule_points_strategy_id === SubmodulePointStrategyEnum.ManualPointsNonBlocking)
                &&
                <Form.Group>
                    <Form.Label>Количество баллов</Form.Label>
                    <FormControl ref={register} type={'number'} name={"points"}/>
                    {compl.submodule.course_submodule_points_strategy_data.max_points > 0 &&
                    <div>
                        <small>максимум {compl.submodule.course_submodule_points_strategy_data.max_points} баллов</small>
                    </div>
                    }
                    {errors.points && <Form.Text className={'text-danger'}>{errors.points.message}</Form.Text>}
                </Form.Group>

                }

                <div className={'d-flex flex-row justify-content-end align-items-center mt-4'}>
                    <Button variant={'primary text-white mr-4'}
                            onClick={() => {
                                setValue("course_submodule_completion_status_id", SubmoduleCompletionStatus.AcceptedAsDraft)
                                if (form.current) {
                                    form.current.dispatchEvent(new Event('submit', {cancelable: true}))
                                }
                            }}>Принять как черновик</Button>
                    <Button variant={'success text-white mr-4'}
                            onClick={() => {
                                setValue("course_submodule_completion_status_id", SubmoduleCompletionStatus.Finished)
                                if (form.current) {
                                    form.current.dispatchEvent(new Event('submit', {cancelable: true}))
                                }
                            }}>Принять</Button>
                    <Button variant={'dark'}
                            onClick={() => {
                                setValue("course_submodule_completion_status_id", SubmoduleCompletionStatus.Declined)
                                if (form.current) {
                                    form.current.dispatchEvent(new Event('submit', {cancelable: true}))
                                }
                            }}>Отклонить</Button>
                </div>
            </Form>

        </Card.Body>
    </Card>
}


function SubmoduleCompletionItem({compl}: ItemProps) {
    return <Card className={'teacher-completion mb-4'}>
        <Card.Body>
            <Row>
                <Col xs={6} className={'d-flex flex-row align-items-center'}>
                    <SubmoduleCompletionStatusBadge status={compl.completion.status}/>
                    {
                        compl.submodule.course_submodule_points_strategy_id !== SubmodulePointStrategyEnum.NoPoints &&
                        compl.completion.status.id === SubmoduleCompletionStatusEnum.Finished &&
                        <small className={'ml-4'}>{compl.completion.max_points && compl.completion.points ?
                            compl.completion.points + " / " + compl.completion.max_points :
                            (compl.completion.points ?? "0")
                        } баллов</small>
                    }
                </Col>
                <Col xs={6} className={'d-flex align-items-center justify-content-end'}>
                    <div className={'d-flex flex-row align-items-center mr-4'}>
                        <span className={'mr-2'}>{compl.user.name}</span>
                        <UserPhoto user={compl.user} className={'teacher-completion__user-icon rounded-circle'}/>
                    </div>
                    <span
                        className={'text-bold'}>{moment(compl.completion.start_time).format('DD.MM.YYYY HH:mm')}</span>
                </Col>
            </Row>
        </Card.Body>
    </Card>

}

function isSubmoduleExportable(st: Type|undefined) {
    return st === Type.Sorting ||
        st === Type.Ranging ||
        st === Type.FileSend ||
        st === Type.TextSend ||
        st === Type.Test
}

function submoduleExportFormat(st: Type|undefined) {
    if (st === Type.Sorting ||
        st === Type.Ranging ||
        st === Type.FileSend ||
        st === Type.TextSend) {
        return "pdf"
    }

    if (st === Type.Test) {
        return "xlsx"
    }

    return "pdf"
}

export default function SubmoduleCompletionList(props: Props) {
    const {userId} = useContext(AuthContext)
    const [submoduleType, setSubmoduleType] = useState<Type|undefined>(undefined);
    const [currentPage, setCurrentPage] = useState<number>(0)
    const fetcher = () => ApiFactory.Instance().CourseSubmoduleCompletionAPI().teacherSubmoduleCompletionList(
        props.courseId, props.submoduleId, currentPage, DEFAULT_PER_PAGE)
    const {data: completions, error} = useSWR<TeacherSubmoduleCompletionList>(['teacher/submodule-completions', userId, props.submoduleId, currentPage], fetcher)

    useEffect(() => {
        if (completions === undefined || completions.items.length === 0) {
            setSubmoduleType(undefined);
            return
        }
        setSubmoduleType(completions.items[0].submodule.type.id)
    }, [completions])
    if (error) {
        return <ErrorHandler error={error}/>
    }

    if (completions === undefined) {
        return <Loader/>
    }

    if (completions.total == 0) {
        return <h5>Нет данных</h5>
    }

    return <>
        <div className={'d-flex flex-direction-row justify-content-end mb-4'}>
            {isSubmoduleExportable(submoduleType) &&
            <Button className={'text-white'} onClick={() => {
                const format = submoduleExportFormat(submoduleType)
                ApiFactory.Instance().CourseSubmoduleCompletionAPI()
                    .teacherExportSubmoduleCompletionList(props.courseId, props.submoduleId, {format: format})
                    .then(resp => resp.blob()).then(blob => {
                    saveAs(blob, `Результаты прохождения.${format}`)
                })
            }}>Экспорт</Button>
            }
        </div>
        {completions.items.map((c, i) => {
            switch(c.completion.status.id) {
                case SubmoduleCompletionStatusEnum.WaitForReview:
                case SubmoduleCompletionStatusEnum.WaitForReviewNonBlocking:
                    return <VerifySubmoduleCompletionItem key={`compl-${i}`} {...props} compl={c} onMutate={() => {
                        mutate(['teacher/submodule-completions', userId, c.submodule.id, currentPage])
                        mutate(['teacher/course-module', props.moduleId, userId])
                    }}/>
                default:
                    return <SubmoduleCompletionItem key={`comple-${i}`} compl={c}/>
            }


        })}
        <div className={'d-flex direction-row justify-content-center'}>
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalItems={completions.total}/>
        </div>
    </>
}
