import {Type} from "./types";
import * as React from "react";

export function TypeIcon({type}: { type?: Type }) {
    let icon;
    switch (type) {
        case Type.Text:
            icon = '/images/text-submodule.svg'
            break;
        case Type.Video:
            icon = '/images/video-submodule.svg'
            break;
        case Type.Test:
            icon = '/images/test-submodule.svg'
            break;
        case Type.Ranging:
            icon = '/images/range.svg'
            break;
        case Type.Sorting:
            icon = '/images/sort.svg'
            break;
        case Type.FileSend:
        case Type.TextSend:
            icon = '/images/upload-submodule.svg'
            break;
        case Type.Discussion:
            icon = '/images/discussion.svg'
            break;
    }

    return icon !== undefined
        ? <img className={'course-menu__availability-icon'} src={icon}/>
        : null;
}