import {Type} from "../types";
import Form from "react-bootstrap/Form";
import * as React from "react";
import {Suspense} from "react";
import {Loader} from "../../Loaders";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import UploadVideo from "./UploadVideo";
import {AdminFullCourseSubmodule} from "../../../api/generated";
import DiscussionTypeControls from "./DiscussionTypeControls";
import RangingTypeControls from "./RangingTypeControls";
import SortingTypeControls from "./SortingTypeControls";

const Editor = React.lazy(() => import("../../common/lazy/AdminCKEditor"))

export type TypeProps = {
    typeId: number
    courseId: number
    moduleId: number
    submodule: AdminFullCourseSubmodule,
    errors: any,
    register: any,
    setValue: (name: string, val: any) => void,
    setError: (e: Error) => void,
}

export function TypeControls(props: TypeProps) {
    const {
        errors,
        register,
        submodule,
        typeId,
        setValue,
        setError,
    } = props

    return <>
        {typeId === Type.Text && <Form.Group>
            <Form.Label>Текст</Form.Label>
            <Suspense fallback={<Loader/>}>
                <Editor initialContent={submodule.course_submodule_type_data.text || ""}
                        onContentChange={text => {
                            setValue("course_submodule_type_text", text)
                        }}/>
            </Suspense>
            {errors && errors.course_submodule_type_text &&
            <Form.Text
                className={'text-danger'}>{errors.course_submodule_type_text.message}</Form.Text>}
        </Form.Group>}

        {typeId === Type.Video && <>
            <Form.Group>
                <Form.Label>Видео файл</Form.Label>
                <Row>
                    <Col xs={12} md={6}>
                        <UploadVideo submodule={submodule} setError={setError} onUpload={url => {
                            setValue("course_submodule_type_content_url", url)
                        }}/>
                    </Col>
                </Row>

                {errors && errors.course_submodule_type_content_url &&
                <Form.Text
                    className={'text-danger'}>{errors.course_submodule_type_content_url.message}</Form.Text>}
            </Form.Group>
            <Form.Group>
                <Form.Label>Описание</Form.Label>
                <Suspense fallback={<Loader/>}>
                    <Editor initialContent={submodule.course_submodule_type_data.description || ""}
                            onContentChange={text => {
                                setValue("course_submodule_type_description", text)
                            }}/>
                </Suspense>
                {errors && errors.course_submodule_type_description &&
                <Form.Text
                    className={'text-danger'}>{errors.course_submodule_type_description.message}</Form.Text>}
            </Form.Group>
        </>}

        {typeId === Type.Test && <>
            <Form.Group>
                <Form.Label>Описание</Form.Label>
                <Suspense fallback={<Loader/>}>
                    <Editor initialContent={submodule.course_submodule_type_data.description || ""}
                            onContentChange={text => {
                                setValue("course_submodule_type_description", text)
                            }}/>
                </Suspense>
                {errors && errors.course_submodule_type_description &&
                <Form.Text
                    className={'text-danger'}>{errors.course_submodule_type_description.message}</Form.Text>}
            </Form.Group>
            <Form.Group>
                <Form.Check ref={register} name={"course_submodule_type_show_result"}
                            label={'Показывать результаты после прохождения'}/>
                {errors && errors.course_submodule_type_show_result &&
                <Form.Text
                    className={'text-danger'}>{errors.course_submodule_type_show_result.message}</Form.Text>}
            </Form.Group>
            <Form.Group>
                <Form.Check ref={register} name={"course_submodule_type_show_answered_description"}
                            label={'Показывать описание выбранных ответов'}/>
                {errors && errors.course_submodule_type_show_answered_description &&
                <Form.Text
                    className={'text-danger'}>{errors.course_submodule_type_show_answered_description.message}</Form.Text>}
            </Form.Group>
            <Form.Group>
                <Form.Check ref={register} name={"course_submodule_type_show_unanswered_description"}
                            label={'Показывать описание невыбранных ответов'}/>
                {errors && errors.course_submodule_type_show_unanswered_description &&
                <Form.Text
                    className={'text-danger'}>{errors.course_submodule_type_show_unanswered_description.message}</Form.Text>}
            </Form.Group>
        </>}

        {typeId === Type.TextSend && <>
            <Form.Group>
                <Form.Label>Описание</Form.Label>
                <Suspense fallback={<Loader/>}>
                    <Editor initialContent={submodule.course_submodule_type_data.description || ""}
                            onContentChange={text => {
                                setValue("course_submodule_type_description", text)
                            }}/>
                </Suspense>

                {errors && errors.course_submodule_type_description &&
                <Form.Text
                    className={'text-danger'}>{errors.course_submodule_type_description.message}</Form.Text>}
            </Form.Group>
        </>}

        {typeId === Type.FileSend && <>
            <Form.Group>
                <Form.Label>Описание</Form.Label>
                <Suspense fallback={<Loader/>}>
                    <Editor initialContent={submodule.course_submodule_type_data.description || ""}
                            onContentChange={text => {
                                setValue("course_submodule_type_description", text)
                            }}/>
                </Suspense>

                {errors && errors.course_submodule_type_description &&
                <Form.Text
                    className={'text-danger'}>{errors.course_submodule_type_description.message}</Form.Text>}
            </Form.Group>
        </>}

        {typeId === Type.Discussion && <DiscussionTypeControls {...props}/>}
        {typeId === Type.Ranging && <RangingTypeControls {...props}/>}
        {typeId === Type.Sorting && <SortingTypeControls {...props}/>}
    </>
}