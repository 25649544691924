import * as React from 'react';
import {useContext, useEffect, useRef, useState, Suspense} from 'react';
import * as yup from "yup";
import {CreateSectionParams, CreateUserGroupParams} from "../../api/generated";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import {mutate} from "swr";
import {AuthContext} from "../auth/AuthContext";
import ApiFactory, {DEFAULT_PER_PAGE} from "../../api/ApiFactory";
import ErrorHandler from "../ErrorHandler";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import {Loader} from "../Loaders";
import {SelectOption} from "../common/types";
import * as _ from "lodash";
import AsyncSelect from "react-select/async";

type Props = {
    courseId: number,
    onSucceed: () => void;
    onCancel: () => void;
}

const formValidationSchema = yup.object<CreateUserGroupParams>({
    name: yup.string().required(),
    user_ids: yup.array().of(yup.number().required()).required(),
})


export default function UserGroupCreate({courseId, onSucceed, onCancel}: Props) {
    const form = useRef<HTMLFormElement>(null);
    const [error, setError] = useState<Error | undefined>(undefined);

    const [selectedUsers, setSelectedUsers] = useState<SelectOption[]>([])

    const loadUsers = _.debounce((input: string, callback: (opts: SelectOption[]) => void) => {
        ApiFactory.Instance().UserAPI().adminUserList(input, undefined, 0, DEFAULT_PER_PAGE)
            .then(resp => {
                callback(resp.items.map<SelectOption>(u => ({value: "" + u.id, label: u.name})))
            }).catch(e => {
            callback([])
        })
    }, 500)

    const {register, handleSubmit, errors, setValue, reset} = useForm<CreateUserGroupParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            name: "",
        }
    });

    useEffect(() => {
        register("user_ids");
    }, []);

    const onSubmit = (params: CreateUserGroupParams) => {
        setError(undefined)
        ApiFactory.Instance().CourseUserGroupAPI().adminCreateCourseUserGroup(courseId, params)
            .then(s => {
                onSucceed()
            }).catch(e => setError(e))
    }

    return <Card className={'m-4'}>
        <Card.Body>
            {error && <ErrorHandler error={error}/>}
            <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
                <div className={'d-flex flex-row align-items-center justify-content-end'}>
                    <div className={'d-flex flex-row'}>
                        <img className={'button-icon ml-4 as-link'} src={"/images/cancel.svg"}
                             onClick={onCancel}/>
                        <img className={'button-icon ml-4 as-link'} src={"/images/save.svg"}
                             onClick={() => {
                                 if (form.current) {
                                     form.current.dispatchEvent(new Event('submit', {cancelable: true}))
                                 }
                             }}/>
                    </div>
                </div>
                <Form.Group>
                    <Form.Label>Название</Form.Label>
                    <Form.Control ref={register} name={"name"}/>
                    {errors && errors.name &&
                    <Form.Text className={'text-danger'}>{(errors.name as any)?.message}</Form.Text>}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Пользователи</Form.Label>
                    <AsyncSelect value={selectedUsers}
                                 placeholder={'Выберите пользователей'}
                                 loadOptions={loadUsers}
                                 defaultOptions={true}
                                 isMulti={true}
                                 onChange={(v: any) => {
                                     let val: SelectOption[] = []
                                     if (v != null) {
                                         val = v as SelectOption[];
                                     }
                                     setSelectedUsers(val)
                                     const newVal = val.map(v => Number(v.value))
                                     setValue("user_ids", newVal)
                                 }}
                    />
                    {errors && errors.user_ids &&
                    <Form.Text
                        className={'text-danger'}>{JSON.stringify(errors.user_ids)}</Form.Text>}
                </Form.Group>
            </Form>
        </Card.Body>

    </Card>;
}