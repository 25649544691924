import * as React from 'react';
import ReactPaginate from "react-paginate";
import {DEFAULT_PER_PAGE} from "../../api/ApiFactory";

type Props = {
    currentPage: number,
    setCurrentPage: (page: number) => void,
    totalItems?: number
    perPage?: number
}

export default function Pagination(props: Props) {
    const {currentPage, setCurrentPage, totalItems} = props
    const perPage = props.perPage ?? DEFAULT_PER_PAGE

    if (totalItems === undefined || totalItems <= perPage) {
        return null
    }
    return <ReactPaginate
        forcePage={currentPage}
        containerClassName="pagination"
        breakClassName="page-item"
        previousLabel={"Назад"}
        nextLabel={"Вперед"}
        breakLabel={<a className="page-link">...</a>}
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
        pageLinkClassName="page-link"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        activeClassName="active"
        onPageChange={(po) => {
            setCurrentPage(po.selected)
        }}
        marginPagesDisplayed={2}
        pageCount={Math.ceil(totalItems / perPage)}
        pageRangeDisplayed={2}/>
}