export default {
    ru: {
        translation: {
            "nameRequired": "Поле Имя должно быть заполнено",
            "emailRequired": "Поле E-mail должно быть заполнено",
            "emailBadFormat": "Неверный формат E-mail",
            "passwordRequired": "Поле Пароль должно быть заполнено",
            "passwordSmallLength": "Длина пароля должна быть больше 6 символов",
            "passwordConfirmShouldMatch": "Значение поля не совпадает с паролем",
            "integerValueRequired": "Значение поля должно быть целым числом"
        }
    }
};