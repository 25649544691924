import * as React from 'react';
import {useContext, useEffect, useState, Suspense, useMemo} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {RouteComponentProps, useNavigate} from "@reach/router";
import SubmoduleContent from "../../../components/submod/SubmoduleContent";
import ApiFactory, {DEFAULT_PER_PAGE, MAX_PER_PAGE} from "../../../api/ApiFactory";
import useSWR, {mutate} from "swr";
import {
    FullDiscussion,
    ShortAdminDiscussionTopic, TopicCreateParams,
} from "../../../api/generated";
import {AuthContext} from "../../../components/auth/AuthContext";
import ContentContainer from "../../../components/common/ContentContainer";
import HorizontalScroll, {Item} from "../../../components/common/HorizontalScroll";
import {LineLoader, ListLoader, Loader} from "../../../components/Loaders";
import ErrorHandler, {AlertSize} from "../../../components/ErrorHandler";
import Pagination from "../../../components/common/Pagination";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import UserPhoto from "../../../components/common/UserPhoto";
import {DATETIME_FORMAT} from "../../../config";
import moment from 'moment';
import Form from 'react-bootstrap/Form'
import Button from "react-bootstrap/Button";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import AsyncSelect from "react-select/async";
import {SelectOption} from "../../../components/common/types";
import * as _ from "lodash";
import Select from "react-select";
import BreadcrumbItem from "react-bootstrap/BreadcrumbItem";

const Editor = React.lazy(() => import("../../../components/common/lazy/AdminCKEditor"));
type Props = {
    id?: string
    search?: string
} & RouteComponentProps


type BreadcrumbsProps = {
    discussion?: FullDiscussion,
    error?: Error
}

function Breadcrumbs(props: BreadcrumbsProps) {
    const navigate = useNavigate()
    const {discussion, error} = props

    const items = useMemo(() => {
        if (!discussion) {
            return [];
        }
        const res: Item[] = [];
        res.push({title: 'Список курсов /', active: false, onClick: () => navigate("/admin")});
        res.push({
            title: discussion.breadcrumbs.course.name + " /",
            active: false,
            onClick: () => navigate(`/admin/course/${discussion.breadcrumbs.course.id}`)
        })
        if (discussion.breadcrumbs.module && discussion.breadcrumbs.module.id) {
            res.push({
                title: discussion.breadcrumbs.module.name + " /",
                active: false,
                onClick: () => navigate(`/admin/course/${discussion!.breadcrumbs.course.id}/module/${discussion.breadcrumbs.module!.id}`)
            })
        }
        res.push({title: discussion.name, active: true})

        return res;
    }, [discussion])

    if (error !== undefined) {
        return null
    }
    if (discussion === undefined) {
        return <LineLoader/>
    }

    return <div className={'mt-4'}>
        <HorizontalScroll alignCenter={false} items={items}/>
    </div>
}

type TopicSearchProps = {
    initial?: string,
    onSearch: (q: string | undefined) => void,
    onCreateTopic: () => void,
}

function TopicSearch(props: TopicSearchProps) {
    const [query, setQuery] = useState<string | undefined>(props.initial)
    return <Form onSubmit={(e) => {
        props.onSearch(query)
        e.preventDefault()
    }}>
        <div className={'d-flex flex-row'}>
            <Form.Control value={query} onChange={e => setQuery(e.target.value)}/>
            <Button type={'submit'} variant={'primary'} className={'ml-2 ml-sm-4'}>
                <span className={'d-none d-sm-block text-white'}>Поиск</span>
                <img className={'d-block d-sm-none in-button-icon'} src={'/images/search-white.svg'}/>
            </Button>
            <Button variant={'success'} className={'ml-2 ml-sm-4 discussion-new-topic-button'}
                    onClick={props.onCreateTopic}>
                <span className={'d-none d-sm-block text-white'}>Новая тема</span>
                <img className={'d-block d-sm-none in-button-icon'} src={'/images/add-white.svg'}/>
            </Button>
        </div>
    </Form>


}

type TopicItemProps = {
    topic: ShortAdminDiscussionTopic
    discussionId: number
}

function TopicItem(props: TopicItemProps) {
    const navigate = useNavigate()
    const {topic} = props
    return <Card className={'mt-4 mb-4'}>
        <Card.Body
            className={'as-link'} onClick={() => {
            navigate(`/admin/discussion/${props.discussionId}/topic/${topic.id}`)
        }}>
            <Row>
                <Col xs={12} md={8} className={'d-flex flex-row mt-2'}>
                    {topic.pinned && <img src={"/images/pinned.svg"} className={'small-icon'}/>}
                    <div>
                        <h6 className={'mb-2'}>{topic.name}</h6>
                        {topic.is_private && <>
                            <div className={'mb-4'}><img src={'/images/private.svg'} className={'small-icon'}/><small>Приватная тема</small></div>
                            {topic.private_users && topic.private_users.length > 0 && <div><small>Пользователи: {topic.private_users.map(u => u.name).join(", ")}</small></div>}
                            {topic.private_user_groups && topic.private_user_groups.length > 0 && <div><small>Группы: {topic.private_user_groups.map(u => u.name).join(", ")}</small></div>}
                        </>}
                    </div>
                </Col>
                <Col xs={12} md={4} className={'d-flex flex-row mt-2 topic-counters'}>
                    {topic.unread_count > 0 &&
                    <div className={'d-flex flex-column align-items-center pl-2 pr-2'}>
                        <span className={'text-danger'}>{topic.unread_count}</span>
                        <span className={'x-small-text'}>новых комментариев</span>
                    </div>
                    }
                    <div className={'d-flex flex-column align-items-center pl-2 pr-2'}>
                        <span>{topic.view_count}</span>
                        <span className={'x-small-text'}>просмотров</span>
                    </div>
                    <div className={'d-flex flex-column align-items-center pl-2 pr-2'}>
                        <span>{topic.comment_count}</span>
                        <span className={'x-small-text'}>комментариев</span>
                    </div>
                </Col>
            </Row>
        </Card.Body>
        <Card.Body className={'d-flex flex-row justify-content-between'}>
            <div>
                <UserPhoto user={topic.created_by} className={'small-user-photo'}/>
                <span className={'small ml-2'}>{topic.created_by.name}</span>
            </div>
            <div>
                {topic.last_commented_by && <div>
                    <span className={'x-small-text mr-2'}>Последний комментарий</span>
                    <span
                        className={'x-small-text ml-2'}>{moment(topic.last_commented_at!).format(DATETIME_FORMAT)}</span>
                </div>}
            </div>
        </Card.Body>
    </Card>
}

type TopicListProps = {
    discussion: FullDiscussion,
    search?: string,
}

function TopicList(props: TopicListProps) {
    const {userId} = useContext(AuthContext)
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [showNewTopic, setShowNewTopic] = useState<boolean>(false);
    const [search, setSearch] = useState<string | undefined>(props.search)
    const fetcher = (key: any, id: number, userId: any, currentPage: number, search: string | undefined) =>
        ApiFactory.Instance().DiscussionTopicAPI().adminDiscussionTopics(id, search, currentPage, DEFAULT_PER_PAGE)
    const key = ['admin/discussion-topics', props.discussion.id, userId, currentPage, search]
    const {data: topics, error} = useSWR(key, fetcher)
    if (error !== undefined) {
        return <ErrorHandler error={error}/>
    }

    if (topics === undefined) {
        return <ListLoader/>
    }

    return <>
        <TopicSearch initial={search} onSearch={s => setSearch(s)} onCreateTopic={() => setShowNewTopic(true)}/>
        {showNewTopic && <CreateTopic
            discussion={props.discussion}
            onFinish={() => {
                setShowNewTopic(false)
                mutate(key)
            }}
            onCancel={() => {
                setShowNewTopic(false)
            }}
        />}
        {topics.items.map((topic: ShortAdminDiscussionTopic) => <TopicItem key={`topic-${topic.id}`} topic={topic}
                                                                           discussionId={props.discussion.id}/>)}
        <Pagination totalItems={topics.total} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
    </>
}

type CreateTopicProps = {
    discussion: FullDiscussion,
    onCancel: () => void,
    onFinish: () => void,
}

function CreateTopic(props: CreateTopicProps) {
    const formValidationSchema = yup.object<TopicCreateParams>({
        text: yup.string().required("Текст темы не может быть пустым"),
        name: yup.string().required("Название темы не может быть пустым"),
        private_user_ids: yup.array(),
        private_user_group_ids: yup.array(),
        is_available_in_my_portfolio: yup.boolean(),
        is_available_in_other_user_portfolio: yup.boolean(),
    })

    const {register, handleSubmit, errors, setValue} = useForm<TopicCreateParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            name: "",
            text: "",
        }
    });

    const [selectGroups, setSelectGroups] = useState<SelectOption[] | undefined>(undefined)
    const [availableGroups, setAvailableGroups] = useState<SelectOption[]|undefined>(undefined);

    useEffect(() => {
        if (props.discussion.is_private) {
            ApiFactory.Instance().CourseUserGroupAPI().adminListCourseUserGroups(props.discussion.course_id, 0, MAX_PER_PAGE)
                .then(resp => setAvailableGroups(resp.items.map(grp => ({value: "" + grp.id, label: grp.name}))))
                .catch(e => setError(e))
        }
    }, [props.discussion])

    useEffect(() => {
        register("text")
        register("private_user_ids")
        register("private_user_group_ids")
    }, [])

    const [error, setError] = useState<Error | undefined>(undefined);
    const onSubmit = (params: TopicCreateParams) => {
        setError(undefined);
        ApiFactory.Instance().DiscussionTopicAPI().adminCreateDiscussionTopic(props.discussion.id, params)
            .then(val => {
                props.onFinish()
            })
            .catch(e => setError(e))
    };

    const [selectUserIds, setSelectUserIds] = useState<SelectOption[] | undefined>(undefined)

    const loadUsers = _.debounce((input: string, callback: (opts: SelectOption[]) => void) => {
        ApiFactory.Instance().DiscussionAPI().adminDiscussionUserList(props.discussion.id, input, 0, DEFAULT_PER_PAGE)
            .then(resp => {
                callback(resp.items.map<SelectOption>(u => ({value: "" + u.id, label: u.name})))
            }).catch(e => {
            callback([])
        })
    }, 500)

    return <Card className={'mt-4 mb-4'}>
        <Card.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
                {error && <ErrorHandler size={AlertSize.Small} error={error}/>}
                <Form.Group>
                    <Form.Label>Название темы</Form.Label>
                    <Form.Control name={"name"} ref={register}/>
                    {errors.name && <Form.Text>{(errors.name as any)?.message}</Form.Text>}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Текст</Form.Label>
                    <Suspense fallback={<Loader/>}>
                        <Editor initialContent={""} onContentChange={text => setValue("text", text)}/>
                    </Suspense>

                    {errors.text && <Form.Text>{(errors.text as any)?.message}</Form.Text>}
                </Form.Group>
                {props.discussion.is_private &&
                <Form.Group>
                    <Form.Label>Пользователь</Form.Label>
                    <AsyncSelect value={selectUserIds}
                                 defaultOptions={true}
                                 placeholder={'Выберите пользователей'}
                                 loadOptions={loadUsers}
                                 isMulti={true}
                                 onChange={(v: any) => {
                                     let val: SelectOption[] = v as SelectOption[]
                                     setSelectUserIds(val)
                                     const newVal = val ? val.map(v => Number(v.value)) : []
                                     setValue("private_user_ids", newVal)
                                 }}
                    />
                    {errors && errors.private_user_ids &&
                    <Form.Text className={'text-danger'}>Некорректно выбраны пользователи</Form.Text>}
                </Form.Group>
                }
                {props.discussion.is_private &&
                <Form.Group>
                    <Form.Label>Группы пользователей</Form.Label>
                    <Select value={selectGroups}
                            isMulti={true}
                            placeholder={'Выберите группу'}
                            onChange={(v: any) => {
                                const val = v as SelectOption[];
                                setSelectGroups(val)
                                const newVal = val ? val.map(v => Number(v.value)): [];
                                setValue("private_user_group_ids", newVal)
                            }}
                            options={availableGroups}
                    />
                    {errors && errors.private_user_group_ids &&
                    <Form.Text
                        className={'text-danger'}>{JSON.stringify(errors.private_user_group_ids)}</Form.Text>}
                </Form.Group>
                }
                <Form.Group>
                    <Form.Check label={'Тема доступна в собственном портфолио пользователя'} type={'checkbox'} ref={register} name={"is_available_in_my_portfolio"}/>
                    {errors && errors.is_available_in_my_portfolio &&
                    <Form.Text className={'text-danger'}>{errors.is_available_in_my_portfolio.message}</Form.Text>}
                </Form.Group>
                <Form.Group>
                    <Form.Check label={'Тема доступна в портфолио других пользователей'} type={'checkbox'} ref={register} name={"is_available_in_other_user_portfolio"}/>
                    {errors && errors.is_available_in_other_user_portfolio &&
                    <Form.Text className={'text-danger'}>{errors.is_available_in_other_user_portfolio.message}</Form.Text>}
                </Form.Group>
                <div className={'d-flex flex-row justify-content-end'}>
                    <Button type={'submit'} variant={'primary'}
                            className={'text-white'}>Сохранить</Button>
                    {props.onCancel &&
                    <Button className={'ml-2'} variant={'light'} onClick={() => {
                        setValue("text", "")
                        if (props.onCancel) {
                            props.onCancel()
                        }
                    }}>Отменить</Button>
                    }

                </div>
            </Form>
        </Card.Body>
    </Card>
}

export default function Discussion(props: Props) {
    const id = Number(props.id)
    const {userId} = useContext(AuthContext)
    const navigate = useNavigate()
    const fetcher = () => ApiFactory.Instance().DiscussionAPI().adminDiscussion(id);
    const {data: discussion, error: breadcrumbsError} = useSWR(['admin/discussion', id, userId], fetcher)

    if (Number.isNaN(id)) {
        return <ErrorHandler error={new Error("id is not a number")}/>
    }
    return (
        <ContentContainer>

            <Breadcrumbs error={breadcrumbsError} discussion={discussion}/>

            <hr className={'hr--divider'}/>
            <Row>
                <Col xs={12}>
                    {discussion && <TopicList discussion={discussion}/>}
                </Col>
            </Row>
        </ContentContainer>
    )
}