export enum Type {
    Text = 1,
    Video,
    Test,
    FileSend,
    TextSend,
    Discussion,
    Ranging,
    Sorting
}

export enum SubmoduleCompletionStatus {
    InProgress = 1,
    WaitForReview = 2,
    Finished = 3,
    Declined = 4,
    WaitForReviewNonBlocking = 5,
    AcceptedAsDraft = 6,
}

export function isStatusPositive(status: SubmoduleCompletionStatus) {
    return status === SubmoduleCompletionStatus.AcceptedAsDraft ||
        status === SubmoduleCompletionStatus.WaitForReviewNonBlocking ||
        status === SubmoduleCompletionStatus.Finished
}