import * as React from 'react';
import {useEffect, useRef, useState, Suspense} from 'react';
import * as yup from "yup";
import {FullCourseSection, FullCourseUserGroup, UpdateSectionParams, UpdateUserGroupParams} from "../../api/generated";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import ApiFactory, {DEFAULT_PER_PAGE, MAX_PER_PAGE} from "../../api/ApiFactory";
import ErrorHandler from "../ErrorHandler";
import {Loader} from "../Loaders";
import Form from 'react-bootstrap/Form';
import Card from "react-bootstrap/Card";
import Button from 'react-bootstrap/Button';
import SucceedAlert from "../common/SucceedAlert";
import * as _ from "lodash";
import {SelectOption} from "../common/types";
import AsyncSelect from "react-select/async";
import useSWR from "swr";

type Props = {
    courseId: number,
    groupId: number,
    onSucceed: () => void,
    onCancel: () => void,
}

const formValidationSchema = yup.object<UpdateUserGroupParams>({
    name: yup.string().required(),
    user_ids: yup.array().of(yup.number().required()).required(),
})

type FormProps = {
    group: FullCourseUserGroup,
} & Props

function UserGroupForm({group, onSucceed, courseId, onCancel}: FormProps) {
    const form = useRef<HTMLFormElement>(null)
    const [error, setError] = useState<Error | undefined>(undefined);
    const [succeedMessage, setSucceedMessage] = useState<string | undefined>(undefined);
    const [selectedUsers, setSelectedUsers] = useState<SelectOption[]>([])

    const {register, handleSubmit, errors, setValue, reset} = useForm<UpdateUserGroupParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            name: group.name,
            user_ids: group.users.map(u => u.id)
        }
    });

    useEffect(() => {
        setValue("name", group.name)
        setValue("user_ids", group.users.map(u => u.id))
        setSelectedUsers(group.users.map(u => ({value: "" + u.id, label: u.name})))
    }, [group])

    useEffect(() => {
        register("user_ids");
    }, [])

    const onSubmit = (params: UpdateUserGroupParams) => {
        setError(undefined)
        ApiFactory.Instance().CourseUserGroupAPI().adminUpdateCourseUserGroup(courseId, group.id, params)
            .then(s => {
                onSucceed();
                setSucceedMessage("Данные сохранены");
            }).catch(e => setError(e))
    }

    const loadUsers = _.debounce((input: string, callback: (opts: SelectOption[]) => void) => {
        ApiFactory.Instance().UserAPI().adminUserList(input, undefined, 0, DEFAULT_PER_PAGE)
            .then(resp => {
                callback(resp.items.map<SelectOption>(u => ({value: "" + u.id, label: u.name})))
            }).catch(e => {
            callback([])
        })
    }, 500)

    return <Card className={'m-4'}>
        {error && <ErrorHandler error={error}/>}
        <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
            <Card.Body>
                <div className={'d-flex flex-row align-items-center justify-content-end'}>
                    <div className={'d-flex flex-row'}>
                        <img className={'button-icon ml-4 as-link'} src={"/images/cancel.svg"}
                             onClick={onCancel}/>
                        <img className={'button-icon ml-4 as-link'} src={"/images/save.svg"}
                             onClick={() => {
                                 if (form.current) {
                                     form.current.dispatchEvent(new Event('submit', {cancelable: true}))
                                 }
                             }}/>
                    </div>
                </div>
                {succeedMessage && <SucceedAlert message={succeedMessage} onHide={() => setSucceedMessage(undefined)}/>}
                <Form.Group>
                    <Form.Label>Название</Form.Label>
                    <Form.Control ref={register} name={"name"}/>
                    {errors && errors.name &&
                    <Form.Text className={'text-danger'}>{(errors.name as any)?.message}</Form.Text>}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Пользователи</Form.Label>
                    <AsyncSelect value={selectedUsers}
                                 placeholder={'Выберите пользователей'}
                                 loadOptions={loadUsers}
                                 defaultOptions={true}
                                 isMulti={true}
                                 onChange={(v: any) => {
                                     let val: SelectOption[] = []
                                     if (v != null) {
                                         val = v as SelectOption[];
                                     }
                                     setSelectedUsers(val)
                                     const newVal = val.map(v => Number(v.value))
                                     setValue("user_ids", newVal)
                                 }}
                    />
                    {errors && errors.user_ids &&
                    <Form.Text
                        className={'text-danger'}>{JSON.stringify(errors.user_ids)}</Form.Text>}
                </Form.Group>
            </Card.Body>
        </Form>
    </Card>;
}



export default function UserGroupEdit(props: Props) {
    const fetcher = () => ApiFactory.Instance().CourseUserGroupAPI().adminViewCourseUserGroup(
        props.groupId, props.groupId)
    const {data: grp, error} = useSWR(['admin/user-group', props.groupId], fetcher)
    if (error !== undefined) {
        return <ErrorHandler error={error}/>
    }

    if (grp === undefined) {
        return <Loader/>
    }

    return <UserGroupForm {...props} group={grp}/>
}