import * as React from 'react';
import {useContext, useEffect, useState, Suspense, useMemo} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {RouteComponentProps, useNavigate} from "@reach/router";
import ApiFactory, {DEFAULT_PER_PAGE, MAX_PER_PAGE} from "../../../../api/ApiFactory";
import useSWR, {mutate} from "swr";
import {AuthContext} from "../../../../components/auth/AuthContext";
import ContentContainer from "../../../../components/common/ContentContainer";
import HorizontalScroll, {Item} from "../../../../components/common/HorizontalScroll";
import {LineLoader, ListLoader, Loader} from "../../../../components/Loaders";
import ErrorHandler, {AlertSize} from "../../../../components/ErrorHandler";
import Pagination from "../../../../components/common/Pagination";
import Card from "react-bootstrap/Card";
import UserPhoto from "../../../../components/common/UserPhoto";
import {DATETIME_FORMAT} from "../../../../config";
import moment from 'moment';
import {
    CommentCreateParams,
    CommentEditParams,
    DiscussionTopicComment,
    FullDiscussionTopic, FullAdminDiscussionTopic,
    RegisterParams, TopicCreateParams, TopicEditParams, ShortCourseUserGroup
} from "../../../../api/generated";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import RichContent from "../../../../components/common/RichContent";
import AsyncSelect from "react-select/async";
import {SelectOption} from "../../../../components/common/types";
import * as _ from "lodash";
import Select from "react-select";

const Editor = React.lazy(() => import("../../../../components/common/lazy/AdminCKEditor"));

type Props = {
    id?: string
    discussionId?: string
} & RouteComponentProps


type BreadcrumbsProps = {
    topic?: FullDiscussionTopic,
    error?: Error
}

function Breadcrumbs(props: BreadcrumbsProps) {
    const navigate = useNavigate()
    const {topic, error} = props

    const items = useMemo<Item[]>(() => {
        if (!topic) {
            return [];
        }

        const res = []
        res.push({title: 'Список курсов /', active: false, onClick: () => navigate("/admin")});
        res.push({
            title: topic.breadcrumbs.course.name + " /",
            active: false,
            onClick: () => navigate(`/admin/course/${topic.breadcrumbs.course.id}`)
        })
        if (topic.breadcrumbs.module && topic.breadcrumbs.module.id) {
            res.push( {
                title: topic.breadcrumbs.module.name + " /",
                active: false,
                onClick: () => navigate(`/admin/course/${topic.breadcrumbs.course.id}/module/${topic.breadcrumbs.module.id}`)
            })
        }
        res.push({
            title: topic.breadcrumbs.discussion.name + " /",
            active: false,
            onClick: () => navigate(`/admin/discussion/${topic.breadcrumbs.discussion.id}`)
        })
        res.push({title: topic.name, active: true});
        return res;
    }, [topic])

    if (error !== undefined) {
        return null
    }
    if (topic === undefined) {
        return <LineLoader/>
    }

    return <div className={'mt-4'}>
        <HorizontalScroll alignCenter={false} items={items}/>
    </div>
}


type CommentItemProps = {
    topicId: number,
    comment: DiscussionTopicComment
    offset: number
    onCommentChanged: () => void
    onCommentDelete: () => void
}

enum CommentItemMode {
    View,
    Edit,
    Comment
}

enum Mode {
    View,
    Edit,
}

type CommentCreateFormProps = {
    topicId: number,
    parentCommentId?: number
    onFinish: () => void,
    onCancel?: () => void,
}

function CommentCreateForm(props: CommentCreateFormProps) {
    const formValidationSchema = yup.object<CommentCreateParams>({
        text: yup.string().required("Вы должны написать комментарий")
    })

    const {register, handleSubmit, errors, setValue} = useForm<CommentCreateParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            parent_comment_id: props.parentCommentId,
            text: "",
        }
    });

    useEffect(() => {
        register("text")
        register("parent_comment_id")
    }, [])

    const [error, setError] = useState<Error | undefined>(undefined);
    const onSubmit = (params: CommentCreateParams) => {
        setError(undefined);
        ApiFactory.Instance().DiscussionTopicCommentAPI().adminCreateTopicComment(props.topicId, params)
            .then(val => {
                setValue("text", "")
                props.onFinish()
            })
            .catch(e => setError(e))
    };

    return <Form onSubmit={handleSubmit(onSubmit)}>
        {error && <ErrorHandler size={AlertSize.Small} error={error}/>}
        <Form.Group>
            <Suspense fallback={<Loader/>}>
                <Editor initialContent={""} onContentChange={text => setValue("text", text)}/>
            </Suspense>
            {errors.text && <Form.Text>{(errors.text as any)?.message}</Form.Text>}
        </Form.Group>
        <div className={'d-flex flex-row justify-content-end'}>
            <Button type={'submit'} variant={'primary'}
                    className={'text-white'}>Сохранить</Button>
            {props.onCancel &&
            <Button className={'ml-2'} variant={'light'} onClick={() => {
                setValue("text", "")
                if (props.onCancel) {
                    props.onCancel()
                }
            }}>Отменить</Button>
            }

        </div>
    </Form>
}

type CommentEditFormProps = {
    comment: DiscussionTopicComment,
    topicId: number,
    onFinish: () => void,
    onCancel: () => void,
}

function CommentEditForm(props: CommentEditFormProps) {
    const formValidationSchema = yup.object<CommentEditParams>({
        text: yup.string().required("Вы должны написать комментарий")
    })

    const {register, handleSubmit, errors, setValue} = useForm<CommentEditParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            text: props.comment.text,
        }
    });

    useEffect(() => {
        register("text")
    }, [])

    const [error, setError] = useState<Error | undefined>(undefined);
    const onSubmit = (params: CommentEditParams) => {
        setError(undefined);
        ApiFactory.Instance().DiscussionTopicCommentAPI().adminUpdateTopicComment(props.topicId, props.comment.id, params)
            .then(val => {
                props.onFinish()
            })
            .catch(e => setError(e))
    };

    return <Form onSubmit={handleSubmit(onSubmit)}>
        {error && <ErrorHandler size={AlertSize.Small} error={error}/>}
        <Form.Group>
            <Suspense fallback={<Loader/>}>
                <Editor initialContent={props.comment.text} onContentChange={text => setValue("text", text)}/>
            </Suspense>
            {errors.text && <Form.Text>{(errors.text as any)?.message}</Form.Text>}
        </Form.Group>
        <div className={'d-flex flex-row justify-content-end'}>
            <Button type={'submit'} variant={'primary'}
                    className={'text-white ml-2'}>Сохранить</Button>
            <Button className={'ml-2'} variant={'light'} onClick={props.onCancel}>Отменить</Button>
        </div>
    </Form>
}

function CommentItem(props: CommentItemProps) {
    const {userId} = useContext(AuthContext)
    const {comment, topicId, onCommentDelete} = props
    const offset = props.offset >= 6 ? props.offset : props.offset + 1
    const span = 12 - offset
    const [mode, setMode] = useState<CommentItemMode>(CommentItemMode.View)

    const onDelete = () => {
        if (window.confirm(`Вы действительно хотите удалить комментарий?`)) {
            ApiFactory.Instance().DiscussionTopicCommentAPI().adminDeleteComment(topicId, comment.id)
                .then(() => {
                    onCommentDelete()
                })
        }

    }

    return <>
        <Row className={'mt-4 mb-4'}>
            <Col xs={{span: span, offset: offset}}>
                <Card>
                    <Card.Body className={'d-flex flex-row justify-content-between'}>
                        <div>
                            <UserPhoto user={comment.created_by} className={'small-user-photo'}/>
                            <span className={'small ml-2'}>{comment.created_by.name}</span>
                        </div>
                        <div className={'d-flex flex-row'}>
                            <span className={'x-small-text'}>{moment(comment.created_at).format(DATETIME_FORMAT)}</span>
                            {comment.updated_at && <span
                                className={'x-small-text ml-2'}>Изменен: {moment(comment.updated_at).format(DATETIME_FORMAT)}</span>}
                        </div>
                    </Card.Body>
                    <Card.Body>
                        {(mode === CommentItemMode.View || mode === CommentItemMode.Comment) &&
                        <RichContent text={comment.text} asSpan={true}/>}
                        {mode === CommentItemMode.Comment && <>
                            <h6 className={'mt-2 mb-2'}>Добавление комментария</h6>
                            <CommentCreateForm
                                topicId={topicId}
                                parentCommentId={comment.id}
                                onCancel={() => setMode(CommentItemMode.View)}
                                onFinish={() => {
                                    setMode(CommentItemMode.View)
                                    props.onCommentChanged()
                                }}/>
                        </>
                        }
                        {mode === CommentItemMode.Edit && <CommentEditForm comment={comment} topicId={topicId}
                                                                           onCancel={() => setMode(CommentItemMode.View)}
                                                                           onFinish={() => {
                                                                               setMode(CommentItemMode.View)
                                                                               props.onCommentChanged()
                                                                           }}/>
                        }
                    </Card.Body>
                    {mode === CommentItemMode.View &&
                    <Card.Body className={'d-flex flex-row justify-content-end align-items-center'}>
                        <span className={'small pl-2 as-link discussion-comment-button'}
                              onClick={() => setMode(CommentItemMode.Comment)}>Ответить</span>
                        <span className={'small pl-2 as-link discussion-comment-button mr-2'}
                              onClick={() => setMode(CommentItemMode.Edit)}>Изменить</span>
                        <span className={'small pl-2 as-link discussion-comment-button mr-2 text-danger'}
                            onClick={() => onDelete()}>Удалить</span>

                    </Card.Body>
                    }
                </Card>
            </Col>
        </Row>
        {comment.comments && comment.comments.map(c => <CommentItem key={`comm-${c.id}`} comment={c} offset={offset}
                                                                    topicId={topicId}
                                                                    onCommentDelete={() => {
                                                                        props.onCommentDelete()
                                                                    }}
                                                                    onCommentChanged={() => {
                                                                        props.onCommentChanged()
                                                                    }}/>)}
    </>
}

type CommentListProps = {
    topicId: number,
}

function CommentList(props: CommentListProps) {
    const {userId} = useContext(AuthContext)
    const [currentPage, setCurrentPage] = useState<number>(0);
    const fetcher = (key: any, id: number, userId: any, currentPage: number) =>
        ApiFactory.Instance().DiscussionTopicCommentAPI().adminTopicComments(id, currentPage, DEFAULT_PER_PAGE)
    const {data: comments, error} = useSWR(['admin/discussion-topic-comments', props.topicId, userId, currentPage], fetcher)
    if (error !== undefined) {
        return <ErrorHandler error={error}/>
    }

    if (comments === undefined) {
        return <ListLoader/>
    }

    return <>
        {comments.items.map(comm => <CommentItem key={`comm-${comm.id}`} comment={comm} offset={0}
                                                 topicId={props.topicId}
                                                 onCommentDelete={() => {
                                                     mutate(['admin/discussion-topic-comments', props.topicId, userId, currentPage])
                                                 }}
                                                 onCommentChanged={() => {
                                                     mutate(['admin/discussion-topic-comments', props.topicId, userId, currentPage])
                                                 }}/>)}
        <h5 className={'mt-4 mb-4'}>Добавление комментария</h5>
        <CommentCreateForm topicId={props.topicId} onFinish={() => {
            mutate(['admin/discussion-topic-comments', props.topicId, userId, currentPage])
        }}/>
        <Pagination totalItems={comments.total} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
    </>
}

type EditTopicProps = {
    discussionId: number,
    topic: FullAdminDiscussionTopic,
    onFinish: () => void,
    onCancel: () => void,
}

function EditTopic(props: EditTopicProps) {
    const formValidationSchema = yup.object<TopicEditParams>({
        text: yup.string().required("Текст темы не может быть пустым"),
        name: yup.string().required("Название темы не может быть пустым"),
        private_user_ids: yup.array(),
        private_user_group_ids: yup.array(),
        is_available_in_my_portfolio: yup.boolean().required(),
        is_available_in_other_user_portfolio: yup.boolean().required(),
    })

    const [selectGroups, setSelectGroups] = useState<SelectOption[] | undefined>(undefined)
    const [availableGroups, setAvailableGroups] = useState<SelectOption[]|undefined>(undefined);

    useEffect(() => {
        if (props.topic.is_private) {
            ApiFactory.Instance().CourseUserGroupAPI().adminListCourseUserGroups(props.topic.course_id, 0, MAX_PER_PAGE)
                .then(resp => setAvailableGroups(resp.items.map(grp => ({value: "" + grp.id, label: grp.name}))))
                .catch(e => setError(e))
        }
        if (props.topic.is_private && props.topic.private_user_groups) {
            setValue("private_user_group_ids", props.topic.private_user_groups.map(u => u.id))
            setSelectGroups(props.topic.private_user_groups.map(u => ({value: "" + u.id, label: u.name})))
        }
    }, [props.topic])

    const {register, handleSubmit, errors, setValue} = useForm<TopicEditParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            name: props.topic.name,
            text: props.topic.text,
            is_available_in_my_portfolio: props.topic.is_available_in_my_portfolio,
            is_available_in_other_user_portfolio: props.topic.is_available_in_other_user_portfolio,
        }
    });

    const [selectUsers, setSelectUsers] = useState<SelectOption[] | undefined>(undefined)
    useEffect(() => {
        register("text")
        register("private_user_ids")
        register("private_user_group_ids")
    }, [])

    useEffect(() => {
        if (props.topic.is_private && props.topic.private_users) {
            setValue("private_user_ids", props.topic.private_users.map(u => u.id))
            setSelectUsers(props.topic.private_users.map(u => ({value: "" + u.id, label: u.name})))
        }

        if (props.topic.is_private && props.topic.private_user_groups) {
            setValue("private_user_group_ids", props.topic.private_user_groups.map(u => u.id))
            setSelectGroups(props.topic.private_user_groups.map(u => ({value: "" + u.id, label: u.name})))
        }

    }, [props.topic])

    const loadUsers = _.debounce((input: string, callback: (opts: SelectOption[]) => void) => {
        ApiFactory.Instance().DiscussionAPI().adminDiscussionUserList(props.discussionId, input, 0, DEFAULT_PER_PAGE)
            .then(resp => {
                callback(resp.items.map<SelectOption>(u => ({value: "" + u.id, label: u.name})))
            }).catch(e => {
            callback([])
        })
    }, 500)

    const [error, setError] = useState<Error | undefined>(undefined);
    const onSubmit = (params: TopicEditParams) => {
        setError(undefined);
        ApiFactory.Instance().DiscussionTopicAPI().adminUpdateDiscussionTopic(props.discussionId, props.topic.id, params)
            .then(val => {
                props.onFinish()
            })
            .catch(e => setError(e))
    };

    return <Form onSubmit={handleSubmit(onSubmit)}>
        {error && <ErrorHandler size={AlertSize.Small} error={error}/>}
        <Form.Group>
            <Form.Label>Название темы</Form.Label>
            <Form.Control name={"name"} ref={register}/>
            {errors.name && <Form.Text>{(errors.name as any)?.message}</Form.Text>}
        </Form.Group>
        <Form.Group>
            <Form.Label>Текст</Form.Label>
            <Suspense fallback={<Loader/>}>
                <Editor initialContent={props.topic.text} onContentChange={text => setValue("text", text)}/>
            </Suspense>
            {errors.text && <Form.Text>{(errors.text as any)?.message}</Form.Text>}
        </Form.Group>
        {props.topic.is_private &&
        <Form.Group>
            <Form.Label>Пользователи</Form.Label>
            <AsyncSelect value={selectUsers}
                         defaultOptions={true}
                         placeholder={'Выберите пользователей'}
                         loadOptions={loadUsers}
                         isMulti={true}
                         onChange={(v: any) => {
                             let val: SelectOption[] = v as SelectOption[]
                             setSelectUsers(val)
                             const newVal = val ? val.map(v => Number(v.value)) : [];
                             setValue("private_user_ids", newVal)
                         }}
            />
            {errors && errors.private_user_ids &&
            <Form.Text className={'text-danger'}>Некорректно выбраны пользователи</Form.Text>}
        </Form.Group>
        }
        {props.topic.is_private &&
        <Form.Group>
            <Form.Label>Группы пользователей</Form.Label>
            <Select value={selectGroups}
                    isMulti={true}
                    placeholder={'Выберите группу'}
                    onChange={(v: any) => {
                        const val = v as SelectOption[];
                        setSelectGroups(val)
                        const newVal = val.map(v => Number(v.value))
                        setValue("private_user_group_ids", newVal)
                    }}
                    options={availableGroups}
            />
            {errors && errors.private_user_group_ids &&
            <Form.Text
                className={'text-danger'}>{JSON.stringify(errors.private_user_group_ids)}</Form.Text>}
        </Form.Group>
        }

        <Form.Group>
            <Form.Check label={'Тема доступна в собственном портфолио пользователя'} type={'checkbox'} ref={register} name={"is_available_in_my_portfolio"}/>
            {errors && errors.is_available_in_my_portfolio &&
            <Form.Text className={'text-danger'}>{errors.is_available_in_my_portfolio.message}</Form.Text>}
        </Form.Group>
        <Form.Group>
            <Form.Check label={'Тема доступна в портфолио других пользователей'} type={'checkbox'} ref={register} name={"is_available_in_other_user_portfolio"}/>
            {errors && errors.is_available_in_other_user_portfolio &&
            <Form.Text className={'text-danger'}>{errors.is_available_in_other_user_portfolio.message}</Form.Text>}
        </Form.Group>

        <div className={'d-flex flex-row justify-content-end'}>
            <Button type={'submit'} variant={'primary'}
                    className={'text-white'}>Сохранить</Button>
            {props.onCancel &&
            <Button className={'ml-2'} variant={'light'} onClick={() => {
                setValue("text", "")
                if (props.onCancel) {
                    props.onCancel()
                }
            }}>Отменить</Button>
            }

        </div>
    </Form>
}

export default function Topic(props: Props) {
    const id = Number(props.id)
    const [mode, setMode] = useState<Mode>(Mode.View)
    const discussionId = Number(props.discussionId)
    const {userId} = useContext(AuthContext)
    const fetcher = () => ApiFactory.Instance().DiscussionTopicAPI().adminDiscussionTopic(discussionId, id);
    const key = ['admin/topic', id, userId]
    const [error, setError] = useState<Error|undefined>(undefined);
    const {data: topic, error: fetchError} = useSWR(key, fetcher)
    const navigate = useNavigate()

    useEffect(() => {
        if (fetchError !== undefined) {
            setError(fetchError)
        }
    }, [fetchError])

    if (Number.isNaN(id) || Number.isNaN(discussionId)) {
        return <ErrorHandler error={new Error("id or discussionId is not a number")}/>
    }

    const pin = () => {
        ApiFactory.Instance().DiscussionTopicAPI().adminPinDiscussionTopic(discussionId, id).then(() => {
            mutate(key)
        })
    }

    const unpin = () => {
        ApiFactory.Instance().DiscussionTopicAPI().adminUnpinDiscussionTopic(discussionId, id).then(() => {
            mutate(key)
        })
    }

    const onDelete = () => {
        if (topic === undefined) {
            return
        }
        if (window.confirm(`Вы действительно хотите удалить тему ${topic.name}`)) {
            ApiFactory.Instance().DiscussionTopicAPI().adminDeleteTopic(discussionId, topic.id)
                .then(() => {
                    navigate(`/admin/discussion/${discussionId}`)
                }).catch(e => setError(e))
        }
    }

    return (
        <ContentContainer>
            <Breadcrumbs error={error} topic={topic}/>
            <hr className={'hr--divider'}/>
            <Row>
                <Col xs={12}>
                    {error && <ErrorHandler error={error}/>}
                    {topic === undefined && error === undefined && <Loader/>}
                    {topic !== undefined &&
                    <Card className={'mt-4 mb-4'}>
                        {mode === Mode.Edit
                            ? <Card.Body>
                                <EditTopic discussionId={discussionId} topic={topic} onFinish={() => {
                                    setMode(Mode.View)
                                    mutate(key)
                                }} onCancel={() => {
                                    setMode(Mode.View)
                                }}/>
                            </Card.Body>
                            : <>
                                <Card.Body>
                                    <Row>
                                        <Col xs={12} md={8} className={'d-flex flex-row mt-2'}>
                                            {topic.pinned && <img src={"/images/pinned.svg"} className={'small-icon'}/>}
                                            <div>
                                                <h6 className={'mb-2'}>{topic.name}</h6>
                                                {topic.is_private && <>
                                                    <div className={'mb-4'}><img src={'/images/private.svg'} className={'small-icon'}/><small>Приватная тема</small></div>
                                                    {topic.private_users && topic.private_users.length > 0 && <div><small>Пользователи: {topic.private_users.map(u => u.name).join(", ")}</small></div>}
                                                    {topic.private_user_groups && topic.private_user_groups.length > 0 && <div><small>Группы: {topic.private_user_groups.map(u => u.name).join(", ")}</small></div>}
                                                </>}
                                            </div>
                                        </Col>
                                        <Col xs={12} md={4} className={'d-flex flex-row topic-counters mt-2'}>
                                            {topic.unread_count > 0 &&
                                            <div className={'d-flex flex-column align-items-center pl-2 pr-2'}>
                                                <span className={'text-danger'}>{topic.unread_count}</span>
                                                <span className={'x-small-text text-danger'}>новых комментариев</span>
                                            </div>
                                            }
                                            <div className={'d-flex flex-column align-items-center pl-2 pr-2'}>
                                                <span>{topic.view_count}</span>
                                                <span className={'x-small-text'}>просмотров</span>
                                            </div>
                                            <div className={'d-flex flex-column align-items-center pl-2 pr-2'}>
                                                <span>{topic.comment_count}</span>
                                                <span className={'x-small-text'}>комментариев</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Body>
                                    <RichContent text={topic.text}/>
                                </Card.Body>
                                {(topic.is_available_in_my_portfolio || topic.is_available_in_other_user_portfolio) &&
                                <Card.Body>
                                    {topic.is_available_in_my_portfolio && <div className={'small'}>Тема отображается в портфолио участника</div>}
                                    {topic.is_available_in_other_user_portfolio && <div className={'small'}>Тема отображается в портфолио других участников</div>}
                                </Card.Body>
                                }
                                <Card.Body className={'d-flex flex-row justify-content-between'}>
                                    <div>
                                        <UserPhoto user={topic.created_by} className={'small-user-photo'}/>
                                        <span className={'small ml-2'}>{topic.created_by.name}</span>
                                    </div>
                                    <div className={'d-flex flex-row align-items-center'}>
                                        <div className={'d-flex flex-row align-items-center mr-4'}>
                                            <span className={'as-link small discussion-comment-button'}
                                                  onClick={() => setMode(Mode.Edit)}>Изменить</span>
                                        </div>
                                        <div className={'d-flex flex-row align-items-center'}>
                                            <img src={'/images/pinned.svg'} className={'small-icon mr-2'}/>
                                            {topic.pinned
                                                ? <span className={'as-link discussion-comment-button small'}
                                                        onClick={unpin}>Удалить закрепление</span>
                                                : <span className={'as-link discussion-comment-button small'}
                                                        onClick={pin}>Закрепить</span>
                                            }
                                        </div>
                                        <div className={'d-flex flex-row align-items-center ml-4'}>
                                            <span className={'as-link small discussion-comment-button text-danger'}
                                                  onClick={() => onDelete()}>Удалить</span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </>
                        }
                    </Card>
                    }

                </Col>
            </Row>
            <CommentList topicId={id}/>
        </ContentContainer>
    )
}