import * as React from 'react';
import RichContent from "../../common/RichContent";
import {CourseSubmoduleCompletionData} from "../../../api/generated";

type Props = {
    completionData?: CourseSubmoduleCompletionData
    typeData?: any
}

export default function TextUploadResult(props: Props) {
    return <div className={'mt-4'}>
        {props.typeData && props.typeData.description && <div className={'content-card__text'}>
            <RichContent text={props.typeData.description}/>
        </div>}
        <div className={'m-4'}>
            {props.completionData && props.completionData.text ?
                <RichContent text={props.completionData.text as string}/> : ""}
        </div>
    </div>
}