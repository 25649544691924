import * as React from "react";
import {Suspense, useContext, useRef, useState} from "react";
import {AuthContext} from "../../auth/AuthContext";
import ApiFactory from "../../../api/ApiFactory";
import {mutate} from "swr";
import {Loader, PhotoLoader} from "../../Loaders";
import Button from "react-bootstrap/Button";
import {AdminFullCourseSubmodule} from "../../../api/generated";

const Player = React.lazy(() => import("../../common/lazy/Player"))

type UploadVideoProps = {
    submodule: AdminFullCourseSubmodule,
    setError: (e: Error) => void,
    onUpload: (url: string) => void,

}

export default function UploadVideo({submodule, setError, onUpload}: UploadVideoProps) {
    const fileInput = useRef<HTMLInputElement>(null)
    const [uploading, setUploading] = useState<boolean>(false);
    const [videoUrl, setVideoUrl] = useState<string>(submodule.course_submodule_type_data.content_url)
    const {userId} = useContext(AuthContext)
    const uploadVideo = () => {
        if (fileInput.current != undefined && fileInput.current.click != undefined) {
            fileInput.current.click()
        }

    }

    const handleUpload = (e: any) => {
        const target = e.target as HTMLInputElement
        if (target.files && target.files.length > 0) {
            setUploading(true)
            ApiFactory.Instance().CourseSubmoduleAPI().adminCourseSubmoduleUploadVideo(submodule.id, target.files[0])
                .then(resp => {
                    setVideoUrl(resp.url);
                    setUploading(false)
                    onUpload(resp.url);
                    mutate(["admin/course-submodule", submodule.id, userId])
                }).catch(e => {
                setUploading(false)
                setError(e)
            })
        }
    }

    return (
        <>
            {uploading
                ? <PhotoLoader/>
                : <div>
                    <Suspense fallback={<Loader/>}>
                        <Player fluid={true} aspectRatio={"16:9"} controls={true} autoplay={false} sources={[
                            {src: UPLOAD_BASE_PATH + videoUrl, type: 'video/mp4'}
                        ]}/>
                    </Suspense>
                    <Button className={'m-2 text-white'} onClick={uploadVideo}>Изменить</Button>
                </div>
            }
            <input ref={fileInput} type={'file'} hidden={true} onChange={handleUpload}/>
        </>

    )

}