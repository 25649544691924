import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import ErrorHandler from "../../../components/ErrorHandler";
import useSWR from "swr";
import {AuthContext} from "../../../components/auth/AuthContext";
import ApiFactory, {DEFAULT_PER_PAGE} from "../../../api/ApiFactory";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link, RouteComponentProps, useNavigate} from "@reach/router";
import Menu, {HorizontalMenu} from "../../../components/admin/Menu";
import CourseEdit from "../../../components/admin/CourseEdit";
import ModuleCreate from "../../../components/admin/ModuleCreate";
import {Loader} from "../../../components/Loaders";
import ModuleAdmin from "../../../components/admin/ModuleAdmin";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ContentContainer from "../../../components/common/ContentContainer";
import UserGroupList from "../../../components/admin/UserGroupList";
import DiscussionsList from "../../../components/admin/DiscussionsList";
import {DiscussionTypeEnum} from "../../../models/discussion";
import {ShortUserWithPhotoListResponse} from "../../../api/generated";
import User from "../../../components/common/User";
import Pagination from "../../../components/common/Pagination";

type Props = {
    id?: string,
    moduleId?: string,
    section?: string,
} & RouteComponentProps;

function ModuleEdit(props: Props) {
    const {userId} = useContext(AuthContext);
    const fetcher = () => ApiFactory.Instance().CourseModuleAPI().adminViewCourseModule(Number(props.moduleId))
    const {data: module, error, mutate} = useSWR(["admin/course-module", Number(props.moduleId), userId], fetcher)
    if (module === undefined) {
        return <Loader/>
    }
    return <ModuleAdmin module={module} courseId={Number(props.id)} onReorder={mutate}/>
}

enum Mode {
    View,
    ModuleEdit,
    AddModule,
    UserGroups,
    Discussions,
    Users
}

type CourseUsersProps = {
    courseId: number,
}

function CourseUsers(props: CourseUsersProps) {
    const {courseId} = props;
    const {userId} = useContext(AuthContext);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const fetcher = () => ApiFactory.Instance().CourseAPI().adminViewCourseUsers(courseId, currentPage, DEFAULT_PER_PAGE)
    const {data: users, error: error} = useSWR<ShortUserWithPhotoListResponse>(["admin/course-users", courseId, userId, currentPage], fetcher)

    if (error !== undefined) {
        return <ErrorHandler error={error}/>
    }

    if (users === undefined) {
        return <Loader/>
    }

    return <div className={'mt-4'}>
        {users.items.length > 0 && <>
            <h5>Пользователи, записавшиеся на курс</h5>
            {users.items.map(u => <User key={`teacher-${u.id}`} user={u} link={`/admin/user/${u.id}`}/>)}
            <div className={'d-flex direction-row justify-content-center'}>
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalItems={users.total}/>
            </div>
        </>}

    </div>
}

export default function View(props: Props) {
    const {id, moduleId} = props
    const navigate = useNavigate()
    const [mode, setMode] = useState<Mode>(moduleId ? Mode.ModuleEdit : Mode.View);
    const {userId} = useContext(AuthContext);

    useEffect(() => {
        if (props.section === "user-groups") {
            setMode(Mode.UserGroups);
        } else if (props.section === "discussions") {
            setMode(Mode.Discussions);
        } else if (props.section == "users") {
            setMode(Mode.Users)
        }
    }, [props.section])

    const fetcher = () => ApiFactory.Instance().CourseAPI().adminViewCourse(Number(id))
    const {data: course, error} = useSWR(["admin/course", Number(id), userId], fetcher)

    if (id === undefined) {
        return <ErrorHandler error={new Error("Id курса не передан")}/>
    }
    const initialModuleID = moduleId !== undefined ? Number(moduleId) : undefined
    const loading = course === undefined && error === undefined;
    return (
        <ContentContainer
            hasNabBottomMenu={true}
            renderNavBottomMenu={() => <HorizontalMenu
                initialModuleId={initialModuleID}
                course={course}
                loading={loading}
                onModuleAdd={() => setMode(Mode.AddModule)}
                onActiveModuleChanged={(mod) => {
                    navigate(`/admin/course/${id}/module/${mod?.id}`)
                }}/>}>
            {course
            && <Breadcrumb className={'course__breadcrumb'}>
                <Breadcrumb.Item><Link to={"/admin"}>Список курсов</Link></Breadcrumb.Item>
                <Breadcrumb.Item active={true}>{course.name}</Breadcrumb.Item>
            </Breadcrumb>
            }

            <>
                <Row>
                    <Col xs={12} md={3} className={'mt-4 d-none d-sm-block'}>
                        <Menu loading={course === undefined}
                              initialModuleId={moduleId !== undefined ? Number(moduleId) : undefined}
                              course={course}
                              onModuleSelect={mod => {
                                  if (navigate) {
                                      navigate(`/admin/course/${id}/module/${mod.id}`)
                                  }
                              }}
                              onModuleAdd={() => setMode(Mode.AddModule)}
                              onUserGroups={() => {
                                  if (navigate) {
                                      navigate(`/admin/course/${id}/user-groups`)
                                  }
                              }}

                              onUsers={() => {
                                  if (navigate) {
                                      navigate(`/admin/course/${id}/users`)
                                  }
                              }}
                              onDiscussions={() => {
                                  if (navigate) {
                                      navigate(`/admin/course/${id}/discussions`)
                                  }
                              }}
                        />
                    </Col>
                    <Col xs={12} md={9}>
                        {error && <ErrorHandler error={error}/>}
                        {loading && <Loader/>}
                        {course !== undefined &&
                        <>
                            {mode === Mode.AddModule && <ModuleCreate courseId={course.id}
                                                onSucceed={(mod) => {
                                                        setMode(Mode.ModuleEdit)
                                                        navigate(`/admin/course/${id}/module/${mod.id}`)
                                                    }}
                                                onCancel={() => setMode(moduleId ? Mode.ModuleEdit : Mode.View)}
                                />
                            }
                            {mode === Mode.ModuleEdit && <ModuleEdit {...props}/>}
                            {mode === Mode.View && <CourseEdit course={course}/>}
                            {mode === Mode.UserGroups && <UserGroupList courseId={course.id}/>}
                            {mode === Mode.Users && <CourseUsers courseId={course.id}/>}
                            {mode === Mode.Discussions && <DiscussionsList
                                discussionType={DiscussionTypeEnum.Course}
                                externalId={course.id}
                                onLoad={() => {}}
                            />}
                        </>
                        }
                    </Col>
                </Row>
            </>
        </ContentContainer>
    )
}