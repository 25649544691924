import * as React from 'react'
import ReactHtmlParser from "react-html-parser";

type Props = {
    text?: string
    asSpan?: boolean
}

export default function RichContent(props: Props) {
    if (props.text === undefined) {
        return null
    }
    if (props.asSpan) {
        return <span className={'ck-content clearfix'}>
            {ReactHtmlParser(props.text)}
        </span>
    }
    return <div className={'ck-content clearfix'}>
        {ReactHtmlParser(props.text)}
    </div>
}