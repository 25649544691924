import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import ContentLoader from "react-content-loader";

export const MenuLoader = () => {
    const containerRef = useRef<HTMLDivElement>(null)

    const [width, setWidth] = useState<number>(0)
    useEffect(() => {
            setWidth(containerRef.current ? containerRef.current.offsetWidth : 0)
        },
        [containerRef.current]
    )

    const loaderWidth = width
    const loaderHeight = loaderWidth / 1.5

    return <div ref={containerRef} className={'d-flex'}>
        <ContentLoader
            speed={1}
            width={loaderWidth}
            height={loaderHeight}
            viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="25" y="15" rx="5" ry="5" width={loaderWidth} height="10"/>
            <rect x="25" y="45" rx="5" ry="5" width={loaderWidth} height="10"/>
            <rect x="25" y="75" rx="5" ry="5" width={loaderWidth} height="10"/>
            <rect x="25" y="105" rx="5" ry="5" width={loaderWidth} height="10"/>
            <rect x="25" y="135" rx="5" ry="5" width={loaderWidth} height="10"/>
            <rect x="25" y="165" rx="5" ry="5" width={loaderWidth} height="10"/>
        </ContentLoader>
    </div>
}

export const PhotoLoader = () => {
    const containerRef = useRef<HTMLDivElement>(null)

    const [width, setWidth] = useState<number>(0)
    useEffect(() => {
            setWidth(containerRef.current ? containerRef.current.offsetWidth : 0)
        },
        [containerRef.current]
    )

    const loaderWidth = width
    const loaderHeight = loaderWidth / 1.33

    return <div ref={containerRef} className={'d-flex'}>
        <ContentLoader
            height={loaderHeight}
            width={loaderWidth}
            speed={1}
            viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="0" rx="0" ry="0" width={loaderWidth} height={loaderHeight}/>
        </ContentLoader>
    </div>
}

export const ListLoader = () => {
    const containerRef = useRef<HTMLDivElement>(null)

    const [width, setWidth] = useState<number>(0)
    useEffect(() => {
            setWidth(containerRef.current ? containerRef.current.offsetWidth : 0)
        },
        [containerRef.current]
    )

    const loaderWidth = width
    const loaderHeight = loaderWidth

    return <div ref={containerRef} className={'d-flex'}>
        <ContentLoader
            viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
            height={loaderHeight}
            width={loaderWidth}
            speed={1}
        >
            <rect x="0" y="10" rx="4" ry="4" width={loaderWidth / 5} height="200"/>
            <rect x={loaderWidth / 5 + 30} y="10" rx="4" ry="4" width={loaderWidth * 0.8} height="200"/>
            <rect x="0" y="240" rx="4" ry="4" width={loaderWidth / 5} height="200"/>
            <rect x={loaderWidth / 5 + 30} y="240" rx="4" ry="4" width={loaderWidth * 0.8} height="200"/>
            <rect x="0" y="470" rx="4" ry="4" width={loaderWidth / 5} height="200"/>
            <rect x={loaderWidth / 5 + 30} y="470" rx="4" ry="4" width={loaderWidth * 0.8} height="200"/>
        </ContentLoader>
    </div>
}

export const LineLoader = () => {
    const containerRef = useRef<HTMLDivElement>(null)

    const [width, setWidth] = useState<number>(0)
    useEffect(() => {
            setWidth(containerRef.current ? containerRef.current.offsetWidth : 0)
        },
        [containerRef.current]
    )

    const loaderWidth = width
    const loaderHeight = 24

    return <div ref={containerRef} className={'d-flex'}>
        <ContentLoader
            viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
            height={loaderHeight}
            width={loaderWidth}
            speed={1}
        >
            <rect x="5" y="5" rx="5" ry="5" width={loaderWidth} height="20"/>
        </ContentLoader>
    </div>
}

export const Loader = () => {
    const containerRef = useRef<HTMLDivElement>(null)

    const [width, setWidth] = useState<number>(0)
    useEffect(() => {
            setWidth(containerRef.current ? containerRef.current.offsetWidth : 0)
        },
        [containerRef.current]
    )

    const loaderWidth = width
    const loaderHeight = loaderWidth

    return <div ref={containerRef} className={'d-flex'}>
        <ContentLoader
            viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
            height={loaderHeight}
            width={loaderWidth}
            speed={1}
        >
            <rect x="0" y="10" rx="4" ry="4" width={loaderWidth} height="30"/>
            <rect x="0" y="70" rx="5" ry="5" width={loaderWidth} height="400"/>
        </ContentLoader>
    </div>
}

export const HeaderInfoLoader = () => {
    const containerRef = useRef<HTMLDivElement>(null)

    const [width, setWidth] = useState<number>(0)
    useEffect(() => {
            setWidth(containerRef.current ? containerRef.current.offsetWidth : 0)
        },
        [containerRef.current]
    )

    const loaderWidth = width
    const loaderHeight = loaderWidth / 5

    return <div ref={containerRef} className={'d-flex'}><ContentLoader
        height={loaderHeight}
        width={loaderWidth}
        speed={1}
        viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="53" y="14" rx="3" ry="3" width={loaderWidth} height="13"/>
        <rect x="53" y="30" rx="3" ry="3" width={loaderWidth} height="10"/>
        <rect x="67" y="30" rx="3" ry="3" width={loaderWidth} height="10"/>
        <rect x="0" y="53" rx="0" ry="0" width={loaderWidth} height="1"/>
        <rect x="219" y="146" rx="0" ry="0" width="0" height="0"/>
    </ContentLoader>
    </div>
}