import {PortfolioSection, PortfolioSubmodule} from "../../../api/generated";
import Card from "react-bootstrap/Card";
import {TypeIcon} from "../../submod/TypeIcon";
import CompletionResult from "../../submod/teacher/CompletionResult";
import * as React from "react";
import {ReactElement, useState} from "react";
import {Type} from "../../submod/types";
import useSWR from "swr";
import ApiFactory from "../../../api/ApiFactory";
import UserCompletionHistory from "./UserCompletionHistory";
import Collapse from "react-bootstrap/Collapse";
import SubmoduleCompletionStatusBadge from "../../common/SubmoduleCompletionStatusBadge";
import Points from "../../common/Points";

type Props = {
    userId: number;
    courseId: number;
    submodule: PortfolioSubmodule;
}

const historyTypes: {[key: number]: boolean} = {
    [Type.Sorting]: true,
    [Type.Ranging]: true,
    [Type.Discussion]: true,
    [Type.FileSend]: true,
    [Type.Test]: true,
    [Type.TextSend]: true,
}

export function Submodule({submodule, userId, courseId}: Props) {
    const [showVersion, setShowVersion] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState(false)
    return <Card className={'mt-4 mb-4'}>
        <Card.Body>
            <div className={'d-flex flex-row align-items-center justify-content-between'} onClick={() => setIsOpen(!isOpen)}>
                <div className={'d-flex flex-row align-items-center as-link'}>
                    <TypeIcon type={submodule.type.id}/>
                    <h6>{submodule.name}</h6>
                </div>
                <div className={'d-flex flex-row justify-content-between'}>
                    <div>
                        {submodule.completion &&
                        submodule.completion.status &&
                        <SubmoduleCompletionStatusBadge status={submodule.completion.status}/>}
                    </div>
                    <div>
                        {submodule.completion && submodule.completion.points && <Points points={submodule.completion.points}/>}
                    </div>
                </div>

            </div>

            <Collapse in={isOpen}>
                <div>
                    <CompletionResult
                        id={submodule.id}
                        name={submodule.name}
                        type={submodule.type}
                        typeData={submodule.course_submodule_type_data}
                        completionData={submodule.completion?.data}/>

                    {submodule.completion && submodule.completion.teacher_comment && <div>
                        <h6 className={'mt-4 mb-4'}>Комментарий преподавателя</h6>
                        <span>{submodule.completion.teacher_comment}</span>
                    </div>}
                    {submodule.type.id && historyTypes[submodule.type.id] && <>
                        <div className={'d-flex flex-row justify-content-end'}>
                            <small className={'as-link'} onClick={() => setShowVersion(!showVersion)}>
                                {showVersion ? 'Свернуть историю версий' : 'История версий'}
                            </small>
                        </div>
                        {showVersion && <>
                            <hr className={'hr--history'}/>
                            <UserCompletionHistory userId={userId} courseId={courseId} submodule={submodule}/>
                        </>}
                    </>
                    }
                </div>
            </Collapse>

        </Card.Body>

    </Card>
}
