import * as React from 'react';
import RichContent from "../../common/RichContent";
import Card from "react-bootstrap/Card";
import {PortfolioSubmodule, CourseSubmoduleCompletionData} from "../../../api/generated";
import {SubmoduleTypeData} from "../../common/types";

type Props = {
    typeData: any
    completionData?: CourseSubmoduleCompletionData
}

export default function RangingResult({typeData, completionData}: Props) {
    return <>
        {typeData.description &&
            <div className={'content-card__text'}>
                <RichContent text={typeData.description}/>
            </div>
        }

        {completionData &&
        <div className={'content-card__text'}>
            {completionData
            && completionData.variants
            && completionData.variants.map((v: string) => (<Card className={'mt-2 mb-2'}>
                <Card.Body className={'p-3'}>
                    <span>{v}</span>
                </Card.Body>
            </Card>))}
        </div>
        }

    </>
}