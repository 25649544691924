import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link, RouteComponentProps, useNavigate} from "@reach/router";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Menu from "../../../components/teacher/SubmoduleMenu";
import SubmoduleCompletionList from "../../../components/teacher/SubmoduleCompletionList";
import ContentContainer from "../../../components/common/ContentContainer";
import Button from "react-bootstrap/Button";
import ApiFactory from "../../../api/ApiFactory";
import {saveAs} from "file-saver";

type Props = {
    id?: string
    courseId?: string
    moduleId?: string
} & RouteComponentProps

export default function View(props: Props) {
    const {id, courseId, moduleId} = props;
    const navigate = useNavigate()

    return (
        <ContentContainer>
            <Breadcrumb className={'course__breadcrumb'}>
                <Breadcrumb.Item><Link to={"/teacher"}>Список курсов</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to={`/teacher/course/${courseId}`}>Вернуться к курсу</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to={`/teacher/course/${courseId}/module/${moduleId}`}>Вернуться к
                    модулю</Link></Breadcrumb.Item>
            </Breadcrumb>
            <hr className={'hr--divider'}/>
            <Row>
                <Col xs={12} md={3}>
                    <Menu
                        onSubmoduleClick={(subId) => {
                            navigate(`/teacher/course/${courseId}/module/${moduleId}/submodule/${subId}`)
                        }}
                        activeSubmoduleId={Number(id)}
                        courseId={Number(courseId)}
                        moduleId={Number(moduleId)}/>
                </Col>
                <Col xs={12} md={9}>
                    <SubmoduleCompletionList
                        courseId={Number(courseId)}
                        moduleId={Number(moduleId)}
                        submoduleId={Number(id)}/>

                </Col>
            </Row>
        </ContentContainer>
    )
}