import * as React from 'react'
import {ReactElement, useContext, useState} from 'react'
import {AdminShortCourse} from "../../api/generated";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ApiFactory, {DEFAULT_PER_PAGE} from "../../api/ApiFactory";
import {Link} from "@reach/router";
import {ListLoader} from "../Loaders";
import useSWR from "swr";
import {AuthContext} from "../auth/AuthContext";
import ErrorHandler from "../ErrorHandler";
import Badge from "react-bootstrap/Badge";
import {CourseStatus} from "../user/CoursesList";
import Pagination from "../common/Pagination";

export default function CoursesList() {
    const {userId} = useContext(AuthContext)
    const [currentPage, setCurrentPage] = useState<number>(0)

    const fetcher = () => ApiFactory.Instance().CourseAPI().adminCourseList(undefined, undefined, currentPage, DEFAULT_PER_PAGE)
    const {data: courses, error} = useSWR(["/admin/course", currentPage, userId], fetcher)

    if (error !== undefined) {
        return <ErrorHandler error={error}/>
    }

    return courses !== undefined ? <div>
            {courses.items.map(sch => <ShortCourse key={"" + sch.id} course={sch}/>)}
            <div className={'d-flex direction-row justify-content-center'}>
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalItems={courses.total}/>
            </div>
        </div>
        : <ListLoader/>
}

type ShortCourseProps = {
    course: AdminShortCourse
}

function ShortCourse(props: ShortCourseProps): ReactElement {
    const {course} = props;

    let statusClassname = ""
    switch (course.status?.id) {
        case CourseStatus.Available:
            statusClassname = 'text-success'
            break;
        case CourseStatus.Draft:
            statusClassname = 'text-warning'
            break;
        case CourseStatus.InArchive:
            statusClassname = 'text-danger'
            break;
    }

    return (
        <>
            <Row className={'m-2'}>
                <Col xs={12} md={4} lg={3}>
                    <img className={'img-fluid admin-course-list__img'}
                         src={course.photo ? UPLOAD_BASE_PATH + course.photo : '/images/no-image.png'}/>
                </Col>
                <Col xs={12} md={8} lg={9}>
                    <div className={"d-block d-md-none"}>
                        <Link to={"/admin/course/" + course.id}><h4>{course.name}</h4></Link>
                        <div>
                            {course.categories.map(cat => <Badge key={`course-category__badge-${cat.id}`}
                                                                 className={'course-category__badge text-white'}
                                                                 variant={'primary'} pill={true}>{cat.name}</Badge>)}
                        </div>
                        <span className={`small font-italic ${statusClassname}`}>{course.status?.name}</span>
                    </div>
                    <div className={"d-none d-md-block"}>
                        <div className={'d-flex flex-row justify-content-between'}>
                            <Link to={"/admin/course/" + course.id}><h4>{course.name}</h4></Link>
                            <span className={`small font-italic ${statusClassname}`}>{course.status?.name}</span>
                        </div>
                        {course.categories.map(cat => <Badge key={`course-category__badge-${cat.id}`}
                                                             className={'course-category__badge text-white'}
                                                             variant={'primary'} pill={true}>{cat.name}</Badge>)}
                    </div>
                    <Row>
                        <Col xs={12} md={6} className={'d-flex flex-column p-2'}>
                                <span>
                                    <img src={"/images/finished_users.svg"} className={'small-icon'}/>
                                    Пользователей прошло курс: <span
                                    className={'bold-text'}>{course.finished_users_count}</span>
                                </span>
                        </Col>
                        <Col xs={12} md={6} className={'d-flex flex-column p-2'}>
                                <span>
                                    <img src={"/images/wait_for_verification.svg"} className={'small-icon'}/>
                                    Заданий требует проверки: <span className={
                                    course.wait_for_task_verification_users_count > 0
                                        ? 'bold-text text-danger'
                                        : 'bold-text'}>{course.wait_for_task_verification_users_count}</span>
                                </span>
                        </Col>

                        <Col xs={12} md={6} className={'d-flex flex-column p-2'}>
                                <span>
                                    <img src={"/images/in_progress_users.svg"} className={'small-icon'}/>
                                    Пользователей проходит курс: <span
                                    className={'bold-text'}>{course.in_progress_users_count}</span>
                                </span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr className={'hr--divider'}/>
        </>

    )
}