import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import {UserFullCourseModule, UserFullCourseSection, UserShortCourseSubmodule} from "../../../api/generated";
import {LineLoader, MenuLoader} from "../../Loaders";
import ApiFactory from "../../../api/ApiFactory";
import useSWR from "swr";
import {AuthContext} from "../../auth/AuthContext";
import ErrorHandler from "../../ErrorHandler";
import {AvailabilityIcon} from "../../common/AvailabilityIcon";
import {TypeIcon} from "../TypeIcon";

type Props = {
    courseId: number;
    moduleId: number;
    onSubmoduleClick: (activeSubmoduleId: number) => void,
    activeSubmoduleId: number,
}

type SubmoduleItemProps = {
    sub: UserShortCourseSubmodule,
    scrollRef: React.MutableRefObject<HTMLDivElement | null>,
    isActive: boolean,
    onClick: () => void,
}

function HorizontalSubmoduleItem(props: SubmoduleItemProps) {
    const itemRef = useRef<HTMLDivElement | null>(null)
    const {sub, onClick, isActive, scrollRef} = props

    useEffect(() => {
        if (isActive) {
            if (scrollRef.current && itemRef.current) {
                const currentElementOffset = itemRef.current.offsetLeft
                const currentElementWidth = itemRef.current.clientWidth
                const windowWidth = window.innerWidth
                const offset = currentElementOffset - windowWidth / 2 + currentElementWidth / 2
                scrollRef.current.scrollTo({
                    behavior: "smooth",
                    top: 0,
                    left: offset,
                })
            }
        }
    }, [isActive])
    return <div
        ref={itemRef}
        onClick={onClick}
        className={'scroll-menu-item'}
        key={`course-menu__submodule-${sub.id}`}>
        <TypeIcon type={sub.type?.id}/>
        <span className={'course-menu__submodule--text ' + (isActive ? 'text-primary' : '')}>{sub.name}</span>
        <AvailabilityIcon model={sub}/>
    </div>
}

export function HorizontalMenu(props: Props) {
    const scrollRef = useRef<HTMLDivElement | null>(null)
    const {moduleId, courseId, activeSubmoduleId, onSubmoduleClick} = props
    const [activeSectionId, setActiveSectionId] = useState<number | undefined>(undefined);
    const fetcher = () => ApiFactory.Instance().CourseModuleAPI().userViewCourseModule(moduleId, courseId)
    const {data: module, error} = useSWR<UserFullCourseModule>(['user/course-module', moduleId, useContext(AuthContext).userId], fetcher)
    useEffect(() => {
        if (activeSubmoduleId && module && module.sections) {
            const actSection = module.sections.find(sec => {
                return sec.submodules?.some(sm => sm.id === activeSubmoduleId)
            })

            if (actSection !== undefined) {
                setActiveSectionId(actSection.id)
            }
        }
    }, [module, activeSubmoduleId])

    const loading = module === undefined;

    if (error) {
        return <ErrorHandler error={error}/>
    }

    const Submodules = ({section}: { section: UserFullCourseSection }) => {
        return <>
            {section.submodules && section.submodules.map(sub => <HorizontalSubmoduleItem
                onClick={() => onSubmoduleClick(sub.id)}
                isActive={sub.id === activeSubmoduleId}
                sub={sub} scrollRef={scrollRef}/>)}
        </>
    }

    const activeSection = module?.sections.find(s => s.id == activeSectionId)

    if (loading) {
        return <LineLoader/>
    }

    if (activeSection === undefined) {
        return null
    }

    return <div ref={scrollRef} className={'scroll-menu d-inline-block d-sm-none'}>
        <Submodules section={activeSection}/>
    </div>
}

export default function Menu({moduleId, courseId, onSubmoduleClick, activeSubmoduleId}: Props) {
    const [activeSectionId, setActiveSectionId] = useState<number | undefined>(undefined);
    const fetcher = () => ApiFactory.Instance().CourseModuleAPI().userViewCourseModule(moduleId, courseId)
    const {data: module, error} = useSWR<UserFullCourseModule>(['user/course-module', moduleId, useContext(AuthContext).userId], fetcher)
    useEffect(() => {
        if (activeSubmoduleId && module && module.sections) {
            const actSection = module.sections.find(sec => {
                return sec.submodules?.some(sm => sm.id === activeSubmoduleId)
            })

            if (actSection !== undefined) {
                setActiveSectionId(actSection.id)
            }
        }
    }, [module, activeSubmoduleId])

    const loading = module === undefined;

    if (error) {
        return <ErrorHandler error={error}/>
    }

    const Submodules = ({section}: { section: UserFullCourseSection }) => {
        return <div>
            {section.submodules && section.submodules.map(sub => {
                let submoduleClassName = 'course-menu__submodule d-flex align-items-center justify-content-between';
                let textStyle = 'course-menu__submodule--text'
                if (sub.id === activeSubmoduleId) {
                    textStyle += " text-primary"
                }
                return <div
                    onClick={() => {
                        onSubmoduleClick(sub.id);
                    }}
                    className={submoduleClassName}
                    key={`course-menu__submodule-${sub.id}`}>
                    <div className={'d-flex flex-row align-items-center'}>
                        <TypeIcon type={sub.type?.id}/>
                        <span className={textStyle}>{sub.name}</span>
                    </div>

                    <AvailabilityIcon model={sub}/>
                </div>
            })}
        </div>
    }

    return loading
        ? <MenuLoader/>
        : <Accordion activeKey={activeSectionId === undefined
            ? undefined
            : "" + activeSectionId
        } onSelect={(id) => setActiveSectionId(Number(id))}>
            {module && module.sections && module.sections.map(sec => <Card key={`course-menu__sec-${sec.id}`}>
                    <Card.Body className={'course-menu__module d-flex flex-row justify-content-between align-items-center'}
                               onClick={() => setActiveSectionId(sec.id)}>
                        <span><Accordion.Toggle as={'a'} eventKey={"" + sec.id}>
                            {sec.name}
                        </Accordion.Toggle></span>
                        <AvailabilityIcon model={sec}/>
                    </Card.Body>
                    <Accordion.Collapse eventKey={"" + sec.id}>
                        <Submodules section={sec}/>
                    </Accordion.Collapse>
                </Card>
            )}

        </Accordion>
}