import * as React from 'react';
import CommonMenu, {ModuleItemProps} from '../common/Menu';
import {TeacherCourseModule, TeacherFullCourse, UserCourseModule, UserFullCourse} from "../../api/generated";
import Card from "react-bootstrap/Card";
import Badge from 'react-bootstrap/Badge';
import CommonHorizontalMenu, {HorizontalModuleItemProps} from "../common/HorizontalMenu";
import {useEffect, useRef} from "react";

type TeacherModuleItemProps = {
    onModuleSelect: (mod: TeacherCourseModule) => void,
} & ModuleItemProps<TeacherCourseModule>

function ModuleItem({mod, isActive, onClick, onModuleSelect}: TeacherModuleItemProps) {
    const className = isActive ? 'text-primary' : undefined
    return (
        <Card.Body className={'course-menu__module d-flex flex-row justify-content-between align-items-center'}
                   onClick={() => {
                       onModuleSelect(mod);
                       onClick(mod)
                   }}>
            <span className={className}>{mod.name}</span>
            <span>
                {mod.wait_for_task_verification_users_count
                && mod.wait_for_task_verification_users_count > 0
                && <Badge variant={'primary text-white'}>{mod.wait_for_task_verification_users_count}</Badge>}
            </span>

        </Card.Body>
    )
}

type Props = {
    course?: TeacherFullCourse,
    loading: boolean,
    onModuleSelect: (mod: TeacherCourseModule) => void,
    initialModuleId?: number,
}

type HorizontalProps = {
    initialModuleId?: number
    course?: TeacherFullCourse,
    loading: boolean,
    onActiveModuleChanged: (mod: TeacherCourseModule | null) => void,
}

export default function Menu(props: Props) {

    return <CommonMenu
        aboutCourseLink={props.course ? `/teacher/course/${props.course.id}` : undefined}
        {...props}
        displayAboutCourse={true}
        onActiveModuleChanged={() => {
        }}
        renderModuleItem={(mod: TeacherCourseModule, isActive, onClick) => <ModuleItem mod={mod}
                                                                                       onModuleSelect={props.onModuleSelect}
                                                                                       isActive={isActive}
                                                                                       onClick={onClick}/>}
    />
}

function HorizontalModuleItem({mod, onClick, isActive, scrollRef}: HorizontalModuleItemProps<TeacherCourseModule>) {
    const itemRef = useRef<HTMLDivElement | null>(null)
    const className = isActive ? 'text-primary' : undefined
    useEffect(() => {
        if (isActive) {
            if (scrollRef.current && itemRef.current) {
                const currentElementOffset = itemRef.current.offsetLeft
                const currentElementWidth = itemRef.current.clientWidth
                const windowWidth = window.innerWidth
                const offset = currentElementOffset - windowWidth / 2 + currentElementWidth / 2
                scrollRef.current.scrollTo({
                    behavior: "smooth",
                    top: 0,
                    left: offset,
                })
            }
        }
    }, [isActive])

    return (
        <div ref={itemRef} className={'scroll-menu-item'} onClick={() => onClick(mod)}>
            <span className={className}>{mod.name}</span>
            <span>
                {mod.wait_for_task_verification_users_count
                && mod.wait_for_task_verification_users_count > 0
                && <Badge variant={'primary text-white'}>{mod.wait_for_task_verification_users_count}</Badge>}
            </span>
        </div>
    )
}


export function HorizontalMenu(props: HorizontalProps) {
    if (props.course === undefined) {
        return null
    }
    return <CommonHorizontalMenu {...props}
                                 displayAboutCourse={false}
                                 aboutCourseLink={props.course ? `/teacher/course/${props.course.id}` : undefined}
                                 renderModuleItem={(mod: TeacherCourseModule, isActive, onClick, scrollRef) =>
                                     <HorizontalModuleItem key={`navModuleItem-${mod.id}`} mod={mod} isActive={isActive}
                                                           onClick={onClick} scrollRef={scrollRef}/>}
    />
}