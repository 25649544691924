import * as React from 'react';
import {useContext, useState} from 'react';
import {AvailableFullCourse, ErrorMessage} from "../../api/generated";
import {AuthContext} from "../auth/AuthContext";
import Button from 'react-bootstrap/Button';
import LoadingButton from "../common/LoadingButton";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import ApiFactory from "../../api/ApiFactory";
import ErrorHandler from "../ErrorHandler";
import {useNavigate} from "@reach/router";
import CommonCourseHeader from '../common/CourseHeader';

type Props = {
    course?: AvailableFullCourse,
    loading: boolean
}

function UserCourseButton({course, setError}: { course?: AvailableFullCourse, setError: (err: Error | undefined) => void }) {
    const navigate = useNavigate()
    const {promiseInProgress: loading} = usePromiseTracker({area: "participate"})
    const {isLoggedIn} = useContext(AuthContext);
    if (course === undefined) {
        return null
    }
    if (!isLoggedIn) {
        return <span className={'course__not-logged-alert'}>
            <img className={'course__not-logged-alert-icon'} src={'/images/small_alert_outlined.svg'}/>
            Для участия войдите в аккаунт
        </span>
    }

    if (course.is_participated) {
        return <div className={'d-flex flex-column m-4'}>
            <Button variant={'primary'} className={'text-white'} onClick={() =>
                navigate(`/user/course/${course!.id}`)
            }>Перейти к курсу</Button>
            <span className={'small text-center'}>Вы уже зарегистрированы на курс</span>
        </div>
    }

    const onParticipate = () => {
        setError(undefined);
        trackPromise(ApiFactory.Instance().CourseAPI().participateCourse(course.id), "participate")
            .then(() => {
                navigate(`/user/course/${course!.id}`)
            })
            .catch(e => {
                const resp = e as Response
                resp.json()
                    .then((j: ErrorMessage) => setError(new Error(j.message)))
                    .catch(e => setError(e))
            })
    };

    return <div className={'d-flex flex-column m-4'}>
        <LoadingButton loading={loading} variant={'primary'} className={'text-white'}
                       onClick={() => onParticipate()}>Записаться</LoadingButton>
        <span className={'small text-center'}>Обучение бесплатное</span>
    </div>
}

export default function CourseHeader({course, loading}: Props) {
    const [error, setError] = useState<Error | undefined>(undefined)
    if (error !== undefined) {
        return <ErrorHandler error={error}/>
    }
    return <CommonCourseHeader loading={loading} course={course}
                               right={<UserCourseButton course={course} setError={setError}/>}/>
}