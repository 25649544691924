import * as React from 'react';
import {CourseSubmoduleType, CourseSubmoduleCompletionData, UserShortCourseSubmodule} from "../../../api/generated";
import ErrorHandler from "../../ErrorHandler";
import {Type} from "../types"
import SortingResult from "../completion/SortingResult";
import {SubmoduleTypeData, VideoSubmoduleTypeData} from "../../common/types";
import DiscussionResult from "./DiscussionResult";
import RangingResult from "../completion/RangingResult";
import FileUploadResult from "../completion/FileUploadResult";
import TestResult from "../completion/TestResult";
import TextResult from "../completion/TextResult";
import TextUploadResult from "../completion/TextUploadResult";
import VideoResult from "../completion/VideoResult";

type Props = {
    id: number;
    name: string;
    type: CourseSubmoduleType;
    typeData: SubmoduleTypeData;
    completionData?: CourseSubmoduleCompletionData;
}

export default function CompletionResult({id, name, typeData, completionData, type}: Props) {
    if (type === undefined) {
        return <ErrorHandler error={new Error("Тип подмодуля неизвестен")}/>
    }
    const submoduleType = type.id as Type;
    switch (submoduleType) {
        case Type.Sorting:
            return <SortingResult typeData={typeData} completionData={completionData}/>
        case Type.Discussion:
            return <DiscussionResult typeData={typeData}/>
        case Type.Ranging:
            return <RangingResult typeData={typeData} completionData={completionData}/>
        case Type.FileSend:
            return <FileUploadResult name={name} completionData={completionData}/>
        case Type.Test:
            return <TestResult submoduleId={id} completionData={completionData}/>
        case Type.Text:
            return <TextResult typeData={typeData}/>
        case Type.TextSend:
            return <TextUploadResult completionData={completionData}/>
        case Type.Video:
            return <VideoResult typeData={typeData as VideoSubmoduleTypeData}/>
    }
    return null;
}
