import * as React from 'react';
import {ReactElement} from 'react';
import Card from "react-bootstrap/Card";
import {AvailableFullCourse, UserFullCourse} from "../../api/generated";
import ContentCard from "./ContentCard";
import {Loader} from "../Loaders";
import User from "./User";
import {DiscussionTypeEnum} from "../../models/discussion";
import DiscussionsCollapse from "../user/DiscussionsCollapse";
import CourseParticipants from "../user/CourseParticipants";

type Props = {
    course?: AvailableFullCourse | UserFullCourse,
    loading: boolean,
    header?: ReactElement
}

export default function CourseContent({course, loading, header}: Props) {
    return loading
        ? <Loader/>
        : <>
            {header}
            {course && <DiscussionsCollapse externalId={course.id} type={DiscussionTypeEnum.Course}/>}
            {course && course.description && <ContentCard title={course.name} text={course.description}/>}

            {course && course.teachers && course.teachers.length > 0 &&
            <Card className={'course__card'}>
                <Card.Body>
                    <h5 className={'text-primary'}>Преподаватели</h5>
                    {course.teachers.map(t => <User key={`teacher-${t.id}`} user={t}/>)}
                </Card.Body>
            </Card>}

            {course && "completion" in course && <CourseParticipants courseId={course.id}/>}

        </>
}