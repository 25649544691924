import * as React from 'react';
import {AvailableCourseModule, AvailableCourseSection} from "../../api/generated";
import Card from 'react-bootstrap/Card';
import HtmlParser from 'react-html-parser'

type Props = {
    module: AvailableCourseModule
}

function SectionContent({section}: { section: AvailableCourseSection }) {
    return <Card style={{marginTop: '3rem'}}>
        <Card.Body className={'p-4'}>
            <h5>{section.name}</h5>

            {section.description && <div>
                {HtmlParser(section.description)}
            </div>}
        </Card.Body>
    </Card>
}

export default function ModuleContent({module}: Props) {
    return <>
        <h3>{module.name}</h3>
        {
            module.sections.map(sec => <SectionContent key={`sec-${sec.id}`} section={sec}/>)
        }
    </>
}