import * as React from 'react';
import {useEffect, useRef} from 'react';
import {AvailableFullCourse, CourseModule} from "../../api/generated";
import Card from "react-bootstrap/Card";
import CommonMenu, {ModuleItemProps} from '../common/Menu';
import CommonHorizontalMenu, {HorizontalModuleItemProps} from "../common/HorizontalMenu";

type Props = {
    course?: AvailableFullCourse,
    loading: boolean,
    initialModuleId?: number,
    onActiveModuleChanged: (mod: CourseModule | null) => void,
}


function ModuleItem({mod, onClick, isActive}: ModuleItemProps<CourseModule>) {
    const className = isActive ? 'text-primary' : undefined
    return (
        <Card.Body className={'course-menu__module'} onClick={() => onClick(mod)}>
            <span className={className}>{mod.name}</span>
        </Card.Body>
    )
}

export default function Menu(props: Props) {
    return (
        <CommonMenu
            {...props}
            displayAboutCourse={true}
            aboutCourseLink={props.course ? `/course/${props.course.id}` : undefined}
            renderModuleItem={(mod, isActive, onClick) =>
                <ModuleItem mod={mod} isActive={isActive} onClick={onClick}/>}
        />
    )
}


function HorizontalModuleItem({mod, onClick, isActive, scrollRef}: HorizontalModuleItemProps<CourseModule>) {
    const itemRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (isActive) {
            if (scrollRef.current && itemRef.current) {
                const currentElementOffset = itemRef.current.offsetLeft
                const currentElementWidth = itemRef.current.clientWidth
                const windowWidth = window.innerWidth
                const offset = currentElementOffset - windowWidth / 2 + currentElementWidth / 2
                scrollRef.current.scrollTo({
                    behavior: "smooth",
                    top: 0,
                    left: offset,
                })
            }
        }
    }, [isActive])

    return (
        <div ref={itemRef} className={'scroll-menu-item'} onClick={() => onClick(mod)}>
            <span className={isActive ? 'text-primary' : undefined}>{mod.name}</span>
        </div>
    )
}


export function HorizontalMenu(props: Props) {
    if (props.course === undefined) {
        return null
    }
    return <CommonHorizontalMenu {...props}
                                 displayAboutCourse={true}
                                 aboutCourseLink={props.course ? `/course/${props.course.id}` : undefined}
                                 renderModuleItem={(mod: CourseModule, isActive, onClick, scrollRef) => {
                                     return <HorizontalModuleItem key={`navModuleItem-${mod.id}`} mod={mod}
                                                                  isActive={isActive} onClick={onClick}
                                                                  scrollRef={scrollRef}/>
                                 }}
    />
}
