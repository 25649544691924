import * as React from 'react';
import {Props, useContext} from 'react';
import {mutate, SWRConfig} from "swr";
import {AuthContext} from "./auth/AuthContext";
import ApiFactory from "../api/ApiFactory";
import Auth from "./auth/Auth";


export default function SWRWrapper(props: Props<any>) {
    const {logout, setShowModal, setLoggedIn} = useContext(AuthContext);

    const sendRefreshToken = () => {
        const refreshToken = Auth.Instance().getRefreshToken()
        const isLoggedIn = Auth.Instance().isLoggedIn()
        if (refreshToken !== undefined && isLoggedIn) {
            return ApiFactory.Instance().AuthAPI().authRefreshToken({
                refresh_token: refreshToken,
            })
        } else {
            return Promise.reject(new Error("refreshToken not set"))
        }
    }
    return <SWRConfig value={{
        revalidateOnFocus: false,
        onError: (error, key, config) => {
            const isLoggedIn = Auth.Instance().isLoggedIn()
            if (error.status === 401 && isLoggedIn) {
                sendRefreshToken().then(tokensResp => {
                    setLoggedIn(tokensResp)
                    mutate(key)
                }).catch(e => {
                    logout();
                    setShowModal(true)
                })
            }
        }
    }}>
        {props.children}
    </SWRConfig>
}