import * as React from 'react'
import ApiFactory from "../../api/ApiFactory";
import {useNavigate} from "@reach/router";
import {AdminFullCourse} from "../../api/generated";
import {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

type Props = {
    show: boolean,
    onHide: () => void,
    course: AdminFullCourse
}

export default function CourseCloneModal(props: Props) {
    const {course, show, onHide} = props
    const navigate = useNavigate()
    const [error, setError] = useState<Error | undefined>(undefined);
    const [withDiscussions, setWithDiscussions] = useState<boolean>(false);
    const [withTopics, setWithTopics] = useState<boolean>(false);
    const [withComments, setWithComments] = useState<boolean>(false);
    const [withTeachers, setWithTeachers] = useState<boolean>(false);
    const [withAdmins, setWithAdmins] = useState<boolean>(false);
    const cloneCourse = () => {
        setError(undefined)
        const params = {
            source_id: course.id,
            with_discussions: withDiscussions,
            with_discussion_topics: withTopics,
            with_topic_comments: withComments,
            with_teachers: withTeachers,
            with_admins: withAdmins,
        }
        ApiFactory.Instance().CourseAPI().cloneCourse(params)
            .then(cloned => {
                onHide()
                navigate(`/admin/course/${cloned.id}`)
            })
            .catch(e => setError(e))
    }

    return <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton={true}>
            <Modal.Title>Перенести дополнительные данные</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group>
                <Form.Check type="checkbox" label="Преподавателей" checked={withTeachers}
                            onChange={() => setWithTeachers(!withTeachers)}/>
            </Form.Group>
            <Form.Group>
                <Form.Check type="checkbox" label="Администраторов" checked={withAdmins}
                            onChange={() => setWithAdmins(!withAdmins)}/>
            </Form.Group>
            <Form.Group>
                <Form.Check type="checkbox" label="Обсуждения" checked={withDiscussions}
                            onChange={() => setWithDiscussions(!withDiscussions)}/>
            </Form.Group>
            <Form.Group>
                <Form.Check type="checkbox" label="Темы обсуждений" checked={withTopics}
                            onChange={() => setWithTopics(!withTopics)}/>
            </Form.Group>
            <Form.Group>
                <Form.Check type="checkbox" label="Комментарии к темам" checked={withComments}
                            onChange={() => setWithComments(!withComments)}/>
            </Form.Group>
        </Modal.Body>
        <Modal.Footer className={'d-flex flex-row justify-content-end'}>
            <Button variant={'primary'} className={'ml-2 text-white'} onClick={cloneCourse}>Продолжить</Button>
            <Button variant={'danger'} className={'ml-2'} onClick={onHide}>Отмена</Button>
        </Modal.Footer>
    </Modal>
}