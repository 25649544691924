import {FullUser} from "../../api/generated";
import UserPhoto from "./UserPhoto";
import * as React from "react";
import {Link} from "@reach/router";

type Props = {
    user: FullUser
    showAbout?: boolean
    link?: string
}

export default function User({user, showAbout, link}: Props) {
    return <div className={'d-flex flex-row p-2 align-items-center'}>
        <UserPhoto user={user} className={'rounded-circle mr-2 teacher__img'}/>
        <div className={'flex-fill'}>
            <h6>{link
                ? <Link to={link}>{user.name}</Link>
                : user.name
            }</h6>
            {showAbout && user.about && <>
                <hr className={'hr__divider'}/>
                <span className={'teacher--about'}>{user.about}</span>
            </>}
        </div>

    </div>
}

User.defaultProps = {
    showAbout: true
}