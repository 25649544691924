import {DiscussionTypeEnum} from "../../models/discussion";
import {useNavigate} from "@reach/router";
import * as React from "react";
import {useState} from "react";
import ApiFactory, {MAX_PER_PAGE} from "../../api/ApiFactory";
import useSWR from "swr";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import ListGroup from "react-bootstrap/ListGroup";

type DiscussionsCollapseProps = {
    externalId: number;
    type: DiscussionTypeEnum;
}

export default function DiscussionsCollapse({externalId, type}: DiscussionsCollapseProps) {
    const navigate = useNavigate()
    const [listOpen, setListOpen] = useState<boolean>(false);

    const fetcher = type === DiscussionTypeEnum.Module
        ? () => ApiFactory.Instance().DiscussionAPI().userModuleDiscussions(externalId, undefined, 0, MAX_PER_PAGE)
        : () => ApiFactory.Instance().DiscussionAPI().userCourseDiscussions(externalId, undefined, 0, MAX_PER_PAGE)

    const {data: discussions} = useSWR(() => {
        return ['/user/discussions', externalId, type]
    }, fetcher)
    if (!discussions || discussions.total == 0) {
        return null;
    }

    return <Card className={'mt-4'}>
        <Card.Body
            className={'d-flex flex-row align-items-center justify-content-between'}>
            <h5>Обсуждения</h5>
            <div className={'d-flex flex-column align-items-center'}>
                <Button variant={'primary'}
                        className={'text-white'}
                        aria-controls={"discussion-list"}
                        aria-expanded={listOpen}
                        onClick={() => {
                            setListOpen(!listOpen)
                        }}
                >Подробнее</Button>
                <span className={'x-small-text pt-1'}>Всего обсуждений: {discussions.total}</span>
            </div>
        </Card.Body>
        <Collapse in={listOpen}>
            <ListGroup variant={"flush"} id={"discussion-list"}>
                {discussions.items.map(disc => <ListGroup.Item key={`disc-${disc.id}`}
                                                               className={'p-4 d-flex flex-row align-items-center justify-content-between as-link'}
                                                               onClick={() => {
                                                                   navigate(`/user/discussion/${disc.id}`)
                                                               }}>
                    <div>
                        <h6>{disc.name}</h6>
                        {disc.is_private &&
                        <small><img src={'/images/private.svg'} className={'small-icon'}/>Личное обсуждение</small>}
                    </div>
                    <div className={'d-flex flex-row'}>
                        <div className={'d-flex flex-column align-items-center pl-2 pr-2'}>
                            <span>{disc.topic_count}</span>
                            <span className={'x-small-text'}>тем</span>
                        </div>
                        <div className={'d-flex flex-column align-items-center pl-2 pr-2'}>
                            <span>{disc.comment_count}</span>
                            <span className={'x-small-text'}>комментариев</span>
                        </div>
                    </div>
                </ListGroup.Item>)}
            </ListGroup>
        </Collapse>
    </Card>
}