import * as React from 'react'
import {useMemo} from 'react'
import useSWR from "swr";
import ApiFactory from "../../../api/ApiFactory";
import {LineLoader, Loader} from "../../../components/Loaders";
import ContentContainer from "../../../components/common/ContentContainer";
import {RouteComponentProps, useNavigate} from "@reach/router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Portfolio, PortfolioCourse, PortfolioModule} from "../../../api/generated";
import HorizontalScroll from "../../../components/common/HorizontalScroll";
import ErrorHandler from "../../../components/ErrorHandler";
import {Module} from "../../../components/portfolio/user/Module";
import {Menu} from "../../../components/portfolio/Menu";
import {Header} from "../../../components/portfolio/Header";
import UserDiscussionTopicList from "../../../components/portfolio/user/UserDiscussionTopicList";
import ListGroup from "react-bootstrap/ListGroup";
import InfoMessage, {Level} from "../../../components/common/InfoMessage";

type Props = {
    completionId?: string
} & RouteComponentProps


type BreadcrumbsProps = {
    course: PortfolioCourse,
}

function Breadcrumbs(props: BreadcrumbsProps) {
    const navigate = useNavigate()
    const {course} = props

    return <div className={'mt-4'}>
        <HorizontalScroll alignCenter={false} items={[
            {title: 'Список курсов /', active: false, onClick: () => navigate("/")},
            {
                title: course.name + " /",
                active: false,
                onClick: () => navigate(`/user/course/${course.id}`)
            },
            {title: "Портфолио", active: true},
        ]}/>
    </div>
}


export default function MyPortfolio(props: Props) {
    const completionId = Number(props.completionId);
    const fetcher = () => ApiFactory.Instance().PortfolioAPI().myPortfolio(completionId)
    const {data: portfolio, error} = useSWR<Portfolio>(['user/portfolio', completionId], fetcher)

    const modules = useMemo<PortfolioModule[]>(() => {
        if (portfolio === undefined || portfolio.modules === undefined) {
            return []
        }
        return portfolio.modules.filter(m => {
            const hasSubmodules = m.sections && m.sections.find(s => !!s.submodules) !== undefined
            const hasDiscussions = m.discussion_topics && m.discussion_topics.length > 0
            return hasSubmodules || hasDiscussions
        })
    }, [portfolio])

    return <ContentContainer>
        {error && <ErrorHandler error={error}/>}
        {portfolio
            ? <Breadcrumbs course={portfolio.course}/>
            : <LineLoader/>
        }

        <hr className={'hr--divider'}/>
        <Row>
            <Col xs={12} md={3} className={'d-none d-sm-block'}>
                <Menu items={modules}/>
            </Col>
            <Col xs={12} md={9}>
                {portfolio && <Header portfolio={portfolio}/>}
                {portfolio
                    ? <>
                        <h4 id={`course-content`}>Выполненные задания</h4>
                        {modules.length == 0 && <InfoMessage level={Level.Info} message={"Пока нет заданий для отображения в портфолио"}/>}
                        <ListGroup variant={"flush"} id={"content-list"}>
                            <ListGroup.Item>
                                {modules.map(m => <Module module={m} courseId={portfolio!.course.id}/>)}
                            </ListGroup.Item>
                        </ListGroup>

                        <h4 id={`course-discussions`}>Участие в обсуждениях</h4>

                        {(!portfolio.discussion_topics || portfolio.discussion_topics.length == 0)
                            && <InfoMessage level={Level.Info} message={"Пока нет обсуждений для отображения в портфолио"}/>}
                        {portfolio.discussion_topics && <>

                            <UserDiscussionTopicList topics={portfolio.discussion_topics}/>
                        </>}
                    </>
                    : <Loader/>
                }
            </Col>
        </Row>
    </ContentContainer>
}