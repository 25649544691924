import * as React from 'react';
import {useEffect, useRef, useState, Suspense} from 'react';
import {
    AdminFullCourseSubmodule,
    AdminQuizAnswer,
    AdminQuizQuestion,
    ErrorMessage,
    SubmoduleQuizAnswerCreateParams,
    SubmoduleQuizAnswerEditParams,
    SubmoduleQuizQuestionCreateParams,
    SubmoduleQuizQuestionEditParams
} from "../../../api/generated";
import useSWR from "swr";
import ApiFactory from "../../../api/ApiFactory";
import ErrorHandler from "../../ErrorHandler";
import {Loader} from "../../Loaders";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import Select from "react-select";
import Col from 'react-bootstrap/Col';
import RichContent from "../../common/RichContent";

const Editor = React.lazy(() => import("../../common/lazy/CKEditor"));


type SelectOption = { value: string, label: string };

enum QuestionTypeEnum {
    Single = 1,
    Multiple = 2,
    UserDefined = 3
}

enum Mode {
    View,
    Edit
}

type Props = {
    submodule: AdminFullCourseSubmodule,
}

type AddQuestionModalProps = {
    show: boolean
    setShow: (show: boolean) => void
}

export default function TestSubmoduleView(props: Props) {
    const [addQuestionShow, setAddQuestionShow] = useState<boolean>(false)

    const fetcher = () => ApiFactory.Instance().CourseSubmoduleAPI().adminCourseSubmoduleQuiz(props.submodule.id)
    const {data: quiz, error, mutate} = useSWR(['admin/course-submodule/quiz', props.submodule.id], fetcher)
    if (error) {
        return <ErrorHandler error={error}/>
    }
    if (quiz === undefined) {
        return <Loader/>
    }

    return <>
        {quiz.questions.map((q, i) => <Question key={`quiz-${q.id}`} mutate={mutate} question={q} index={i}
                                                submoduleId={props.submodule.id}/>)}
        {addQuestionShow
            ?
            <QuestionCreate mutate={mutate} submoduleId={props.submodule.id} onCancel={() => setAddQuestionShow(false)}
                            onSuccess={() => setAddQuestionShow(false)}/>
            : <div className={'d-flex flex-row pl-4 align-items-center justify-content-center as-link'}
                   onClick={() => setAddQuestionShow(true)}>
                <span>Добавить вопрос  <img src={"/images/add.svg"} className={'small-icon m-2'}/></span>
            </div>
        }
    </>
}

type QuestionProps = {
    question: AdminQuizQuestion,
    index: number,
    submoduleId: number
    mutate: () => void,
}

const formValidationSchema = yup.object<SubmoduleQuizQuestionEditParams>({
    question: yup.string().required("Вопрос должен быть заполнен").max(1000),
    points: yup.number().required("Количество баллов должно быть заполнено"),
    quiz_question_type_id: yup.number().required("Тип вопроса должен быть заполнен"),
    is_fixed_points: yup.boolean().required(),
    is_user_comment_required: yup.boolean().required(),
})

function Question({question, index, submoduleId, mutate}: QuestionProps) {
    const [mode, setMode] = useState<Mode>(Mode.View);
    const [showAddAnswer, setShowAddAnswer] = useState<boolean>(false)

    const [error, setError] = useState<Error | undefined>(undefined);
    const [questionText, setQuestionText] = useState<string>(question.question || "")
    const [availableTypes, setAvailableTypes] = useState<SelectOption[]>([]);
    const [selectedTypeId, setSelectedTypeId] = useState<SelectOption>({
        value: "" + question.type.id,
        label: question.type.name
    });

    const form = useRef<HTMLFormElement>(null)

    useEffect(() => {
        ApiFactory.Instance().StaticAPI().staticGet(["quiz_question_type"])
            .then(resp => {
                if (resp.quiz_question_type === undefined) {
                    setAvailableTypes([])
                    return
                }
                setAvailableTypes(resp.quiz_question_type.map<SelectOption>(t => ({value: "" + t.id, label: t.name})))
            }).catch(e => {
        })
    }, [])

    const {register, handleSubmit, errors, setValue} = useForm<SubmoduleQuizQuestionEditParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            question: question.question,
            points: question.points || 0,
            quiz_question_type_id: question.type.id,
            is_user_comment_required: question.is_user_comment_required,
            is_fixed_points: question.is_fixed_points,
        }
    });
    useEffect(() => {
        register("quiz_question_type_id");
        register("question");
    }, [])

    if (mode == Mode.View) {
        return <Card className={'m-4'}>
            <Card.Body>
                {error && <ErrorHandler error={error}/>}
                <div className={'d-flex flex-row justify-content-between align-items-center'}>
                    <div>
                        <h6>Вопрос №{index + 1} - {question.points ? question.points : 0} балл(ов)</h6>
                    </div>
                    <div>
                        <small>{question.type.name}</small>
                        <img onClick={() => {
                            if (window.confirm(`Вы действительно хотите удалить вопрос ${question.id}`)) {
                                ApiFactory.Instance().CourseSubmoduleAPI().deleteSubmoduleQuizQuestion(submoduleId, question.id)
                                    .then(() => {
                                        mutate()
                                    })
                            }
                        }} src={"/images/delete.svg"} className={'button-icon as-link ml-2'}/>
                        <img className={'button-icon ml-2 as-link'} src={"/images/edit.svg"}
                             onClick={() => setMode(Mode.Edit)}/>
                    </div>
                </div>

                <div className={'mt-4'}>
                    <RichContent text={question.question}/>
                </div>

                <div className={'mt-4'}>
                    {question.answers && question.answers.map(ans => <>
                        <div key={`answer-${ans.id}`}
                             className={'d-flex flex-row align-items-center m-2'}>
                            <img className={'button-icon'}
                                 src={ans.is_correct ? '/images/correct.svg' : '/images/wrong.svg'}/>
                            <RichContent asSpan={true} text={ans.answer}/>
                        </div>
                        <small className={'text-muted'}>{ans.description}</small>
                        <hr className={'hr--no-divider'}/>
                    </>)}
                </div>
            </Card.Body>
        </Card>
    }

    const onSubmit = (params: SubmoduleQuizQuestionEditParams) => {
        ApiFactory.Instance().CourseSubmoduleAPI().editSubmoduleQuizQuestion(submoduleId, question.id, params)
            .then(resp => {
                mutate()
                setMode(Mode.View)
            }).catch(e => {
            const resp = e as Response
            resp.json()
                .then((j: ErrorMessage) => setError(new Error(j.message)))
                .catch(e => setError(e))
        })
    }

    return <Card className={'m-4'}>
        <Card.Body>
            {error && <ErrorHandler error={error}/>}
            <Form ref={form} onSubmit={(e) => {
                handleSubmit(onSubmit)(e);
            }}>
                <div className={'d-flex flex-row align-items-center justify-content-end'}>
                    <img className={'button-icon ml-4 as-link'} src={"/images/cancel.svg"}
                         onClick={() => setMode(Mode.View)}/>
                    <img className={'button-icon ml-4 as-link'} src={"/images/save.svg"} onClick={() => {
                        if (form.current !== null) {
                            form.current.dispatchEvent(new Event('submit', {cancelable: true}))
                        }
                    }}/>
                </div>
                <Form.Group>
                    <Form.Label>Текст вопроса</Form.Label>
                    <Suspense fallback={<Loader/>}>
                        <Editor initialContent={questionText} onContentChange={text => {
                            setQuestionText(text)
                            setValue("question", text)
                        }}/>
                    </Suspense>

                    {errors && errors.question &&
                    <Form.Text className={'text-danger'}>{(errors.question as any)?.message}</Form.Text>}
                </Form.Group>
                <Form.Row>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>Тип вопроса</Form.Label>
                            <Select value={selectedTypeId}
                                    placeholder={'Выберите тип вопроса'}
                                    onChange={(v: any) => {
                                        const val = v as SelectOption;
                                        setSelectedTypeId(val)
                                        const newVal = Number(v.value)
                                        setValue("quiz_question_type_id", newVal)
                                    }}
                                    options={availableTypes}
                            />
                            {errors && errors.quiz_question_type_id &&
                            <Form.Text className={'text-danger'}>{errors.quiz_question_type_id.message}</Form.Text>}
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>Количество баллов</Form.Label>
                            <Form.Control ref={register} name="points" type={'number'}/>
                            {errors && errors.points &&
                            <Form.Text className={'text-danger'}>{errors.points.message}</Form.Text>}
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Group>
                    <Form.Check label={"Необходим комментарий пользователя при ответе"} type={"checkbox"} name={"is_user_comment_required"} ref={register}/>
                    {errors && errors.is_user_comment_required &&
                    <Form.Text
                        className={'text-danger'}>{errors.is_user_comment_required.message}</Form.Text>}
                </Form.Group>
                <Form.Group>
                    <Form.Check label={"Начислять фиксированное число баллов"} type={"checkbox"} name={"is_fixed_points"} ref={register}/>
                    {errors && errors.is_fixed_points &&
                    <Form.Text
                        className={'text-danger'}>{errors.is_fixed_points.message}</Form.Text>}
                </Form.Group>
            </Form>

            {question.answers && question.answers.map(ans =>
                <AnswerRowForm mutate={mutate} answer={ans} submoduleId={submoduleId} questionId={question.id}/>)}

            {showAddAnswer
                ? <AnswerCreateRowForm mutate={mutate} submoduleId={submoduleId} questionId={question.id}
                                       onSuccess={() => setShowAddAnswer(false)}
                                       onCancel={() => setShowAddAnswer(false)}/>
                : <div className={'d-flex flex-row mt-4 justify-content-center as-link'}
                       onClick={() => setShowAddAnswer(true)}>
                    <span>Добавить ответ  <img src={"/images/add.svg"} className={'small-icon m-2'}/></span>
                </div>
            }
        </Card.Body>
    </Card>
}

const quizFormValidationSchema = yup.object<SubmoduleQuizQuestionCreateParams>({
    question: yup.string().required("Вопрос должен быть заполнен").max(1000),
    points: yup.number().required("Количество баллов должно быть заполнено"),
    quiz_question_type_id: yup.number().required("Тип вопроса должен быть заполнен"),
    is_fixed_points: yup.boolean().required(),
    is_user_comment_required: yup.boolean().required(),
})

function QuestionCreate({submoduleId, onSuccess, onCancel, mutate}: { submoduleId: number, onSuccess: () => void, onCancel: () => void, mutate: () => void }) {
    const [error, setError] = useState<Error | undefined>(undefined);
    const [questionText, setQuestionText] = useState<string>("")
    const [availableTypes, setAvailableTypes] = useState<SelectOption[]>([]);
    const [selectedTypeId, setSelectedTypeId] = useState<SelectOption | undefined>(undefined);

    const form = useRef<HTMLFormElement>(null)

    useEffect(() => {
        ApiFactory.Instance().StaticAPI().staticGet(["quiz_question_type"])
            .then(resp => {
                if (resp.quiz_question_type === undefined) {
                    setAvailableTypes([])
                    return
                }
                setAvailableTypes(resp.quiz_question_type.map<SelectOption>(t => ({value: "" + t.id, label: t.name})))
            }).catch(e => {
        })
    }, [])

    const {register, handleSubmit, errors, setValue} = useForm<SubmoduleQuizQuestionCreateParams>({
        resolver: yupResolver(quizFormValidationSchema),
    });
    useEffect(() => {
        register("quiz_question_type_id");
        register("question");
    }, [])

    const onSubmit = (params: SubmoduleQuizQuestionEditParams) => {
        ApiFactory.Instance().CourseSubmoduleAPI().createSubmoduleQuizQuestion(submoduleId, params)
            .then(resp => {
                mutate()
                onSuccess()
            }).catch(e => {
            const resp = e as Response
            resp.json()
                .then((j: ErrorMessage) => setError(new Error(j.message)))
                .catch(e => setError(e))
        })
    }

    return <Card className={'m-4'}>
        <Card.Body>
            {error && <ErrorHandler error={error}/>}
            <Form ref={form} onSubmit={(e) => {
                handleSubmit(onSubmit)(e);
            }}>
                <div className={'d-flex flex-row align-items-center justify-content-end'}>
                    <img className={'button-icon ml-4 as-link'} src={"/images/cancel.svg"}
                         onClick={() => onCancel()}/>
                    <img className={'button-icon ml-4 as-link'} src={"/images/save.svg"} onClick={() => {
                        if (form.current !== null) {
                            form.current.dispatchEvent(new Event('submit', {cancelable: true}))
                        }
                    }}/>
                </div>
                <Form.Group>
                    <Form.Label>Текст вопроса</Form.Label>
                    <Suspense fallback={<Loader/>}>
                        <Editor initialContent={questionText} onContentChange={text => {
                            setQuestionText(text)
                            setValue("question", text)
                        }}/>
                    </Suspense>
                    {errors && errors.question &&
                    <Form.Text className={'text-danger'}>{(errors.question as any)?.message}</Form.Text>}
                </Form.Group>
                <Form.Row>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>Тип вопроса</Form.Label>
                            <Select value={selectedTypeId}
                                    placeholder={'Выберите тип вопроса'}
                                    onChange={(v: any) => {
                                        const val = v as SelectOption;
                                        setSelectedTypeId(val)
                                        const newVal = Number(v.value)
                                        setValue("quiz_question_type_id", newVal)
                                    }}
                                    options={availableTypes}
                            />
                            {errors && errors.quiz_question_type_id &&
                            <Form.Text className={'text-danger'}>{errors.quiz_question_type_id.message}</Form.Text>}
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>Количество баллов</Form.Label>
                            <Form.Control ref={register} name="points" type={'number'}/>
                            {errors && errors.points &&
                            <Form.Text className={'text-danger'}>{errors.points.message}</Form.Text>}
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Group>
                    <Form.Check label={"Необходим комментарий пользователя при ответе"} type={"checkbox"} name={"is_user_comment_required"} ref={register}/>
                    {errors && errors.is_user_comment_required &&
                    <Form.Text
                        className={'text-danger'}>{errors.is_user_comment_required.message}</Form.Text>}
                </Form.Group>
                <Form.Group>
                    <Form.Check label={"Начислять фиксированное число баллов"} type={"checkbox"} name={"is_fixed_points"} ref={register}/>
                    {errors && errors.is_fixed_points &&
                    <Form.Text
                        className={'text-danger'}>{errors.is_fixed_points.message}</Form.Text>}
                </Form.Group>
            </Form>
        </Card.Body>
    </Card>
}

const answerFormValidationSchema = yup.object<SubmoduleQuizAnswerEditParams>({
    answer: yup.string().required("Необходимо заполнить текст ответа"),
    is_correct: yup.boolean().required(),
    description: yup.string(),
})

function AnswerRowForm({answer, submoduleId, questionId, mutate}: { answer: AdminQuizAnswer, submoduleId: number, questionId: number, mutate: () => void }) {

    const form = useRef<HTMLFormElement>(null)
    const [mode, setMode] = useState<Mode>(Mode.View);
    const [error, setError] = useState<Error | undefined>(undefined);

    const fill = (answer: AdminQuizAnswer) => ({
        answer: answer.answer,
        is_correct: answer.is_correct,
        description: answer.description,
        result_description: answer.result_description,
    })

    const {register, handleSubmit, errors, setValue, reset} = useForm<SubmoduleQuizAnswerEditParams>({
        resolver: yupResolver(answerFormValidationSchema),
        defaultValues: fill(answer)
    });

    useEffect(() => {
        reset(fill(answer))
    }, [answer])

    if (mode == Mode.View) {
        return <>
            <div
                className={'d-flex flex-row align-items-center mt-4 mb-r justify-content-between'}>
                <div className={'d-flex flex-row'}>
                    <img className={'button-icon'}
                         src={answer.is_correct ? '/images/correct.svg' : '/images/wrong.svg'}/>
                    <RichContent text={answer.answer} asSpan={true}/>
                </div>
                <div className={'d-flex flex-row align-items-center'}>
                    <img onClick={() => {
                        if (window.confirm(`Вы действительно хотите удалить ответ ${answer.answer}`)) {
                            ApiFactory.Instance().CourseSubmoduleAPI().deleteSubmoduleQuizAnswer(submoduleId, questionId, answer.id)
                                .then(() => {
                                    mutate()
                                })
                        }
                    }} src={"/images/delete.svg"} className={'button-icon as-link'}/>
                    <div>
                        <img className={'button-icon ml-4 as-link'} src={"/images/edit.svg"}
                             onClick={() => setMode(Mode.Edit)}/>
                    </div>
                </div>
            </div>
            <small className={'text-muted'}>{answer.description}</small>
            <hr className={'hr--no-divider'}/>
        </>
    }

    const onSubmit = (params: SubmoduleQuizAnswerEditParams) => {
        ApiFactory.Instance().CourseSubmoduleAPI().editSubmoduleQuizAnswer(submoduleId, questionId, answer.id, params)
            .then(resp => {
                mutate()
                setMode(Mode.View)
            })
            .catch(e => {
                const resp = e as Response
                resp.json()
                    .then((j: ErrorMessage) => setError(new Error(j.message)))
                    .catch(e => setError(e))
            })
    }

    return <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
        {error && <ErrorHandler error={error}/>}
        <Form.Row className={'mt-4 mb-4 align-items-center'}>
            <Col xs={6}>
                <Form.Control ref={register} name={"answer"} type={'text'}/>
                {errors && errors.answer &&
                <Form.Text className={'text-danger'}>{(errors.answer as any)?.message}</Form.Text>}
            </Col>
            <Col xs={6} className={'d-flex flex-row justify-content-between'}>
                <div className={'d-flex flex-row'}><Form.Check ref={register} name={"is_correct"}
                                                               type={'checkbox'}/> Верный ответ
                </div>
                <img className={'button-icon ml-4 as-link'} src={"/images/save.svg"}
                     onClick={() => {
                         if (form.current) {
                             form.current.dispatchEvent(new Event('submit', {cancelable: true}))
                         }
                     }}/>
            </Col>
            <Col xs={12} className={'mt-2'}>
                <Form.Label>Описание</Form.Label>
                <Form.Control as={'textarea'} ref={register} name={"description"} type={'text'}/>
                {errors && errors.description &&
                <Form.Text className={'text-danger'}>{(errors.description as any)?.message}</Form.Text>}
            </Col>

            <Col xs={12} className={'mt-2'}>
                <Form.Label>Описание в результате (отображается пользователю в результате прохождения теста)</Form.Label>
                <Form.Control as={'textarea'} ref={register} name={"result_description"} type={'text'}/>
                {errors && errors.result_description &&
                <Form.Text className={'text-danger'}>{(errors.result_description as any)?.message}</Form.Text>}
            </Col>

        </Form.Row>
        <hr/>
    </Form>

}

const createAnswerFormValidationSchema = yup.object<SubmoduleQuizAnswerCreateParams>({
    answer: yup.string().required("Необходимо заполнить текст ответа"),
    is_correct: yup.boolean().required(),
    description: yup.string(),
})

function AnswerCreateRowForm({submoduleId, questionId, onSuccess, onCancel, mutate}: {
    submoduleId: number, questionId: number, onSuccess: () => void, onCancel: () => void, mutate: () => void
}) {
    const form = useRef<HTMLFormElement>(null)
    const [error, setError] = useState<Error | undefined>(undefined);

    const {register, handleSubmit, errors, setValue} = useForm<SubmoduleQuizAnswerCreateParams>({
        resolver: yupResolver(createAnswerFormValidationSchema),
        defaultValues: {
            answer: "",
            is_correct: false,
            description: "",
        }
    });

    const onSubmit = (params: SubmoduleQuizAnswerCreateParams) => {
        ApiFactory.Instance().CourseSubmoduleAPI().createSubmoduleQuizAnswer(submoduleId, questionId, params)
            .then(resp => {
                mutate()
                onSuccess()
            })
            .catch(e => {
                const resp = e as Response
                resp.json()
                    .then((j: ErrorMessage) => setError(new Error(j.message)))
                    .catch(e => setError(e))
            })
    }

    return <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
        {error && <ErrorHandler error={error}/>}
        <Form.Row className={'mt-4 mb-4 align-items-center'}>
            <Col xs={6}>
                <Form.Control ref={register} name={"answer"} type={'text'}/>
                {errors && errors.answer &&
                <Form.Text className={'text-danger'}>{(errors.answer as any)?.message}</Form.Text>}
            </Col>
            <Col xs={6} className={'d-flex flex-row justify-content-between'}>
                <div className={'d-flex flex-row'}><Form.Check ref={register} name={"is_correct"}
                                                               type={'checkbox'}/> Верный ответ
                </div>
                <div className={'d-flex flex-row'}>
                    <img className={'button-icon ml-4 as-link'} src={"/images/cancel.svg"}
                         onClick={onCancel}/>
                    <img className={'button-icon ml-4 as-link'} src={"/images/save.svg"}
                         onClick={() => {
                             if (form.current) {
                                 form.current.dispatchEvent(new Event('submit', {cancelable: true}))
                             }
                         }}/>
                </div>
            </Col>
            <Col xs={12} className={'mt-2'}>
                <Form.Label>Описание</Form.Label>
                <Form.Control as={'textarea'} ref={register} name={"description"} type={'text'}/>
                {errors && errors.description &&
                <Form.Text className={'text-danger'}>{(errors.description as any)?.message}</Form.Text>}
            </Col>
            <Col xs={12} className={'mt-2'}>
                <Form.Label>Описание в результате (отображается пользователю в результате прохождения теста)</Form.Label>
                <Form.Control as={'textarea'} ref={register} name={"result_description"} type={'text'}/>
                {errors && errors.result_description &&
                <Form.Text className={'text-danger'}>{(errors.result_description as any)?.message}</Form.Text>}
            </Col>
        </Form.Row>
        <hr/>
    </Form>

}