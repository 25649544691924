import * as React from 'react';
import {ReactElement, useContext, useEffect, useState, Suspense} from 'react';
import {UserFullCourseSubmodule} from "../../api/generated";
import Card from 'react-bootstrap/Card'
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import ApiFactory from "../../api/ApiFactory";
import {mutate} from "swr";
import {isStatusPositive, SubmoduleCompletionStatus} from "../submod/types";
import {AuthContext} from "../auth/AuthContext";
import ErrorHandler from "../ErrorHandler";
import {Loader} from "../Loaders";
import RichContent from "../common/RichContent";

const Editor = React.lazy(() => import("../common/lazy/CKEditor"));

type Props = {
    submodule: UserFullCourseSubmodule,
    courseId: number
    courseCompletionId: number
}

type TypeData = {
    description: string
}

type FormData = {
    text: string
}

const formValidationSchema = yup.object<FormData>({
    text: yup.string().max(10000).required("Необходимо заполнить текст задания"),
})

export default function TextSendSubmodule(props: Props) {
    const {submodule, courseCompletionId, courseId} = props
    const [displayForm, setDisplayForm] = useState<boolean>(false)
    const {userId} = useContext(AuthContext);
    const [error, setError] = useState<Error | undefined>(undefined);

    const {register, handleSubmit, errors, setValue} = useForm<FormData>({
        resolver: yupResolver(formValidationSchema)
    });

    useEffect(() => {
        register("text")
    }, [])

    const onSubmit = (params: FormData) => {
        if (props.submodule.completion === undefined) {
            setError(new Error('нет доступных прохождений'))
            return
        }

        ApiFactory.Instance().CourseSubmoduleCompletionAPI().attemptCourseSubmoduleCompletion(
            props.courseId,
            props.courseCompletionId,
            props.submodule.id,
            props.submodule.completion.id,
            params,
        ).then(() => {
            mutate(['user/course-submodule', submodule.id, userId])
            setDisplayForm(false)
        })
            .catch(e => setError(e))
    }

    if (!displayForm) {
        let content: ReactElement | null = null;
        if (!submodule.completion) {
            content = <Button variant={'primary text-white mt-4'} onClick={() => {
                ApiFactory.Instance().CourseSubmoduleCompletionAPI().userCreateCourseSubmoduleCompletion(courseId, courseCompletionId, submodule.id)
                    .then(() => {
                        mutate(['user/course-submodule', submodule.id, userId])
                        setDisplayForm(true)
                    }).catch(e => setError(e))
            }}>Перейти к отправке текста</Button>
        } else if (isStatusPositive(submodule.completion.status.id)) {
            content = <>
                <span><img src={'/images/finished.png'} className={'small-icon'}/>Ваш предыдущий результат: {submodule.completion.points} балла(ов)</span>
                {submodule.completion.teacher_comment &&
                <div className={'mt-2'}>
                    <div><small>Комментарий преподавателя:</small></div>
                    <RichContent text={submodule.completion.teacher_comment}/>
                </div>
                }
                <div>
                    <Button variant={'primary text-white mt-4'} onClick={() => {
                        ApiFactory.Instance().CourseSubmoduleCompletionAPI().userCreateCourseSubmoduleCompletion(courseId, courseCompletionId, submodule.id, true)
                            .then(() => {
                                mutate(['user/course-submodule', submodule.id, userId])
                                setDisplayForm(true)
                            }).catch(e => setError(e))
                    }}>Отправить текст заново</Button>
                </div>
            </>
        } else if (submodule.completion.status.id == SubmoduleCompletionStatus.InProgress) {
            content = <Button variant={'primary text-white  mt-4'} onClick={() => {
                setDisplayForm(true)
            }}>Продолжить</Button>
        } else if (submodule.completion.status.id == SubmoduleCompletionStatus.WaitForReview) {
            content = <span className={'text-danger'}>Ожидает проверки</span>
        } else if (submodule.completion.status.id == SubmoduleCompletionStatus.Declined) {
            content = <div>
                <span className={'text-danger'}>Предыдущая отправка была отклонена</span>
                <div className={'mt-2'}>
                    <div><small>Комментарий преподавателя:</small></div>
                    <RichContent text={submodule.completion.teacher_comment}/>
                </div>
                <div>
                    <Button variant={'primary text-white mt-4'} onClick={() => {
                        ApiFactory.Instance().CourseSubmoduleCompletionAPI().userCreateCourseSubmoduleCompletion(courseId, courseCompletionId, submodule.id)
                            .then(() => {
                                mutate(['user/course-submodule', submodule.id, userId])
                                setDisplayForm(true)
                            }).catch(e => setError(e))
                    }}>Отправить текст заново</Button>
                </div>
            </div>
        }
        return <Card className={'m-4'}>
            <Card.Body>
                {error && <ErrorHandler error={error}/>}
                <h5 className={'text-primary'}>
                    {submodule.name}
                </h5>
                {content}
            </Card.Body>
        </Card>
    }


    const typeData = props.submodule.course_submodule_type_data as TypeData
    return <Card className={'m-4'}>
        <Card.Body>
            {error && <ErrorHandler error={error}/>}
            <div className={'content-card__text'}>
                <RichContent text={typeData.description}/>
            </div>

            <div className={'content-card__text'}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label>Текст</Form.Label>
                        <Suspense fallback={<Loader/>}>
                            <Editor initialContent={""}
                                    onContentChange={text => {
                                        setValue("text", text)
                                    }}/>
                        </Suspense>

                        {errors && errors.text &&
                        <Form.Text
                            className={'text-danger'}>{errors.text.message}</Form.Text>}
                    </Form.Group>
                    <div className={'d-flex flex-row justify-content-end mt-4'}>
                        <Button variant={'primary text-white'} type={'submit'}>Отправить на проверку</Button>
                    </div>
                </Form>
            </div>
        </Card.Body>
    </Card>
}