import {PortfolioSection} from "../../../api/generated";
import * as React from "react";
import {Submodule} from "./Submodule";
import {ReactElement, useState} from "react";
import Collapse from "react-bootstrap/Collapse";

type SectionProps = {
    section: PortfolioSection;
    courseId: number;
    userId: number
}

export function Section({section,courseId, userId}: SectionProps) {
    const [open, setOpen] = useState(false);
    return <div className={'mt-4'}>
        <h6 className={'as-link'} onClick={() => setOpen(!open)}>{section.name}</h6>
        <Collapse in={open}>
            <div>
                {section.submodules && section.submodules.map(s =><Submodule submodule={s} courseId={courseId} userId={userId}/>)}
            </div>
        </Collapse>

    </div>
}