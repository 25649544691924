import * as React from 'react'
import {ReactElement, useContext, useState} from 'react'
import {AvailableShortCourse} from "../../api/generated";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ApiFactory, {DEFAULT_PER_PAGE} from "../../api/ApiFactory";
import {Link} from "@reach/router";
import {ListLoader} from "../Loaders";
import useSWR from "swr";
import {AuthContext} from "../auth/AuthContext";
import ErrorHandler from "../ErrorHandler";
import Badge from "react-bootstrap/Badge";
import Pagination from "../common/Pagination";

export default function CoursesList() {
    const {userId} = useContext(AuthContext)
    const [currentPage, setCurrentPage] = useState<number>(0)

    const fetcher = () => ApiFactory.Instance().CourseAPI().courseList(undefined, currentPage, DEFAULT_PER_PAGE)
    const {data: courses, error} = useSWR(["/available/course", userId, currentPage], fetcher)

    if (error !== undefined) {
        return <ErrorHandler error={error}/>
    }

    return courses !== undefined ? <div>
            {courses.items.map(sch => <ShortCourse key={"" + sch.id} course={sch}/>)}
            <div className={'d-flex direction-row justify-content-center'}>
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalItems={courses.total}/>
            </div>
        </div>
        : <ListLoader/>
}

type ShortCourseProps = {
    course: AvailableShortCourse
}

function ShortCourse(props: ShortCourseProps): ReactElement {
    const {course} = props;
    return (
        <>
            <Row className={'m-4'}>
                <Col xs={12} md={4} lg={3}>
                    <img className={'img-fluid admin-course-list__img'}
                         src={course.photo ? UPLOAD_BASE_PATH + course.photo : '/images/no-image.png'}/>
                </Col>
                <Col xs={12} md={8} lg={9} className={" d-flex justify-content-between flex-column"}>
                    <div className={'p-4'}>
                        <Link to={course.is_participated
                            ? "/user/course/" + course.id
                            : "/course/" + course.id}><h4>{course.name}</h4></Link>
                        {course.categories.map(cat => <Badge key={`course-category__badge-${cat.id}`}
                                                             className={'course-category__badge text-white'}
                                                             variant={'primary'} pill={true}>{cat.name}</Badge>)}
                    </div>

                    <div>
                        {course.is_participated && <span
                            className={'float-right course-list__already_participated_hint'}>Вы уже записаны на курс</span>}
                    </div>
                </Col>
            </Row>
            <hr className={'hr--divider'}/>
        </>

    )
}