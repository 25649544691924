import * as React from 'react';
import {Col, Row} from 'react-bootstrap'
import {PROJECT_NAME} from "../config";

export default function Footer() {
    return (
        <footer>
            <Row className={'mx-auto'}>
                <Col className={'d-none d-sm-block'} lg={{span: 3, offset: 6}}>
                    <h5 className={'column-header'}>{PROJECT_NAME}</h5>
                    <div className={'link-container'}><a href='#'>О проекте</a></div>
                    <div className={'link-container'}><a href='#'>Условия использования</a></div>
                    <div className={'link-container'}><a href='#'>Политика конфиденциальности</a></div>
                </Col>
                <Col xs={12} lg={{span: 3}}>
                    <h5 className={'column-header'}>Наши контакты</h5>
                    <div className={'link-container'}>(+55) 254. 254. 254</div>
                    <div className={'link-container'}>Info@courses-portal.org</div>
                    <div className={'link-container'}>Республика Абхазия, г. Сухум</div>
                </Col>
            </Row>
        </footer>
    )
}