import Card from "react-bootstrap/Card";
import ApiFactory from "../../api/ApiFactory";
import {navigate} from "@reach/router";
import Table from "react-bootstrap/Table";
import * as React from "react";
import {AdminFullCourseModule} from "../../api/generated";
import {Mode} from "./ModuleAdmin";

type Props = {
    courseId?: number,
    module: AdminFullCourseModule,
}

export function ModuleView({courseId, module, setMode}: { setMode: (m: Mode) => void } & Props) {
    return <>
        <Card className={'m-4'}>
            <Card.Body>
                <div className={'mb-2 float-right'}>
                    <img onClick={() => {
                        if (window.confirm(`Вы действительно хотите удалить модуль ${module.name}`)) {
                            ApiFactory.Instance().CourseModuleAPI().adminDeleteCourseModule(module.id)
                                .then(() => {
                                    navigate(`/admin/course/${courseId}`)
                                })
                        }
                    }} src={"/images/delete.svg"} className={'button-icon as-link'}/>
                    <img onClick={() => setMode(Mode.Edit)} src={"/images/edit.svg"} className={'button-icon as-link'}/>
                </div>
                <Table>
                    <tbody>
                    <tr>
                        <td className={'font-weight-bold'}>Название</td>
                        <td>{module.name}</td>
                    </tr>
                    <tr>
                        <td className={'font-weight-bold'}>Вес</td>
                        <td>{module.weight}</td>
                    </tr>
                    <tr>
                        <td className={'font-weight-bold'}>Политика доступности модуля</td>
                        <td>{module.course_module_availability_strategy?.name}</td>
                    </tr>
                    </tbody>
                </Table>
            </Card.Body>
        </Card>


    </>
}