import {CourseSubmoduleApi, UrlResponse} from "./generated";

export default class CourseSubmoduleApiWrapper extends CourseSubmoduleApi {
    adminCourseSubmoduleUploadVideo(id: number, file?: any, options?: any): Promise<UrlResponse> {
        const formData = new FormData()
        formData.append("file", file)
        const apiKey = this.configuration.apiKey !== undefined ? this.configuration.apiKey as string : ""
        return fetch(this.basePath + `/admin/course-submodule/${id}/upload-video`, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': apiKey,
            }
        })
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
    }
}