import * as React from 'react';
import {useEffect, useState} from 'react';
import Select, {GroupedOptionsType} from "react-select";

export type Item = {
    id: number,
    name: string
}

export type Group = {
    label: string,
    items: Item[]
}

export type GroupedSelectOptions = {label: string, options: SelectOption[]}

type Props = {
    items?: Item[],
    groupedItems?: Group[],
    initial: Item | null,
    propertyName: string,
    register: any,
    setValue: any,
    onValueChanged?: (i: Item) => void,
    placeholder?: string
}

type MultiProps = {
    items: Item[],
    initial?: Item[],
    propertyName: string,
    register: any,
    setValue: any,
    onValueChanged?: (i: Item[]) => void,
    placeholder?: string
}

const formatGroupLabel = (data: any) => (
    <div style={{
        display: 'flex',
    }}>
        <span className={'small'}>{data.label}</span>
    </div>
);

type SelectOption = { value: string, label: string };

export function DictionaryMultiSelect(props: MultiProps) {
    const [selected, setSelected] = useState(props.initial !== undefined ? props.initial.map(i => ({
        value: "" + i.id,
        label: i.name
    })) : [])
    const [availableValues, setAvailableValues] = useState<SelectOption[]>([]);
    useEffect(() => {
        props.register(props.propertyName)
        setAvailableValues(props.items.map(i => ({value: "" + i.id, label: i.name})))
    }, [props.items])
    return <Select value={selected}
                   isMulti={true}
                   placeholder={props.placeholder}
                   onChange={(v: any) => {
                       const arrayVal = v as SelectOption[]
                       setSelected(arrayVal)
                       const newVal = arrayVal.map(i => Number(i.value))
                       const changedValue = arrayVal.map(i => ({id: Number(i.value), name: i.label}))
                       if (props.onValueChanged !== undefined) {
                           props.onValueChanged(changedValue)
                       }
                       props.setValue(props.propertyName, newVal)
                   }}
                   options={availableValues}
    />
}

const selectOptionFromItem = (i: Item|null) => i ? {
    value: "" + i.id,
    label: i.name
} : null

export default function DictionarySelect(props: Props) {
    const [selected, setSelected] = useState(selectOptionFromItem(props.initial))
    const [availableValues, setAvailableValues] = useState<SelectOption[]|GroupedSelectOptions[]>([]);
    const [registered, setRegistered] = useState<boolean>(false)
    useEffect(() => {
        if (!registered) {
            console.log('register propertyName', props.propertyName)
            props.register(props.propertyName)
            setRegistered(true)
        }

        if (props.items) {
            setAvailableValues(props.items.map(i => ({value: "" + i.id, label: i.name})))
        } else if (props.groupedItems) {
            setAvailableValues(props.groupedItems.map(g => {
                return {
                    label: g.label,
                    options: g.items.map(i => ({value: "" + i.id, label: i.name}))
                }
            }))
        }

    }, [props.items, props.groupedItems])

    useEffect(() => {
        setSelected(selectOptionFromItem(props.initial))
    }, [props.initial])

    return <Select value={selected}
                   isMulti={false}
                   placeholder={props.placeholder}
                   onChange={(v: any) => {
                       setSelected(v)
                       const newVal = Number(v.value)
                       if (props.onValueChanged !== undefined) {
                           props.onValueChanged({id: Number(v.value), name: v.label})
                       }
                       props.setValue(props.propertyName, newVal)
                   }}
                   formatGroupLabel={formatGroupLabel}
                   options={availableValues}
    />
}