import * as React from 'react';
import {PortfolioDiscussionTopic} from "../../../api/generated";
import ListGroup from "react-bootstrap/ListGroup";
import {useNavigate} from "@reach/router";

type Props = {
    topics: PortfolioDiscussionTopic[];
}

export default function UserDiscussionTopicList({topics}: Props) {
    const navigate = useNavigate()
    if (!topics || topics.length == 0)  {
        return null;
    }

    return <ListGroup variant={"flush"} id={"discussion-list"}>
        {topics.map(topic => <ListGroup.Item key={`disc-${topic.discussion_id}-${topic.id}`}
                                             className={'p-4 d-flex flex-row align-items-center justify-content-between as-link'}
                                             onClick={() => {
                                                 navigate(`/teacher/discussion/${topic.discussion_id}/topic/${topic.id}`)
                                             }}>
            <div>
                <h6>{topic.name}</h6>
                {topic.is_private &&
                <small><img src={'/images/private.svg'} className={'small-icon'}/>Личная тема</small>}
            </div>
            <div className={'d-flex flex-row'}>
                <div className={'d-flex flex-column align-items-center pl-2 pr-2'}>
                    <span>{topic.comment_count}</span>
                    <span className={'x-small-text'}>комментариев</span>
                </div>
            </div>
        </ListGroup.Item>)}
    </ListGroup>
}