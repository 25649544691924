import * as React from 'react';
import {useContext, useMemo, useState} from 'react';
import {
    DiscussionList, TeacherDiscussionList,
    TeacherFullCourseModule,
    TeacherFullCourseSection,
    TeacherShortCourseSubmodule,
} from "../../api/generated";
import useSWR from "swr";
import ApiFactory, {MAX_PER_PAGE} from "../../api/ApiFactory";
import ErrorHandler from "../ErrorHandler";
import {AuthContext} from "../auth/AuthContext";
import {Loader} from "../Loaders";
import Card from 'react-bootstrap/Card';
import {TeacherSubmoduleItem} from "../common/SubmoduleItem";
import {useNavigate} from "@reach/router";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import ListGroup from "react-bootstrap/ListGroup";

type Props = {
    id: number
    courseId: number
}

function SectionContent({section, onSubmoduleClick}: { section: TeacherFullCourseSection, onSubmoduleClick: (sub: TeacherShortCourseSubmodule) => void }) {
    return <Card style={{marginTop: '3rem'}}>
        <Card.Body className={'p-4'}>
            <h5>{section.name}</h5>
        </Card.Body>
        <Card.Body>
            {section.submodules && section.submodules.map(sub => <TeacherSubmoduleItem key={`sub-${sub.id}`}
                                                                                       submodule={sub}
                                                                                       onSubmoduleClick={onSubmoduleClick}/>)}
        </Card.Body>
    </Card>
}


type DiscussionContentProps = {
    discussions: TeacherDiscussionList
}

function DiscussionContent({discussions}: DiscussionContentProps) {
    const navigate = useNavigate()
    const hasUnread = useMemo(() => discussions.items.find(t => t.unread_count > 0) !== undefined, [discussions])
    const [listOpen, setListOpen] = useState<boolean>(false);
    return <Card className={'mt-4'}>
        <Card.Body
            className={'d-flex flex-row align-items-center justify-content-between'}>
            <div>
                <h5>Обсуждения</h5>
                {hasUnread && <span className={'small text-danger'}>Есть новые комментарии</span>}
            </div>

            <div className={'d-flex flex-column align-items-center'}>
                <Button variant={'primary'}
                        className={'text-white'}
                        aria-controls={"discussion-list"}
                        aria-expanded={listOpen}
                        onClick={() => {
                            setListOpen(!listOpen)
                        }}
                >Подробнее</Button>
                <span className={'x-small-text pt-1'}>Всего обсуждений: {discussions.total}</span>
            </div>
        </Card.Body>
        <Collapse in={listOpen}>
            <ListGroup variant={"flush"} id={"discussion-list"}>
                {discussions.items.map(disc => <ListGroup.Item key={`disc-${disc.id}`}
                                                               className={'p-4 d-flex flex-row align-items-center justify-content-between as-link'}
                                                               onClick={() => {
                                                                   navigate(`/teacher/discussion/${disc.id}`)
                                                               }}>
                    <div>
                        <h6>{disc.name}</h6>
                        {disc.is_private &&
                        <small><img src={'/images/private.svg'} className={'small-icon'}/>Личное обсуждение</small>}
                    </div>
                    <div className={'d-flex flex-row'}>
                        {disc.unread_count > 0 &&
                        <div className={'d-flex flex-column align-items-center pl-2 pr-2'}>
                            <span className={'text-danger'}>{disc.unread_count}</span>
                            <span className={'x-small-text'}>новых комментариев</span>
                        </div>
                        }
                        <div className={'d-flex flex-column align-items-center pl-2 pr-2'}>
                            <span>{disc.topic_count}</span>
                            <span className={'x-small-text'}>тем</span>
                        </div>
                        <div className={'d-flex flex-column align-items-center pl-2 pr-2'}>
                            <span>{disc.comment_count}</span>
                            <span className={'x-small-text'}>комментариев</span>
                        </div>
                    </div>
                </ListGroup.Item>)}
            </ListGroup>
        </Collapse>
    </Card>
}

export default function ModuleContent({id, courseId}: Props) {
    const navigate = useNavigate();
    const userId = useContext(AuthContext).userId
    const {data: module, error} = useSWR<TeacherFullCourseModule>(['teacher/course-module', id, userId],
        () => ApiFactory.Instance().CourseModuleAPI().teacherViewCourseModule(id, courseId))

    const {data: discussions} = useSWR(() => {
        if (module === undefined) {
            return null;
        }
        return ['/teacher/discussions', module.id]
    }, () => ApiFactory.Instance().DiscussionAPI().teacherModuleDiscussions(module!.id, undefined, 0, MAX_PER_PAGE))
    if (error) {
        return <ErrorHandler error={error}/>
    }
    if (module === undefined) {
        return <Loader/>
    }

    return <>
        <h3>{module.name}</h3>

        {discussions && discussions.total > 0 && <DiscussionContent discussions={discussions}/>}
        {
            module.sections.map(sec => <SectionContent key={`sec-${sec.id}`} section={sec} onSubmoduleClick={sub =>
                navigate(`/teacher/course/${courseId}/module/${module.id}/submodule/${sub.id}`)}/>)
        }
    </>
}