import * as React from 'react';
import {ReactElement} from 'react';
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import {CourseCategory} from "../../api/generated";
import Badge from "react-bootstrap/Badge";
import {HeaderInfoLoader, PhotoLoader} from "../Loaders";
import Row from "react-bootstrap/Row";

interface BaseCourse {
    photo?: string
    name: string
    categories: CourseCategory[]
}

export type CommonCourseHeaderProps<T> = {
    course?: T,
    loading: boolean,
    right?: ReactElement,
    bottom?: ReactElement,
}

export default function CourseHeader<T extends BaseCourse>({course, loading, right, bottom}: CommonCourseHeaderProps<T>) {

    return <Row>
        <Col xs={12} md={3}>
            {loading || course === undefined
                ? <PhotoLoader/>
                : <Image className={'course__photo'}
                         src={course.photo ? UPLOAD_BASE_PATH + course.photo : '/images/no-image.png'}/>
            }

        </Col>
        <Col xs={12} md={9}>
            {loading || course === undefined
                ? <HeaderInfoLoader/>
                : <>
                    <div className={'d-flex justify-content-between w-100'}>
                        <div className={'d-flex flex-column h-100 flex-fill'}>
                            <div>
                                <h4>{course.name}</h4>
                                {course.categories.map(cat => <Badge key={`course-category__badge-${cat.id}`}
                                                                     className={'course-category__badge text-white'}
                                                                     variant={'primary'}
                                                                     pill={true}>{cat.name}</Badge>)}
                            </div>
                            {bottom && bottom}
                        </div>
                        <div className={'d-none d-sm-block'}>
                            {right && right}
                        </div>
                    </div>
                    <div className={'d-block d-sm-none'}>
                        {right && right}
                    </div>
                </>
            }

        </Col>
    </Row>
}