import * as React from 'react';
import RichContent from "../../common/RichContent";
import {Link} from "@reach/router";

type Props = {
    typeData: any
}

export default function DiscussionResult(props: Props) {
    return <div className={'mt-4'}>

        <div className={'content-card__text'}>
            <RichContent text={props.typeData.description}/>
        </div>

        <Link className={'btn btn-primary text-white'}
              to={`/user/discussion/${props.typeData.discussion_id}/topic/${props.typeData.topic_id}`}>Перейти к
            обсуждению</Link>
    </div>
}
