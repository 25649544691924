import * as React from 'react';
import {TypeProps} from "./TypeControls";
import Form from "react-bootstrap/Form";
import {ChangeEvent, Suspense, useEffect, useState} from "react";
import {Loader} from "../../Loaders";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import {navigate} from "@reach/router";
import {ShortCourseSubmodule} from "../../../api/generated";
import Card from "react-bootstrap/Card";
import InnerSubmoduleItem from "../../common/SubmoduleItem";
import Col from "react-bootstrap/Col";

const Editor = React.lazy(() => import("../../common/lazy/AdminCKEditor"))

type AddVariantProps = {
    onCancel: () => void;
    onSuccess: (text: string) => void;
}

function AddVariantForm(props: AddVariantProps) {
    const [variant, setVariant] = useState<string>("")
    return <Form.Row className={'mt-2'}>
        <Col xs={10}>
            <Form.Control type={'text'} value={variant} onChange={e => setVariant(e.target.value)} placeholder="Введите текст варианта"/>
        </Col>
        <Col xs={2} className={'d-flex justify-content-end align-items-center'}>
            <div className={'d-flex flex-row'}>
                <img className={'button-icon ml-4 as-link'} src={"/images/cancel.svg"}
                     onClick={props.onCancel}/>
                <img className={'button-icon ml-4 as-link'} src={"/images/save.svg"}
                     onClick={() => {
                         props.onSuccess(variant)
                     }}/>
            </div>
        </Col>

    </Form.Row>
}

type AddGroupProps = {
    onCancel: () => void;
    onSuccess: (text: string) => void;
}

function AddGroupForm(props: AddGroupProps) {
    const [group, setGroup] = useState<string>("")
    return <Form.Row className={'mt-2'}>
        <Col xs={10}>
            <Form.Control type={'text'} value={group} onChange={e => setGroup(e.target.value)} placeholder="Введите название группы"/>
        </Col>
        <Col xs={2} className={'d-flex justify-content-end align-items-center'}>
            <div className={'d-flex flex-row'}>
                <img className={'button-icon ml-4 as-link'} src={"/images/cancel.svg"}
                     onClick={props.onCancel}/>
                <img className={'button-icon ml-4 as-link'} src={"/images/save.svg"}
                     onClick={() => {
                         props.onSuccess(group)
                     }}/>
            </div>
        </Col>

    </Form.Row>
}

export default function SortingTypeControls(props: TypeProps) {
    const [showAddVariant, setShowAddVariant] = useState<boolean>(false);
    const [showAddGroup, setShowAddGroup] = useState<boolean>(false);
    const [variants, setVariants] = useState<string[]>(props.submodule.course_submodule_type_data.variants ?? [])
    const [groups, setGroups] = useState<string[]>(props.submodule.course_submodule_type_data.groups ?? [])

    const [userCanAddGroups, setUserCanAddGroups] = useState<boolean>(props.submodule.course_submodule_type_data.user_can_add_groups)
    const [userCanAddVariants, setUserCanAddVariants] = useState<boolean>(props.submodule.course_submodule_type_data.user_can_add_variants)
    const [minGroups, setMinGroups] = useState<number|undefined>(props.submodule.course_submodule_type_data.min_groups)

    useEffect(() => {
        setVariants(props.submodule.course_submodule_type_data.variants ?? [])
        setGroups(props.submodule.course_submodule_type_data.groups ?? [])
        setUserCanAddVariants(props.submodule.course_submodule_type_data.user_can_add_variants)
        setUserCanAddGroups(props.submodule.course_submodule_type_data.user_can_add_groups)
        setMinGroups(props.submodule.course_submodule_type_data.min_groups)
    }, [props.submodule])

    const addVariant = (variant: string) => {
        setVariants([...variants, variant])
    }

    const deleteVariant = (i: number) => {
        setVariants([...variants.slice(0, i), ...variants.slice(i + 1)])
    }

    const deleteGroup = (i: number) => {
        setGroups([...groups.slice(0, i), ...groups.slice(i + 1)])
    }

    const addGroup = (grp: string) => {
        setGroups([...groups, grp])
    }

    useEffect(() => {
        props.setValue("course_submodule_type_variants", variants)
    }, [variants])

    useEffect(() => {
        props.setValue("course_submodule_type_user_can_add_groups", userCanAddGroups)
    }, [userCanAddGroups])

    useEffect(() => {
        props.setValue("course_submodule_type_user_can_add_variants", userCanAddVariants)
    }, [userCanAddVariants])

    useEffect(() => {
        props.setValue("course_submodule_type_min_groups", minGroups)
    }, [minGroups])

    useEffect(() => {
        props.setValue("course_submodule_type_groups", groups)
    }, [groups])

    return <>
        <Form.Group>
            <Form.Label>Описание</Form.Label>
            <Suspense fallback={<Loader/>}>
                <Editor initialContent={props.submodule.course_submodule_type_data.description || ""}
                        onContentChange={text => {
                            props.setValue("course_submodule_type_description", text)
                        }}/>
            </Suspense>
            {props.errors && props.errors.course_submodule_type_description &&
            <Form.Text
                className={'text-danger'}>{props.errors.course_submodule_type_description.message}</Form.Text>}
        </Form.Group>

        <Form.Group>
            <Form.Check label={"Пользователь может сам добавлять группы"} checked={userCanAddGroups}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setUserCanAddGroups(e.target.checked)
                        }}/>
        </Form.Group>

        <Form.Group>
            <Form.Check label={"Пользователь может сам добавлять варианты"} checked={userCanAddVariants}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setUserCanAddVariants(e.target.checked)
                        }}/>
        </Form.Group>
        <Form.Group>
            <Form.Label>Минимальное количество групп</Form.Label>
            <Form.Control type={"number"} value={minGroups} onChange={(e: any) => setMinGroups(e.target.value)}/>
        </Form.Group>


        <Form.Group className={'mt-4 mb-4'}>
            <h6>Группы</h6>
            {groups.map((v, i) => {
                return <Card key={`variant-card-${i}`} className={'mt-2 mb-2'}>
                    <Card.Body className={'p-3 d-flex justify-content-between'}>
                        <span>{v}</span>
                        <img src={'/images/delete.svg'} className={'small-icon m-2 as-link'} onClick={() => deleteGroup(i)}/>
                    </Card.Body>
                </Card>
            })}
            {showAddGroup
                ? <AddGroupForm onCancel={() => setShowAddGroup(false)} onSuccess={(v) => {
                    addGroup(v)
                    setShowAddGroup(false)
                }}/>
                : <div className={'d-flex flex-row mt-4 justify-content-center as-link'}
                       onClick={() => setShowAddGroup(true)}>
                    <span>Добавить группу  <img src={"/images/add.svg"} className={'small-icon m-2'}/></span>
                </div>
            }
        </Form.Group>

        <Form.Group className={'mt-4 mb-4'}>
            <h6>Варианты</h6>
            {variants.map((v, i) => {
                return <Card key={`variant-card-${i}`} className={'mt-2 mb-2'}>
                    <Card.Body className={'p-3 d-flex justify-content-between'}>
                        <span>{v}</span>
                        <img src={'/images/delete.svg'} className={'small-icon m-2 as-link'} onClick={() => deleteVariant(i)}/>
                    </Card.Body>
                </Card>
            })}
            {showAddVariant
                ? <AddVariantForm onCancel={() => setShowAddVariant(false)} onSuccess={(v) => {
                    addVariant(v)
                    setShowAddVariant(false)
                }}/>
                : <div className={'d-flex flex-row mt-4 justify-content-center as-link'}
                       onClick={() => setShowAddVariant(true)}>
                    <span>Добавить вариант  <img src={"/images/add.svg"} className={'small-icon m-2'}/></span>
                </div>
            }
        </Form.Group>

    </>
}