import * as React from 'react';
import {useContext, useEffect, Suspense} from 'react';
import {UserFullCourseSubmodule} from "../../api/generated";
import ContentCard from "../common/ContentCard";
import {Loader} from "../Loaders";
import ApiFactory from "../../api/ApiFactory";
import {AuthContext} from "../auth/AuthContext";
import ErrorHandler from "../ErrorHandler";
import useSWR, {mutate} from "swr";
import {Type} from "./types";
import Card from "react-bootstrap/Card";
import TestSubmodule from "../user/TestSubmodule";
import TextSendSubmodule from "../user/TextSendSubmodule";
import FileSendSubmodule from "../user/FileSendSubmodule";
import DiscussionSubmodule from "../user/DiscussionSubmodule";
import RichContent from "../common/RichContent";
import RangingSubmodule from "../user/RangingSubmodule";
import SortingSubmodule from "../user/SortingSubmodule";

const Player = React.lazy(() => import('../common/lazy/Player'))

type Props = {
    submoduleId: number,
    courseId: number,
    completionId: number,
    moduleId: number,
}


function Content({submodule, courseId, completionId}: { submodule?: UserFullCourseSubmodule } & Props) {
    if (submodule === undefined) {
        return null
    }
    switch (submodule.type.id) {
        case Type.Text:
            return <ContentCard title={submodule.name} text={submodule.course_submodule_type_data.text}/>
        case Type.Video:
            return <Card className={'content-card__card'}>
                <Card.Body>
                    <h5 className={'text-primary'}>
                        {submodule.name}
                    </h5>

                    <div className={'content-card__video'}>
                        <Suspense fallback={<Loader/>}>
                            <Player fluid={true} aspectRatio={"16:9"} controls={true} autoplay={false} sources={[
                                {src: submodule.course_submodule_type_data.content_url, type: 'video/mp4'}
                            ]}/>
                        </Suspense>
                    </div>

                    <div className={'content-card__text'}>
                        <RichContent text={submodule.course_submodule_type_data.description}/>
                    </div>
                </Card.Body>
            </Card>
        case Type.Test:
            return <TestSubmodule submodule={submodule} courseId={courseId} courseCompletionId={completionId}/>
        case Type.TextSend:
            return <TextSendSubmodule submodule={submodule} courseId={courseId} courseCompletionId={completionId}/>
        case Type.FileSend:
            return <FileSendSubmodule submodule={submodule} courseId={courseId} courseCompletionId={completionId}/>
        case Type.Discussion:
            return <DiscussionSubmodule submodule={submodule} courseId={courseId} courseCompletionId={completionId}/>
        case Type.Ranging:
            return <RangingSubmodule submodule={submodule} courseId={courseId} courseCompletionId={completionId}/>
        case Type.Sorting:
            return <SortingSubmodule submodule={submodule} courseId={courseId} courseCompletionId={completionId}/>
    }
    return null
}


export default function SubmoduleContent(props: Props) {
    const fetcher = () => ApiFactory.Instance().CourseSubmoduleAPI().userViewCourseSubmodule(props.courseId, props.completionId, props.submoduleId);
    const {data: submodule, error} = useSWR<UserFullCourseSubmodule>(['user/course-submodule', props.submoduleId, useContext(AuthContext).userId], fetcher)
    const loading = submodule === undefined;
    const userId = useContext(AuthContext).userId
    useEffect(() => {
        // Модули могут сразу после открытия делать следующие задания доступными, поэтому обновляем меню для этого модуля
        if (submodule) {
            mutate(['user/course-module', props.moduleId, userId])
        }
    }, [submodule])
    if (error !== undefined) {
        return <ErrorHandler error={error}/>;
    }

    return loading
        ? <Loader/>
        : <Content {...props} submodule={submodule!}/>
}