import * as React from 'react';
import {PRIMARY_COLOR} from "../../config";

type Props = {
    points: number;
}

export default function Points({points}: Props) {
    return  <small style={{
        padding: '5px',
        backgroundColor: PRIMARY_COLOR,
        borderRadius: '5px',
        color: '#fff'
    }}>{points} балла(ов)</small>
}