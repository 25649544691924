import {CourseSubmoduleCompletionStatus} from "../../api/generated";
import {SubmoduleCompletionStatusEnum} from "../user/CoursesList";
import Badge from "react-bootstrap/Badge";
import * as React from "react";

export default function SubmoduleCompletionStatusBadge({status}: { status: CourseSubmoduleCompletionStatus }) {
    let variant = "warning";
    switch (status.id) {
        case SubmoduleCompletionStatusEnum.WaitForReview:
        case SubmoduleCompletionStatusEnum.WaitForReviewNonBlocking:
            variant = "warning";
            break;
        case SubmoduleCompletionStatusEnum.Finished:
            variant = "success";
            break;
        case SubmoduleCompletionStatusEnum.Declined:
            variant = "danger";
            break;
        case SubmoduleCompletionStatusEnum.InProgress:
            variant = "info";
            break;
    }
    return <Badge variant={variant}>{status.name}</Badge>
}