import * as React from 'react';
import {ReactElement, useContext, useState} from 'react';
import {UserFullCourseSubmodule} from "../../api/generated";
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import ApiFactory from "../../api/ApiFactory";
import {mutate} from "swr";
import {SubmoduleCompletionStatus} from "../submod/types";
import {AuthContext} from "../auth/AuthContext";
import ErrorHandler from "../ErrorHandler";
import RichContent from "../common/RichContent";
import PreviousSubmoduleResult from "./PreviousSubmoduleResult";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";

type Props = {
    submodule: UserFullCourseSubmodule,
    courseId: number
    courseCompletionId: number
}

type TypeData = {
    description: string
    variants: string[]
}

export default function RangingSubmodule(props: Props) {
    const {userId} = useContext(AuthContext);
    const initialData = props.submodule.course_submodule_type_data as TypeData
    const [variants, setVariants] = useState(initialData.variants)
    const [order, setOrder] = useState<number[]>(props.submodule.course_submodule_type_data.variants ?
        props.submodule.course_submodule_type_data.variants.map((v: string, i: number) => i) : [])
    const {submodule, courseCompletionId, courseId} = props
    const [displayForm, setDisplayForm] = useState<boolean>(false)
    const [error, setError] = useState<Error | undefined>(undefined);

    const reorder = (list: number[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const reorderVariants = (list: string[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    function onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const reordered = reorder(
            order,
            result.source.index,
            result.destination.index
        );
        setVariants(reorderVariants(variants, result.source.index, result.destination.index))
        setOrder(reordered);
    }

    const typeData = props.submodule.course_submodule_type_data as TypeData;
    const onSubmit = () => {
        if (props.submodule.completion === undefined) {
            setError(new Error('нет доступных прохождений'))
            return
        }

        const subCompl = {
            variants: variants
        };

        ApiFactory.Instance().CourseSubmoduleCompletionAPI().attemptCourseSubmoduleCompletion(
            props.courseId,
            props.courseCompletionId,
            props.submodule.id,
            props.submodule.completion.id,
            subCompl,
        ).then(() => {
            mutate(['user/course-submodule', submodule.id, userId])
            setDisplayForm(false)
        })
            .catch(e => {
                setError(e)
            })
    }

    if (!displayForm) {
        return <PreviousSubmoduleResult submodule={submodule}
                                        courseId={courseId}
                                        courseCompletionId={courseCompletionId}
                                        setDisplayForm={setDisplayForm}
                                        error={error}
                                        setError={setError}/>
    }

    return <Card className={'m-4'}>
        <Card.Body>
            {error && <ErrorHandler error={error}/>}
            <h5 className={'text-primary'}>
                {submodule.name}
            </h5>
            <div className={'content-card__text'}>
                <RichContent text={typeData.description}/>
            </div>

            <div className={'content-card__text'}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={`submodule-range-${props.submodule.id}`}>
                        {provided => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {variants.map((v, i) => {
                                    return <Draggable draggableId={props.submodule.id + "-" + i} index={i}>
                                        {provided => (
                                            <Card className={'mt-2 mb-2'} ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}>
                                                <Card.Body className={'p-3'}>
                                                    <span>{v}</span>
                                                </Card.Body>
                                            </Card>
                                        )}
                                    </Draggable>
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <div className={'d-flex flex-row justify-content-end mt-4'}>
                <Button variant={'primary text-white'} onClick={() => onSubmit()}>Отправить на проверку</Button>
            </div>
        </Card.Body>
    </Card>
}