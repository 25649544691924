import * as React from "react";
import {Suspense, useEffect, useState} from "react";
import ApiFactory, {DEFAULT_PER_PAGE, MAX_PER_PAGE} from "../../../api/ApiFactory";
import useSWR from "swr";
import {DiscussionList, DiscussionTopicList, ShortDiscussion} from "../../../api/generated";
import * as _ from "lodash";
import ErrorHandler from "../../ErrorHandler";
import {LineLoader, Loader} from "../../Loaders";
import Form from "react-bootstrap/Form";
import DictionarySelect, {Item} from "../../common/DictionarySelect";
import AsyncSelect from "react-select/async";
import {TypeProps} from "./TypeControls";
import {SelectOption} from "../../common/types";
import {DiscussionTypeEnum} from "../../../models/discussion";

const Editor = React.lazy(() => import("../../common/lazy/AdminCKEditor"))

export default function DiscussionTypeControls(props: TypeProps) {
    const [error, setError] = useState<Error | undefined>(undefined)
    const [selectedDiscussion, setSelectedDiscussion] = useState<ShortDiscussion | null>(null)
    const [selectedTopic, setSelectedTopic] = useState<SelectOption | undefined>(undefined)
    const [topicOptions, setTopicOptions] = useState<SelectOption[]>([])
    const [discussions, setDiscussions] = useState<ShortDiscussion[]|undefined>(undefined)

    const moduleDiscFetcher = () => ApiFactory.Instance().DiscussionAPI().adminModuleDiscussions(props.moduleId, 0, MAX_PER_PAGE)
    const {
        data: fetchModuleDiscussions,
        error: moduleDiscError
    } = useSWR(['admin/module-discussions', props.moduleId, DiscussionTypeEnum.Module, 0], moduleDiscFetcher)

    const courseDiscFetcher = () => ApiFactory.Instance().DiscussionAPI().adminCourseDiscussions(props.courseId, 0, MAX_PER_PAGE)
    const {
        data: fetchCourseDiscussions,
        error: courseDiscError
    } = useSWR(['admin/course-discussions', props.courseId, DiscussionTypeEnum.Course, 0], courseDiscFetcher)

    useEffect(() => {
        setDiscussions([
            ...fetchModuleDiscussions ? fetchModuleDiscussions.items : [],
            ...fetchCourseDiscussions ? fetchCourseDiscussions.items : []
        ])
    }, [fetchModuleDiscussions, fetchCourseDiscussions])

    useEffect(() => {
        if (!discussions) {
            return
        }
        const initialDiscussionId = props.submodule.course_submodule_type_data.discussion_id;
        const disc = discussions.find(d => d.id === initialDiscussionId)
        if (disc === undefined) {
            return
        }
        setSelectedDiscussion(disc)
        const topicId = props.submodule.course_submodule_type_data.topic_id
        if (topicId === undefined) {
            return
        }
        ApiFactory.Instance().DiscussionTopicAPI().adminDiscussionTopic(disc.id, topicId)
            .then(resp => setSelectedTopic({value: "" + resp.id, label: resp.name}))
    }, [discussions])

    useEffect(() => {
        if (!selectedDiscussion?.id) {
            return
        }
        ApiFactory.Instance().DiscussionTopicAPI().adminDiscussionTopics(selectedDiscussion.id, undefined, 0, DEFAULT_PER_PAGE)
            .then(resp => {
                const options = resp.items.map<SelectOption>(u => ({value: "" + u.id, label: u.name}))
                setTopicOptions(options)
            }).catch(e => {
            setError(e)
            setTopicOptions([])
        })
    }, [selectedDiscussion])


    const loadTopics = _.debounce((input: string, callback: (opts: SelectOption[]) => void) => {
        if (!selectedDiscussion) {
            callback([])
            return
        }
        ApiFactory.Instance().DiscussionTopicAPI().adminDiscussionTopics(selectedDiscussion.id, input, 0, DEFAULT_PER_PAGE)
            .then(resp => {
                callback(resp.items.map<SelectOption>(u => ({value: "" + u.id, label: u.name})))
            }).catch(e => {
            setError(e)
            callback([])
        })
    }, 500)

    if (moduleDiscError !== undefined) {
        return <ErrorHandler error={moduleDiscError}/>
    }

    if (courseDiscError !== undefined) {
        return <ErrorHandler error={courseDiscError}/>
    }

    if (error !== undefined) {
        return <ErrorHandler error={error}/>
    }

    if (discussions === undefined) {
        return <LineLoader/>
    }
    return <>
        <Form.Group>
            <Form.Label>Описание</Form.Label>
            <Suspense fallback={<Loader/>}>
                <Editor initialContent={props.submodule.course_submodule_type_data.description || ""}
                        onContentChange={text => {
                            props.setValue("course_submodule_type_description", text)
                        }}/>
            </Suspense>
            {props.errors && props.errors.course_submodule_type_description &&
            <Form.Text
                className={'text-danger'}>{props.errors.course_submodule_type_description.message}</Form.Text>}
        </Form.Group>
        <Form.Group>
            <Form.Label>Обсуждения</Form.Label>
            <DictionarySelect
                              groupedItems={[
                                {
                                    label: "Обсуждения модуля",
                                    items: fetchModuleDiscussions ? fetchModuleDiscussions.items : [],
                                },
                                {
                                    label: "Обсуждения курса",
                                    items: fetchCourseDiscussions ? fetchCourseDiscussions.items : [],
                                }
                              ]}
                              initial={selectedDiscussion}
                              propertyName={'course_submodule_type_discussion_id'}
                              register={props.register}
                              setValue={props.setValue}
                              onValueChanged={v => {
                                  const selectedDisc = discussions.find(d => d.id == v.id)
                                  setSelectedDiscussion(selectedDisc ? selectedDisc : null)
                                  setSelectedTopic(undefined)
                                  props.setValue("course_submodule_type_topic_id", undefined)
                              }}
            />
            {props.errors && props.errors.course_submodule_type_discussion_id &&
            <Form.Text
                className={'text-danger'}>{props.errors.course_submodule_type_discussion_id.message}</Form.Text>}
        </Form.Group>
        <Form.Group>
            <Form.Label>Темы</Form.Label>
            <AsyncSelect value={selectedTopic}
                         placeholder={'Выберите тему'}
                         loadOptions={loadTopics}
                         options={topicOptions}
                         defaultOptions={topicOptions}
                         onChange={(v: any) => {
                             const val = v as SelectOption;
                             setSelectedTopic(val)
                             props.setValue("course_submodule_type_topic_id", Number(val.value))
                         }}
            />
            {props.errors && props.errors.course_submodule_type_topic_id &&
            <Form.Text
                className={'text-danger'}>{props.errors.course_submodule_type_topic_id.message}</Form.Text>}
        </Form.Group>
    </>
}