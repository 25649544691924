import * as React from 'react';
import {ReactElement, useContext, useEffect, useState} from 'react';
import Alert from 'react-bootstrap/Alert'
import {ErrorMessage} from "../api/generated";
import {AuthContext} from "./auth/AuthContext";
import {Redirect} from "@reach/router";
import {Roles} from "./auth/Auth";
import ApiFactory from "../api/ApiFactory";
import SucceedAlert from "./common/SucceedAlert";

export enum AlertSize {
    Small,
    Normal
}

type Props = {
    error: any;
    size?: AlertSize;
}

export default function ErrorHandler(props: Props) {
    const [redirectToHomeOnLogout, setRedirectToHomeOnLogout] = useState<boolean>(false)
    const {isLoggedIn, setShowModal, roles} = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState<string | ReactElement>("")
    const [succeedMessage, setSucceedMessage] = useState<string | undefined>(undefined)
    const size = props.size ?? AlertSize.Normal
    let className = ""
    if (size === AlertSize.Small) {
        className += " alert--small"
    }

    const resendConfirmation = async () => {
        try {
            const me = await ApiFactory.Instance().UserAPI().userMe()
            await ApiFactory.Instance().AuthAPI().authResendConfirmation({
                username: me.username,
            })
            setSucceedMessage("Инструкции отправлены заново на электронный адрес, указанный при регистрации")
        } catch (e) {
            parseError(e)
        }
    }

    const parseError = (err: any) => {
        if (err.status === undefined) {
            setErrorMessage(err.message)
            return
        }
        err.clone().json().then((resp: ErrorMessage) => {
            if (resp.code === 401) {
                if (isLoggedIn) {
                    console.log('Обновление авторизационных данных')
                    return
                }
                setShowModal(true)
                setRedirectToHomeOnLogout(true)
            } else if (resp.code === 403) {
                if (roles.has(Roles.NotConfirmedUser)) {
                    setErrorMessage(<>
                        Для продолжения работы вы должны подтвердить адрес электронной почты. Инструкции отправлены на
                        электронный адрес, указанный при регистрации
                        <span className={'btn btn-link'} onClick={resendConfirmation}>Отправить заново</span>
                    </>)
                    return
                }
                setErrorMessage(resp.message!)
            } else {
                setErrorMessage(resp.message!)
            }
        })
    }

    useEffect(() => {
        parseError(props.error)
    }, [props.error])

    if (redirectToHomeOnLogout) {
        return <Redirect noThrow={true} to={"/"}/>
    }

    if (errorMessage == "") {
        return null
    }

    return <>
        {succeedMessage && <SucceedAlert message={succeedMessage} onHide={() => setSucceedMessage(undefined)}/>}
        <Alert variant={'danger'}>
            <span className={className}>{errorMessage}</span>
        </Alert>
    </>
}