import * as React from 'react'
import {useContext, useRef, useState} from 'react'
import {RouteComponentProps} from "@reach/router";
import PageName from "../../../components/common/PageName";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ErrorHandler from "../../../components/ErrorHandler";
import FormGroup from "react-bootstrap/FormGroup";
import Button from "react-bootstrap/Button";
import FormIndicator from "../../../components/common/FormIndicator";
import * as yup from "yup";
import {MeEditParams, User} from "../../../api/generated";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import useSWR, {mutate} from "swr";
import ApiFactory from "../../../api/ApiFactory";
import {AuthContext} from "../../../components/auth/AuthContext";
import {Loader} from "../../../components/Loaders";
import SucceedAlert from "../../../components/common/SucceedAlert";
import Modal from 'react-bootstrap/Modal';
import ReactCrop, {Crop} from "react-image-crop";
import {useTranslation} from "react-i18next";
import ContentContainer from "../../../components/common/ContentContainer";
import HorizontalScroll from "../../../components/common/HorizontalScroll";

type Props = {} & RouteComponentProps

enum Mode {
    Edit,
    ChangePassword
}

type ResizeProps = {
    src: string
    onFinish: (file: Blob) => void
    onCancel: () => void
    show: boolean
    setShow: (s: boolean) => void
}

function getCroppedImg(image: HTMLImageElement, crop: Crop): Promise<Blob> {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const cropWidth = crop.width || 0
    const cropHeight = crop.height || 0
    const cropX = crop.x || 0
    const cropY = crop.y || 0
    canvas.width = Math.ceil(cropWidth * scaleX);
    canvas.height = Math.ceil(cropHeight * scaleY);
    const ctx = canvas.getContext('2d');
    if (ctx === null) {
        return Promise.reject("ctx is null")
    }
    ctx.drawImage(
        image,
        cropX * scaleX,
        cropY * scaleY,
        cropWidth * scaleX,
        cropHeight * scaleY,
        0,
        0,
        cropWidth * scaleX,
        cropHeight * scaleY,
    );
    return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (blob !== null) {
                resolve(blob);
            }
        }, 'image/jpeg', 1);
    });
}

function ResizeModal(props: ResizeProps) {
    const imageRef = useRef<HTMLImageElement | null>(null)
    const [resultCrop, setResultCrop] = useState<Crop | undefined>(undefined)
    const [photoCrop, setPhotoCrop] = useState<Crop | undefined>({
        unit: '%',
        width: 30,
        aspect: 1,
    })

    return <Modal show={props.show} onHide={() => {
        props.setShow(false)
        props.onCancel()
    }}>
        <Modal.Body>
            <ReactCrop src={props.src}
                       crop={photoCrop}
                       onImageLoaded={i => imageRef.current = i}
                       onComplete={c => setResultCrop(c)}
                       onChange={(crop) => setPhotoCrop(crop)}/>
        </Modal.Body>
        <Modal.Footer className={'d-flex flex-row justify-content-end'}>
            <Button variant={'success'} onClick={() => {
                if (imageRef.current === null || resultCrop === undefined) {
                    return
                }
                getCroppedImg(imageRef.current, resultCrop)
                    .then(b => {
                        props.onFinish(b)
                        props.setShow(false)
                    })
                    .catch(e => console.warn(e))
            }}>Сохранить</Button>
            <Button variant={'danger'} onClick={() => {
                props.setShow(false)
                props.onCancel()
            }}>Отмена</Button>
        </Modal.Footer>
    </Modal>
}

type EditProps = {
    user: User
}

const formValidationSchema = yup.object<MeEditParams>({
    name: yup.string().required("Поле Имя обязательно к заполнению"),
    about: yup.string().required("Поле О пользователе обязательно к заполнению"),
})

function Edit(props: EditProps) {
    const {userId} = useContext(AuthContext)
    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const [saveError, setSaveError] = useState<Error | undefined>(undefined);
    const [saveSucceed, setSaveSucceed] = useState<boolean>(false);
    const [showResizeModal, setShowResizeModal] = useState<boolean>(false);

    const [photoSrc, setPhotoSrc] = useState<string | ArrayBuffer | null | undefined>(undefined)

    const {register, handleSubmit, errors} = useForm<MeEditParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            name: props.user.name,
            about: props.user.about || "",
        }
    });

    const onSubmit = (params: MeEditParams) => {
        setSaveSucceed(false);
        setSaveError(undefined);
        ApiFactory.Instance().UserAPI().meEdit(params)
            .then(() => {
                mutate(["user/me", userId])
                setSaveSucceed(true)
            })
            .catch(e => setSaveError(e))
    }

    const onSelectFile = (e: React.FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement
        if (target.files && target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setPhotoSrc(reader.result)
                setShowResizeModal(true)
            });
            reader.readAsDataURL(target.files[0]);
        }
    };

    return <>
        <ResizeModal show={showResizeModal}
                     setShow={setShowResizeModal}
                     src={photoSrc as string}
                     onCancel={() => {
                         if (fileInputRef.current !== null) {
                             fileInputRef.current.value = ""
                         }
                     }}
                     onFinish={file => {
                         ApiFactory.Instance().UserAPI().mePhotoUpload(file)
                             .then(r => {
                                 if (fileInputRef.current !== null) {
                                     fileInputRef.current.value = ""
                                 }
                                 mutate(['user/me', userId])
                             }).catch(e => setSaveError(e))
                     }}/>
        <div className={'d-flex flex-column align-items-center mt-4 as-link'} onClick={() => {
            if (fileInputRef && fileInputRef.current) {
                fileInputRef.current.click()
            }
        }}>
            <img className={'choose-photo__img rounded-circle'} src={props.user.photo
                ? UPLOAD_BASE_PATH + props.user.photo : '/images/choose-photo-empty.png'}/>
            <span className={'mt-2 font-weight-bold'}>Нажмите для редактирования изображения</span>
        </div>
        <input ref={fileInputRef} hidden={true} type={'file'} onChange={(e) => onSelectFile(e)}/>
        <Form className={'mt-4'} onSubmit={handleSubmit(onSubmit)}>
            {saveError && <ErrorHandler error={saveError}/>}
            {saveSucceed && <SucceedAlert message={"Данные успешно сохранены"} onHide={() => setSaveSucceed(false)}/>}
            <FormGroup>
                <Form.Label>Имя пользователя</Form.Label>
                <Form.Control className={'mt-1'} ref={register} name={'name'}/>
                {errors.name && <Form.Text>{(errors.name as any)?.message}</Form.Text>}
            </FormGroup>
            <FormGroup>
                <Form.Label>О пользователе</Form.Label>
                <Form.Control as="textarea" className={'mt-1'} ref={register} name={'about'}/>
                {errors.about && <Form.Text>{(errors.about as any)?.message}</Form.Text>}
            </FormGroup>
            <Button type={'submit'} style={{marginTop: '30px'}} block={true} variant={'primary'}
                    className={'text-white'}>Сохранить</Button>
        </Form>
    </>
}

type ChangePasswordFormParams = {
    password: string
    passwordConfirm: string
}

function ChangePassword() {
    const {t} = useTranslation()
    const [changeSucceed, setChangeSucceed] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>(undefined);
    const formValidationSchema = yup.object<ChangePasswordFormParams>({
        password: yup.string().required().min(6, t("passwordSmallLength")).required("passwordRequired"),
        passwordConfirm: yup.string().required().oneOf([yup.ref('password')], t('passwordConfirmShouldMatch')),
    })

    const {register, handleSubmit, errors} = useForm<ChangePasswordFormParams>({
        resolver: yupResolver(formValidationSchema)
    });

    const onSubmit = (params: ChangePasswordFormParams) => {
        ApiFactory.Instance().UserAPI().meChangePassword({
            newPassword: params.password,
        }).then(() => {
            setChangeSucceed(true)
        }).catch(e => setError(e))
    }

    return <Form className={'mt-4'} onSubmit={handleSubmit(onSubmit)}>
        {changeSucceed && <SucceedAlert message={'Пароль успешно изменен'} onHide={() => setChangeSucceed(false)}/>}
        {error && <ErrorHandler error={error}/>}
        <FormGroup>
            <Form.Label>Пароль</Form.Label>
            <Form.Control ref={register} name={'password'} style={{marginTop: '5px'}} type={'password'}/>
            {errors.password && <Form.Text>{errors.password.message}</Form.Text>}
        </FormGroup>
        <FormGroup>
            <Form.Label>Подтверждение пароля</Form.Label>
            <Form.Control ref={register} name={'passwordConfirm'} style={{marginTop: '5px'}}
                          type={'password'}/>
            {errors.passwordConfirm && <Form.Text>{errors.passwordConfirm.message}</Form.Text>}
        </FormGroup>
        <Button type={'submit'} style={{marginTop: '30px'}} block={true} variant={'primary'}
                className={'text-white'}>Сохранить</Button>
    </Form>
}

export default function View(props: Props) {
    const {userId} = useContext(AuthContext)
    const [mode, setMode] = useState<Mode>(Mode.Edit)
    const {data: user, error} = useSWR(["user/me", userId], () => ApiFactory.Instance().UserAPI().userMe())

    return <ContentContainer>
        <PageName title={'Профиль'}/>
        <Row>
            <Col xs={12} md={{offset: 3, span: 6}} lg={{offset: 4, span: 4}}>
                <HorizontalScroll alignCenter={true} items={[
                    {
                        render: (active, onClick) => <div key={Mode.Edit}
                                                          className={'form__tab-header' + (active ? ' form__tab-header-active' : '')}
                                                          onClick={onClick}>
                            <span>Изменить данные</span>
                            {active && <FormIndicator/>}
                        </div>,
                        onClick: () => setMode(Mode.Edit),
                        active: mode === Mode.Edit
                    },
                    {
                        render: (active, onClick) => <div key={Mode.ChangePassword}
                                                          className={'form__tab-header' + (active ? ' form__tab-header-active' : '')}
                                                          onClick={onClick}>
                            <span>Сменить пароль</span>
                            {active && <FormIndicator/>}
                        </div>,
                        onClick: () => setMode(Mode.ChangePassword),
                        active: mode === Mode.ChangePassword
                    },
                ]}/>
                {error && <ErrorHandler error={error}/>}
                {user === undefined && error === undefined && <Loader/>}
                {user !== undefined &&
                <>
                    {mode === Mode.Edit && <Edit user={user}/>}
                </>
                }
                {mode === Mode.ChangePassword && <ChangePassword/>}
            </Col>
        </Row>
    </ContentContainer>
}