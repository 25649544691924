import * as React from 'react';
import {useEffect, useState} from 'react';
import Auth from "./Auth";
import {TokensResponse} from "../../api/generated";

type AuthValue = {
    userId?: number
    isLoggedIn: boolean
    setLoggedIn: (tokens: TokensResponse) => void,
    logout: () => void,
    showModal: boolean
    setShowModal: (s: boolean) => any,
    roles: Set<string>,
    refreshToken?: string,
}

const defaultValue = {
    userId: undefined,
    refreshToken: undefined,
    isLoggedIn: false,
    setLoggedIn: (tokens: TokensResponse) => {
    },
    logout: () => {
    },
    showModal: false,
    setShowModal: () => {
    },
    roles: new Set<string>(),
}

export const AuthContext = React.createContext<AuthValue>(defaultValue)

export function AuthProvider(props: any) {
    const [showModal, setShowModal] = useState(false)
    const [isLoggedIn, setLoggedIn] = useState(false)
    const [userId, setUserId] = useState<number | undefined>(undefined)

    useEffect(() => {
        setLoggedIn(Auth.Instance().isLoggedIn())
        setUserId(Auth.Instance().getUserID())
    }, [])

    return <AuthContext.Provider value={{
        userId: userId,
        showModal: showModal,
        setShowModal: setShowModal,
        isLoggedIn: isLoggedIn,
        setLoggedIn: (tokens: TokensResponse) => {
            Auth.Instance().setTokens(tokens.access_token, tokens.refresh_token);
            setLoggedIn(Auth.Instance().isLoggedIn())
            setUserId(Auth.Instance().getUserID())
        },
        logout: () => {
            Auth.Instance().clearTokens()
            setLoggedIn(false)
            setUserId(undefined)
        },
        roles: Auth.Instance().roles(),
        refreshToken: Auth.Instance().getRefreshToken(),
    }}>
        {props.children}
    </AuthContext.Provider>
}