import * as React from 'react'
import {ImgHTMLAttributes} from 'react'

interface User {
    photo?: string
}

type Props = {
    user: User
} & ImgHTMLAttributes<any>

export default function UserPhoto(props: Props) {
    return <img {...props} src={props.user.photo ? UPLOAD_BASE_PATH + props.user.photo : '/images/user-no-photo.svg'}/>
}