import * as React from 'react';
import {ReactElement, useContext, useEffect, useState} from 'react';
import {UserFullCourseSubmodule} from "../../api/generated";
import Card from 'react-bootstrap/Card'
import {Link} from "@reach/router";
import ApiFactory from "../../api/ApiFactory";
import {isStatusPositive, SubmoduleCompletionStatus} from "../submod/types";
import RichContent from "../common/RichContent";
import {mutate} from "swr";
import {AuthContext} from "../auth/AuthContext";
import Button from "react-bootstrap/Button";

type Props = {
    submodule: UserFullCourseSubmodule,
    courseId: number
    courseCompletionId: number
}

type TypeData = {
    description: string
    discussion_id: number,
    topic_id: number,
}

export default function DiscussionSubmodule(props: Props) {
    const {submodule} = props
    const userId = useContext(AuthContext).userId

    useEffect(() => {
        attemptToCreateCompletion()
    }, [])

    // Если подмодуль уже проверен преподавателем, то не начинаем новое прохождение
    const attemptToCreateCompletion = () => {
        if (!submodule.completion || !isStatusPositive(submodule.completion.status.id)) {
            ApiFactory.Instance().CourseSubmoduleCompletionAPI().userCreateCourseSubmoduleCompletion(props.courseId,
                props.courseCompletionId, submodule.id, true).then(() => {
                    mutate(['user/course-submodule', submodule.id, userId])
            })
        }
    }

    const typeData = submodule.course_submodule_type_data as TypeData
    return <Card className={'m-4'}>
        <Card.Body>
            <h5 className={'text-primary'}>
                {submodule.name}
            </h5>
            {submodule.completion && submodule.completion.status.id === SubmoduleCompletionStatus.InProgress &&
            <div className={'mb-4'}>
                <span className={'text-danger'}><img src={'/images/warning.svg'} className={'small-icon'}/>  Для прохождения, необходимо оставить комментарий в теме обсуждении</span>
            </div>
            }

            {submodule.completion && submodule.completion.status.id === SubmoduleCompletionStatus.WaitForReview &&
            <div className={'mb-4'}>
                <span className={'text-danger'}><img src={'/images/warning.svg'} className={'small-icon'}/> Ожидает проверки</span>
            </div>
            }

            {submodule.completion &&
            (submodule.completion.status.id === SubmoduleCompletionStatus.Finished ||
                submodule.completion.status.id === SubmoduleCompletionStatus.AcceptedAsDraft
            ) &&
            <div className={'mb-4'}>
                <span><img src={'/images/finished.png'} className={'small-icon'}/> Подмодуль пройден{submodule.completion.points > 0 && <span>. Ваш результат: {submodule.completion.points} балла(ов)</span>}</span>
            </div>
            }

            {submodule.completion &&
            submodule.completion.status.id === SubmoduleCompletionStatus.Declined &&
                <div>
                    <span className={'text-danger'}>Предыдущий комментарий был отклонен</span>
                    <div className={'mt-2'}>
                        <div><small>Комментарий преподавателя:</small></div>
                        <div>{submodule.completion.teacher_comment}</div>
                    </div>
                </div>
            }

            <div className={'content-card__text'}>
                <RichContent text={typeData.description}/>
            </div>
            {typeData.discussion_id && typeData.topic_id
                ? <Link className={'btn btn-primary text-white'}
                      to={`/user/discussion/${typeData.discussion_id}/topic/${typeData.topic_id}`}>Перейти к теме обсуждения</Link>
                : undefined
            }

            {typeData.discussion_id && !typeData.topic_id
                ? <Link className={'btn btn-primary text-white'}
                  to={`/user/discussion/${typeData.discussion_id}`}>Перейти к обсуждению</Link>
                : undefined
            }


        </Card.Body>
    </Card>
}