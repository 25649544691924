import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {AdminUser, UserEditParams, UserStatus} from "../../api/generated";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import ApiFactory from "../../api/ApiFactory";
import {mutate} from "swr";
import ErrorHandler from "../ErrorHandler";
import Card from "react-bootstrap/Card";
import DictionarySelect from "../common/DictionarySelect";

type Props = {
    user: AdminUser
    onCancel: () => void;
    onFinish: () => void;
}

type SelectOption = { value: string, label: string };

type FormEditParams = {

} & UserEditParams

const formValidationSchema = yup.object<FormEditParams>({
    name: yup.string().required(),
    about: yup.string(),
    password: yup.string(),
    user_status_id: yup.number().required(),
    roles: yup.array(),
})

export default function UserEdit({user, onCancel, onFinish}: Props) {
    const formRef = useRef<HTMLFormElement|null>(null)
    const [error, setError] = useState<Error | undefined>(undefined);

    const [availableRoles, setAvailableRoles] = useState<SelectOption[]>([])
    const [selectedRoles, setSelectedRoles] = useState<SelectOption[]>(user.roles.map(r => ({value: r, label: r})));
    const [availableStatuses, setAvailableStatuses] = useState<UserStatus[]>([])

    useEffect(() => {
        ApiFactory.Instance().StaticAPI().staticGet(["user_status", "user_role"])
            .then(resp => {
                if (resp.user_status === undefined) {
                    setAvailableStatuses([])
                    return
                }
                if (resp.user_role === undefined) {
                    setAvailableRoles([])
                    return
                }

                setAvailableStatuses(resp.user_status)
                setAvailableRoles(resp.user_role.map<SelectOption>(c => ({value: "" + c, label: c})))
            }).catch(e => {
            setAvailableRoles([])
            setAvailableStatuses([])
        });
    }, [])

    const {register, handleSubmit, errors, setValue} = useForm<FormEditParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
           name: user.name,
           roles: user.roles,
           user_status_id: user.status.id,
           about: user.about,
        }
    });
    useEffect(() => {
        register("roles")
    }, [])


    const onSubmit = (params: FormEditParams) => {
        setError(undefined)
        ApiFactory.Instance().UserAPI().adminUpdateUser(user.id, params)
            .then(() => {
            mutate(["admin/user", user.id]);
            onFinish();
        }).catch(e => setError(e));
    }

    return <Card className={'m-4'}>
        <Card.Body>
            {error && <ErrorHandler error={error}/>}
            <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                <div className={'d-flex flex-row align-items-center justify-content-end'}>
                    <div className={'d-flex flex-row'}>
                        <img className={'button-icon ml-4 as-link'} src={"/images/cancel.svg"}
                             onClick={onCancel}/>
                        <img className={'button-icon ml-4 as-link'} src={"/images/save.svg"}
                             onClick={() => {
                                 if (formRef.current) {
                                     formRef.current.dispatchEvent(new Event('submit', {cancelable: true}))
                                 }
                             }}/>
                    </div>
                </div>
                <Form.Group>
                    <Form.Label>ФИО пользователя</Form.Label>
                    <Form.Control ref={register} name={"name"}/>
                    {errors && errors.name &&
                    <Form.Text className={'text-danger'}>{(errors.name as any)?.message}</Form.Text>}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Пароль</Form.Label>
                    <Form.Control autoComplete="off" type={'password'} ref={register} name={"password"}/>
                    {errors && errors.password &&
                    <Form.Text className={'text-danger'}>{(errors.password as any)?.message}</Form.Text>}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Статус пользователя</Form.Label>
                    <DictionarySelect
                        items={availableStatuses}
                        initial={user.status}
                        propertyName={"user_status_id"}
                        register={register}
                        setValue={setValue}/>
                    {errors && errors.user_status_id &&
                    <Form.Text className={'text-danger'}>{errors.user_status_id.message}</Form.Text>}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Роли пользователя</Form.Label>
                    <Select value={selectedRoles}
                            placeholder={'Выберите роли'}
                            isMulti={true}
                            onChange={(v: any) => {
                                let val: SelectOption[] = []
                                if (v != null) {
                                    val = v as SelectOption[];
                                }
                                setSelectedRoles(val)
                                const newVal = val.map(v => v.value)
                                setValue("roles", newVal)
                            }}
                            options={availableRoles}
                    />
                    {errors && errors.roles &&
                    <Form.Text className={'text-danger'}>Неверные роли</Form.Text>}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Информация о пользователе</Form.Label>
                    <Form.Control as={"textarea"} ref={register} name={"about"}></Form.Control>
                    {errors && errors.about &&
                    <Form.Text className={'text-danger'}>{(errors.about as any)?.message}</Form.Text>}
                </Form.Group>
            </Form>
        </Card.Body>
    </Card>
}
