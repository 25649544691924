import * as React from "react";
import {AvailabilityStatus} from "../../api/generated";


export interface AvailabilityStatusContainer {
    availability_status: AvailabilityStatus,
    finished: boolean,
}

export function AvailabilityIcon({model}: { model: AvailabilityStatusContainer }) {
    let icon;
    if (model.finished) {
        icon = '/images/finished.png';
    } else if (!model.availability_status.available) {
        icon = '/images/locked.png';
    }

    return icon !== undefined
        ? <img className={'course-menu__availability-icon'} src={icon}/>
        : <div className={'d-inline-flex course-menu__availability-icon'}></div>;
}