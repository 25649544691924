import * as React from 'react';
import {useContext, useEffect, useMemo, useRef, useState, Suspense} from 'react';
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import {AdminFullCourse, CourseAvailabilityType, CourseEditParams} from "../../api/generated";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import ApiFactory, {DEFAULT_PER_PAGE} from "../../api/ApiFactory";
import AsyncSelect from 'react-select/async'
import * as _ from 'lodash';
import {mutate} from "swr";
import {AuthContext} from "../auth/AuthContext";
import ErrorHandler from "../ErrorHandler";
import {Loader, PhotoLoader} from "../Loaders";
import Row from 'react-bootstrap/Row';
import {useNavigate} from "@reach/router";
import Card from "react-bootstrap/Card";
import DictionarySelect from "../common/DictionarySelect";
import {CourseAvailabilityTypeEnum} from "../common/types";
import PrivateUsersModal from "./PrivateUsersModal";
import SucceedAlert from "../common/SucceedAlert";
import CourseCloneModal from "./CourseCloneModal";
import {Roles} from "../auth/Auth";
import NotifyModal from "./NotifyModal";

const Editor = React.lazy(() => import("../common/lazy/AdminCKEditor"));

type Props = {
    course: AdminFullCourse
}

type SelectOption = { value: string, label: string };


function UploadPhoto({course, setError}: { setError: (e: Error) => void } & Props) {
    const fileInput = useRef<HTMLInputElement>(null)
    const [uploading, setUploading] = useState<boolean>(false);
    const {userId} = useContext(AuthContext)
    const uploadImage = () => {
        if (fileInput.current != undefined && fileInput.current.click != undefined) {
            fileInput.current.click()
        }

    }

    const handleUpload = (e: any) => {
        const target = e.target as HTMLInputElement
        if (target.files && target.files.length > 0) {
            setUploading(true)
            ApiFactory.Instance().CourseAPI().adminCourseUploadPhoto(course.id, target.files[0])
                .then(resp => {
                    setUploading(false)
                    mutate(["admin/course", course.id, userId])
                }).catch(e => {
                setUploading(false)
                setError(e)
            })
        }
    }

    return (
        <>
            {uploading
                ? <PhotoLoader/>
                : <Image className={'course__photo as-link'}
                         src={course.photo ? UPLOAD_BASE_PATH + course.photo : '/images/no-image.png'}
                         onClick={uploadImage}
                />
            }
            <input ref={fileInput} type={'file'} hidden={true} onChange={handleUpload}/>
        </>

    )

}

type FormEditParams = {
    course_status_id: number
} & CourseEditParams

const formValidationSchema = yup.object<FormEditParams>({
    name: yup.string().required(),
    category_ids: yup.array<number>(),
    teacher_ids: yup.array<number>(),
    min_rating: yup.number().required(),
    description: yup.string(),
    course_status_id: yup.number().required(),
    course_availability_type_id: yup.number().required(),
    display_progress: yup.boolean().required(),
    is_portfolio_available: yup.boolean().required(),
})

export default function CourseEdit({course}: Props) {
    const navigate = useNavigate();
    const {userId, roles} = useContext(AuthContext)
    const [error, setError] = useState<Error | undefined>(undefined);
    const [showCloneModal, setShowCloneModal] = useState<boolean>(false);
    const [showNotifyModal, setShowNotifyModal] = useState<boolean>(false);
    const [succeedMessage, setSucceedMessage] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string>(course.description || "")

    const [availableCategories, setAvailableCategories] = useState<SelectOption[]>([])
    const [availableStatuses, setAvailableStatuses] = useState<SelectOption[]>([])
    const [availableAvailabilityTypes, setAvailableAvailabilityTypes] = useState<CourseAvailabilityType[]>([])
    const [selectCategoryIds, setSelectCategoryIds] = useState<SelectOption[]>(
        useMemo(() => course.categories.map(c => ({value: "" + c.id, label: c.name})), [course])
    );
    const [selectStatusId, setSelectStatusId] = useState<SelectOption | undefined>(course.status ? {
        value: "" + course.status.id,
        label: course.status.name
    } : undefined)


    const [selectedAvailabilityTypeId, setSelectedAvailabilityTypeId] = useState<number>(course.course_availability_type.id)

    const [selectTeacherIds, setSelectTeacherIds] = useState<SelectOption[]>(
        useMemo(() => course.teachers.map(t => ({value: "" + t.id, label: t.name})), [course])
    )

    const [selectAdminIds, setSelectAdminIds] = useState<SelectOption[]>(
        useMemo(() => course.admins.map(t => ({value: "" + t.id, label: t.name})), [course])
    )


    const [showPrivateUsersModal, setShowPrivateUsersModal] = useState<boolean>(false)

    useEffect(() => {
        ApiFactory.Instance().StaticAPI().staticGet(["course_category", "course_status", "course_availability_type"])
            .then(resp => {
                if (resp.course_category === undefined) {
                    setAvailableCategories([])
                    return
                }
                if (resp.course_status === undefined) {
                    setAvailableStatuses([])
                    return
                }

                if (resp.course_availability_type === undefined) {
                    setAvailableAvailabilityTypes([])
                    return
                }
                setAvailableCategories(resp.course_category.map<SelectOption>(c => ({value: "" + c.id, label: c.name})))
                setAvailableStatuses(resp.course_status.map<SelectOption>(c => ({value: "" + c.id, label: c.name})))
                setAvailableAvailabilityTypes(resp.course_availability_type)
            }).catch(e => {
            setAvailableCategories([])
            setAvailableStatuses([])
        });
    }, [])

    const loadAdmins = _.debounce((input: string, callback: (opts: SelectOption[]) => void) => {
        ApiFactory.Instance().UserAPI().adminUserList(input, undefined, 0, DEFAULT_PER_PAGE)
            .then(resp => {
                callback(resp.items.map<SelectOption>(u => ({value: "" + u.id, label: u.name})))
            }).catch(e => {
            callback([])
        })
    }, 500);

    const loadTeachers = _.debounce((input: string, callback: (opts: SelectOption[]) => void) => {
        ApiFactory.Instance().UserAPI().adminUserList(input, undefined, 0, DEFAULT_PER_PAGE)
            .then(resp => {
                callback(resp.items.map<SelectOption>(u => ({value: "" + u.id, label: u.name})))
            }).catch(e => {
            callback([])
        })
    }, 500)

    const {register, handleSubmit, errors, setValue} = useForm<FormEditParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            name: course.name,
            category_ids: useMemo(() => course.categories.map(c => c.id), [course]),
            teacher_ids: useMemo(() => course.teachers.map(t => t.id), [course]),
            admin_ids: useMemo(() => course.admins.map(t => t.id), [course]),
            min_rating: course.min_rating || 0,
            description: course.description || "",
            course_status_id: course.status!.id,
            course_availability_type_id: course.course_availability_type.id,
            display_progress: course.display_progress,
            is_portfolio_available: course.is_portfolio_available,
        }
    });
    useEffect(() => {
        register("category_ids");
        register("description");
        register("teacher_ids");
        register("admin_ids");
        register("course_status_id");
    }, [])



    const onSubmit = (params: FormEditParams) => {
        setError(undefined)
        ApiFactory.Instance().CourseAPI().updateCourse(course.id, params)
            .then(c => {
                if (course.status?.id !== params.course_status_id) {
                    return ApiFactory.Instance().CourseAPI().adminCourseSetStatus(course.id, params.course_status_id)
                }
                return Promise.resolve(c)
            }).then(() => {
            mutate(["admin/course", course.id, userId])
            setSucceedMessage("Данные сохранены")
        }).catch(e => setError(e));
    }

    return <Card className={'m-4'}>
        <NotifyModal show={showNotifyModal} courseId={course.id} onHide={() => setShowNotifyModal(false)}/>
        <CourseCloneModal show={showCloneModal} onHide={() => setShowCloneModal(false)} course={course}/>
        <PrivateUsersModal show={showPrivateUsersModal} setShow={setShowPrivateUsersModal} course_id={course.id}/>
        <Card.Body>
            {error && <ErrorHandler error={error}/>}
            {succeedMessage && <SucceedAlert message={succeedMessage} onHide={() => setSucceedMessage(undefined)}/>}
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xs={12} md={3}>
                        <UploadPhoto course={course} setError={setError}/>
                    </Col>
                    <Col xs={12} md={9}>
                        <Form.Group>
                            <Form.Label>Название курса</Form.Label>
                            <Form.Control ref={register} name={"name"}/>
                            {errors && errors.name &&
                            <Form.Text className={'text-danger'}>{(errors.name as any)?.message}</Form.Text>}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Статус курса</Form.Label>
                            <Select value={selectStatusId}
                                    placeholder={'Выберите статус'}
                                    onChange={(v: any) => {
                                        const val = v as SelectOption;
                                        setSelectStatusId(val)
                                        const newVal = Number(v.value)
                                        setValue("course_status_id", newVal)
                                    }}
                                    options={availableStatuses}
                            />
                            {errors && errors.course_status_id &&
                            <Form.Text className={'text-danger'}>{errors.course_status_id}</Form.Text>}
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group>
                    <Form.Label>Категории</Form.Label>
                    <Select value={selectCategoryIds}
                            placeholder={'Выберите категории'}
                            isMulti={true}
                            onChange={(v: any) => {
                                let val: SelectOption[] = []
                                if (v != null) {
                                    val = v as SelectOption[];
                                }
                                setSelectCategoryIds(val)
                                const newVal = val.map(v => Number(v.value))
                                setValue("category_ids", newVal)
                            }}
                            options={availableCategories}
                    />
                    {errors && errors.category_ids &&
                    <Form.Text className={'text-danger'}>Некорректно выбраны категории</Form.Text>}
                </Form.Group>
                {roles.has(Roles.Admin)
                && <>
                    <Form.Group>
                        <Form.Label>Преподаватели</Form.Label>
                        <AsyncSelect value={selectTeacherIds}
                                     placeholder={'Выберите преподавателя'}
                                     loadOptions={loadTeachers}
                                     isMulti={true}
                                     defaultOptions={true}
                                     onChange={(v: any) => {
                                         let val: SelectOption[] = []
                                         if (v != null) {
                                             val = v as SelectOption[];
                                         }
                                         setSelectTeacherIds(val)
                                         const newVal = val.map(v => Number(v.value))
                                         setValue("teacher_ids", newVal)
                                     }}
                        />
                        {errors && errors.teacher_ids &&
                        <Form.Text className={'text-danger'}>Некорректно выбраны преподаватели</Form.Text>}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Администраторы</Form.Label>
                        <AsyncSelect value={selectAdminIds}
                                     placeholder={'Выберите администратора'}
                                     loadOptions={loadAdmins}
                                     isMulti={true}
                                     defaultOptions={true}
                                     onChange={(v: any) => {
                                         let val: SelectOption[] = []
                                         if (v != null) {
                                             val = v as SelectOption[];
                                         }
                                         setSelectAdminIds(val)
                                         const newVal = val.map(v => Number(v.value))
                                         setValue("admin_ids", newVal)
                                     }}
                        />
                        {errors && errors.admin_ids &&
                        <Form.Text className={'text-danger'}>Некорректно выбраны администраторы</Form.Text>}
                    </Form.Group>
                </>
                }
                <Form.Group>
                    <Form.Label>Минимальный рейтинг для завершения курса</Form.Label>
                    <Form.Control type={'number'} ref={register} name={"min_rating"}/>
                    {errors && errors.min_rating &&
                    <Form.Text className={'text-danger'}>{errors.min_rating.message}</Form.Text>}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Тип доступности курса для прохождения</Form.Label>
                    <Form.Row>
                        <Col xs={selectedAvailabilityTypeId === CourseAvailabilityTypeEnum.Public ? 12 : 9}>
                            <DictionarySelect
                                items={availableAvailabilityTypes}
                                initial={course.course_availability_type}
                                propertyName={"course_availability_type_id"}
                                register={register}
                                onValueChanged={i => setSelectedAvailabilityTypeId(i.id)}
                                setValue={setValue}/>
                        </Col>
                        {selectedAvailabilityTypeId === CourseAvailabilityTypeEnum.Private
                        && <Col xs={3}>
                            <Button
                                block={true}
                                className={'text-white'}
                                variant={'primary'}
                                onClick={() => setShowPrivateUsersModal(true)}
                            >Пользователи</Button>
                        </Col>
                        }
                    </Form.Row>

                    {errors && errors.course_availability_type_id &&
                    <Form.Text className={'text-danger'}>{errors.course_availability_type_id.message}</Form.Text>}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Описание курса</Form.Label>
                    <Suspense fallback={<Loader/>}>
                        <Editor initialContent={description} onContentChange={text => {
                            setDescription(text)
                            setValue("description", text)
                        }}/>
                    </Suspense>
                    {errors && errors.description &&
                    <Form.Text className={'text-danger'}>{(errors.description as any)?.message}</Form.Text>}
                </Form.Group>

                <Form.Group>
                    <Form.Check
                        ref={register}
                        name={'display_progress'}
                        label={'Отображать пользователю информацию о прогрессе прохождения'}
                        />
                    {errors && errors.display_progress &&
                    <Form.Text className={'text-danger'}>{(errors.display_progress as any)?.message}</Form.Text>}
                </Form.Group>

                <Form.Group>
                    <Form.Check
                        ref={register}
                        name={'is_portfolio_available'}
                        label={'Функционал портфолио включен на курсе'}
                    />
                    {errors && errors.is_portfolio_available &&
                    <Form.Text className={'text-danger'}>{(errors.is_portfolio_available as any)?.message}</Form.Text>}
                </Form.Group>

                <div className={'float-right'}>
                    <Button className={'m-2'} variant={'success'} onClick={() => setShowNotifyModal(true)}>Отправить сообщение пользователям</Button>
                    <Button variant={'danger'} onClick={() => setShowCloneModal(true)}>Клонировать курс</Button>
                    <Button type={'submit'} className={'text-white m-2'}
                            variant={'primary'}>Сохранить</Button>
                </div>

            </Form>
        </Card.Body>
    </Card>
}