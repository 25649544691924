import * as React from 'react';
import {ReactElement, useContext} from "react";
import Button from "react-bootstrap/Button";
import ApiFactory from "../../api/ApiFactory";
import {mutate} from "swr";
import {SubmoduleCompletionStatus} from "../submod/types";
import Card from "react-bootstrap/Card";
import ErrorHandler from "../ErrorHandler";
import {UserFullCourseSubmodule} from "../../api/generated";
import {AuthContext} from "../auth/AuthContext";

type Props = {
    submodule: UserFullCourseSubmodule,
    courseId: number,
    courseCompletionId: number,
    setDisplayForm: (d: boolean) => void,
    error: Error|undefined,
    setError: (e: Error|undefined) => void,
}

export default function PreviousSubmoduleResult({submodule, courseId, courseCompletionId, setDisplayForm, setError, error}: Props) {
    const {userId} = useContext(AuthContext);
    let content: ReactElement | null = null;
    if (!submodule.completion) {
        content = <Button variant={'primary text-white mt-4'} onClick={() => {
            ApiFactory.Instance().CourseSubmoduleCompletionAPI().userCreateCourseSubmoduleCompletion(courseId, courseCompletionId, submodule.id, true)
                .then(() => {
                    mutate(['user/course-submodule', submodule.id, userId])
                    setDisplayForm(true)
                }).catch(e => setError(e))
        }}>Перейти к заданию</Button>
    } else if (submodule.completion.status.id == SubmoduleCompletionStatus.Finished || submodule.completion.status.id == SubmoduleCompletionStatus.AcceptedAsDraft) {
        content = <>
            <span><img src={'/images/finished.png'} className={'small-icon'}/> Ваш предыдущий результат: {submodule.completion.points} балла(ов)</span>
            <div>
                <Button variant={'primary text-white mt-4'} onClick={() => {
                    ApiFactory.Instance().CourseSubmoduleCompletionAPI().userCreateCourseSubmoduleCompletion(courseId, courseCompletionId, submodule.id, true)
                        .then(() => {
                            mutate(['user/course-submodule', submodule.id, userId])
                            setDisplayForm(true)
                        }).catch(e => setError(e))
                }}>Пройти задание заново</Button>
            </div>
        </>
    } else if (submodule.completion.status.id == SubmoduleCompletionStatus.InProgress) {
        content = <Button variant={'primary text-white  mt-4'} onClick={() => {
            setDisplayForm(true)
        }}>Продолжить</Button>
    } else if (submodule.completion.status.id == SubmoduleCompletionStatus.WaitForReview ||
        submodule.completion.status.id == SubmoduleCompletionStatus.WaitForReviewNonBlocking
    ) {
        content = <span className={'text-danger'}>Ожидает проверки</span>
    } else if (submodule.completion.status.id == SubmoduleCompletionStatus.Declined) {
        content = <div>
            <span className={'text-danger'}>Предыдущая отправка была отклонена</span>
            <div className={'mt-2'}>
                <div><small>Комментарий преподавателя:</small></div>
                <div>{submodule.completion.teacher_comment}</div>
            </div>
            <div>
                <Button variant={'primary text-white mt-4'} onClick={() => {
                    ApiFactory.Instance().CourseSubmoduleCompletionAPI().userCreateCourseSubmoduleCompletion(courseId, courseCompletionId, submodule.id, true)
                        .then(() => {
                            mutate(['user/course-submodule', submodule.id, userId])
                            setDisplayForm(true)
                        }).catch(e => setError(e))
                }}>Пройти задание заново</Button>
            </div>
        </div>
    }
    return <Card className={'m-4'}>
        <Card.Body>
            {error && <ErrorHandler error={error}/>}
            <h5 className={'text-primary'}>
                {submodule.name}
            </h5>
            {content}
        </Card.Body>
    </Card>
}