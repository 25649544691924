import * as React from 'react';
import {useContext} from 'react';
import {UserFullCourseModule, UserFullCourseSection, UserShortCourseSubmodule} from "../../api/generated";
import useSWR from "swr";
import ApiFactory from "../../api/ApiFactory";
import ErrorHandler from "../ErrorHandler";
import {AuthContext} from "../auth/AuthContext";
import {Loader} from "../Loaders";
import Card from 'react-bootstrap/Card';
import HtmlParser from 'react-html-parser'
import {UserSubmoduleItem} from "../common/SubmoduleItem";
import {useNavigate} from "@reach/router";
import {DiscussionTypeEnum} from "../../models/discussion";
import DiscussionsCollapse from "./DiscussionsCollapse";
import InfoMessage, {Level} from "../common/InfoMessage";

type Props = {
    id: number
    courseId: number
    completionId: number
}

function SectionContent({section, onSubmoduleClick}: { section: UserFullCourseSection, onSubmoduleClick: (sub: UserShortCourseSubmodule) => void }) {
    return <Card style={{marginTop: '3rem'}}>
        <Card.Body className={'p-4'}>
            <h5>{section.name}</h5>

            {section.description && <div>
                {HtmlParser(section.description)}
            </div>}
        </Card.Body>
        <Card.Body>
            {section.submodules && section.submodules.map(sub => <UserSubmoduleItem key={`sub-${sub.id}`}
                                                                                    submodule={sub}
                                                                                    onSubmoduleClick={onSubmoduleClick}/>)}
        </Card.Body>
    </Card>
}

export default function ModuleContent({id, courseId, completionId}: Props) {
    const navigate = useNavigate();
    const userId = useContext(AuthContext).userId
    const {data: module, error} = useSWR<UserFullCourseModule>(() => {
            if (!completionId) {
                return null
            }
            return ['/user/course-module', id, userId]
        },
        () => ApiFactory.Instance().CourseModuleAPI().userViewCourseModule(id, courseId))


    if (error) {
        return <ErrorHandler error={error}/>
    }
    if (module === undefined) {
        return <Loader/>
    }

    return <>
        <h3>{module.name}</h3>
        {!module.availability_status.available && <InfoMessage level={Level.Warn} message={module.availability_status.message || 'Модуль недоступен для прохождения'}/>}

        {module.availability_status.available && <DiscussionsCollapse externalId={module.id} type={DiscussionTypeEnum.Module}/>}

        {
            module.sections.map(sec => <SectionContent key={`section-${sec.id}`} section={sec} onSubmoduleClick={sub =>
                navigate(`/user/course/${courseId}/module/${module.id}/completion/${completionId}/submodule/${sub.id}`)}/>)
        }
    </>
}