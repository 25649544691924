import * as React from "react";
import {ReactElement, Suspense, useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "../auth/AuthContext";
import {ModuleAvailabilityStrategyEnum} from "../common/types";
import {AdminFullCourseModule, CourseModuleAvailabilityStrategy, UpdateModuleParams} from "../../api/generated";
import ApiFactory from "../../api/ApiFactory";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import {mutate} from "swr";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import ErrorHandler from "../ErrorHandler";
import DictionarySelect from "../common/DictionarySelect";
import * as yup from "yup";
import {LineLoader} from "../Loaders";

const DatePicker = React.lazy(() => import("react-datepicker"));

type Props = {
    courseId?: number,
    module: AdminFullCourseModule,
}

type FormParams = {
    startTime?: string,
    isAvailable?: boolean,
} & UpdateModuleParams

const formValidationSchema = yup.object<FormParams>({
    name: yup.string().required(),
    weight: yup.number().required(),
    course_module_availability_strategy_id: yup.number().required(),
    course_module_availability_strategy_data: yup.mixed(),
});

export function ModuleEdit({courseId, module, onSucceed, onCancel}: { onSucceed: () => void, onCancel: () => void } & Props) {
    const {userId} = useContext(AuthContext);
    const form = useRef<HTMLFormElement>(null)
    const [strategyId, setStrategyId] = useState<number>(
        module.course_module_availability_strategy?.id
            ? module.course_module_availability_strategy?.id
            : ModuleAvailabilityStrategyEnum.Auto
    );

    const [startTime, setStartTime] = useState<Date>(module.course_module_availability_strategy_data.start_time
        ? new Date(module.course_module_availability_strategy_data.start_time)
        : new Date()
    );

    const [error, setError] = useState<Error | undefined>(undefined);

    const [availableStrategies, setAvailableStrategies] = useState<CourseModuleAvailabilityStrategy[]>([])
    useEffect(() => {
        ApiFactory.Instance().StaticAPI().staticGet(["course_module_availability_strategy",])
            .then(resp => {
                if (resp.course_module_availability_strategy === undefined) {
                    setAvailableStrategies([])
                } else {
                    setAvailableStrategies(resp.course_module_availability_strategy)
                }
            })
    }, [])

    const {register, handleSubmit, errors, setValue, reset, getValues} = useForm<FormParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            name: module.name,
            weight: module.weight,
            startTime: module.course_module_availability_strategy_data.start_time ?? startTime.toISOString(),
            isAvailable: module.course_module_availability_strategy_data.is_available,
            course_module_availability_strategy_id: module.course_module_availability_strategy.id,
        }
    });

    useEffect(() => {
        register("startTime")
    }, [])

    const onSubmit = (params: FormParams) => {
        if (params.course_module_availability_strategy_id == ModuleAvailabilityStrategyEnum.ByTime && params.startTime === undefined) {
            setError(new Error("Необходимо заполнить время доступности"))
        }

        if (params.course_module_availability_strategy_id == ModuleAvailabilityStrategyEnum.Manual && params.isAvailable === undefined) {
            setError(new Error("Необходимо заполнить признак доступности"))
        }
        let typeParams = {}
        switch (params.course_module_availability_strategy_id) {
            case ModuleAvailabilityStrategyEnum.Auto:
                typeParams = {}
                break;
            case ModuleAvailabilityStrategyEnum.ByTime:
                typeParams = {
                    start_time: params.startTime
                }
                break;
            case ModuleAvailabilityStrategyEnum.Manual:
                typeParams = {
                    is_available: params.isAvailable
                }
        }

        setError(undefined)
        ApiFactory.Instance().CourseModuleAPI().adminUpdateCourseModule(module.id, {
            course_module_availability_strategy_data: typeParams,
            course_module_availability_strategy_id: params.course_module_availability_strategy_id,
            name: params.name,
            weight: params.weight,
        })
            .then(m => {
                onSucceed()
                mutate(["admin/course", courseId, userId])
                mutate(["admin/course-module", module.id, userId])
            }).catch(e => setError(e))
    }

    let strategyContent: ReactElement | null = null;

    switch (strategyId) {
        case ModuleAvailabilityStrategyEnum.Manual:
            strategyContent = <Form.Group>
                <Form.Label>Модуль доступен</Form.Label>
                <Form.Check type={"checkbox"} name={"isAvailable"} ref={register}/>
                {errors && errors.isAvailable &&
                <Form.Text
                    className={'text-danger'}>{errors.isAvailable.message}</Form.Text>}
            </Form.Group>
            break;
        case ModuleAvailabilityStrategyEnum.ByTime:
            strategyContent = <Form.Group>
                <Form.Label>Дата с которой доступен модуль</Form.Label>
                <Suspense fallback={<LineLoader/>}>
                    <DatePicker dateFormat={'dd.MM.yyyy'} selected={startTime} className={'form-control'}
                                onChange={val => {
                                    const res = val as Date
                                    res.setHours(0, 0, 0, 0)
                                    setValue("startTime", res.toISOString())
                                    setStartTime(res)
                                }}/>
                </Suspense>
                {errors && errors.startTime &&
                <Form.Text
                    className={'text-danger'}>{errors.startTime.message}</Form.Text>}
            </Form.Group>
            break;
    }

    return <Card className={'m-4'}>
        <Card.Body>
            {error && <ErrorHandler error={error}/>}
            <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
                <div className={'d-flex flex-row align-items-center justify-content-end'}>
                    <div className={'d-flex flex-row'}>
                        <img className={'button-icon ml-4 as-link'} src={"/images/cancel.svg"}
                             onClick={onCancel}/>
                        <img className={'button-icon ml-4 as-link'} src={"/images/save.svg"}
                             onClick={() => {
                                 if (form.current) {
                                     form.current.dispatchEvent(new Event('submit', {cancelable: true}))
                                 }
                             }}/>
                    </div>
                </div>
                <Form.Group>
                    <Form.Label>Название модуля</Form.Label>
                    <Form.Control ref={register} name={"name"}/>
                    {errors && errors.name &&
                    <Form.Text className={'text-danger'}>{(errors.name as any)?.message}</Form.Text>}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Вес</Form.Label>
                    <Form.Control type={'number'} ref={register} name={"weight"}/>
                    {errors && errors.weight &&
                    <Form.Text className={'text-danger'}>{errors.weight.message}</Form.Text>}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Политика доступности модуля</Form.Label>
                    <DictionarySelect items={availableStrategies}
                                      initial={module.course_module_availability_strategy}
                                      propertyName={"course_module_availability_strategy_id"}
                                      register={register}
                                      setValue={setValue}
                                      onValueChanged={i => setStrategyId(i.id)}
                    />
                </Form.Group>

                {strategyContent}
            </Form>
        </Card.Body>

    </Card>
}