import * as React from 'react';
import {UserFullCourse} from "../../api/generated";
import Progress from 'react-bootstrap/ProgressBar'
import CommonCourseHeader from "../common/CourseHeader"

type Props = {
    course?: UserFullCourse,
    loading: boolean
}

function ProgressInfo({course}: { course?: UserFullCourse }) {
    if (!course) {
        return null
    }
    return (
        <>
            {course.progress !== undefined && <div className={'p-2 flex-fill'}>
                Завершен на {course.progress}%
                <Progress now={course.progress}/>
            </div>}
            {course.min_rating !== undefined && course.min_rating > 0 && <div className={'p-2 flex-fill'}>
                Минимальный рейтинг для прохождения курса - {course.min_rating}%
            </div>}
            {course.rating !== undefined && <div className={'p-2 flex-fill'}>
                Ваш текущий рейтинг - {course.rating}%
            </div>}
        </>
    )
}

export default function CourseHeader({course, loading}: Props) {
    return <CommonCourseHeader loading={loading} course={course} bottom={<ProgressInfo course={course}/>}/>
}