import * as React from 'react';
import {Suspense, useContext, useEffect, useMemo, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {RouteComponentProps, useNavigate} from "@reach/router";
import ApiFactory, {DEFAULT_PER_PAGE} from "../../../../api/ApiFactory";
import useSWR, {mutate} from "swr";
import {AuthContext} from "../../../../components/auth/AuthContext";
import ContentContainer from "../../../../components/common/ContentContainer";
import {LineLoader, ListLoader, Loader} from "../../../../components/Loaders";
import ErrorHandler, {AlertSize} from "../../../../components/ErrorHandler";
import Pagination from "../../../../components/common/Pagination";
import Card from "react-bootstrap/Card";
import UserPhoto from "../../../../components/common/UserPhoto";
import {DATETIME_FORMAT} from "../../../../config";
import moment from 'moment';
import {
    CommentCreateParams,
    CommentEditParams,
    DiscussionTopicComment, FullDiscussionTopic,
    FullUserDiscussionTopic,
    TopicCreateParams,
    TopicEditParams
} from "../../../../api/generated";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import RichContent from "../../../../components/common/RichContent";
import HorizontalScroll, {Item} from "../../../../components/common/HorizontalScroll";

const Editor = React.lazy(() => import("../../../../components/common/lazy/CKEditor"));



type BreadcrumbsProps = {
    topic?: FullDiscussionTopic,
    error?: Error
}

function Breadcrumbs(props: BreadcrumbsProps) {
    const navigate = useNavigate()
    const {topic, error} = props

    const items = useMemo(() => {
        if (!props.topic) {
            return [];
        }
        const topic = props.topic!;
        const res: Item[] = [];
        res.push({title: 'Список курсов /', active: false, onClick: () => navigate("/admin")});
        res.push({
            title: topic.breadcrumbs.course.name + " /",
            active: false,
            onClick: () => navigate(`/user/course/${topic.breadcrumbs.course.id}`)
        })
        if (props.topic.breadcrumbs.module && props.topic.breadcrumbs.module.id) {
            res.push({
                title: topic.breadcrumbs.module.name + " /",
                active: false,
                onClick: () => navigate(`/user/course/${topic.breadcrumbs.course.id}/module/${topic.breadcrumbs.module.id}`)
            })
        }
        res.push({
            title: topic.breadcrumbs.discussion.name + " /",
            active: false,
            onClick: () => navigate(`/user/discussion/${topic.breadcrumbs.discussion.id}`)
        });
        res.push({title: props.topic.name, active: true})

        return res;
    }, [props.topic])

    if (error !== undefined) {
        return null
    }
    if (topic === undefined) {
        return <LineLoader/>
    }

    return <div className={'mt-4'}>
        <HorizontalScroll alignCenter={false} items={items}/>
    </div>
}

type Props = {
    id?: string
    discussionId?: string
} & RouteComponentProps

enum Mode {
    View,
    Edit,
}


type CommentItemProps = {
    topicId: number,
    comment: DiscussionTopicComment
    offset: number
    onCommentChanged: () => void
}

enum CommentItemMode {
    View,
    Edit,
    Comment
}

type CommentCreateFormProps = {
    topicId: number,
    parentCommentId?: number
    onFinish: () => void,
    onCancel?: () => void,
}

function CommentCreateForm(props: CommentCreateFormProps) {
    const formValidationSchema = yup.object<CommentCreateParams>({
        text: yup.string().required("Вы должны написать комментарий")
    })

    const {register, handleSubmit, errors, setValue} = useForm<CommentCreateParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            parent_comment_id: props.parentCommentId,
            text: "",
        }
    });

    useEffect(() => {
        register("text")
        register("parent_comment_id")
    }, [])

    const [error, setError] = useState<Error | undefined>(undefined);
    const onSubmit = (params: CommentCreateParams) => {
        setError(undefined);
        ApiFactory.Instance().DiscussionTopicCommentAPI().userCreateTopicComment(props.topicId, params)
            .then(val => {
                setValue("text", "")
                props.onFinish()
            })
            .catch(e => setError(e))
    };

    return <Form onSubmit={handleSubmit(onSubmit)}>
        {error && <ErrorHandler size={AlertSize.Small} error={error}/>}
        <Form.Group>
            <Suspense fallback={<Loader/>}>
                <Editor initialContent={""} onContentChange={text => setValue("text", text)}/>
            </Suspense>
            {errors.text && <Form.Text>{(errors.text as any)?.message}</Form.Text>}
        </Form.Group>
        <div className={'d-flex flex-row justify-content-end'}>
            <Button type={'submit'} variant={'primary'}
                    className={'text-white'}>Сохранить</Button>
            {props.onCancel &&
            <Button className={'ml-2'} variant={'light'} onClick={() => {
                setValue("text", "")
                if (props.onCancel) {
                    props.onCancel()
                }
            }}>Отменить</Button>
            }

        </div>
    </Form>
}

type CommentEditFormProps = {
    comment: DiscussionTopicComment,
    topicId: number,
    onFinish: () => void,
    onCancel: () => void,
}

function CommentEditForm(props: CommentEditFormProps) {
    const formValidationSchema = yup.object<CommentEditParams>({
        text: yup.string().required("Вы должны написать комментарий")
    })

    const {register, handleSubmit, errors, setValue} = useForm<CommentEditParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            text: props.comment.text,
        }
    });

    useEffect(() => {
        register("text")
    }, [])

    const [error, setError] = useState<Error | undefined>(undefined);
    const onSubmit = (params: CommentEditParams) => {
        setError(undefined);
        ApiFactory.Instance().DiscussionTopicCommentAPI().userUpdateTopicComment(props.topicId, props.comment.id, params)
            .then(val => {
                props.onFinish()
            })
            .catch(e => setError(e))
    };

    return <Form onSubmit={handleSubmit(onSubmit)}>
        {error && <ErrorHandler size={AlertSize.Small} error={error}/>}
        <Form.Group>
            <Suspense fallback={<Loader/>}>
                <Editor initialContent={props.comment.text} onContentChange={text => setValue("text", text)}/>
            </Suspense>
            {errors.text && <Form.Text>{(errors.text as any)?.message}</Form.Text>}
        </Form.Group>
        <div className={'d-flex flex-row justify-content-end'}>
            <Button type={'submit'} variant={'primary'}
                    className={'text-white ml-2'}>Сохранить</Button>
            <Button className={'ml-2'} variant={'light'} onClick={props.onCancel}>Отменить</Button>
        </div>
    </Form>
}

function CommentItem(props: CommentItemProps) {
    const {userId} = useContext(AuthContext)
    const {comment, topicId} = props
    const offset = props.offset >= 6 ? props.offset : props.offset + 1
    const span = 12 - offset
    const [mode, setMode] = useState<CommentItemMode>(CommentItemMode.View)
    return <>
        <Row className={'mt-4 mb-4'}>
            <Col xs={{span: span, offset: offset}}>
                <Card>
                    <Card.Body className={'d-flex flex-row justify-content-between'}>
                        <div>
                            <UserPhoto user={comment.created_by} className={'small-user-photo'}/>
                            <span className={'small ml-2'}>{comment.created_by.name}</span>
                        </div>
                        <div className={'d-flex flex-row'}>
                            <span className={'x-small-text'}>{moment(comment.created_at).format(DATETIME_FORMAT)}</span>
                            {comment.updated_at && <span
                                className={'x-small-text ml-2'}>Изменен: {moment(comment.updated_at).format(DATETIME_FORMAT)}</span>}
                        </div>
                    </Card.Body>
                    <Card.Body>
                        {(mode === CommentItemMode.View || mode === CommentItemMode.Comment) &&
                        <RichContent text={comment.text} asSpan={true}/>}
                        {mode === CommentItemMode.Comment && <>
                            <h6 className={'mt-2 mb-2'}>Добавление комментария</h6>
                            <CommentCreateForm
                                topicId={topicId}
                                parentCommentId={comment.id}
                                onCancel={() => setMode(CommentItemMode.View)}
                                onFinish={() => {
                                    setMode(CommentItemMode.View)
                                    props.onCommentChanged()
                                }}/>
                        </>
                        }
                        {mode === CommentItemMode.Edit && <CommentEditForm comment={comment} topicId={topicId}
                                                                           onCancel={() => setMode(CommentItemMode.View)}
                                                                           onFinish={() => {
                                                                               setMode(CommentItemMode.View)
                                                                               props.onCommentChanged()
                                                                           }}/>
                        }
                    </Card.Body>
                    {mode === CommentItemMode.View &&
                    <Card.Body className={'d-flex flex-row justify-content-end align-items-center'}>
                        {comment.created_by.id === userId
                            ? <span className={'small pl-2 as-link discussion-comment-button'}
                                    onClick={() => setMode(CommentItemMode.Edit)}>Изменить</span>
                            : <span className={'small pl-2 as-link discussion-comment-button'}
                                    onClick={() => setMode(CommentItemMode.Comment)}>Ответить</span>
                        }
                    </Card.Body>
                    }
                </Card>
            </Col>
        </Row>
        {comment.comments && comment.comments.map(c => <CommentItem key={`comm-${c.id}`} comment={c} offset={offset}
                                                                    topicId={topicId}
                                                                    onCommentChanged={() => {
                                                                        props.onCommentChanged()
                                                                    }}/>)}
    </>
}

type CommentListProps = {
    topicId: number,
}

function CommentList(props: CommentListProps) {
    const {userId} = useContext(AuthContext)
    const [currentPage, setCurrentPage] = useState<number>(0);
    const fetcher = (key: any, id: number, userId: any, currentPage: number) =>
        ApiFactory.Instance().DiscussionTopicCommentAPI().userTopicComments(id, currentPage, DEFAULT_PER_PAGE)
    const {data: comments, error} = useSWR(['user/discussion-topic-comments', props.topicId, userId, currentPage], fetcher)
    if (error !== undefined) {
        return <ErrorHandler error={error}/>
    }

    if (comments === undefined) {
        return <ListLoader/>
    }

    return <>
        {comments.items.map(comm => <CommentItem key={`comm-${comm.id}`} comment={comm} offset={0}
                                                 topicId={props.topicId}
                                                 onCommentChanged={() => {
                                                     mutate(['user/discussion-topic-comments', props.topicId, userId, currentPage])
                                                 }}/>)}
        <h5 className={'mt-4 mb-4'}>Добавление комментария</h5>
        <CommentCreateForm topicId={props.topicId} onFinish={() => {
            mutate(['user/discussion-topic-comments', props.topicId, userId, currentPage])
        }}/>
        <Pagination totalItems={comments.total} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
    </>
}

type EditTopicProps = {
    discussionId: number,
    topic: FullUserDiscussionTopic,
    onFinish: () => void,
    onCancel: () => void,
}

function EditTopic(props: EditTopicProps) {
    const formValidationSchema = yup.object<TopicCreateParams>({
        text: yup.string().required("Текст темы не может быть пустым"),
        name: yup.string().required("Название темы не может быть пустым")
    })

    const {register, handleSubmit, errors, setValue} = useForm<TopicEditParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            name: props.topic.name,
            text: props.topic.text,
        }
    });

    useEffect(() => {
        register("text")
    }, [])

    const [error, setError] = useState<Error | undefined>(undefined);
    const onSubmit = (params: TopicEditParams) => {
        setError(undefined);
        ApiFactory.Instance().DiscussionTopicAPI().teacherUpdateDiscussionTopic(props.discussionId, props.topic.id, params)
            .then(val => {
                props.onFinish()
            })
            .catch(e => setError(e))
    };


    return <Form onSubmit={handleSubmit(onSubmit)}>
        {error && <ErrorHandler size={AlertSize.Small} error={error}/>}
        <Form.Group>
            <Form.Label>Название темы</Form.Label>
            <Form.Control name={"name"} ref={register}/>
            {errors.name && <Form.Text>{(errors.name as any)?.message}</Form.Text>}
        </Form.Group>
        <Form.Group>
            <Form.Label>Текст</Form.Label>
            <Suspense fallback={<Loader/>}>
                <Editor initialContent={props.topic.text} onContentChange={text => setValue("text", text)}/>
            </Suspense>

            {errors.text && <Form.Text>{(errors.text as any)?.message}</Form.Text>}
        </Form.Group>
        <div className={'d-flex flex-row justify-content-end'}>
            <Button type={'submit'} variant={'primary'}
                    className={'text-white'}>Сохранить</Button>
            {props.onCancel &&
            <Button className={'ml-2'} variant={'light'} onClick={() => {
                setValue("text", "")
                if (props.onCancel) {
                    props.onCancel()
                }
            }}>Отменить</Button>
            }

        </div>
    </Form>
}

export default function Topic(props: Props) {
    const id = Number(props.id)
    const [mode, setMode] = useState<Mode>(Mode.View)
    const discussionId = Number(props.discussionId)
    const {userId} = useContext(AuthContext)
    const fetcher = () => ApiFactory.Instance().DiscussionTopicAPI().userDiscussionTopic(discussionId, id);
    const {data: topic, error: error, mutate} = useSWR(['user/topic', id, userId], fetcher)
    const [viewCount, setViewCount] = useState<number | undefined>(undefined)
    useEffect(() => {
        if (topic === undefined || viewCount !== undefined) {
            return
        }
        setViewCount(topic.view_count)
        ApiFactory.Instance().DiscussionTopicAPI().userDiscussionTopicIncViewCount(discussionId, id)
            .then(resp => setViewCount(resp.view_count))
            .catch(e => console.warn(e))
    }, [topic])

    if (Number.isNaN(id) || Number.isNaN(discussionId)) {
        return <ErrorHandler error={new Error("id or discussionId is not a number")}/>
    }

    const subscribe = () => {
        ApiFactory.Instance().DiscussionTopicAPI().userDiscussionTopicSubscribe(discussionId, id).then(() => {
            mutate()
        })
    }

    const unsubscribe = () => {
        ApiFactory.Instance().DiscussionTopicAPI().userDiscussionTopicUnsubscribe(discussionId, id).then(() => {
            mutate()
        })
    }

    return (
        <ContentContainer>
            <Breadcrumbs error={error} topic={topic}/>
            <hr className={'hr--divider'}/>
            <Row>
                <Col xs={12}>
                    {error && <ErrorHandler error={error}/>}
                    {topic === undefined && error === undefined && <Loader/>}
                    {topic !== undefined &&
                    <Card className={'mt-4 mb-4'}>
                        {mode === Mode.Edit
                            ? <Card.Body>
                                <EditTopic discussionId={discussionId} topic={topic} onFinish={() => {
                                    setMode(Mode.View)
                                    mutate()
                                }} onCancel={() => {
                                    setMode(Mode.View)
                                }}/>
                            </Card.Body>
                            : <>
                                <Card.Body>
                                    <Row>
                                        <Col className={'d-flex flex-row mt-2'} xs={12} md={8}>
                                            {topic.pinned && <img src={"/images/pinned.svg"} className={'small-icon'}/>}
                                            <div>
                                                <h6 className={'mb-2'}>{topic.name}</h6>
                                                {topic.is_private && <small>
                                                    <img src={'/images/private.svg'} className={'small-icon'}/>
                                                    Приватная тема
                                                </small>}
                                            </div>
                                        </Col>
                                        <Col className={'d-flex flex-row topic-counters mt-2'} xs={12} md={4}>
                                            <div className={'d-flex flex-row'}>
                                                <div className={'d-flex flex-column align-items-center pl-2 pr-2'}>
                                                    <span>{viewCount}</span>
                                                    <span className={'x-small-text'}>просмотров</span>
                                                </div>
                                                <div className={'d-flex flex-column align-items-center pl-2 pr-2'}>
                                                    <span>{topic.comment_count}</span>
                                                    <span className={'x-small-text'}>комментариев</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </Card.Body>
                                <Card.Body>
                                    <RichContent text={topic.text}/>
                                </Card.Body>
                                <Card.Body className={'d-flex flex-row justify-content-between'}>
                                    <div>
                                        <UserPhoto user={topic.created_by} className={'small-user-photo'}/>
                                        <span className={'small ml-2'}>{topic.created_by.name}</span>
                                    </div>
                                    <div className={'d-flex flex-row align-items-center'}>
                                        {topic.created_by.id === userId &&
                                        <div className={'d-flex flex-row align-items-center mr-4'}>
                                                <span className={'as-link small discussion-comment-button'}
                                                      onClick={() => setMode(Mode.Edit)}>Изменить</span>
                                        </div>
                                        }
                                        <div className={'d-flex flex-row align-items-center'}>
                                            <img src={'/images/notifications.png'}
                                                 className={'notification__icon--small mr-2'}/>
                                            {topic.subscribed
                                                ? <span className={'as-link discussion-comment-button small'}
                                                        onClick={unsubscribe}>Отказаться от уведомлений</span>
                                                : <span className={'as-link discussion-comment-button small'}
                                                        onClick={subscribe}>Хочу получать уведомления</span>
                                            }

                                        </div>
                                    </div>
                                </Card.Body>
                            </>
                        }
                    </Card>
                    }
                </Col>
            </Row>
            <CommentList topicId={id}/>
        </ContentContainer>
    )
}