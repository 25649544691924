import * as React from 'react'
import Home from "../pages/Home";
import ResetPassword from "../pages/user/ResetPassword";
import AvailableCourseView from "../pages/course/View";
import UserCourseView from "../pages/user/course/View";
import UserSubmoduleView from "../pages/user/submod/View";
import AdminHome from "../pages/admin/Home";
import AdminUserIndex from "../pages/admin/user/Index";
import AdminUserView from "../pages/admin/user/View";
import AdminCourseView from "../pages/admin/course/View";
import AdminSectionView from "../pages/admin/coursec/View";
import TeacherHome from "../pages/teacher/Home";
import TeacherCourseView from "../pages/teacher/course/View";
import TeacherSubmoduleView from "../pages/teacher/coursub/View";
import Profile from "../pages/user/profile/View";
import UserDiscussionView from "../pages/user/discussion/View";
import UserTopicView from "../pages/user/discussion/topic/View";
import TeacherDiscussionView from "../pages/teacher/discussion/View";
import TeacherTopicView from "../pages/teacher/discussion/topic/View";
import AdminDiscussionView from "../pages/admin/discussion/View";
import AdminTopicView from "../pages/admin/discussion/topic/View";
import {Router as ReachRouter} from "@reach/router";
import MyPortfolio from "../pages/user/portfolio/MyPortfolio";
import OtherUserPortfolio from "../pages/user/portfolio/OtherUserPortfolio";
import UserPortfolio from "../pages/teacher/portfolio/UserPortfolio";

export default function Router() {
    return <ReachRouter>
        <Home path={"/"}/>
        <ResetPassword path={"/reset-password/:token"}/>
        <Home path={"/action/:action/token/:token"}/>
        <Home path={"/action/:action"}/>
        <Home path={"/action/:action/:actionId"}/>
        <AvailableCourseView path={"/course/:id"}/>
        <AvailableCourseView path={"/course/:id/module/:moduleId"}/>
        <UserCourseView path={"/user/course/:id"}/>
        <UserCourseView path={"/user/course/:id/module/:moduleId"}/>
        <UserSubmoduleView
            path={"/user/course/:courseId/module/:moduleId/completion/:completionId/submodule/:id"}/>

        <AdminHome path={"/admin"}/>
        <AdminCourseView path={"/admin/course/:id"}/>
        <AdminCourseView path={"/admin/course/:id/:section"}/>
        <AdminCourseView path={"/admin/course/:id/module/:moduleId"}/>
        <AdminSectionView path={"/admin/course/:courseId/module/:moduleId/section/:id"}/>
        <AdminSectionView
            path={"/admin/course/:courseId/module/:moduleId/section/:id/submodule/:submoduleId"}/>
        <AdminUserIndex path={"/admin/user"}/>
        <AdminUserView path={"/admin/user/:id"}/>
        <TeacherHome path={"/teacher"}/>
        <TeacherCourseView path={"/teacher/course/:id"}/>
        <TeacherCourseView path={"/teacher/course/:id/module/:moduleId"}/>
        <TeacherSubmoduleView
            path={"/teacher/course/:courseId/module/:moduleId/submodule/:id"}/>
        <Profile path={"/user/profile"}/>
        <UserDiscussionView path={"/user/discussion/:id"}/>
        <UserTopicView path={"/user/discussion/:discussionId/topic/:id"}/>
        <TeacherDiscussionView path={"/teacher/discussion/:id"}/>
        <TeacherTopicView path={"/teacher/discussion/:discussionId/topic/:id"}/>
        <AdminDiscussionView path={"/admin/discussion/:id"}/>
        <AdminTopicView path={"/admin/discussion/:discussionId/topic/:id"}/>
        <MyPortfolio path={"/user/portfolio/:completionId"}/>
        <OtherUserPortfolio path={"/user/portfolio/:completionId/:userId"}/>
        <UserPortfolio path={"/teacher/portfolio/:courseId/:userId"}/>
    </ReachRouter>
}