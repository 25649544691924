import * as React from 'react';
import {useContext, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {AuthContext} from "./AuthContext";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers';
import * as yup from "yup";
import {ErrorMessage, LoginParams, RegisterParams, ResetPasswordParams, TokensResponse} from "../../api/generated";
import {useTranslation} from "react-i18next";
import ApiFactory from "../../api/ApiFactory";
import ErrorHandler, {AlertSize} from "../ErrorHandler";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import LoadingButton from "../common/LoadingButton";
import Alert from 'react-bootstrap/Alert';
import FormIndicator from "../common/FormIndicator";

type Props = {}

enum CurrentForm {
    Login,
    Register,
    RepairPassword
}

type FormRegisterParams = {
    passwordConfirm?: string
} & RegisterParams;

type RegisterProps = {
    onLoginClicked: () => void;
    setLoggedIn: (tokens: TokensResponse) => void;
}

function RegisterForm(props: RegisterProps) {
    const {t} = useTranslation()

    const registerFormValidationSchema = yup.object<FormRegisterParams>({
        name: yup.string().required("nameRequired"),
        username: yup.string().email(t("emailBadFormat")).required(t("emailRequired")),
        password: yup.string().min(6, t("passwordSmallLength")).required("passwordRequired"),
        passwordConfirm: yup.string().oneOf([yup.ref('password')], t('passwordConfirmShouldMatch')),
    })

    const {register, handleSubmit, errors} = useForm<FormRegisterParams>({
        resolver: yupResolver(registerFormValidationSchema)
    });

    const [error, setError] = useState<Error | undefined>(undefined);
    const [registerSucceed, setRegisteredSucceed] = useState<boolean>(false);
    const onSubmit = (params: RegisterParams) => {
        setError(undefined);
        ApiFactory.Instance().AuthAPI().authRegister(params)
            .then(val => {
                setRegisteredSucceed(true)
                props.setLoggedIn(val)
            })
            .catch(e => setError(e))
    };

    return (
        <div className={'auth-dialog__form'}>
            <img style={{width: '170px', height: 'auto', margin: '20px'}} src={'/images/logo.png'}/>

            <Form style={{marginTop: '20px', width: '80%'}} onSubmit={handleSubmit(onSubmit)}>
                {error && <ErrorHandler size={AlertSize.Small} error={error}/>}
                {registerSucceed &&
                <Alert variant={"success"}>Регистрация прошла успешно. Вам надо подтвердить адрес электронной
                    почты.</Alert>}
                <Form.Group>
                    <Form.Control ref={register} name={'name'} style={{marginTop: '5px'}} type={'text'}
                                  placeholder={'Ваше имя'}/>
                    {errors.username && <Form.Text>{(errors.username as any)?.message}</Form.Text>}
                    <Form.Control ref={register} name={'username'} style={{marginTop: '5px'}} type={'email'}
                                  placeholder={'E-mail'}/>
                    {errors.username && <Form.Text>{(errors.username as any)?.message}</Form.Text>}
                    <Form.Control ref={register} name={'password'} style={{marginTop: '5px'}} type={'password'}
                                  placeholder={'Пароль'}/>
                    {errors.password && <Form.Text>{(errors.password as any)?.message}</Form.Text>}
                    <Form.Control ref={register} name={'passwordConfirm'} style={{marginTop: '5px'}} type={'password'}
                                  placeholder={'Подтвердите пароль'}/>
                    {errors.passwordConfirm && <Form.Text>{(errors.passwordConfirm as any)?.message}</Form.Text>}
                </Form.Group>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                    <a href='#' style={{fontSize: '14px'}} onClick={props.onLoginClicked}>Уже есть аккаунт?</a>
                </div>

                <Button type={'submit'} style={{marginTop: '30px'}} block={true} variant={'primary'}
                        className={'text-white'}>Регистрация</Button>
            </Form>
        </div>
    )
}


type RepairPasswordFormProps = {
    onLoginClicked: () => void;
}

function RepairPasswordForm(props: RepairPasswordFormProps) {
    const {t} = useTranslation()
    const [resetSucceed, setResetSucceed] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>(undefined);

    const repairFormValidationSchema = yup.object<ResetPasswordParams>({
        username: yup.string().email(t("emailBadFormat")).required(t("emailRequired")),
    })

    const {register, handleSubmit, errors} = useForm<ResetPasswordParams>({
        resolver: yupResolver(repairFormValidationSchema)
    });

    const onSubmit = (data: ResetPasswordParams) => {
        setError(undefined)
        ApiFactory.Instance().AuthAPI().authResetPassword(data)
            .then(() => setResetSucceed(true))
            .catch(e => setError(e))
    };
    return (
        <div className={'auth-dialog__form'}>
            <img style={{width: '170px', height: 'auto', margin: '20px'}} src={'/images/logo.png'}/>
            <span style={{fontSize: '14px'}}>Введите свой e-mail для восстановления пароля</span>
            <Form style={{marginTop: '20px', width: '80%'}} onSubmit={handleSubmit(onSubmit)}>
                {error && <ErrorHandler size={AlertSize.Small} error={error}/>}
                {resetSucceed &&
                <Alert variant={'success'}>Вам на почту отправлено письмо с дальнейшими инструкциями</Alert>}
                <Form.Group>
                    <Form.Control ref={register} name={'username'} style={{marginTop: '5px'}} type={'email'}
                                  placeholder={'E-mail'}/>
                    {errors.username && <Form.Text>{(errors.username as any)?.message}</Form.Text>}
                </Form.Group>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                    <a href='#' style={{fontSize: '14px'}} onClick={props.onLoginClicked}>Вернуться на форму входа</a>
                </div>

                <Button type={'submit'} style={{marginTop: '30px'}} block={true} variant={'primary'}
                        className={'text-white'}>Вход</Button>
            </Form>
        </div>
    )
}


type LoginFormProps = {
    onForgotClicked: () => void;
    onRegisterClicked: () => void;
    setLoggedIn: (tokens: TokensResponse) => void;
}

function LoginForm(props: LoginFormProps) {
    const {t} = useTranslation()

    const loginFormValidationSchema = yup.object<LoginParams>({
        username: yup.string().email(t("emailBadFormat")).required(t("emailRequired")),
        password: yup.string().min(6, t("passwordSmallLength")).required("passwordRequired")
    })

    const {register, handleSubmit, errors} = useForm<LoginParams>({
        resolver: yupResolver(loginFormValidationSchema)
    });

    const [error, setError] = useState<Error | undefined>(undefined);
    const {promiseInProgress: loading} = usePromiseTracker({area: "login"})
    const onSubmit = (params: LoginParams) => {
        setError(undefined);
        trackPromise(ApiFactory.Instance().AuthAPI().authLogin(params), "login")
            .then(val => props.setLoggedIn(val))
            .catch(e => {
                const resp = e as Response
                resp.json()
                    .then((j: ErrorMessage) => setError(new Error(j.message)))
                    .catch(e => setError(e))
            })
    };

    return (
        <div className={'auth-dialog__form'}>
            <img style={{width: '170px', height: 'auto', margin: '20px'}} src={'/images/logo.png'}/>

            <Form style={{marginTop: '20px', width: '80%'}} onSubmit={handleSubmit(onSubmit)}>
                {error && <ErrorHandler size={AlertSize.Small} error={error}/>}
                <Form.Group>
                    <Form.Control ref={register} name={'username'} style={{marginTop: '5px'}} type={'email'}
                                  placeholder={'E-mail'}/>
                    {errors.username && <Form.Text>{(errors.username as any)?.message}</Form.Text>}
                    <Form.Control ref={register} name={'password'} style={{marginTop: '5px'}} type={'password'}
                                  placeholder={'Пароль'}/>
                    {errors.password && <Form.Text>{(errors.password as any)?.message}</Form.Text>}
                </Form.Group>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <a href='#' style={{fontSize: '14px'}} onClick={props.onForgotClicked}>Забыли пароль</a>
                    <a href='#' style={{fontSize: '14px'}} onClick={props.onRegisterClicked}>Еще нет аккаунта?</a>
                </div>

                <LoadingButton
                    loading={loading}
                    type={'submit'}
                    style={{marginTop: '30px'}}
                    block={true}
                    variant={'primary'}
                    className={'text-white'}
                >Вход</LoadingButton>
            </Form>
        </div>
    )
}

type AuthFormProps = {
    type: CurrentForm,
    setNextType: (type: CurrentForm) => void,
    setLoggedIn: (t: TokensResponse) => void
}

function AuthForm({type, setNextType, setLoggedIn}: AuthFormProps) {
    switch (type) {
        case CurrentForm.Login:
            return <LoginForm setLoggedIn={setLoggedIn} onForgotClicked={() => setNextType(CurrentForm.RepairPassword)}
                              onRegisterClicked={() => setNextType(CurrentForm.Register)}/>
        case CurrentForm.Register:
            return <RegisterForm setLoggedIn={setLoggedIn} onLoginClicked={() => setNextType(CurrentForm.Login)}/>
        case CurrentForm.RepairPassword:
            return <RepairPasswordForm onLoginClicked={() => setNextType(CurrentForm.Login)}/>
    }
}

export default function AuthModal(props: Props) {
    const {showModal, setShowModal, setLoggedIn} = useContext(AuthContext)
    const [currentForm, setCurrentForm] = useState<CurrentForm>(CurrentForm.Login)

    return (
        <Modal
            show={showModal}
            keyboard={false}
            dialogClassName="auth-modal__dialog"
            onHide={() => setShowModal(false)}
        >
            <Modal.Body>
                <div className={'d-flex justify-content-around'} style={{paddingLeft: '50px', paddingRight: '50px'}}>
                    <div
                        className={'auth-form__tab-header' + ((currentForm === CurrentForm.Login) ? ' auth-form__tab-header-active' : '')}
                        onClick={() => setCurrentForm(CurrentForm.Login)}>
                        <span>Вход</span>
                        {currentForm === CurrentForm.Login && <FormIndicator/>}
                    </div>
                    <div
                        className={'auth-form__tab-header' + ((currentForm === CurrentForm.Register) ? ' auth-form__tab-header-active' : '')}
                        onClick={() => setCurrentForm(CurrentForm.Register)}>
                        <span>Регистрация</span>
                        {currentForm === CurrentForm.Register && <FormIndicator/>}
                    </div>
                </div>
                <div style={{position: 'absolute', top: '10px', right: '10px'}} onClick={() => setShowModal(false)}>
                    <img className={'as-link'} style={{width: '24px', height: '24px'}} src={'/images/close.svg'}/>
                </div>
                <AuthForm type={currentForm} setNextType={setCurrentForm} setLoggedIn={(tokens) => {
                    setLoggedIn(tokens);
                    setShowModal(false);
                }}/>
            </Modal.Body>
        </Modal>
    )
}