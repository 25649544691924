import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import {RouteComponentProps, useNavigate} from "@reach/router";
import CoursesList from "../../components/admin/CoursesList";
import {CourseCreateParams} from "../../api/generated";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form'
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import {useTranslation} from "react-i18next";
import Alert from "react-bootstrap/Alert";
import ApiFactory from "../../api/ApiFactory";
import ContentContainer from "../../components/common/ContentContainer";
import PageName from "../../components/common/PageName";
import ErrorHandler from "../../components/ErrorHandler";

type Props = {} & RouteComponentProps

type AddCourseModalProps = {
    show: boolean
    setShow: (v: boolean) => void
    error: Error | undefined
    onSubmit: (params: CourseCreateParams) => void
}

function AddCourseModal({setShow, show, error, onSubmit}: AddCourseModalProps) {
    const {t} = useTranslation()

    const formValidationSchema = yup.object<CourseCreateParams>({
        name: yup.string().required(t("nameRequired")),
        min_rating: yup.number().typeError(t("integerValueRequired")).required(t("integerValueRequired")),
    })

    const {register, handleSubmit, errors} = useForm<CourseCreateParams>({
        resolver: yupResolver(formValidationSchema)
    });

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton={true}><Modal.Title>Добавление курса</Modal.Title></Modal.Header>
            <Modal.Body>
                {error && <ErrorHandler error={error}/>}
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group controlId={"formName"}>
                        <Form.Label>Название курса</Form.Label>
                        <Form.Control ref={register} name={'name'} type={'integer'}/>
                        {errors.name &&
                        <Form.Text className={'text-danger'}>{(errors.name as any)?.message}</Form.Text>}
                    </Form.Group>
                    <Form.Group controlId={"formMinRating"}>
                        <Form.Label>Минимальный рейтинг для прохождения курса</Form.Label>
                        <Form.Control ref={register} name={'min_rating'} type={'number'}/>
                        {errors.min_rating &&
                        <Form.Text className={'text-danger'}>{errors.min_rating.message}</Form.Text>}
                    </Form.Group>
                    <Button type={'submit'} block={true} variant={'primary'} className={'text-white'}>Сохранить</Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default function Home(props: Props) {
    const navigate = useNavigate()
    const [show, setShow] = useState<boolean>(false)
    const [error, setError] = useState<Error | undefined>(undefined)
    const onSubmit = (params: CourseCreateParams) => {
        ApiFactory.Instance().CourseAPI().createCourse(params)
            .then(course => navigate(`/admin/course/${course.id}`))
            .catch(e => setError(e))
    }

    return (
        <>
            <AddCourseModal show={show} setShow={setShow} error={error} onSubmit={onSubmit}/>
            <ContentContainer>
                <div className={'d-flex direction-row align-items-center'}>
                    <PageName title="Все курсы"/>
                    <img className={'admin-course-list__add-button as-link'} src={'/images/add.svg'}
                         onClick={() => setShow(true)}/>
                </div>
                <CoursesList/>
            </ContentContainer>
        </>
    )
}
