export enum CourseAvailabilityTypeEnum {
    Public = 1,
    Private = 2,
}

export enum SubmodulePointStrategyEnum {
    NoPoints = 1,
    FixedPoints,
    ManualPoints,
    AutoPoints,
    ManualPointsNonBlocking
}

export enum SubmodulePeriodStrategyEnum {
    NoLimits = 1,
    LimitByCount,
    LimitByCountAndTime
}

export type SubmoduleTypeData = TextSubmoduleTypeData | VideoSubmoduleTypeData | TestSubmoduleTypeData;

export interface TestSubmoduleTypeData {
}

export interface TextSubmoduleTypeData {
    text: string
}

export interface VideoSubmoduleTypeData {
    content_url: string,
    description: string,
}

export enum ModuleAvailabilityStrategyEnum {
    Auto = 3,
    ByTime = 1,
    Manual = 2,
}

export enum UserStatusEnum {
    Active = 1,
    Blocked = 2,
    Deleted = 3,
    NotConfirmed = 4,
}

export type SelectOption = { value: string, label: string };