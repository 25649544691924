import * as React from 'react';
import {useContext, useEffect, useRef, useState, Suspense} from 'react';
import * as yup from "yup";
import {CreateSectionParams} from "../../api/generated";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import {mutate} from "swr";
import {AuthContext} from "../auth/AuthContext";
import ApiFactory, {DEFAULT_PER_PAGE, MAX_PER_PAGE} from "../../api/ApiFactory";
import ErrorHandler from "../ErrorHandler";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import {Loader} from "../Loaders";
import AsyncSelect from "react-select/async";
import {SelectOption} from "../common/types";
import * as _ from "lodash";
import Select from "react-select";

const Editor = React.lazy(() => import("../common/lazy/AdminCKEditor"));

type Props = {
    moduleId: number,
    courseId: number,
    onSucceed: () => void;
    onCancel: () => void;
}

const formValidationSchema = yup.object<CreateSectionParams>({
    course_module_id: yup.number().required(),
    name: yup.string().required(),
    description: yup.string(),
    is_private: yup.boolean().required(),
    course_user_group_id: yup.number(),
})


function SectionForm({moduleId, courseId, onSucceed, onCancel}: Props) {
    const form = useRef<HTMLFormElement>(null);
    const {userId} = useContext(AuthContext);
    const [description, setDescription] = useState<string>("")
    const [error, setError] = useState<Error | undefined>(undefined);
    const [selectGroupId, setSelectGroupId] = useState<SelectOption | undefined>(undefined);
    const [availableGroups, setAvaialbleGroups] = useState<SelectOption[]|undefined>(undefined);
    const [isPrivate, setIsPrivate] = useState<boolean>(false);

    const {register, handleSubmit, errors, setValue, reset} = useForm<CreateSectionParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            course_module_id: moduleId,
            name: "",
            description: ""
        }
    });

    useEffect(() => {
        register("description");
        register("course_module_id");
    }, [])

    useEffect(() => {
        ApiFactory.Instance().CourseUserGroupAPI().adminListCourseUserGroups(courseId, 0, MAX_PER_PAGE)
            .then(resp => setAvaialbleGroups(resp.items.map(grp => ({value: "" + grp.id, label: grp.name}))))
            .catch(e => setError(e))
    }, [])

    const onSubmit = (params: CreateSectionParams) => {
        setError(undefined)
        ApiFactory.Instance().CourseSectionAPI().adminCreateCourseSection(params)
            .then(s => {
                mutate(["admin/course", courseId, userId])
                mutate(["admin/course-module", moduleId, userId])
                onSucceed()
            }).catch(e => setError(e))
    }


    const loadGroups = _.debounce((input: string, callback: (opts: SelectOption[]) => void) => {
        ApiFactory.Instance().UserAPI().adminUserList(input, undefined, 0, DEFAULT_PER_PAGE)
            .then(resp => {
                callback(resp.items.map<SelectOption>(u => ({value: "" + u.id, label: u.name})))
            }).catch(e => {
            callback([])
        })
    }, 500)

    return <Card className={'m-4'}>
        <Card.Body>
            {error && <ErrorHandler error={error}/>}
            <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
                <div className={'d-flex flex-row align-items-center justify-content-end'}>
                    <div className={'d-flex flex-row'}>
                        <img className={'button-icon ml-4 as-link'} src={"/images/cancel.svg"}
                             onClick={onCancel}/>
                        <img className={'button-icon ml-4 as-link'} src={"/images/save.svg"}
                             onClick={() => {
                                 if (form.current) {
                                     form.current.dispatchEvent(new Event('submit', {cancelable: true}))
                                 }
                             }}/>
                    </div>
                </div>
                <Form.Group>
                    <Form.Label>Название</Form.Label>
                    <Form.Control ref={register} name={"name"}/>
                    {errors && errors.name &&
                    <Form.Text className={'text-danger'}>{(errors.name as any)?.message}</Form.Text>}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Описание</Form.Label>
                    <Suspense fallback={<Loader/>}>
                        <Editor initialContent={description} onContentChange={text => {
                            setDescription(text)
                            setValue("description", text)
                        }}/>
                    </Suspense>
                    {errors && errors.description &&
                    <Form.Text className={'text-danger'}>{(errors.description as any)?.message}</Form.Text>}
                </Form.Group>
                <Form.Group>
                    <Form.Check ref={register}
                                name={"is_private"}
                                label={'Ограниченная доступность'}
                                onChange={(e: any) => {
                                    setIsPrivate(e.target.checked)
                                }}
                    />
                    {errors && errors.is_private &&
                    <Form.Text className={'text-danger'}>{errors.is_private.message}</Form.Text>}
                </Form.Group>
                {isPrivate &&
                    <Form.Group>
                        <Form.Label>Группа пользователей</Form.Label>
                        <Select value={selectGroupId}
                                placeholder={'Выберите группу'}
                                onChange={(v: any) => {
                                    const val = v as SelectOption;
                                    setSelectGroupId(val)
                                    const newVal = Number(v.value)
                                    setValue("course_submodule_type_id", newVal)
                                }}
                                options={availableGroups}
                        />
                        {errors && errors.course_user_group_id &&
                        <Form.Text
                            className={'text-danger'}>{JSON.stringify(errors.course_user_group_id)}</Form.Text>}
                    </Form.Group>
                }
            </Form>
        </Card.Body>

    </Card>;
}


export default function SectionCreate(props: Props) {
    return <SectionForm {...props}/>;
}