import * as React from 'react';
import {ReactElement, useRef, useState} from 'react';
import {MenuLoader} from "../Loaders";
import {useNavigate} from "@reach/router";

interface BaseCourse<T1 extends BaseModule> {
    modules?: T1[],
}

type BaseModule = {
    id: number
    name: string
}

type RenderModuleItemFunc<T2> = (mod: T2, isActive: boolean, onClick: (mod: T2) => void, scrollRef: React.MutableRefObject<HTMLDivElement | null>) => ReactElement
type RenderItemFunc = (isActive: boolean, onClick: () => void, scrollRef: React.MutableRefObject<HTMLDivElement | null>) => ReactElement

export type HorizontalModuleItemProps<T1> = { mod: T1, isActive: boolean, onClick: (mod: T1) => void, scrollRef: React.MutableRefObject<HTMLDivElement | null> };
export type HorizontalItemProps = { isActive: boolean, onClick: () => void, scrollRef: React.MutableRefObject<HTMLDivElement | null> };

type Props<T, T1> = {
    initialModuleId?: number
    course?: T,
    loading: boolean,
    onActiveModuleChanged: (mod: T1 | null) => void,
    renderModuleItem: RenderModuleItemFunc<T1>,
    renderEndElement?: RenderItemFunc,
    renderStartElement?: RenderItemFunc,
    displayAboutCourse: boolean,
    aboutCourseLink?: string,
    additionalMenuItems?: ReactElement
}

enum ActiveEnum {
    StartActive = -2,
    EndActive = -1,
}

export default function Horizontal<T extends BaseCourse<T1>, T1 extends BaseModule>(
    {course, loading, renderModuleItem, renderEndElement, initialModuleId, aboutCourseLink,
        onActiveModuleChanged, displayAboutCourse, renderStartElement, additionalMenuItems}: Props<T, T1>) {
    const scrollRef = useRef<HTMLDivElement | null>(null)

    const navigate = useNavigate();
    const [activeElementId, setActiveElementId] = useState<number | undefined>(initialModuleId);
    if (course === undefined || course.modules === undefined) {
        return null
    }

    if (loading) {
        return <MenuLoader/>
    }

    return (
        <div ref={scrollRef} className={'scroll-menu d-inline-block d-sm-none'}>
            {renderStartElement && renderStartElement(activeElementId === ActiveEnum.StartActive, () => {
                setActiveElementId(ActiveEnum.StartActive)
            }, scrollRef)}
            {displayAboutCourse &&
            <span className={'scroll-menu-item ' + (activeElementId === undefined ? 'text-primary' : '')}
                  onClick={() => {
                      if (aboutCourseLink) {
                          navigate(aboutCourseLink)
                      }
                  }}>О курсе</span>}

            {additionalMenuItems}

            {course && course.modules && course.modules.map(mod => {
                const isActive = activeElementId === mod.id
                return renderModuleItem(mod, isActive, (mod) => {
                    setActiveElementId(mod.id)
                    onActiveModuleChanged(mod);
                }, scrollRef)
            })}
            {renderEndElement && renderEndElement(activeElementId === ActiveEnum.EndActive, () => {
                setActiveElementId(ActiveEnum.EndActive)
            }, scrollRef)}
        </div>
    )
}