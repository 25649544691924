import * as React from 'react';
import {useEffect, useState} from "react";
import Card from "react-bootstrap/Card";
import {navigate} from "@reach/router";
import HtmlParser from "react-html-parser";
import InnerSubmoduleItem from "../common/SubmoduleItem";
import SectionCreate from "./SectionCreate";
import {
    AdminFullCourseModule,
    AdminFullCourseSubmodule, FullCourseSection,
    ShortCourseSection,
    ShortCourseSubmodule
} from "../../api/generated";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import ApiFactory from "../../api/ApiFactory";

type Props = {
    courseId?: number,
    module: AdminFullCourseModule,
    onReorder: () => void,
}

type ItemProps = {
    submodule: ShortCourseSubmodule,
    index: number,
    onSubmoduleClick?: (sub: ShortCourseSubmodule) => void
}

function SubmoduleItem(props: ItemProps) {
    return <Draggable draggableId={props.submodule.id + ""} index={props.index}>
        {provided => (
            <div className={'mt-4 mb-4'}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <InnerSubmoduleItem {...props}/>
            </div>
        )}
    </Draggable>
}

function SectionItem({sec, courseId, moduleId, onReorder}: {sec: FullCourseSection, courseId: number, moduleId: number, onReorder: () => void}) {
    const [submodules, setSubmodules] = useState(sec.submodules)
    const [order, setOrder] = useState<number[]>(sec.submodules.map(s => s.id))

    useEffect(() => {
        setSubmodules(sec.submodules);
    }, [sec])

    useEffect(() => {
        const submodulesOrder = sec.submodules.map(s => s.id)
        if (JSON.stringify(submodulesOrder) !== JSON.stringify(order)) {
            ApiFactory.Instance().CourseSectionAPI().adminCourseSubmoduleReorder(sec.id, order)
                .then(r => onReorder());
        }
    }, [order])

    const reorder = (list: number[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const reorderSubmodules = (list: ShortCourseSubmodule[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const reordered = reorder(
            order,
            result.source.index,
            result.destination.index
        );
        setSubmodules(reorderSubmodules(submodules, result.source.index, result.destination.index))
        setOrder(reordered);
    }

    return <Card key={`section-${sec.id}`} className={'m-4'}>
        <Card.Body className={'p-4 d-flex flex-column'}>
            <div className={'d-flex flex-row justify-content-between align-items-center flex-fill'}>
                <h6>{sec.name}</h6>
                <img className={'button-icon as-link m-2'} src={"/images/edit.svg"}
                     onClick={() => navigate(`/admin/course/${courseId}/module/${moduleId}/section/${sec.id}`)}/>
            </div>
            <div className={'m-2'}>{sec.description ? HtmlParser(sec.description) : ""}</div>
            <div className={'mt-4'}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={`submodules-section-${sec.id}`}>
                        {provided => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {submodules.map((sm, i) =>
                                    <SubmoduleItem index={i} key={`sm-${sm.id}`} submodule={sm}
                                                   onSubmoduleClick={() => navigate(`/admin/course/${courseId}/module/${moduleId}/section/${sec.id}/submodule/${sm.id}`)}/>)}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </Card.Body>
    </Card>
}

export default function SectionsInfo({courseId, module, onReorder}: Props) {
    const [showAddSection, setShowAddSection] = useState<boolean>(false)

    return <>
        {module.sections.map(sec =><SectionItem
            sec={sec}
            courseId={courseId!}
            moduleId={module.id}
            onReorder={onReorder}
        />)}

        {showAddSection
            ? <SectionCreate moduleId={module.id} courseId={courseId!} onSucceed={() => setShowAddSection(false)}
                             onCancel={() => setShowAddSection(false)}/>
            : <div className={'d-flex flex-row align-items-center justify-content-center p-2 as-link'}
                   onClick={() => setShowAddSection(true)}>
                <h5 className={'m-0'}>Добавить секцию</h5>
                <img src={"/images/add.svg"} className={'small-icon as-link m-2'}/>
            </div>
        }
    </>
}