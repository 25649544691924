import {CourseSubmoduleCompletionApi} from "./generated";

export default class CourseSubmoduleCompletionApiWrapper extends CourseSubmoduleCompletionApi {

    public attemptSubmoduleCompletionUpload(course_id: number, course_completion_id: number, submodule_id: number, submodule_completion_id: number, file?: any, options?: any) {
        const formData = new FormData()
        formData.append("file", file)
        const apiKey = this.configuration.apiKey !== undefined ? this.configuration.apiKey as string : ""
        return fetch(this.basePath + `/user/course/${course_id}/completion/${course_completion_id}/submodule/${submodule_id}/course-submodule-completion/${submodule_completion_id}/upload`, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': apiKey,
            }
        })
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
    }
}