import * as React from 'react';
import {useContext, useState} from 'react';
import ErrorHandler from "../../../components/ErrorHandler";
import useSWR from "swr";
import {AuthContext} from "../../../components/auth/AuthContext";
import ApiFactory, {DEFAULT_PER_PAGE} from "../../../api/ApiFactory";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link, RouteComponentProps, useNavigate} from "@reach/router";
import Menu, {HorizontalMenu} from "../../../components/teacher/Menu";
import {Loader} from "../../../components/Loaders";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ModuleContent from "../../../components/teacher/ModuleContent";
import ContentContainer from "../../../components/common/ContentContainer";
import {ShortUser, ShortUserWithPhotoListResponse, TeacherCourseUsersResponse} from "../../../api/generated";
import Pagination from "../../../components/common/Pagination";
import User from "../../../components/common/User";
import DiscussionsCollapse from "../../../components/teacher/DiscussionsCollapse";
import {DiscussionTypeEnum} from "../../../models/discussion";
import {CourseCompletionStatusEnum} from "../../../components/user/CoursesList";

type Props = {
    id?: string,
    moduleId?: string,
} & RouteComponentProps;

enum Mode {
    View,
    ModuleView,
}

type CourseUsersProps = {
    courseId: number,
}

function CourseUsers(props: CourseUsersProps) {
    const {courseId} = props;
    const {userId} = useContext(AuthContext);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [mutateError, setMutateError] = useState<Error|undefined>(undefined);
    const fetcher = () => ApiFactory.Instance().CourseAPI().teacherViewCourseUsers(courseId, currentPage, DEFAULT_PER_PAGE)
    const {data: users, error: error, mutate} = useSWR<TeacherCourseUsersResponse>(["teacher/course-users", courseId, userId, currentPage], fetcher)

    if (error !== undefined) {
        return <ErrorHandler error={error}/>
    }


    if (users === undefined) {
        return <Loader/>
    }

    const onFinish  = (user: ShortUser) => {
        if (window.confirm(`Вы действительно хотите завершить прохождение курса для пользователя ${user.name}?`)) {
            ApiFactory.Instance().CourseAPI().teacherCourseCompletionFinish(user.id, courseId)
                .then(() => mutate())
                .catch((e) => setMutateError(e))
        }
    }

    return <div className={'mt-4'}>
        {mutateError && <ErrorHandler error={mutateError}/>}
        {users.items.length > 0 && <>
            <h5>Пользователи, записавшиеся на курс</h5>
            {users.items.map(u => {
                if (u.completion_status && u.completion_status.id == CourseCompletionStatusEnum.InProgress) {
                    return <div className={'d-flex direction-row justify-content-between align-items-center'}>
                        <User key={`teacher-${u.id}`} user={u} link={`/teacher/portfolio/${courseId}/${u.id}`}/>
                        <small className={'as-link'} onClick={() => onFinish(u)}>Завершить</small>
                    </div>
                }
                return <div className={'d-flex direction-row align-items-center'}>
                    <User key={`teacher-${u.id}`} user={u} link={`/teacher/portfolio/${courseId}/${u.id}`}/>

                </div>

            })}
            <div className={'d-flex direction-row justify-content-center'}>
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalItems={users.total}/>
            </div>
        </>}

    </div>
}

export default function View(props: Props) {
    const {id, moduleId} = props
    const navigate = useNavigate()
    const [activeModuleId, setActiveModuleId] = useState<number | undefined>(moduleId ? Number(moduleId) : undefined)
    const [mode, setMode] = useState<Mode>(moduleId ? Mode.ModuleView : Mode.View);
    const {userId} = useContext(AuthContext);
    const fetcher = () => ApiFactory.Instance().CourseAPI().teacherViewCourse(Number(id))
    const {data: course, error} = useSWR(["teacher/course", Number(id), userId], fetcher)
    const loading = course === undefined;


    if (id === undefined) {
        return <ErrorHandler error={new Error("Id курса не передан")}/>
    }

    const initialModuleID = moduleId !== undefined ? Number(moduleId) : undefined

    return (
        <ContentContainer
            hasNabBottomMenu={true}
            renderNavBottomMenu={() => <HorizontalMenu
                initialModuleId={initialModuleID}
                course={course}
                loading={loading}
                onActiveModuleChanged={(mod) => {
                    setActiveModuleId(mod?.id)
                    navigate(`/teacher/course/${id}/module/${mod?.id}`)
                }}/>}>
            <Breadcrumb className={'course__breadcrumb'}>
                <Breadcrumb.Item><Link to={"/teacher"}>Список курсов</Link></Breadcrumb.Item>
            </Breadcrumb>
            <>
                <Row>
                    <Col xs={12} md={3} className={'mt-4 d-none d-sm-block'}>
                        <Menu loading={course === undefined}
                              initialModuleId={moduleId !== undefined ? Number(moduleId) : undefined}
                              course={course}
                              onModuleSelect={mod => {
                                  if (course === undefined) {
                                      return
                                  }
                                  if (navigate) {
                                      navigate(`/teacher/course/${course.id}/module/${mod.id}`)
                                  }
                              }}
                        />
                    </Col>
                    <Col xs={12} md={9}>
                        {error && <ErrorHandler error={error}/>}
                        {course === undefined && error === undefined && <Loader/>}
                        {course !== undefined &&
                        <>
                            {mode === Mode.ModuleView
                                ? <ModuleContent courseId={course.id} id={Number(moduleId)}/>
                                : <>
                                    <DiscussionsCollapse externalId={course.id} type={DiscussionTypeEnum.Course}/>
                                    <CourseUsers courseId={course.id}/>
                                </>}
                        </>
                        }
                    </Col>
                </Row>
            </>
        </ContentContainer>
    )
}