import * as React from 'react';
import {PortfolioSubmodule, CourseSubmoduleCompletionData} from "../../../api/generated";
import Card from "react-bootstrap/Card";
import RichContent from "../../common/RichContent";

type Props = {
    name: string
    completionData?: CourseSubmoduleCompletionData
    typeData?: any
}

export default function FileUploadResult(props: Props) {
    return <div className={'mt-4'}>
        {props.typeData && props.typeData.description && <div className={'content-card__text'}>
            <RichContent text={props.typeData.description}/>
        </div>}
        <div className={'m-4'}>
            {props.completionData && props.completionData.file
                ? <span>
                            <img src={'/images/file.svg'} className={'small-icon'}/>
                            <a className={'as-link'} href={UPLOAD_BASE_PATH + props.completionData.file}
                               download={`${props.name}`}>Скачать файл</a>
                        </span>
                : <span className={'text-danger'}>Файл не найден</span>
            }
        </div>
    </div>
}