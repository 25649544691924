import * as React from 'react'
import {useEffect, useState} from 'react'
import useSWR, {mutate} from "swr";
import ApiFactory, {DEFAULT_PER_PAGE, MAX_PER_PAGE} from "../../api/ApiFactory";
import Modal from 'react-bootstrap/Modal';
import {Loader} from "../Loaders";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import {CourseSetPrivateUsersParams} from "../../api/generated";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import ErrorHandler from "../ErrorHandler";
import SucceedAlert from "../common/SucceedAlert";
import AsyncSelect from "react-select/async";
import * as _ from "lodash";
import {SelectOption} from "../common/types";

type Props = {
    show: boolean,
    setShow: (s: boolean) => void,
    course_id: number
}

const formValidationSchema = yup.object<CourseSetPrivateUsersParams>({
    course_private_user_ids: yup.array<number>()
})

export default function PrivateUsersModal(props: Props) {
    const fetcher = () => ApiFactory.Instance().UserAPI().adminUserList(undefined, props.course_id, 0, MAX_PER_PAGE)
    const {data: users, error} = useSWR(['course/private-users', props.course_id], fetcher)
    const [sendError, setSendError] = useState<Error | undefined>(undefined);
    const [sendSucceed, setSendSucceed] = useState<boolean>(false);

    const [selectedUsers, setSelectedUsers] = useState<SelectOption[]>([])

    const loadUsers = _.debounce((input: string, callback: (opts: SelectOption[]) => void) => {
        ApiFactory.Instance().UserAPI().adminUserList(input, undefined, 0, DEFAULT_PER_PAGE)
            .then(resp => {
                callback(resp.items.map<SelectOption>(u => ({value: "" + u.id, label: u.name})))
            }).catch(e => {
            callback([])
        })
    }, 500)

    const {register, handleSubmit, errors, setValue} = useForm<CourseSetPrivateUsersParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            course_private_user_ids: [],
        }
    });

    useEffect(() => {
        register("course_private_user_ids")
    }, [])

    useEffect(() => {
        if (users === undefined) {
            setValue("course_private_user_ids", [])
            return
        }
        setSelectedUsers(users.items.map(u => ({value: "" + u.id, label: u.name})))
        setValue("course_private_user_ids", users.items.map(u => u.id))
    }, [users])

    const onSubmit = (params: CourseSetPrivateUsersParams) => {
        setSendError(undefined)
        setSendSucceed(false)
        ApiFactory.Instance().CourseAPI().courseSetPrivateUsers(props.course_id, params)
            .then(() => {
                mutate(['course/private-users', props.course_id])
                setSendSucceed(true)
            }).catch(e => setSendError(e))
    }

    return <Modal show={props.show} onHide={() => props.setShow(false)}>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton={true}/>
            <Modal.Body>
                {error && <ErrorHandler error={error}/>}
                {sendError && <ErrorHandler error={sendError}/>}
                {sendSucceed &&
                <SucceedAlert message={"Данные успешно сохранены"} onHide={() => setSendSucceed(false)}/>}
                {users === undefined
                    ? <Loader/>
                    : <Form.Group>
                        <Form.Label>Пользователи</Form.Label>
                        <AsyncSelect value={selectedUsers}
                                     placeholder={'Выберите пользователя'}
                                     loadOptions={loadUsers}
                                     defaultOptions={true}
                                     isMulti={true}
                                     onChange={(v: any) => {
                                         let val: SelectOption[] = []
                                         if (v != null) {
                                             val = v as SelectOption[];
                                         }
                                         setSelectedUsers(val)
                                         const newVal = val.map(v => Number(v.value))
                                         setValue("course_private_user_ids", newVal)
                                     }}
                        />
                        {errors && errors.course_private_user_ids &&
                        <Form.Text
                            className={'text-danger'}>{JSON.stringify(errors.course_private_user_ids)}</Form.Text>}
                    </Form.Group>
                }
            </Modal.Body>
            <Modal.Footer className={'justify-content-end'}>
                <Button variant={'danger'} onClick={() => props.setShow(false)}>Отмена</Button>
                {users !== undefined &&
                <Button type={'submit'} className={'text-white'} variant={'primary'}>Сохранить</Button>}
            </Modal.Footer>
        </Form>

    </Modal>
}