import * as React from 'react';
import {Suspense, useContext, useEffect, useRef, useState} from 'react';
import {
    AdminFullCourseSubmodule,
    CourseSubmodulePeriodStrategy,
    CourseSubmodulePointsStrategy,
    ErrorMessage, IdNameEntity,
    UpdateSubmoduleParams
} from "../../../api/generated";
import ErrorHandler from "../../ErrorHandler";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import Select from "react-select";
import ApiFactory from "../../../api/ApiFactory";
import {Type} from "../types";
import Card from "react-bootstrap/Card";
import {AuthContext} from "../../auth/AuthContext";
import {LineLoader} from "../../Loaders";
import {SubmodulePeriodStrategyEnum, SubmodulePointStrategyEnum} from "../../common/types";
import DictionarySelect, {Item} from "../../common/DictionarySelect";
import Nav from "react-bootstrap/Nav";
import SucceedAlert from "../../common/SucceedAlert";
import TestSubmoduleView from "./TestSubmoduleView";
import {TypeControls} from "./TypeControls";

const DatePicker = React.lazy(() => import("react-datepicker"));

type FormParams = {
    course_submodule_type_id: number
    course_submodule_type_text?: string
    course_submodule_type_show_result?: boolean
    course_submodule_type_show_answered_description?: boolean
    course_submodule_type_show_unanswered_description?: boolean
    course_submodule_type_description?: string
    course_submodule_type_content_url?: string
    course_submodule_type_discussion_id?: number
    course_submodule_type_topic_id?: number
    course_submodule_type_variants?: string[]
    course_submodule_type_groups?: string[]
    course_submodule_type_min_groups?: number
    course_submodule_type_user_can_add_groups?: boolean
    course_submodule_type_user_can_add_variants?: boolean
    max_retries?: number
    min_time_between_retries?: number
    points?: number
    max_points?: number

} & UpdateSubmoduleParams

type SelectOption = { value: string, label: string };

const formValidationSchema = yup.object<FormParams>({
    name: yup.string().required(),
    duration: yup.number().required(),
    optional: yup.boolean().required(),
    is_verification_anonymous: yup.mixed().oneOf([true, false]).required(),
    course_submodule_type_id: yup.number().required(),
    course_submodule_type_data: yup.mixed(),
    course_submodule_points_strategy_data: yup.mixed(),
    course_submodule_points_strategy_id: yup.number().required(),
    course_submodule_period_strategy_data: yup.mixed(),
    course_submodule_period_strategy_id: yup.number().required(),
    min_time_between_retries: yup.number().min(0),
    max_points: yup.number(),
    max_retries: yup.number(),
    points: yup.number(),
    course_submodule_type_discussion_id: yup.number(),
    course_submodule_type_topic_id: yup.number(),
    has_deadline: yup.boolean().required(),
    course_submodule_type_variants: yup.array(),
    course_submodule_type_groups: yup.array(),
    course_submodule_type_min_groups: yup.number(),
    course_submodule_type_user_can_add_groups: yup.boolean(),
    course_submodule_type_user_can_add_variants: yup.boolean(),
    is_available_in_my_portfolio: yup.boolean().required(),
    is_available_in_other_user_portfolio: yup.boolean().required(),
})

type Props = {
    courseId: number;
    moduleId: number,
    submodule: AdminFullCourseSubmodule,
    onDelete: () => void,
    onChange: () => void,
}


enum NavKey {
    Main = "main",
    Type = "type",
    Quiz = "quiz",
    Points = "points",
    Period = "period",
}

type MainProps = {
    submodule: AdminFullCourseSubmodule,
    register: any,
    errors: any,
    setValue: (name: string, val: any) => void,
    availableTypes: SelectOption[],
    setType: (t: IdNameEntity) => void
}

function MainControls(props: MainProps) {
    const {register, errors, submodule, setValue, availableTypes, setType} = props;
    const [selectType, setSelectType] = useState<SelectOption>(
        {value: "" + submodule.course_submodule_type.id, label: submodule.course_submodule_type.name}
    );

    useEffect(() => {
        setSelectType({value: "" + submodule.course_submodule_type.id, label: submodule.course_submodule_type.name})
    }, [submodule.course_submodule_type])

    const [deadlineTime, setDeadlineTime] = useState<Date>(submodule.deadline_time ? new Date(submodule.deadline_time) : new Date());
    const [hasDeadline, setHasDeadline] = useState<boolean>(submodule.has_deadline);

    return <>
        <Form.Group>
            <Form.Label>Название</Form.Label>
            <Form.Control ref={register} name={"name"}/>
            {errors && errors.name &&
            <Form.Text className={'text-danger'}>{errors.name.message}</Form.Text>}
        </Form.Group>

        <Form.Group>
            <Form.Label>Длительность</Form.Label>
            <Form.Control ref={register} name={"duration"}/>
            {errors && errors.duration &&
            <Form.Text className={'text-danger'}>{errors.duration.message}</Form.Text>}
        </Form.Group>
        <Form.Group>
            <Form.Label>Тип</Form.Label>
            <Select value={selectType}
                    placeholder={'Выберите тип'}
                    onChange={(v: any) => {
                        const val = v as SelectOption;
                        setSelectType(val)
                        const newVal = Number(v.value)
                        setType({id: newVal, name: v.label})
                        setValue("course_submodule_type_id", newVal)
                    }}
                    options={availableTypes}
            />
            {errors && errors.course_submodule_type_id &&
            <Form.Text className={'text-danger'}>{errors.course_submodule_type_id.message}</Form.Text>}
        </Form.Group>
        <Form.Group>
            <Form.Check label={'Необязательный для прохождения'} type={'checkbox'} ref={register} name={"optional"}/>
            {errors && errors.optional &&
            <Form.Text className={'text-danger'}>{errors.optional.message}</Form.Text>}
        </Form.Group>
        <Form.Group>
            <Form.Check label={'Подмодуль доступен в собственном портфолио пользователя'} type={'checkbox'} ref={register} name={"is_available_in_my_portfolio"}/>
            {errors && errors.is_available_in_my_portfolio &&
            <Form.Text className={'text-danger'}>{errors.is_available_in_my_portfolio.message}</Form.Text>}
        </Form.Group>
        <Form.Group>
            <Form.Check label={'Подмодуль доступен в портфолио других пользователей'} type={'checkbox'} ref={register} name={"is_available_in_other_user_portfolio"}/>
            {errors && errors.is_available_in_other_user_portfolio &&
            <Form.Text className={'text-danger'}>{errors.is_available_in_other_user_portfolio.message}</Form.Text>}
        </Form.Group>
        <Form.Group>
            <Form.Check ref={register}
                        name={"has_deadline"}
                        type={'checkbox'}
                        label={'Задание должно быть выполнено до определенной даты'}
                        onChange={(e: any) => setHasDeadline(e.target.value)}
            />
            {errors && errors.has_deadline &&
            <Form.Text className={'text-danger'}>{errors.has_deadline.message}</Form.Text>}
        </Form.Group>
        {hasDeadline &&
            <Form.Group>
                <Form.Label>Дата ограничения выполнения задания</Form.Label>
                <Suspense fallback={<LineLoader/>}>
                    <DatePicker dateFormat={'dd.MM.yyyy'} selected={deadlineTime} className={'form-control'}
                                onChange={val => {
                                    const res = val as Date
                                    res.setHours(0, 0, 0, 0)
                                    setValue("deadline_time", res.toISOString())
                                    setDeadlineTime(res)
                                }}/>
                </Suspense>
                {errors && errors.deadline_time &&
                <Form.Text
                    className={'text-danger'}>{errors.deadline_time.message}</Form.Text>}
            </Form.Group>
        }
    </>
}


function QuizControls({submodule}: { submodule: AdminFullCourseSubmodule }) {
    return <TestSubmoduleView submodule={submodule}/>
}

type PointsProps = {
    availablePointStrategies: CourseSubmodulePointsStrategy[],
    register: any,
    errors: any,
    onStrategyValueChanged: (i: IdNameEntity) => void
    setValue: (name: string, val: any) => void,
    pointsStrategyId?: number
    pointStrategy: IdNameEntity|null
}

function PointsControls(props: PointsProps) {
    const {
        availablePointStrategies, setValue,
        register, pointsStrategyId, pointStrategy, errors, onStrategyValueChanged
    } = props
    return <>
        <Form.Group>
            <Form.Label>Политика начисления баллов</Form.Label>
            <DictionarySelect
                items={availablePointStrategies}
                initial={pointStrategy}
                propertyName={'course_submodule_points_strategy_id'}
                register={register}
                setValue={setValue}
                onValueChanged={i => {
                    onStrategyValueChanged(i)
                }}
            />
        </Form.Group>

        {(pointsStrategyId === SubmodulePointStrategyEnum.ManualPoints || pointsStrategyId === SubmodulePointStrategyEnum.ManualPointsNonBlocking)
        && <Form.Group>
            <Form.Label>Максимальное количество баллов для начисления</Form.Label>
            <Form.Control ref={register} name={"max_points"} type={'number'}/>
            {errors && errors.max_points &&
            <Form.Text className={'text-danger'}>{errors.max_points.message}</Form.Text>}
        </Form.Group>
        }

        {pointsStrategyId === SubmodulePointStrategyEnum.FixedPoints
        && <Form.Group>
            <Form.Label>Количество баллов для начисления</Form.Label>
            <Form.Control ref={register} name={"points"} type={'number'}/>
            {errors && errors.points &&
            <Form.Text className={'text-danger'}>{errors.points.message}</Form.Text>}
        </Form.Group>
        }

    </>
}

type PeriodProps = {
    availablePeriodStrategies: CourseSubmodulePeriodStrategy[],
    register: any,
    onStrategyValueChanged: (i: IdNameEntity) => void
    setValue: (name: string, val: any) => void,
    errors: any,
    periodStrategyId?: number,
    periodStrategy: IdNameEntity|null
}

function PeriodControls(props: PeriodProps) {
    const {
        availablePeriodStrategies,
        errors,
        register,
        setValue,
        periodStrategyId,
        periodStrategy,
        onStrategyValueChanged
    } = props;
    return <>
        <Form.Group>
            <Form.Label>Политика пероидичности прохождения</Form.Label>
            <DictionarySelect
                items={availablePeriodStrategies}
                initial={periodStrategy}
                propertyName={'course_submodule_period_strategy_id'}
                register={register}
                setValue={setValue}
                onValueChanged={i => onStrategyValueChanged(i)}
            />
        </Form.Group>

        {periodStrategyId === SubmodulePeriodStrategyEnum.LimitByCount
        && <>
            <Form.Group>
                <Form.Label>Максимальное количество прохождений</Form.Label>
                <Form.Control ref={register} name={"max_retries"} type={'number'}/>
                {errors && errors.max_retries &&
                <Form.Text className={'text-danger'}>{errors.max_retries.message}</Form.Text>}
            </Form.Group>
        </>
        }

        {periodStrategyId === SubmodulePeriodStrategyEnum.LimitByCountAndTime
        && <>
            <Form.Group>
                <Form.Label>Максимальное количество прохождений</Form.Label>
                <Form.Control ref={register} name={"max_retries"} type={'number'}/>
                {errors && errors.max_retries &&
                <Form.Text className={'text-danger'}>{errors.max_retries.message}</Form.Text>}
            </Form.Group>
            <Form.Group>
                <Form.Label>Время между прохождениями (мин)</Form.Label>
                <Form.Control ref={register} name={"min_time_between_retries"} type={'number'}/>
                {errors && errors.min_time_between_retries &&
                <Form.Text className={'text-danger'}>{errors.min_time_between_retries.message}</Form.Text>}
            </Form.Group>
        </>
        }
    </>
}

function BottomButtons({submodule, onDelete}: { submodule: AdminFullCourseSubmodule, onDelete: () => void }) {
    return <div className={'d-flex flex-row justify-content-end'}>
        <Button variant={'danger'} className={'ml-2'} onClick={() => {
            if (window.confirm(`Вы действительно хотите удалить подмодуль ${submodule.name}`)) {
                ApiFactory.Instance().CourseSubmoduleAPI().adminDeleteCourseSubModule(submodule.id)
                    .then(() => {
                        onDelete()
                    })
            }
        }}>Удалить</Button>
        <Button type={'submit'} variant={'primary'} className={'text-white ml-2'}>Сохранить</Button>
    </div>
}

export default function SubmoduleEdit({submodule, onChange, onDelete, moduleId, courseId}: Props) {
    const [navKey, setNavKey] = useState<NavKey>(NavKey.Main)
    const form = useRef<HTMLFormElement>(null);
    const {userId} = useContext(AuthContext)
    const [error, setError] = useState<Error | undefined>(undefined);
    const [saveSucceed, setSaveSucceed] = useState<boolean>(false);
    const [type, setType] = useState<IdNameEntity>(submodule.course_submodule_type);
    const [pointsStrategyId, setPointsStrategyId] = useState<SubmodulePointStrategyEnum|undefined>(submodule.course_submodule_points_strategy.id);
    const [periodStrategyId, setPeriodStrategyId] = useState<SubmodulePeriodStrategyEnum|undefined>(submodule.course_submodule_period_strategy.id);
    const [selectPeriod, setSelectPeriod] = useState<IdNameEntity | null>(null);
    const [selectPoints, setSelectPoints] = useState<IdNameEntity | null>(null);


    const [availableTypes, setAvailableTypes] = useState<SelectOption[]>([])
    const [availablePointStrategies, setAvailablePointStrategies] = useState<CourseSubmodulePointsStrategy[]>([])
    const [availablePeriodStrategies, setAvailablePeriodStrategies] = useState<CourseSubmodulePeriodStrategy[]>([])

    useEffect(() => {
        setType(submodule.course_submodule_type)
        setPointsStrategyId(submodule.course_submodule_points_strategy.id)
        setPeriodStrategyId(submodule.course_submodule_period_strategy.id)
    }, [submodule])

    useEffect(() => {
        ApiFactory.Instance().CourseSubmoduleAPI().adminCourseSubmoduleAvailablePeriodStrategies(type.id)
            .then(resp => {
                const strat = resp.find(r => r.id == periodStrategyId)
                if (strat === undefined) {
                    setPeriodStrategyId(undefined)
                    setSelectPeriod(null)
                } else {
                    setSelectPeriod(strat)
                }

                setAvailablePeriodStrategies(resp)
            })
        ApiFactory.Instance().CourseSubmoduleAPI().adminCourseSubmoduleAvailablePointStrategies(type.id)
            .then(resp => {
                const strat = resp.find(r => r.id == pointsStrategyId)
                if (strat === undefined) {
                    setPointsStrategyId(undefined)
                    setSelectPoints(null)
                } else {
                    setSelectPoints(strat)
                }

                setAvailablePointStrategies(resp)
            })
    }, [type])

    useEffect(() => {
        ApiFactory.Instance().StaticAPI().staticGet(["course_submodule_type"])
            .then(resp => {
                if (resp.course_submodule_type === undefined) {
                    setAvailableTypes([])
                    return
                }
                setAvailableTypes(resp.course_submodule_type.map<SelectOption>(t => ({
                    value: "" + t.id,
                    label: t.name
                })))
            })
    }, [])

    const fillValues = (submodule: AdminFullCourseSubmodule) => ({
        name: submodule.name,
        optional: submodule.optional,
        duration: submodule.duration,
        is_verification_anonymous: submodule.is_verification_anonymous,
        course_submodule_type_id: submodule.course_submodule_type.id,
        course_submodule_type_content_url: submodule.course_submodule_type_data.content_url,
        course_submodule_type_description: submodule.course_submodule_type_data.description,
        course_submodule_type_text: submodule.course_submodule_type_data.text,
        course_submodule_type_show_result: submodule.course_submodule_type_data.show_result,
        course_submodule_type_show_answered_description: submodule.course_submodule_type_data.show_answered_description,
        course_submodule_type_show_unanswered_description: submodule.course_submodule_type_data.show_unanswered_description,
        course_submodule_type_discussion_id: submodule.course_submodule_type_data.discussion_id,
        course_submodule_type_topic_id: submodule.course_submodule_type_data.topic_id,
        course_submodule_type_variants: submodule.course_submodule_type_data.variants,
        course_submodule_type_groups: submodule.course_submodule_type_data.groups,
        course_submodule_type_min_groups: submodule.course_submodule_type_data.min_groups,
        course_submodule_type_user_can_add_groups: submodule.course_submodule_type_data.user_can_add_groups,
        course_submodule_type_user_can_add_variants: submodule.course_submodule_type_data.user_can_add_variants,
        course_submodule_period_strategy_id: submodule.course_submodule_period_strategy.id,
        course_submodule_points_strategy_id: submodule.course_submodule_points_strategy.id,
        max_points: submodule.course_submodule_points_strategy_data.max_points,
        points: submodule.course_submodule_points_strategy_data.points,
        max_retries: submodule.course_submodule_period_strategy_data.max_retries,
        min_time_between_retries: submodule.course_submodule_period_strategy_data.min_time_between_retries,
        has_deadline: submodule.has_deadline,
        deadline_time: submodule.deadline_time,
        is_available_in_my_portfolio: submodule.is_available_in_my_portfolio,
        is_available_in_other_user_portfolio: submodule.is_available_in_other_user_portfolio,
    })
    const {register, handleSubmit, errors, setValue, reset, getValues} = useForm<FormParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: fillValues(submodule)
    });

    useEffect(() => {
        if (submodule !== undefined) {
            reset(fillValues(submodule))
            register("name")
            register("duration");
            register("course_submodule_period_strategy_id");
            register("course_submodule_points_strategy_id");
            register("max_points");
            register("points");
            register("max_retries");
            register("min_time_between_retries");
            register("is_verification_anonymous")
            register("course_submodule_type_id")
            register("course_submodule_type_text")
            register("course_submodule_type_show_result")
            register("course_submodule_type_show_answered_description")
            register("course_submodule_type_show_unanswered_description")
            register("course_submodule_type_content_url")
            register("course_submodule_type_description")
            register("course_submodule_type_discussion_id")
            register("course_submodule_type_topic_id")
            register("course_submodule_type_variants")
            register("course_submodule_type_groups")
            register("course_submodule_type_min_groups")
            register("course_submodule_type_user_can_add_groups")
            register("course_submodule_type_user_can_add_variants")
            register("deadline_time")
        }
    }, [submodule])

    const onSubmit = (params: FormParams) => {
        let typeData: any = {}
        let pointsStrategyData = {}
        let periodStrategyData = {}
        switch (params.course_submodule_type_id) {
            case Type.Text:
                typeData = {
                    text: params.course_submodule_type_text,
                }
                break;
            case Type.Test:
                typeData = {
                    description: params.course_submodule_type_description,
                    show_result: params.course_submodule_type_show_result,
                    show_answered_description: params.course_submodule_type_show_answered_description,
                    show_unanswered_description: params.course_submodule_type_show_unanswered_description,
                }
                break;
            case Type.Video:
                typeData = {
                    content_url: params.course_submodule_type_content_url,
                    description: params.course_submodule_type_description,
                }
                break;
            case Type.FileSend:
                typeData = {
                    description: params.course_submodule_type_description,
                }
                break;
            case Type.TextSend:
                typeData = {
                    description: params.course_submodule_type_description,
                }
                break;
            case Type.Discussion:
                typeData = {
                    description: params.course_submodule_type_description,
                    discussion_id: params.course_submodule_type_discussion_id,
                    topic_id: params.course_submodule_type_topic_id,
                }
                break;
            case Type.Ranging:
                typeData = {
                    description: params.course_submodule_type_description,
                    variants: params.course_submodule_type_variants,
                }
                break;
            case Type.Sorting:
                typeData = {
                    description: params.course_submodule_type_description,
                    variants: params.course_submodule_type_variants,
                    groups: params.course_submodule_type_groups,
                    min_groups: params.course_submodule_type_min_groups,
                    user_can_add_groups: params.course_submodule_type_user_can_add_groups,
                    user_can_add_variants: params.course_submodule_type_user_can_add_variants,
                }
                break;
        }

        switch (params.course_submodule_points_strategy_id) {
            case SubmodulePointStrategyEnum.FixedPoints:
                pointsStrategyData = {
                    points: params.points
                }
                break;
            case SubmodulePointStrategyEnum.ManualPoints:
            case SubmodulePointStrategyEnum.ManualPointsNonBlocking:
                pointsStrategyData = {
                    max_points: params.max_points
                }
                break;
        }

        switch (params.course_submodule_period_strategy_id) {
            case SubmodulePeriodStrategyEnum.LimitByCount:
                periodStrategyData = {
                    max_retries: params.max_retries
                }
                break;
            case SubmodulePeriodStrategyEnum.LimitByCountAndTime:
                periodStrategyData = {
                    max_retries: params.max_retries,
                    min_time_between_retries: params.min_time_between_retries,
                }
        }

        if (params.course_submodule_type_id === Type.Discussion &&
            (typeData.topic_id === undefined || typeData.discussion_id === undefined)) {
            setError(new Error('Обсуждения или тема не заполнены'))
            return
        }
        setError(undefined);
        ApiFactory.Instance().CourseSubmoduleAPI().adminUpdateCourseSubModule(submodule.id, {
            is_verification_anonymous: params.is_verification_anonymous,
            duration: params.duration,
            optional: params.optional,
            name: params.name,
            course_submodule_period_strategy_id: params.course_submodule_period_strategy_id,
            course_submodule_period_strategy_data: periodStrategyData,
            course_submodule_points_strategy_id: params.course_submodule_points_strategy_id,
            course_submodule_points_strategy_data: pointsStrategyData,
            course_submodule_type_id: params.course_submodule_type_id,
            course_submodule_type_data: typeData,
            has_deadline: params.has_deadline,
            deadline_time: params.deadline_time,
            is_available_in_my_portfolio: params.is_available_in_my_portfolio,
            is_available_in_other_user_portfolio: params.is_available_in_other_user_portfolio,
        }).then(() => {
            setSaveSucceed(true)
            onChange()
        }).catch(e => {
            const resp = e as Response
            resp.json()
                .then((j: ErrorMessage) => setError(new Error(j.message)))
                .catch(e => setError(e))
        })
    }

    return <>
        <Nav fill variant="tabs" activeKey={navKey} defaultActiveKey={NavKey.Main} onSelect={ek => {
            if (ek !== null) {
                setNavKey(ek as NavKey)
            }
        }}>
            <Nav.Item>
                <Nav.Link eventKey={NavKey.Main}>Главное</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey={NavKey.Type}>Дополнительно</Nav.Link>
            </Nav.Item>
            {submodule && submodule.course_submodule_type.id === Type.Test
            && <Nav.Item>
                <Nav.Link eventKey={NavKey.Quiz}>Вопросы</Nav.Link>
            </Nav.Item>
            }
            <Nav.Item>
                <Nav.Link eventKey={NavKey.Points}>Баллы</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey={NavKey.Period}>Прохождение</Nav.Link>
            </Nav.Item>
        </Nav>
        <Card className={'content-card__card m-4'}>
            <Card.Body>
                {error && <ErrorHandler error={error}/>}
                {saveSucceed &&
                <SucceedAlert message={"Данные успешно сохранены"} onHide={() => setSaveSucceed(false)}/>}
                <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
                    <div className={navKey === NavKey.Main ? 'd-block' : 'd-none'}>
                        <MainControls
                            submodule={submodule}
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            availableTypes={availableTypes}
                            setType={setType}/>
                    </div>

                    <div className={navKey === NavKey.Type ? 'd-block' : 'd-none'}>
                        <TypeControls
                            courseId={courseId}
                            moduleId={moduleId}
                            typeId={type.id}
                            submodule={submodule}
                            errors={errors}
                            register={register}
                            setValue={setValue}
                            setError={setError}/>
                    </div>

                    <div className={navKey === NavKey.Quiz ? 'd-block' : 'd-none'}>
                        {navKey === NavKey.Quiz && <QuizControls submodule={submodule}/>}
                    </div>

                    <div className={navKey === NavKey.Period ? 'd-block' : 'd-none'}>
                        <PeriodControls
                            availablePeriodStrategies={availablePeriodStrategies}
                            register={register}
                            setValue={setValue}
                            errors={errors}
                            periodStrategy={selectPeriod}
                            onStrategyValueChanged={v => {
                                setPeriodStrategyId(v.id)
                                setSelectPeriod(v)
                            }}
                            periodStrategyId={periodStrategyId}/>
                    </div>

                    <div className={navKey === NavKey.Points ? 'd-block' : 'd-none'}>
                        <PointsControls
                            availablePointStrategies={availablePointStrategies}
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            pointStrategy={selectPoints}
                            onStrategyValueChanged={v => {
                                setPointsStrategyId(v.id)
                                setSelectPoints(v)
                            }}
                            pointsStrategyId={pointsStrategyId}/>
                    </div>

                    {navKey !== NavKey.Quiz && <BottomButtons submodule={submodule} onDelete={onDelete}/>}
                </Form>
            </Card.Body>
        </Card>
    </>
}
