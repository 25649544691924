import moment from 'moment';

export function minutesDurationToString(source: number) {
    const dur = moment.duration(source, 'minutes');
    const hours = Math.floor(dur.asHours());
    const mins = Math.floor(dur.asMinutes()) - hours * 60;
    const sec = Math.floor(dur.asSeconds()) - hours * 60 * 60 - mins * 60;
    let res = ""
    if (hours > 0) {
        res += hours + "ч"
    }
    if (mins > 0) {
        res += mins + "м"
    }
    if (sec > 0) {
        res += sec + 'с'
    }
    return res
}