import * as React from 'react'
import {ReactElement} from 'react'
import ScrollMenu from "react-horizontal-scrolling-menu";

type ElementProps = {
    onClick?: () => void,
    active: boolean
}

export type Item = {
    title?: string,
    render?: (active: boolean, onClick?: () => void) => ReactElement<ElementProps>
    onClick?: () => void,
    active: boolean
}

type Props = {
    items: Item[],
    selected?: number
    alignCenter: boolean
}

export default function HorizontalScroll(props: Props) {
    const selected = props.selected ?? props.items.findIndex(item => item.active)
    const data = props.items.map((item, i) => {
        if (item.render) {
            return item.render(item.active, item.onClick)
        } else if (item.title) {
            return <span key={i + ""} className={item.active ? 'text-primary' : 'as-link'}
                         onClick={item.onClick}>{item.title}</span>
        }
        return <></>
    })
    return <ScrollMenu
        useButtonRole={false}
        selected={selected + ""}
        scrollToSelected={true}
        alignCenter={props.alignCenter}
        wrapperClass={"horizontal-scroll"}
        menuClass={'horizontal-menu'}
        data={data}
    />
}

