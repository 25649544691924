import * as React from 'react';
import {useEffect, useRef, useState, Suspense} from 'react';
import * as yup from "yup";
import {FullCourseSection, UpdateSectionParams} from "../../api/generated";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import ApiFactory, {MAX_PER_PAGE} from "../../api/ApiFactory";
import ErrorHandler from "../ErrorHandler";
import {Loader} from "../Loaders";
import Form from 'react-bootstrap/Form';
import Card from "react-bootstrap/Card";
import Button from 'react-bootstrap/Button';
import SucceedAlert from "../common/SucceedAlert";
import Select from "react-select";
import {SelectOption} from "../common/types";

const Editor = React.lazy(() => import("../common/lazy/AdminCKEditor"));

type Props = {
    section?: FullCourseSection,
    courseId: number,
    onSucceed: () => void,
    onDelete: () => void,
}

const formValidationSchema = yup.object<UpdateSectionParams>({
    name: yup.string().required(),
    description: yup.string(),
})

type SectionFormProps = {
    section: FullCourseSection,
    onSucceed: () => void,
    onDelete: () => void,
    courseId: number,
}

function SectionForm({section, onDelete, onSucceed, courseId}: SectionFormProps) {
    const form = useRef<HTMLFormElement>(null)
    const [description, setDescription] = useState<string>(section && section.description ? section.description : "")
    const [error, setError] = useState<Error | undefined>(undefined);
    const [succeedMessage, setSucceedMessage] = useState<string | undefined>(undefined);
    const [isPrivate, setIsPrivate] = useState<boolean>(section.is_private);
    const [selectGroupId, setSelectGroupId] = useState<SelectOption | undefined>(
        section.course_user_group ? {value: section.course_user_group.id + "", label: section.course_user_group.name}
                                            : undefined
    );
    const [availableGroups, setAvaialbleGroups] = useState<SelectOption[]|undefined>(undefined);

    const {register, handleSubmit, errors, setValue, reset} = useForm<UpdateSectionParams>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            name: section.name,
            description: section.description ?? "",
            is_private: section.is_private,
            course_user_group_id: section.course_user_group ? section.course_user_group.id : undefined,
        }
    });

    useEffect(() => {
        ApiFactory.Instance().CourseUserGroupAPI().adminListCourseUserGroups(courseId, 0, MAX_PER_PAGE)
            .then(resp => setAvaialbleGroups(resp.items.map(grp => ({value: "" + grp.id, label: grp.name}))))
            .catch(e => setError(e))
    }, [])

    useEffect(() => {
        if (section === undefined) {
            return
        }
        setDescription(section.description || "");
        reset({
            name: section.name,
            description: section.description,
            is_private: section.is_private,
            course_user_group_id: section.course_user_group ? section.course_user_group.id : undefined,
        })
    }, [section])

    useEffect(() => {
        register("description");
        register("course_user_group_id");
    }, [])

    const onSubmit = (params: UpdateSectionParams) => {
        setError(undefined)
        ApiFactory.Instance().CourseSectionAPI().adminUpdateCourseSection(section.id, params)
            .then(s => {
                onSucceed();
                setSucceedMessage("Данные сохранены");
            }).catch(e => setError(e))
    }

    return <Card className={'m-4'}>
        {error && <ErrorHandler error={error}/>}
        <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
            <Card.Body>
                {succeedMessage && <SucceedAlert message={succeedMessage} onHide={() => setSucceedMessage(undefined)}/>}
                <Form.Group>
                    <Form.Label>Название</Form.Label>
                    <Form.Control ref={register} name={"name"}/>
                    {errors && errors.name &&
                    <Form.Text className={'text-danger'}>{(errors.name as any)?.message}</Form.Text>}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Описание секции</Form.Label>
                    <Suspense fallback={<Loader/>}>
                        <Editor initialContent={description} onContentChange={text => {
                            setDescription(text)
                            setValue("description", text)
                        }}/>
                    </Suspense>

                    {errors && errors.description &&
                    <Form.Text className={'text-danger'}>{(errors.description as any)?.message}</Form.Text>}
                </Form.Group>

                <Form.Group>
                    <Form.Check ref={register}
                                name={"is_private"}
                                label={'Ограниченная доступность'}
                                onChange={(e: any) => {
                                    setIsPrivate(e.target.checked)
                                }}
                    />
                    {errors && errors.is_private &&
                    <Form.Text className={'text-danger'}>{errors.is_private.message}</Form.Text>}
                </Form.Group>
                {isPrivate &&
                <Form.Group>
                    <Form.Label>Группа пользователей</Form.Label>
                    <Select value={selectGroupId}
                            placeholder={'Выберите группу'}
                            onChange={(v: any) => {
                                const val = v as SelectOption;
                                setSelectGroupId(val)
                                const newVal = Number(v.value)
                                setValue("course_user_group_id", newVal)
                            }}
                            options={availableGroups}
                    />
                    {errors && errors.course_user_group_id &&
                    <Form.Text
                        className={'text-danger'}>{JSON.stringify(errors.course_user_group_id)}</Form.Text>}
                </Form.Group>
                }
            </Card.Body>
            <Card.Body className={'d-flex flex-row justify-content-end'}>
                <Button variant={'danger'} className={'ml-2'} onClick={() => {
                    if (window.confirm(`Вы действительно хотите удалить секцию ${section.name}`)) {
                        ApiFactory.Instance().CourseSectionAPI().adminDeleteCourseSection(section.id)
                            .then(() => {
                                onDelete()
                            }).catch(e => setError(e))
                    }
                }}>Удалить</Button>
                <Button type={'submit'} variant={'primary'} className={'ml-2 text-white'}>Сохранить</Button>
            </Card.Body>
        </Form>
    </Card>;
}

export default function SectionEdit(props: Props) {
    if (props.section === undefined) {
        return <Loader/>
    }

    return <SectionForm {...props} section={props.section}/>
}