import React, {useState} from "react";
import {RouteComponentProps, useNavigate} from "@reach/router";
import CoursesList from "../../components/teacher/CoursesList";
import ContentContainer from "../../components/common/ContentContainer";
import PageName from "../../components/common/PageName";

type Props = {} & RouteComponentProps

export default function Home(props: Props) {
    const navigate = useNavigate()
    const [show, setShow] = useState<boolean>(false)
    const [error, setError] = useState<Error | undefined>(undefined)

    return (
        <>
            <ContentContainer>
                <PageName title="Все курсы"/>
                <CoursesList/>
            </ContentContainer>
        </>
    )
}
