import * as React from 'react';
import {useContext} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {RouteComponentProps, useNavigate} from "@reach/router";
import Menu, {HorizontalMenu} from '../../../components/submod/user/Menu';
import SubmoduleContent from "../../../components/submod/SubmoduleContent";
import ApiFactory from "../../../api/ApiFactory";
import useSWR from "swr";
import {UserFullCourseSubmodule} from "../../../api/generated";
import {AuthContext} from "../../../components/auth/AuthContext";
import ContentContainer from "../../../components/common/ContentContainer";
import HorizontalScroll from "../../../components/common/HorizontalScroll";
import {LineLoader} from "../../../components/Loaders";

type Props = {
    id?: string
    courseId?: string
    moduleId?: string
    completionId?: string
} & RouteComponentProps


type BreadcrumbsProps = {
    submodule?: UserFullCourseSubmodule,
    error?: Error
    courseId: number
}

function Breadcrumbs(props: BreadcrumbsProps) {
    const navigate = useNavigate()
    const {submodule, error, courseId} = props
    if (error !== undefined) {
        return null
    }
    if (submodule === undefined) {
        return <LineLoader/>
    }

    return <div className={'mt-4'}>
        <HorizontalScroll alignCenter={false} items={[
            {title: 'Список курсов /', active: false, onClick: () => navigate("/")},
            {
                title: submodule.breadcrumbs.course.name + " /",
                active: false,
                onClick: () => navigate(`/user/course/${courseId}`)
            },
            {
                title: submodule.breadcrumbs.module.name + " /",
                active: false,
                onClick: () => navigate(`/user/course/${courseId}/module/${submodule.breadcrumbs.module.id}`)
            },
            {title: submodule.breadcrumbs.section.name, active: true},
        ]}/>
    </div>
}

export default function View(props: Props) {
    const {id, completionId, courseId, moduleId} = props;
    const navigate = useNavigate()
    const fetcher = () => ApiFactory.Instance().CourseSubmoduleAPI().userViewCourseSubmodule(Number(courseId), Number(completionId), Number(id));
    const {data: submodule, error} = useSWR<UserFullCourseSubmodule>(['user/course-submodule', Number(id), useContext(AuthContext).userId], fetcher)
    return (
        <ContentContainer
            hasNabBottomMenu={true}
            renderNavBottomMenu={() => <HorizontalMenu
                courseId={Number(courseId)}
                moduleId={Number(moduleId)}
                onSubmoduleClick={(subId) => {
                    navigate(`/user/course/${courseId}/module/${moduleId}/completion/${completionId}/submodule/${subId}`)
                }}
                activeSubmoduleId={Number(id)}/>
            }>

            <Breadcrumbs courseId={Number(courseId)} error={error} submodule={submodule}/>

            <hr className={'hr--divider'}/>
            <Row>
                <Col xs={12} md={3} className={'d-none d-sm-block'}>
                    <Menu
                        onSubmoduleClick={(subId) => {
                            navigate(`/user/course/${courseId}/module/${moduleId}/completion/${completionId}/submodule/${subId}`)
                        }}
                        activeSubmoduleId={Number(id)}
                        courseId={Number(courseId)}
                        moduleId={Number(moduleId)}/>
                </Col>
                <Col xs={12} md={9}>
                    <SubmoduleContent moduleId={Number(moduleId)} submoduleId={Number(id)}
                                      courseId={Number(courseId)} completionId={Number(completionId)}/>

                </Col>
            </Row>
        </ContentContainer>
    )
}