import React, {useContext, useEffect, useState} from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import Button from "react-bootstrap/Button";
import {Redirect, RouteComponentProps} from "@reach/router";
import {AuthContext} from "../components/auth/AuthContext";
import AvailableCoursesList from "../components/available/CoursesList";
import {PRIMARY_COLOR} from "../config";
import CoursesList, {CourseCompletionStatusEnum} from "../components/user/CoursesList";
import ApiFactory from "../api/ApiFactory";
import ErrorHandler from "../components/ErrorHandler";
import Alert from 'react-bootstrap/Alert';
import PageName from "../components/common/PageName";
import ContentContainer from "../components/common/ContentContainer";
import HorizontalScroll from "../components/common/HorizontalScroll";
import { redirectTo } from "@reach/router"
import {ErrorMessage} from "../api/generated";

type Props = {
    action?: Action
    token?: boolean,
    actionId?: string,
} & RouteComponentProps

enum TabsEnum {
    InProgress,
    Finished,
    Available,
}

enum Action {
    Confirmed = "confirmed",
    Confirmation = "confirmation",
    ResetSucceed = "reset-succeed",
    FollowNotification = "follow-notification",
}

function ActiveIndicator() {
    return <div style={{width: '40px', height: '4px', backgroundColor: PRIMARY_COLOR}}/>
}

function Content() {
    const [activeTab, setActiveTab] = useState<TabsEnum>(TabsEnum.InProgress);

    const {isLoggedIn} = useContext(AuthContext)

    if (!isLoggedIn) {
        return <AvailableCoursesList/>
    }

    const renderTab = (activeTab: TabsEnum) => {
        switch (activeTab) {
            case TabsEnum.InProgress:
                return <CoursesList statusId={CourseCompletionStatusEnum.InProgress}
                                    emptyMessage={'Вы не подписаны ни на один курс'}/>
            case TabsEnum.Finished:
                return <CoursesList statusId={CourseCompletionStatusEnum.Finished}
                                    emptyMessage={'У Вас нет завершенных курсов'}/>
            case TabsEnum.Available:
                return <AvailableCoursesList/>
        }
    }

    return <>
        <HorizontalScroll alignCenter={true} items={[
            {
                render: (active, onClick) => <div key={TabsEnum.InProgress}
                                                  className={'course-list__tab-header' + (active ? ' course-list__tab-header-active' : '')}
                                                  onClick={onClick}>
                    <span>В процессе</span>
                    {active && <ActiveIndicator/>}
                </div>,
                onClick: () => setActiveTab(TabsEnum.InProgress),
                active: activeTab === TabsEnum.InProgress
            },
            {
                render: (active, onClick) => <div key={TabsEnum.Finished}
                                                  className={'course-list__tab-header' + (active ? ' course-list__tab-header-active' : '')}
                                                  onClick={onClick}>
                    <span>Завершенные</span>
                    {active && <ActiveIndicator/>}
                </div>,
                onClick: () => setActiveTab(TabsEnum.Finished),
                active: activeTab === TabsEnum.Finished
            },
            {
                render: (active, onClick) => <div key={TabsEnum.Available}
                                                  className={'course-list__tab-header' + (active ? ' course-list__tab-header-active' : '')}
                                                  onClick={onClick}>
                    <span>Все</span>
                    {active && <ActiveIndicator/>}
                </div>,
                onClick: () => setActiveTab(TabsEnum.Available),
                active: activeTab === TabsEnum.Available
            }
        ]}/>

        <div>
            {renderTab(activeTab)}
        </div>
    </>
}

type ConfirmationProps = {
    token?: string
}

function ConfirmationHandler({token}: ConfirmationProps) {
    const [confirmSucceed, setConfirmSucceed] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>(undefined);
    useEffect(() => {
        if (token) {
            ApiFactory.Instance().AuthAPI().authHandleConfirmation({
                token: token
            }).then(() => {
                setConfirmSucceed(true)
            }).catch(e => setError(e))
        }
    }, [])

    if (error) {
        return <ErrorHandler error={error}/>
    }
    if (confirmSucceed) {
        return <Redirect to={"/action/confirmed"}/>
    }

    return null
}

export default function Home(props: Props) {
    console.log(props)
    const {action, token, actionId} = props
    const {setShowModal, isLoggedIn} = useContext(AuthContext)
    const [error, setError] = useState<Error|undefined>(undefined)
    const [displayConfirm, setDisplayConfirm] = useState<boolean>(action === Action.Confirmed)
    useEffect(() => {
        if (displayConfirm) {
            setTimeout(() => {
                setDisplayConfirm(false)
            }, 5000)
        }
        if (action === Action.ResetSucceed) {
            setShowModal(true)
        }

        if (action === Action.FollowNotification) {
            ApiFactory.Instance().NotificationAPI().followNotification(Number(actionId))
                .then(val => {
                    if (val.link) {
                        console.log('redirect link ', val.link);
                        window.location.href = val.link
                    }
                }).catch((resp: any) => {
                    if (resp.json) {
                        resp.json().then((resp: ErrorMessage) => {
                            if (resp.code === 401 || resp.code === 402) {
                                setError(new Error("Нет доступа для просмотра нотификации. Пожалуйста, выполните вход по нужным пользователем"))
                            }
                        })
                    } else {
                        console.warn('redirect failed', resp)
                    }
            })
        }
    }, [])
    return (
        <>
            <ContentContainer>
                {displayConfirm && <Alert variant={"success"}>Ваш адрес электронной почты успешно подтвержден</Alert>}
                {action === Action.Confirmation && <ConfirmationHandler token={token as string | undefined}/>}
                {error && <ErrorHandler error={error}/>}
                <PageName title={'Курсы'}/>
                <Content/>
            </ContentContainer>
        </>
    )
}