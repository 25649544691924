import {
    AuthApi,
    Configuration,
    CourseApi,
    CourseModuleApi,
    CourseSectionApi,
    CourseSubmoduleApi,
    CourseSubmoduleCompletionApi, CourseUserGroupApi,
    DiscussionApi,
    DiscussionTopicApi,
    DiscussionTopicCommentApi,
    NotificationApi, PortfolioApi,
    StaticApi,
    UserApi
} from "./generated";
import Auth from "../components/auth/Auth";
import CourseApiWrapper from "./CourseApiWrapper";
import CourseSubmoduleApiWrapper from "./CourseSubmoduleApiWrapper";
import CourseSubmoduleCompletionApiWrapper from "./CourseSubmoduleCompletionApiWrapper";
import UserApiWrapper from "./UserApiWrapper";
import UploadApiWrapper from "./UploadApiWrapper";

export const DEFAULT_PER_PAGE = 20;
export const MAX_PER_PAGE = 100;

export default class ApiFactory {
    private static _instance: ApiFactory;
    private _auth: Auth;

    constructor() {
        this._auth = new Auth();
    }

    public static Instance() {
        if (this._instance == undefined) {
            this._instance = new ApiFactory()
        }
        return this._instance
    }

    public StaticAPI(): StaticApi {
        return new StaticApi(this._getConfiguration())
    }

    public CourseSubmoduleAPI(): CourseSubmoduleApi {
        return new CourseSubmoduleApiWrapper(this._getConfiguration())
    }

    public CourseSubmoduleCompletionAPI(): CourseSubmoduleCompletionApi {
        return new CourseSubmoduleCompletionApiWrapper(this._getConfiguration())
    }

    public CourseModuleAPI(): CourseModuleApi {
        return new CourseModuleApi(this._getConfiguration())
    }

    public CourseAPI(): CourseApi {
        return new CourseApiWrapper(this._getConfiguration())
    }

    public CourseSectionAPI(): CourseSectionApi {
        return new CourseSectionApi(this._getConfiguration())
    }

    public CourseUserGroupAPI(): CourseUserGroupApi {
        return new CourseUserGroupApi(this._getConfiguration())
    }

    public AuthAPI(): AuthApi {
        return new AuthApi(this._getConfiguration())
    }

    public UserAPI(): UserApi {
        return new UserApiWrapper(this._getConfiguration())
    }

    public NotificationAPI(): NotificationApi {
        return new NotificationApi(this._getConfiguration());
    }

    public DiscussionAPI(): DiscussionApi {
        return new DiscussionApi(this._getConfiguration());
    }

    public DiscussionTopicAPI(): DiscussionTopicApi {
        return new DiscussionTopicApi(this._getConfiguration())
    }

    public DiscussionTopicCommentAPI(): DiscussionTopicCommentApi {
        return new DiscussionTopicCommentApi(this._getConfiguration())
    }

    public UploadAPI(): UploadApiWrapper {
        return new UploadApiWrapper(this._getConfiguration())
    }

    public PortfolioAPI(): PortfolioApi {
        return new PortfolioApi(this._getConfiguration())
    }

    private _getConfiguration() {
        return new Configuration({
            apiKey: this._auth.getAccessToken(),
            basePath: API_BASE_PATH,
        })
    }
}