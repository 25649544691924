import * as React from 'react';
import {useEffect, useState} from 'react';
import Alert from 'react-bootstrap/Alert';

type Props = {
    message: string
    onHide: () => void
}

export default function SucceedAlert(props: Props) {
    const [displayed, setDisplayed] = useState<boolean>(true)
    useEffect(() => {
        const timeout = setTimeout(() => {
            setDisplayed(false)
            props.onHide()
        }, 5000)
        return () => clearTimeout(timeout)
    }, [])
    return displayed ? <Alert variant={'success'}>{props.message}</Alert> : null
}