import * as React from 'react';
import RichContent from "../../common/RichContent";

type Props = {
    typeData: any
}

export default function TextResult(props: Props) {
    return <div className={'mt-4'}>
        <div className={'m-4'}>
            {props.typeData && props.typeData.text ?
                <RichContent text={props.typeData.text as string}/> : ""}
        </div>
    </div>
}