import * as React from 'react';
import {useEffect, useState} from "react";
import Card from "react-bootstrap/Card";
import {navigate} from "@reach/router";
import HtmlParser from "react-html-parser";
import SubmoduleItem from "../common/SubmoduleItem";
import SectionCreate from "./SectionCreate";
import {AdminFullCourseModule, ShortCourseUserGroup} from "../../api/generated";
import useSWR from "swr";
import ApiFactory, {DEFAULT_PER_PAGE, MAX_PER_PAGE} from "../../api/ApiFactory";
import ErrorHandler from "../ErrorHandler";
import {Loader} from "../Loaders";
import UserGroupCreate from "./UserGroupCreate";
import UserGroupEdit from "./UserGroupEdit";

type Props = {
    courseId: number,
}

type ItemProps = {
    grp: ShortCourseUserGroup,
    courseId: number,
    onDelete: () => void,
    onEdit: () => void,
}

enum ItemMode {
    View,
    Edit
}
function UserGroupItem({grp, onDelete, courseId, onEdit}: ItemProps) {
    const [mode, setMode] = useState<ItemMode>(ItemMode.View)
    if (mode === ItemMode.Edit) {
        return <UserGroupEdit groupId={grp.id} courseId={courseId}
            onSucceed={() => {
                setMode(ItemMode.View);
                onEdit()
            }}
            onCancel={() => {
                setMode(ItemMode.View)
            }}
        />
    }

    return <Card key={`grp-${grp.id}`} className={'m-4'}>
        <Card.Body className={'p-4 d-flex flex-column'}>
            <div className={'d-flex flex-row justify-content-between align-items-center flex-fill'}>
                <h6>{grp.name}</h6>
                <div className={'d-flex flex-row'}>
                    <img className={'button-icon as-link m-2'} src={"/images/edit.svg"}
                         onClick={() => setMode(ItemMode.Edit)}/>
                    <img className={'button-icon as-link m-2'} src={"/images/delete.svg"}
                         onClick={() => onDelete()}/>
                </div>

            </div>
        </Card.Body>
    </Card>
}

export default function UserGroupList({courseId}: Props) {
    const [showAddUserGroup, setShowAddUserGroup] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [error, setError] = useState<Error|undefined>();
    const fetcher = () => ApiFactory.Instance().CourseUserGroupAPI().adminListCourseUserGroups(
        courseId, currentPage, MAX_PER_PAGE)
    const {data: groups, error: fetchError, mutate} = useSWR([`admin/user-group/${courseId}`, currentPage], fetcher)

    useEffect(() => {
        if (fetchError !== undefined) {
            setError(fetchError)
        }
    }, [fetchError])

    if (error) {
        return <ErrorHandler error={error}/>
    }

    if (groups === undefined) {
        return <Loader/>
    }

    const onDelete = (grp: ShortCourseUserGroup) => {
        if (window.confirm(`Вы действительно хотите удалить группу ${grp.name}`)) {
            ApiFactory.Instance().CourseUserGroupAPI().adminDeleteCourseUserGroup(grp.id, courseId)
                .then(() => {
                    mutate()
                }).catch(e => setError(e))
        }
    }

    return <>
        {groups.items.map(grp => <UserGroupItem grp={grp} onDelete={() => onDelete(grp)} onEdit={() => mutate()} courseId={courseId}/>)}

        {showAddUserGroup
            ? <UserGroupCreate courseId={courseId} onSucceed={() => {
                setShowAddUserGroup(false);
                mutate()
            }}
                             onCancel={() => setShowAddUserGroup(false)}/>
            : <div className={'d-flex flex-row align-items-center justify-content-center p-2 as-link'}
                   onClick={() => setShowAddUserGroup(true)}>
                <h5 className={'m-0'}>Добавить группу</h5>
                <img src={"/images/add.svg"} className={'small-icon as-link m-2'}/>
            </div>
        }
    </>
}