import * as React from 'react';
import Modal from "react-bootstrap/Modal";
import {Suspense, useState} from "react";
import Button from "react-bootstrap/Button";
import ApiFactory from "../../api/ApiFactory";
import ErrorHandler from "../ErrorHandler";
import {Loader} from "../Loaders";
import Form from "react-bootstrap/Form";

const Editor = React.lazy(() => import("../common/lazy/AdminCKEditor"));

type Props = {
    show: boolean
    courseId: number
    onHide: () => void
}

export default function NotifyModal({courseId, show, onHide}: Props) {
    const [message, setMessage] = useState<string>("")
    const [error, setError] = useState<Error|undefined>(undefined)

    const onSubmit = () => {
        ApiFactory.Instance().CourseAPI().courseNotifyUsers(courseId, {
            message,
        }).then(() => {
            onHide()
        }).catch(e => {
            setError(e)
        })
    }

    return <Modal show={show}>
        <Modal.Header>
            <Modal.Title>Отправить оповещение всем пользователям курса</Modal.Title>
        </Modal.Header>
        {error && <ErrorHandler error={error}/>}
        <Modal.Body>
            <Form.Control as={'textarea'} value={message} onChange={e => setMessage(e.target.value)} rows={7}/>
            <div className={'d-flex flex-direction-row justify-content-end mt-2'}>
                <Button variant={'danger'} className={'text-white mr-2'} onClick={onHide}>Отмена</Button>
                <Button className={'text-white'} onClick={onSubmit}>Отправить</Button>
            </div>
        </Modal.Body>
    </Modal>
}