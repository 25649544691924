import * as React from 'react';
import {useContext, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {navigate, RouteComponentProps} from "@reach/router";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Menu from "../../../components/submod/admin/Menu";
import useSWR, {mutate} from "swr";
import ApiFactory from "../../../api/ApiFactory";
import {AuthContext} from "../../../components/auth/AuthContext";
import SectionAdmin from "../../../components/admin/SectionEdit";
import ErrorHandler from "../../../components/ErrorHandler";
import SubmoduleAdmin from "../../../components/submod/admin/SubmoduleAdmin";
import SubmoduleCreate from "../../../components/submod/admin/SubmoduleCreate";
import {Loader} from "../../../components/Loaders";
import ContentContainer from "../../../components/common/ContentContainer";
import {Link} from "@reach/router";
import {HorizontalMenu} from "../../../components/submod/admin/Menu";

enum Mode {
    Section,
    SubModule,
    AddSubModule
}

type Props = {
    id?: string
    courseId?: string
    moduleId?: string
    submoduleId?: string
} & RouteComponentProps

export default function Section(props: Props) {
    const id = props.id !== undefined ? Number(props.id) : undefined
    const courseId = props.courseId !== undefined ? Number(props.courseId) : undefined
    const moduleId = props.moduleId !== undefined ? Number(props.moduleId) : undefined
    const submoduleId = props.submoduleId !== undefined ? Number(props.submoduleId) : undefined

    const [mode, setMode] = useState<Mode>(submoduleId ? Mode.SubModule : Mode.Section)
    const {userId} = useContext(AuthContext);

    const fetcher = () => ApiFactory.Instance().CourseSectionAPI().adminViewCourseSection(id!)
    const key = ['admin/course-section', id, userId]
    const {data: section, error} = useSWR(key, fetcher)

    if (id === undefined || courseId === undefined || moduleId === undefined) {
        return <ErrorHandler error={new Error('Неверные параметры страницы')}/>
    }

    return (
        <ContentContainer
            hasNabBottomMenu={true}
            renderNavBottomMenu={() => <HorizontalMenu
                sectionId={section?.id}
                courseId={courseId}
                moduleId={moduleId}
                onAddSubmodule={() => {
                    setMode(Mode.AddSubModule)
                }}
                onSubmoduleClick={(subId) => {
                    navigate(`/admin/course/${courseId}/module/${moduleId}/section/${props.id}/submodule/${subId}`)
                }}
                activeSubmoduleId={submoduleId}/>}>
            {section
            && <Breadcrumb className={'course__breadcrumb'}>
                <Breadcrumb.Item href={`/admin`}><Link to={"/admin"}>Список курсов</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link
                    to={`/admin/course/${courseId}`}>{section.breadcrumbs.course.name}</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link
                    to={`/admin/course/${courseId}/module/${moduleId}`}>{section.breadcrumbs.module.name}</Link></Breadcrumb.Item>
                <Breadcrumb.Item active={true}>{section.name}</Breadcrumb.Item>
            </Breadcrumb>
            }

            <hr className={'hr--divider'}/>
            <Row>
                <Col xs={12} md={3} className={'d-none d-sm-block'}>
                    <Menu
                        initialActiveSubmoduleId={submoduleId}
                        onSectionAboutClick={() => {
                            navigate(`/admin/course/${courseId}/module/${moduleId}/section/${id}`)
                        }}
                        onSubmoduleClick={sub => {
                            navigate(`/admin/course/${courseId}/module/${moduleId}/section/${id}/submodule/${sub.id}`)
                        }}
                        section={section}
                        onSubmoduleAdd={() => {
                            setMode(Mode.AddSubModule)
                        }}
                    />
                </Col>
                <Col xs={12} md={9}>
                    {error && <ErrorHandler error={error}/>}
                    {section === undefined && error === undefined && <Loader/>}
                    {section !== undefined &&
                    <>
                    {mode === Mode.AddSubModule
                        ? <SubmoduleCreate
                            onCancel={() => setMode(submoduleId ? Mode.SubModule : Mode.Section)}
                            onSuccess={(sm) => {
                                mutate(key)
                                navigate(`/admin/course/${courseId}/module/${moduleId}/section/${id}/submodule/${sm.id}`)
                                setMode(submoduleId ? Mode.SubModule : Mode.Section)
                            }}
                            sectionId={section.id}
                        />
                        : mode === Mode.SubModule
                            ? <SubmoduleAdmin id={props.submoduleId}
                                              courseId={courseId}
                                              moduleId={moduleId}
                                              sectionId={section.id}/>
                            : <SectionAdmin courseId={courseId} section={section} onDelete={() => {
                                mutate(key)
                            }}
                                            onSucceed={() => {
                                                mutate(key)
                                            }}/>
                    }
                    </>
                    }
                </Col>
            </Row>
        </ContentContainer>
    )
}