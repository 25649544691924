import * as React from 'react'
import {ReactElement, useContext, useState} from 'react'
import {UserShortCourse} from "../../api/generated";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ApiFactory, {DEFAULT_PER_PAGE} from "../../api/ApiFactory";
import {Link} from "@reach/router";
import {ListLoader} from "../Loaders";
import useSWR from "swr";
import {AuthContext} from "../auth/AuthContext";
import ErrorHandler from "../ErrorHandler";
import Badge from "react-bootstrap/Badge";
import Pagination from "../common/Pagination";

export enum CourseStatus {
    Available = 1,
    InArchive = 2,
    Deleted = 3,
    Draft = 4
}

export enum CourseCompletionStatusEnum {
    InProgress = 1,
    Finished = 2,
}

export enum SubmoduleCompletionStatusEnum {
    InProgress = 1,
    WaitForReview = 2,
    Finished = 3,
    Declined = 4,
    WaitForReviewNonBlocking = 5,
}

type Props = {
    statusId?: CourseCompletionStatusEnum,
    query?: string,
    emptyMessage?: string
}

export default function CoursesList({statusId, query, emptyMessage}: Props) {
    const {userId} = useContext(AuthContext)
    const [currentPage, setCurrentPage] = useState<number>(0)

    const fetcher = () => ApiFactory.Instance().CourseAPI().userCourseList(statusId, query, currentPage, DEFAULT_PER_PAGE)
    const {data: courses, error} = useSWR(["/user/course", userId, statusId, query, currentPage], fetcher)

    if (error !== undefined) {
        return <ErrorHandler error={error}/>
    }

    if (courses !== undefined && courses.total == 0) {
        return emptyMessage ? <div style={{"height": "200px"}} className={'d-flex flex-row align-items-center justify-content-center'}>
            <h5>{emptyMessage}</h5>
        </div> : null
    }

    return courses !== undefined ? <div>
            {courses.items.map(sch => <ShortCourse key={"" + sch.id} course={sch}/>)}
            <div className={'d-flex direction-row justify-content-center'}>
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalItems={courses.total}/>
            </div>
        </div>
        : <ListLoader/>
}

type ShortCourseProps = {
    course: UserShortCourse
}

function ShortCourse(props: ShortCourseProps): ReactElement {
    const {course} = props;
    return (
        <>
            <Row className={'m-2'}>
                <Col xs={12} md={4} lg={3}>
                    <img className={'img-fluid admin-course-list__img'}
                         src={course.photo ? UPLOAD_BASE_PATH + course.photo : '/images/no-image.png'}/>
                </Col>
                <Col xs={12} md={8} lg={9}>
                    <div style={{padding: "17px"}}>

                        <Link to={"/user/course/" + course.id}><h4>{course.name}</h4></Link>
                        {course.categories.map(cat => <Badge key={`course-category__badge-${cat.id}`}
                                                             className={'course-category__badge text-white'}
                                                             variant={'primary'} pill={true}>{cat.name}</Badge>)}
                    </div>
                </Col>
            </Row>
            <hr className={'hr--divider'}/>
        </>

    )
}