import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import React, {ReactElement, useContext, useState} from "react";
import {AuthContext} from "./auth/AuthContext";
import UserInfo from "./user/UserInfo";
import Notifications from "./user/Notifications";
import {useNavigate, Link} from "@reach/router";

type Props = {
    hasLeftMenu?: boolean
    renderLeftMenu?: (onItemClicked: () => void) => ReactElement
    hasBottomMenu?: boolean
    renderBottomMenu?: () => ReactElement
}

function Right() {
    const {setShowModal, isLoggedIn} = useContext(AuthContext)
    return <div id={"nav-right"} className="d-flex justify-content-end ml-auto align-items-center flex-fill">
        {isLoggedIn
            ? <>
                <Notifications/>
                <UserInfo/>
            </>
            : <>
                <Button
                    variant={'primary'}
                    className={'as-link text-white btn-sm d-block d-sm-none'}
                    onClick={() => setShowModal(true)}>
                    Присоединиться
                </Button>
                <Button
                    variant={'primary'}
                    className={'as-link text-white d-none d-sm-block'}
                    onClick={() => setShowModal(true)}>
                    Присоединиться бесплатно
                </Button>
            </>
        }

    </div>
}

export default function Header(props: Props) {
    const navigate = useNavigate()
    const [expanded, setExpanded] = useState<boolean>(false);
    const onItemClicked = () => {
        setExpanded(false)
    }
    return (
        <>
            <Navbar style={{background: '#fff', boxShadow: "0px 12px 35px rgba(160, 121, 0, 0.2)"}} sticky={"top"}
                    bg={'light'} expand={'sm'} expanded={expanded}>
                {props.hasLeftMenu && <Navbar.Toggle aria-controls={"left-menu"} onClick={() => {
                    setExpanded(!expanded)
                }}/>}
                <Navbar.Brand>
                    <Link to={"/"}><img className={'logo'} src="/images/logo.png"/></Link>
                </Navbar.Brand>
                <Right/>
                {props.hasLeftMenu &&
                <Navbar.Collapse id={"left-menu"} className={'mr-auto d-sm-none d-md-none d-lg-none d-xl-none'}>
                    {props.renderLeftMenu !== undefined ? props.renderLeftMenu(onItemClicked) : null}
                </Navbar.Collapse>}
                {props.hasBottomMenu && props.renderBottomMenu && props.renderBottomMenu()}
            </Navbar>
        </>)
}