import * as React from 'react';
import useSWR from "swr";
import ApiFactory from "../../../api/ApiFactory";
import ErrorHandler from "../../ErrorHandler";
import {Loader} from "../../Loaders";
import {PortfolioCompletionList, PortfolioSubmodule, ShortCourseSubmodule} from "../../../api/generated";
import CompletionResult from "../../submod/user/CompletionResult";
import moment from "moment";
import SubmoduleCompletionStatusBadge from "../../common/SubmoduleCompletionStatusBadge";
import Points from "../../common/Points";

type Props = {
    courseId: number,
    submodule: PortfolioSubmodule,
}

export default function MyCompletionHistory({courseId, submodule}: Props) {
    const fetcher = () => ApiFactory.Instance().PortfolioAPI().myPortfolioSubmoduleHistory(courseId, submodule.id)
    const {data: history, error} = useSWR<PortfolioCompletionList>(["user/portfolio/history", courseId, submodule.id], fetcher)

    if (error !== undefined) {
        return <ErrorHandler error={error}/>
    }

    if (history === undefined) {
        return <Loader/>
    }

    if (history.length == 0) {
        return <div className={'d-flex flex-row justify-content-center flex-fill'}>
            <span>Нет предыдущих версий</span>
        </div>
    }
    return <div>
        {history.map(c => <>
                <div className={'d-flex flex-row justify-content-between'}>
                    <div>{c.status && <SubmoduleCompletionStatusBadge status={c.status}/>}</div>
                    <div>{c.points && <Points points={c.points}/>}</div>
                </div>
                <CompletionResult
                    id={submodule.id}
                    name={submodule.name}
                    type={submodule.type}
                    typeData={submodule.course_submodule_type_data}
                    completionData={c.data}/>
                {c.teacher_comment && <div>
                    <h6 className={'mt-4 mb-4'}>Комментарий преподавателя</h6>
                    <span>{c.teacher_comment}</span>
                </div>}
                <div className={'d-flex flex-row justify-content-end'}>
                    {c.start_time &&
                    <small>{moment(c.start_time).format('DD.MM.YYYY HH:mm')}</small>}
                </div>
                <hr className={'hr--divider'}/>
            </>
        )}
    </div>
}
